import React, {useEffect, useContext} from 'react';
import {BreadsContext, PageContext, PageTitleContext} from "../../../../stores/PageStore";
import {Grid, Paper} from '@material-ui/core';
import CreateForm from './CreateForm'

const Create = () => {
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);

    useEffect(() => {
        const breads = [
            {
                link: '/admin/bbb/create',
                title: 'Yeni Sınıf Ekle',
                last: true
            },
        ];
        setBreads(breads)
        setPage(20.1)
        setPageTitle('Yeni Sınıf Ekle')

        // instance
        //     .get('/api/admin/bbb/room/list')
        //     .then(function (res) {
        //         setLoadContex(false)
        //     })
    }, [])

    const users = [];

    return <>

        <Paper className="paper-handler">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <h2>Canlı Sınıf Oluşturma</h2>
                    <p>
                        Ders Adı, Başlama Tarihi, Katılabilecek öğrencileri belirleyip eğitim dökümanlarınızı ekleyerek ileri tarihli bir sınıf oluşturabilir ve derse başlayabilirsiniz
                    </p>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <CreateForm/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    {/*  <UsersList/>*/}
                </Grid>

            </Grid>
        </Paper>
    </>

}

export default Create;
