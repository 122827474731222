import React, {useEffect, useContext, useState} from 'react';
import {BreadsContext, PageContext, PageTitleContext} from "../../../../stores/PageStore";
import {Paper, Grid, CircularProgress, Switch, Divider} from "@material-ui/core";
import instance from "../../../../apis";
import {toast} from "react-toastify";
import moment from 'moment'
import {Segment, Button} from 'semantic-ui-react';
import {KreditCardContext} from "./KreditCardStore";
import KreditCards from "./KreditCards";
import KreditCardInfo from "./KreditCardInfo";

const KreditCardConfig = () => {
    const [state, setState] = useContext(KreditCardContext)
    const [, setBreads] = useContext(BreadsContext);
    const [pageTitle, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    const [load, setLoad] = useState(false);
    useEffect(() => {
        const breads = [
            {
                link: null,
                title: 'Kredi Kartı Ayarları',
                last: true
            }
        ]
        setBreads(breads)
        setPageTitle('Kredi Kartı Ayarları')
        setPage(5.4)
    }, [])
    useEffect(() => {
        instance
            .get(`/api/admin/kredit/card/config`)
            .then(function (result) {
                setLoad(false)
                if (result.status == 200) {
                    if (result.data.item) {
                        const item = result.data.item;
                        setState(item)
                    }
                } else {
                    toast.warn('Veriler çekilirken bir hata meydana geldi, lütfen yeniden deneyin.')
                }
            })
            .catch(function () {
                toast.error('Bağlantı hatası, lütfen yeniden deneyin.')
            })
    }, [])


    if (load)
        return <CircularProgress/>

    function formSubmit(e) {
        e.preventDefault()
        const formData = new FormData(e.target)
        formData.append('card_id', state.card_id)
        formData.append('active', state.active ? 1 : 0)

        instance
            .post(`/api/admin/kredit/card/config`, formData)
            .then(function (res) {
                if (res.status == 200) {
                    toast.success('Değişiklikler kaydedildi.')
                } else {
                    toast.error('Veriler kaydedilemedi, lütfen tekrar deneyin')
                }
            })
            .catch(function (res) {
                toast.error('Bağlantı problemi, veriler gönderilemedi, lütfen tekrar deneyin')
            })
    }

    return <Paper className="paper-handler">
        <form method="post" onSubmit={formSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <h2>{pageTitle}</h2>
                </Grid>
                <Grid item xs={12} sm={12}><Divider/></Grid>
                <Grid item xs={12} sm={5}>
                    <Segment>
                        <Grid container spacing={2} alignItems={"center"} alignContent={"center"}>
                            <Grid item xs={2} sm={3}>
                                <Switch
                                    id={"active"}
                                    checked={state.active === 1 ? true : false}
                                    onChange={(e) => setState(state => ({...state, active: e.target.checked ? 1 : 0}))}
                                    color="primary"
                                    inputProps={{'aria-label': 'primary checkbox'}}
                                />
                            </Grid>
                            <Grid item xs={10} sm={9}><h4>Kredi kartı ile satışı aktif et!</h4></Grid>
                            <Grid item xs={12} sm={12}><KreditCards/></Grid>
                        </Grid>
                    </Segment>
                </Grid>
                <Grid item xs={12} sm={7}>
                    <KreditCardInfo/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12} sm={12}>
                        <Button type={"submit"} color={'blue'} size={"huge"} fluid>Değişiklikleri Kaydet</Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    </Paper>

}

export default KreditCardConfig;
