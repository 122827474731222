import React, {useState} from "react";
import {
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    IconButton,
    ListItemSecondaryAction
} from "@material-ui/core";
import RootRef from "@material-ui/core/RootRef";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import InboxIcon from "@material-ui/icons/Inbox";
import EditIcon from "@material-ui/icons/Edit";


export default function MontlyUpload() {
    const getItems = count =>
        Array.from({length: count}, (v, k) => k).map(k => ({
            id: `item-${k}`,
            primary: `item ${k}`,
            secondary: k % 2 === 0 ? `Whatever for ${k}` : undefined
        }));

    const [items, setItems] = useState(getItems(10))
    // fake data generator

// a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        // styles we need to apply on draggables
        ...draggableStyle,
        ...(isDragging && {
            background: "rgb(235,235,235)"
        })
    });

    const getListStyle = isDraggingOver => ({
        //background: isDraggingOver ? 'lightblue' : 'lightgrey',
    });


    function onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const newItems = reorder(
            items,
            result.source.index,
            result.destination.index
        );
        setItems(newItems)
    }

// Normally you would want to split things out into separate components.
// But in this example everything is just done in one place for simplicity
    if (!items) {
        return <></>
    }
    return <>
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <RootRef rootRef={provided.innerRef}>
                        <List dense style={getListStyle(snapshot.isDraggingOver)}>
                            {items.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                        <ListItem
                                            ContainerComponent="li"
                                            ContainerProps={{ref: provided.innerRef}}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <ListItemIcon>
                                                <InboxIcon/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={item.primary}
                                                secondary={item.secondary}
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton>
                                                    <EditIcon/>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </List>
                    </RootRef>
                )}
            </Droppable>
        </DragDropContext>
    </>
}
