import React, {useLayoutEffect} from 'react';
import {Grid,  } from '@material-ui/core';
import { connect } from 'react-redux'

import instance from '../../../../apis';
import {setUserList} from '../../../../redux/classroomAdd'

import SelectedUserList from './SelectedUserList';
import UserList from './UserList';
import { useParams, useHistory } from 'react-router-dom';

function Users (props) {
  const history = useHistory();
  const {id} = useParams();
    useLayoutEffect(() => {
      if(!id){
        instance.get(`/api/admin/classroom/user/list`)
            .then(function (res) {
                props.setUserList(res.data);
            })
        }
    }, []);


  return <>
    <Grid container >
      <Grid item xs={6} sm={6}>
        <h3>Öğrenci Listesi</h3>
        <UserList />
      </Grid>
      <Grid item xs={6} sm={6}>
      <h3>Sınıf Listesi</h3>
        <SelectedUserList />
      </Grid>
    </Grid>


    </>
}

const mapStateToProps = (state) => {
};

const mapDispatchToProps = {
	setUserList,
}


export default connect(mapStateToProps, mapDispatchToProps)(Users);
