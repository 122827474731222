import React from 'react';
import {Grid} from "@material-ui/core";
import {Link} from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

function KonuRows(props) {
    return props.konular.map(item => <Grid key={item.id} container className="acr-row" alignItems={"center"}>
        <Grid onClick={() => props.konuSec(item.id)} style={{cursor: "pointer"}} item xs={12} sm={10}>{item.name}</Grid>
        <Grid item xs={6} sm={1}>
            <Link to={`/admin/esinav/kazanims/add/${props.sinav_id}/${item.id}`}>
                <EditIcon className="acr-icon"/></Link>
        </Grid>
        <Grid item xs={6} sm={1}><DeleteIcon className="acr-icon" onClick={() => props.deleteRow(item.id)}/></Grid>
    </Grid>)
}

export default KonuRows;