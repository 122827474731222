import React, {useContext, useEffect, useState} from 'react';
import {Grid, Radio, RadioGroup, FormControlLabel, FormLabel, TextField, FormControl, Divider, MenuItem, CircularProgress, Switch, Tooltip} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import instance from "../../../../apis";
import {Button, Segment} from "semantic-ui-react";
import {Link, useHistory, useParams} from "react-router-dom"
import _ from 'lodash';
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {InitialsContext} from "../../../../Store";

const CreateForm = () => {
    const history = useHistory()
    let {room_id} = useParams()
    const [initials] = useContext(InitialsContext)
    const [servers, setServers] = useState([])
    const [bbb, setBbb] = useState({})
    const [load, setLoad] = useState(true)
    const [doluluk, setDoluluk] = useState(0)
    const [conf, setConf] = useState({})
    const [getClass, setGetClass] = useState([])
    const [tumOgrSayi, setTumOgrSayi] = useState(0)
    const [rezerve, setRezerve] = useState([]);
    const [categories, setCategories] = useState([{title: 'Kategori', parent_name: 'Alt Kategori'}]);
    const [anlikDoluluk, setAnlikDoluluk] = useState(0)
    const [cost, setCost] = useState(1)
    const dt = new Date();
    let baslama_saat = bbb.baslama_tarih ? moment(bbb.baslama_tarih).format('H') : dt.getHours();
    let baslama_dk = bbb.baslama_tarih ? moment(bbb.baslama_tarih).format('m') : Math.ceil(dt.getMinutes() / 5) * 5;

    dt.setHours(dt.getHours() + 2);
    let bitis_saat = bbb.bitis_tarih ? moment(bbb.bitis_tarih).format('H') : dt.getHours();
    let bitis_dk = bbb.bitis_tarih ? moment(bbb.bitis_tarih).format('m') : Math.ceil(dt.getMinutes() / 5) * 5;
    bitis_saat = bitis_saat == 25 ? 1 : bitis_saat;
    bitis_saat = bitis_saat == 24 ? 0 : bitis_saat;
    bitis_dk = bitis_dk == 60 ? 0 : bitis_dk;
    baslama_saat = baslama_saat == 25 ? 1 : baslama_saat;
    baslama_saat = baslama_saat == 24 ? 0 : baslama_saat;
    baslama_dk = baslama_dk == 60 ? 0 : baslama_dk;
    baslama_dk = baslama_dk == 65 ? 0 : baslama_dk;
    let baslama_tarih = bbb.baslama_tarih ? bbb.baslama_tarih : moment().format('YYYY-MM-DD')
    const [referansCarpan, setReferansCarpan] = useState(1)
    const sesCarpan = initials.sac.voice_carpan;
    const videoCarpan = initials.sac.video_carpan;
    let duzenle = false
    if (room_id && room_id != 0) {
        duzenle = true;
    }

    function getServersData() {
        instance
            .get(`/api/admin/bbb/servers`)
            .then(function (res) {
                setServers(res.data.items)
                setGetClass(res.data.classes)
                setCost(res.data.cost / 2)
                const ogrSayi = [];
                res.data.classes.map(item => ogrSayi.push(parseInt(item.users_count)))
                setTumOgrSayi(_.sum(ogrSayi))
                setAnlikDoluluk(res.data.anlik_doluluk)
                setLoad(false)
                setRezerve(res.data.rezerve)
                setCategories(res.data.categories)
                const conf = res.data.conf;
                if (conf != null) {
                    setConf(res.data.conf)
                }
                let newCarpan = 1;
                if (conf) {
                    if (conf.video == 1) {
                        newCarpan = newCarpan * videoCarpan;
                    }
                    if (conf.voice == 1) {
                        newCarpan = newCarpan * sesCarpan;
                    }
                    setReferansCarpan(newCarpan)
                }
            })
    }


    useEffect(() => {
        if (room_id && room_id != 0) {
            instance
                .get(`/api/admin/bbb/room/editV2/${room_id}`)
                .then(function (res) {
                    const data = res.data.item
                    setBbb(data)
                    let newCarpan = 1;
                    if (data.webcamsOnlyForModerator == 1) {
                        newCarpan = newCarpan * videoCarpan;
                    }
                    if (data.muteOnStart == 1) {
                        newCarpan = newCarpan * sesCarpan;
                    }
                    let baslama_saat = moment(data.baslama_tarih).format('H')
                    let baslama_dk = moment(data.baslama_tarih).format('m')
                    let bitis_saat = moment(data.bitis_tarih).format('H')
                    let bitis_dk = moment(data.bitis_tarih).format('m');
                    instance
                        .get(`/api/admin/bbb/room/rezerve/times`, {
                            params: {
                                tarih: baslama_tarih,
                                baslama_saat: baslama_saat,
                                bitis_saat: bitis_saat,
                                bitis_dk: bitis_dk,
                                baslama_dk: baslama_dk,
                            }
                        })
                        .then(function (res) {
                            setDoluluk(parseInt(res.data.rezerve))

                        })
                    setReferansCarpan(newCarpan)
                    getServersData()
                })
        } else {
            getServersData()
            instance
                .get(`/api/admin/bbb/room/rezerve/times`, {
                    params: {
                        tarih: baslama_tarih,
                        baslama_saat: baslama_saat,
                        bitis_saat: bitis_saat,
                        bitis_dk: bitis_dk,
                        baslama_dk: baslama_dk,
                    }
                })
                .then(function (res) {
                    setDoluluk(parseInt(res.data.rezerve))

                })
        }
    }, [room_id])


    if (bbb.baslama_tarih) {
//TODO son 15 dk kala ders düzenlemeyi engelliyoruz

        /* if (moment(bbb.baslama_tarih).format('DD/MM/YYYY') == moment().format('DD/MM/YYYY')) {
             if (moment(bbb.baslama_tarih).format('HH') == moment().format('HH')) {
                 if (parseInt(moment(bbb.baslama_tarih).format('m')) < parseInt(moment().format('m')) + 15) {
                     alert('Son 15dk kala ders düzenlenemez lütfen yeni ders oluşturun.')
                     history.push(`/admin/bbb/room/list`)
                 }
             }
         }*/
    }
    if (load)
        return <CircularProgress/>

    // useEffect(() => {
    //     setInputs({'baslama_tarih': new Date()});
    //     setInputs({'users_tumu': 0});

    // });

    const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]


    const getServer = bbb.server_id ? servers.filter(x => x.id == bbb.server_id)[0] : servers.filter(x => x.id == 1)[0]


    function ServerSettingView() {
        const cap = getServer.capacity
        const dks = [
            {dk: 0, capacity: cap},
            {dk: 5, capacity: cap},
            {dk: 10, capacity: cap},
            {dk: 15, capacity: cap},
            {dk: 20, capacity: cap},
            {dk: 25, capacity: cap},
            {dk: 30, capacity: cap},
            {dk: 35, capacity: cap},
            {dk: 40, capacity: cap},
            {dk: 45, capacity: cap},
            {dk: 50, capacity: cap},
            {dk: 55, capacity: cap}
        ]
        const [rezerveLoad, setRezerveLoad] = useState(false)
        const [states, setStates] = useState({
            katilimci_sayisi: bbb.katilimci_sayisi ? bbb.katilimci_sayisi : 5,
            baslama_dk: baslama_dk,
            baslama_saat: baslama_saat,
            bitis_saat: bitis_saat,
            bitis_dk: bitis_dk,
            server: getServer,
            max: ((cap - doluluk) / referansCarpan).toFixed(),
            classes: getClass,
            doluluk: doluluk,
            hours: hours,
            dks_baslama: dks,
            dks_bitis: dks,
            dk: 0,
            sinif: bbb.classroom_id ? bbb.classroom_id : conf.sinif_id ? conf.sinif_id : 0,
            originMax: getServer.capacity,
            timers: rezerve,
            dks: dks,
            sonraki_gun: false,
            baslama_tarih: baslama_tarih,
            webcamsOnlyForModerator: bbb.webcamsOnlyForModerator ? bbb.webcamsOnlyForModerator : conf.video ? conf.video : 0,
            muteOnStart: bbb.muteOnStart ? bbb.muteOnStart : conf.voice ? conf.voice : 0,
            carpan: referansCarpan,
        })

        function roomRezerveTimes({baslama_tarih = null, baslama_dk = null, bitis_dk = null, baslama_saat = null, bitis_saat = null, sonraki_gun = false, server = null, originMax = null}) {
            if (!room_id && room_id == 0) {
                room_id = null
            }
            baslama_tarih = baslama_tarih ? baslama_tarih : states.baslama_tarih
            sonraki_gun = sonraki_gun ? sonraki_gun : states.sonraki_gun
            baslama_saat = baslama_saat ? baslama_saat : states.baslama_saat
            baslama_saat = baslama_saat == 24 ? 0 : baslama_saat
            baslama_saat = baslama_saat == 25 ? 1 : baslama_saat
            bitis_saat = bitis_saat ? bitis_saat : states.bitis_saat
            if (bitis_saat >= 24) {
                sonraki_gun = true
            }
            bitis_saat = bitis_saat == 24 ? 0 : bitis_saat
            bitis_saat = bitis_saat == 25 ? 1 : bitis_saat

            bitis_dk = bitis_dk || bitis_dk == 0 ? bitis_dk : states.bitis_dk
            baslama_dk = baslama_dk || baslama_dk == 0 ? baslama_dk : states.baslama_dk
            server = server ? server : states.server
            originMax = originMax ? originMax : states.originMax
            setRezerveLoad(true)
            instance
                .get(`/api/admin/bbb/room/rezerve/times`, {
                    params: {
                        tarih: baslama_tarih,
                        baslama_saat: baslama_saat,
                        bitis_saat: bitis_saat,
                        bitis_dk: bitis_dk,
                        baslama_dk: baslama_dk,
                        sonraki_gun: sonraki_gun,
                        room_id: room_id,
                    }
                })
                .then(function (res) {
                    setStates(states => ({
                        ...states,
                        doluluk: res.data.rezerve,
                        max: calcCapacity(originMax - calcCapacity(res.data.rezerve)),
                        baslama_tarih: baslama_tarih,
                        baslama_saat: baslama_saat,
                        bitis_saat: bitis_saat,
                        bitis_dk: bitis_dk,
                        baslama_dk: baslama_dk,
                        sonraki_gun: sonraki_gun,
                        server: server,
                        originMax: originMax,
                    }))
                    setRezerveLoad(false)

                })
        }

        function LoadRezerveView() {
            if (rezerveLoad)
                return <CircularProgress style={{width: 24, height: 24}}/>
            return <></>
        }


        function calcCapacity(capacity) {
            return parseInt((capacity / states.carpan).toFixed())
        }


        function UnixTarihHesapla(tarih, saat, dk, sonraki_gun = null) {
            let newTarih = null
            if (sonraki_gun) {
                newTarih = new Date(tarih)
                newTarih.setDate(newTarih.getDate() + 1)
                tarih = moment(newTarih).format('YYYY-MM-DD');
            }
            return moment(`${tarih} ${saat}:${dk}`).unix()
        }

        function TahminiUcretView() {
            function fiyatHesapla() {
                const baslama = moment(states.baslama_tarih).format('YYYY-MM-DD')
                const sure = ((UnixTarihHesapla(baslama, states.bitis_saat, states.bitis_dk, states.sonraki_gun) - UnixTarihHesapla(baslama, states.baslama_saat, states.baslama_dk)) / 60).toFixed()
                if (states.server.id === 1 && sure > 120) { // 2dk da bir bktğı için 2 saatten büyük hemen başla sunucusu
                    const cost_1 = (cost * states.katilimci_sayisi * states.carpan * 120).toFixed(2)
                    const cost_2 = (cost * states.katilimci_sayisi * states.carpan * (sure - 120) * 3).toFixed(2)
                    const total = parseFloat(cost_1) + parseFloat(cost_2);
                    return total.toFixed(2);
                }
                let price = cost * states.katilimci_sayisi * states.carpan * sure;
                price = price < 0 ? 0 : price;
                return price.toFixed(2)
            }

            return <Segment style={{textAlign: "center", paddingTop: 28, paddingBottom: 28}}>
                <span style={{fontSize: 26,}}>Tahmini <span style={{color: "mediumseagreen"}}>{fiyatHesapla()} ₺</span></span><LoadRezerveView/>
            </Segment>
        }

        function ServerViews() {
            function serverChange(item) {
                let dt = new Date();
                let baslama_saat = dt.getHours();
                let baslama_dk = dt.getMinutes();
                dt.setHours(dt.getHours() + 2);
                let bitis_saat = dt.getHours();
                let bitis_dk = dt.getMinutes();
                if (item.id === 2) {
                    baslama_saat = dt.getHours();
                    baslama_dk = dt.getMinutes();
                    dt.setHours(dt.getHours() + 2);
                    bitis_saat = dt.getHours();
                    bitis_dk = dt.getMinutes();
                }
                bitis_saat = bitis_saat == 25 ? 1 : bitis_saat;
                bitis_saat = bitis_saat == 0 ? 24 : bitis_saat;
                bitis_dk = bitis_dk == 60 ? 0 : bitis_dk;
                baslama_saat = baslama_saat == 25 ? 1 : baslama_saat;
                baslama_saat = baslama_saat == 0 ? 24 : baslama_saat;
                baslama_dk = baslama_dk == 60 ? 0 : baslama_dk;

                if (duzenle) {
                    roomRezerveTimes({server: item, originMax: item.capacity})
                } else {
                    roomRezerveTimes({server: item, originMax: item.capacity, baslama_dk: baslama_dk, baslama_saat: baslama_saat, bitis_saat: bitis_saat, bitis_dk: bitis_dk})
                }
            }

            return servers.map(item => <Grid key={item.id} item xs={12} sm={4}>
                    <Button onClick={() => serverChange(item)} color={states.server.id == item.id ? 'teal' : 'default'} size="huge">{item.title}</Button>
                </Grid>
            )
        }

        function TimeSelectView() {
            if (!states.server.id) {
                return <></>
            }
            if (states.server.id === 1) {
                return <Grid item xs={12} sm={12}>
                    <Segment>
                        <Grid container spacing={2} alignContent={"center"} alignItems={"center"}>
                            <Grid item xs={12} sm={3}>
                                <BitisSaatiView/>
                            </Grid>
                            <Grid item xs={12} sm={6}> 2 saati geçen anlık canlı yayınlarda, sonraki saatler için fiyat <span style={{color: "red"}}> 3 kat</span> artar.
                                Sistem sağlığı için 2 saat sonra yayını durdurup, sunucunun
                                kapasitesine göre tekrar başlatabilirsiniz.</Grid>
                            <Grid item xs={12} sm={3}>
                                <TahminiUcretView/>
                            </Grid>
                        </Grid>
                    </Segment>
                </Grid>
            }


            function BitisSaatiView() {
                function bitisSaatChange(e) {
                    const val = e.target.value
                    if (!states.sonraki_gun) {
                        if (states.server.id === 1) {
                            if (((UnixTarihHesapla(states.baslama_tarih, val, states.bitis_dk) <= moment().unix())))
                                return alert('Bitiş zamanı şuanki zamandan küçük olamaz')
                        } else {
                            if (((UnixTarihHesapla(states.baslama_tarih, val, states.bitis_dk) <= (UnixTarihHesapla(states.baslama_tarih, states.baslama_saat, states.baslama_dk)))))
                                return alert('Bitiş saati başlangıç saatinden küçük olamaz')
                        }
                    }
                    roomRezerveTimes({bitis_saat: val})

                }

                function bitisDkChange(e) {
                    const val = e.target.value
                    if (((UnixTarihHesapla(states.baslama_tarih, states.bitis_saat, val) <= (UnixTarihHesapla(states.baslama_tarih, states.baslama_saat, states.baslama_dk)))))
                        return alert('Bitiş saati başlangıç saatinden küçük veya eşit olamaz')

                    /*   const max_capacity = states.dks_bitis.filter(x => x.dk === val)[0].capacity;
                       let katilimci_sayisi = states.katilimci_sayisi
                       let max = states.max
                       if (states.katilimci_sayisi > max_capacity) {
                           katilimci_sayisi = max_capacity
                           max = max_capacity
                       }*/
                    roomRezerveTimes({bitis_dk: val})

                }

                function sonrakiGunChange(e) {
                    const checked = e.target.checked
                    roomRezerveTimes({sonraki_gun: checked})

                }

                const dt = new Date(states.baslama_tarih)
                dt.setDate(dt.getDate() + 1);
                return <Segment>
                    <Grid container spacing={1} alignItems={"center"} justify={"space-evenly"} alignContent={"center"}>
                        <Grid item xs={12} sm={12}>
                            <Grid container justify={"space-between"}>
                                <Grid item xs={4} sm={4}>
                                    <h5>Bitiş Saati</h5>
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    <Tooltip title={`Sonraki Gün:  ${moment(dt).format('DD.MM.YYYY')}`}>
                                        <Switch
                                            checked={states.sonraki_gun}
                                            onChange={sonrakiGunChange}
                                            size={"small"}/>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                value={states.bitis_saat}
                                size={"small"}
                                variant={"outlined"}
                                select
                                onChange={bitisSaatChange}
                                name="bitis_saat"
                            >
                                {hours.map(hour => <MenuItem value={hour}>{hour}</MenuItem>)}
                            </TextField>
                            <TextField
                                value={Math.ceil((states.bitis_dk) / 5) * 5}
                                size={"small"}
                                variant={"outlined"}
                                select
                                name="bitis_dk"
                                onChange={bitisDkChange}
                            >
                                {states.dks_bitis.map(dk => <MenuItem value={dk.dk}>{`${dk.dk}`}</MenuItem>)}
                            </TextField>
                        </Grid>
                    </Grid>
                </Segment>
            }

            function BaslamaTarihView() {
                return <KeyboardDatePicker
                    variant="outlined"
                    margin="normal"
                    id="baslama_tarih"
                    name="baslama_tarih"
                    label="Başlama Tarihi"
                    format="dd/MM/yyyy"
                    value={states.baslama_tarih}
                    onChange={e => {
                        roomRezerveTimes({baslama_tarih: e})
                    }
                    }
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            }

            function baslamaSaatChange(e) {

                const val = e.target.value;
                if (((UnixTarihHesapla(states.baslama_tarih, val, states.baslama_dk) - moment().unix()) / 60) < 120)
                    return alert('İleri tarihli canlı yayın en az 2 saat sonra olabilir.')
                if (val > states.bitis_saat)
                    return roomRezerveTimes({baslama_saat: val, bitis_saat: val + 2})
                if (val === states.bitis_saat && states.bitis_dk < states.baslama_dk)
                    return roomRezerveTimes({baslama_saat: val, bitis_saat: val + 2})
                roomRezerveTimes({baslama_saat: val})
            }

            function baslamaDkChange(e) {
                const val = e.target.value;
                if (((UnixTarihHesapla(states.baslama_tarih, states.baslama_saat, val) - moment().unix()) / 60) < 120)
                    return alert('İleri tarihli canlı yayın en az 2 saat sonra olabilir.')

                /*  const max_capacity = states.dks_baslama.filter(x => x.dk === val)[0].capacity;
                  let katilimci_sayisi = states.katilimci_sayisi
                  let max = states.max
                  if (states.katilimci_sayisi > max_capacity) {
                      katilimci_sayisi = max_capacity
                      max = max_capacity
                  }*/
                roomRezerveTimes({baslama_dk: e.target.value})

            }

            return <Grid item xs={12} sm={12}>
                <Segment>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container spacing={2} alignContent={"center"} alignItems={"center"}>
                            <Grid item xs={12} sm={3}>
                                <Segment style={{paddingTop: 24, paddingBottom: 24}}>
                                    <BaslamaTarihView/>
                                </Segment>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Segment>
                                    <Grid container spacing={1} alignItems={"center"} justify={"space-evenly"} alignContent={"center"}>
                                        <Grid item xs={12} sm={12}><h5>Başlama Saati</h5></Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                value={states.baslama_saat}
                                                size={"small"}
                                                variant={"outlined"}
                                                select
                                                name="baslama_saat"
                                                onChange={baslamaSaatChange}
                                            >
                                                {hours.map(hour => <MenuItem value={hour}>{hour}</MenuItem>)}
                                            </TextField>
                                            <TextField
                                                value={Math.ceil((states.baslama_dk) / 5) * 5}
                                                size={"small"}
                                                variant={"outlined"}
                                                select
                                                onChange={baslamaDkChange}
                                                name="baslama_dk"
                                            >
                                                {states.dks_baslama.map(dk => <MenuItem value={dk.dk}>{`${dk.dk}`}</MenuItem>)}
                                            </TextField>
                                        </Grid>

                                    </Grid>
                                </Segment>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <BitisSaatiView/>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TahminiUcretView/>
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                </Segment>
            </Grid>
        }

        function MaxKatilimciView() {
            function changeClassCount(e) {
                const value = e.target.value;
                if (value === 0) {
                    return;
                }
                if (value === -1) {
                    return setStates(states => ({...states, sinif: value, katilimci_sayisi: Math.ceil(parseInt(tumOgrSayi) / 5) * 5}))
                }
                const sinif = states.classes.filter(x => x.id == value)
                const katilimci = sinif ? Math.ceil((sinif[0].users_count / 5)) * 5 : 0;
                setStates(states => ({...states, sinif: value, katilimci_sayisi: katilimci}))
            }

            function handleMaxValueChange(e) {
                const value = e.target.value;
                if (value > states.max) {
                    setStates(states => ({...states, katilimci_sayisi: states.max}))
                } else {
                    setStates(states => ({...states, katilimci_sayisi: value}))
                }
            }

            function maxValues() {
                let maxValues = []
                for (let i = 1; i <= states.max; i++) {
                    if (i % 5 == 0)
                        maxValues.push(i)
                }
                if (maxValues[maxValues.length - 1] < states.max)
                    maxValues.push(states.max)
                return maxValues;
            }

            function KonferansOtherSetting() {
                if (states.muteOnStart == 1) {
                    return <>
                        <Grid container>
                            <Grid item xs={12} sm={4}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend" style={{fontWeight: 'bold', color: '#000', marginBottom: 10}}>Katılımcıların Kamera Ayarı?</FormLabel>
                                    <RadioGroup aria-label="gender" name="webcamsOnlyForModerator" value={states.webcamsOnlyForModerator}
                                                onChange={(e) => {
                                                    const val = e.target.value;
                                                    const newCarpan = val == 1 ? videoCarpan * states.carpan : Math.ceil(states.carpan / videoCarpan);
                                                    const max = parseInt(val) === 1 ? (states.max / videoCarpan).toFixed() : states.max * videoCarpan
                                                    setStates(states => ({
                                                        ...states, webcamsOnlyForModerator: parseInt(val), max: max, carpan: newCarpan
                                                    }))
                                                }}>
                                        <FormControlLabel value={0} control={<Radio/>} label="Hayır"/>
                                        <FormControlLabel value={1} control={<Radio/>} label="Evet"/>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} sm={8}>
                                Konferans yönetimi ile tüm katılımcıların kameralara ve ses açmaları sağlanabilir. Fakat bu işlem sunucu üzerindeki yükü arttırdığı için normal tarifenin
                                <span style={{color: "red"}}> {states.carpan} kat</span> fazlasıdır.
                            </Grid>
                        </Grid>
                    </>

                } else {
                    return <>
                        Yalnızca eğitimci/modaratör kullanıcısının kamera ve ses açmasına izin verilir.
                    </>
                }
            }

            return <><Grid item xs={12} sm={6}>
                <TextField
                    name="sinif"
                    size={"small"}
                    required
                    fullWidth
                    variant={"outlined"}
                    defaultValue={states.sinif}
                    helperText="Yada bir sınıf seçin"
                    onChange={changeClassCount}
                    select>
                    <MenuItem value={0}>Boş Sınıf</MenuItem>
                    <MenuItem value={-1}>Tüm Sınıflar/Tüm Öğrenciler</MenuItem>
                    {states.classes.map(item => <MenuItem value={item.id}>{item.ad}</MenuItem>)}
                </TextField>

            </Grid>
                <Grid item xs={6} sm={6}>
                    <TextField
                        select
                        defaultValue={states.katilimci_sayisi}
                        size={"small"}
                        fullWidth
                        onChange={handleMaxValueChange}
                        required
                        variant={"outlined"}
                        name="katilimci_sayisi"
                        placeholder="Katılımcı sayısı, ücretlendirmede kullanılır"
                        helperText="Katılımcı sayısından daha fazla kişiye izin verilmez"
                    >
                        {maxValues().map(item => <MenuItem value={item}>{item}</MenuItem>)}

                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Segment>
                        <Grid container>
                            <Grid item xs={12} sm={3}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend" style={{fontWeight: 'bold', color: '#000', marginBottom: 10}}>Konferans Olacak mı</FormLabel>
                                    <RadioGroup aria-label="gender" name="muteOnStart"
                                                value={states.muteOnStart}
                                                onChange={(e) => {
                                                    console.log(states.muteOnStart)
                                                    const val = e.target.value
                                                    let webcamsOnlyForModerator = states.webcamsOnlyForModerator;
                                                    if (val == 0) {
                                                        webcamsOnlyForModerator = 0;
                                                        states.carpan = states.carpan / initials.sac.video_carpan
                                                    }
                                                    const newCarpan = val == 1 ? sesCarpan * states.carpan : Math.ceil(states.carpan / sesCarpan);
                                                    let max = parseInt(val) === 1 ?
                                                        (states.max / sesCarpan).toFixed() :
                                                        states.max * sesCarpan * (states.webcamsOnlyForModerator === 1 ? initials.sac.video_carpan : 1)
                                                    setStates(states => ({
                                                        ...states, max, webcamsOnlyForModerator, muteOnStart: parseInt(val), carpan: newCarpan
                                                    }))
                                                }}>
                                        <FormControlLabel value={0} control={<Radio/>} label="Hayır"/>
                                        <FormControlLabel value={1} control={<Radio/>} label="Evet"/>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <KonferansOtherSetting/>
                            </Grid>
                        </Grid>
                    </Segment>
                </Grid>
            </>
        }


        return <Grid container spacing={2} alignItems={"center"} alignContent={"center"}>
            <Grid item xs={12} sm={12}>
                <Grid container justify="space-around" alignItems="center">
                    <ServerViews/>
                    <Grid item xs={6} sm={2}><h3>MAX KATILIMCI:</h3></Grid>
                    <Grid item xs={6} sm={2}><h2>{states.max} <LoadRezerveView/></h2></Grid>
                </Grid>

            </Grid>
            <TimeSelectView/>
            <MaxKatilimciView/>
            <input type="hidden" value={states.sonraki_gun} name="sonraki_gun"/>
            <input type="hidden" value={states.server.id} name="server_id"/>
            <input type="hidden" value={states.muteOnStart} name="konferans"/>
            <input type="hidden" value={room_id} name="id"/>
        </Grid>
    }

    function OtherConfigView() {
        const [chat, setChat] = useState(bbb.lockSettingsDisablePrivateChat ? bbb.lockSettingsDisablePrivateChat : conf.chat ? conf.chat : 0)
        return <>
            <Grid item xs={12} sm={4}>
                <FormControl component="fieldset">
                    <FormLabel component="legend" style={{fontWeight: 'bold', color: '#000', marginBottom: 10}}>Katılımcılar Arası Özel Sohbet</FormLabel>
                    <RadioGroup aria-label="gender" name="lockSettingsDisablePrivateChat" value={chat}
                                onChange={(e) => {
                                    setChat(parseInt(e.target.value))
                                }}>
                        <FormControlLabel value={0} control={<Radio/>} label="Kapalı"/>
                        <FormControlLabel value={1} control={<Radio/>} label="Açık"/>
                    </RadioGroup>
                </FormControl>
            </Grid>
        </>
    }

    function handleSubmitForm(e) {
        e.preventDefault()
        const formData = new FormData(e.target)
        instance
            .post(`/api/admin/bbb/room/createV2`, formData)
            .then(function (res) {
                history.push(`/admin/bbb/room/file/upload/${res.data.data.id}/${res.data.data.transfer_key}`);
            })
    }

    function CategoriesView() {
        const [catState, setCatState] = useState({
            selectCategory: room_id && room_id != 0 ? bbb.category_id ? true : false : true,
            category: bbb.category_id ? categories.filter(x => x.id === bbb.category_id)[0] : conf.category_id ? categories.filter(x => x.id === conf.category_id)[0] : categories[0]
        })

        function SelectCategoryView() {

            if (!catState.selectCategory) {
                return <>
                    <TextField
                        required
                        size={"small"}
                        variant="outlined"
                        label="Canlı sınıf ismi"
                        margin="normal"
                        name={"room_ad"}
                        defaultValue={bbb.room_ad}
                        fullWidth={true}
                        autoFocus
                    />
                </>
            }

            function selectCategory(e, v) {
                setCatState(catState => ({...catState, category: v}))
            }

            return <><Autocomplete
                size={"small"}

                options={categories}
                getOptionLabel={(option) => `${option.parent_name} - ${option.title}`}
                filterSelectedOptions
                onChange={selectCategory}
                value={catState.category}
                renderInput={(params) => (
                    <TextField
                        required
                        {...params}
                        variant="outlined"
                        label="Kategori Seçimi"
                        name="room_ad"
                    />
                )}
            />
                <input type="hidden" name="category_id" value={catState.category ? catState.category.id : 0}/>
            </>
        }

        function switchChange(e) {
            const checked = e.target.checked
            setCatState(catState => ({...catState, selectCategory: checked}))
        }

        function SwitchDesc() {
            if (catState.selectCategory)
                return <> Videonuz otomatik olarak seçeceğiniz kategoriye kaydedilir.<Link to={`/admin/category/new/0`}></Link>
                </>
            return <>Video kaydedilir, fakat herhangi bir kategoriye dahil edilmez.</>

        }

        return <Segment>
            <Grid container alignItems={"center"} alignContent={"center"} spacing={2}>
                <Grid item xs={4} sm={4}>
                    <SelectCategoryView/>
                </Grid>
                <Grid item xs={8} sm={8}>
                    <Grid container alignItems={"center"} alignContent={"center"} spacing={2}>
                        <Grid item xs={2} sm={2}>
                            <Switch

                                checked={catState.selectCategory}
                                onChange={switchChange}
                                size={"small"}/>
                        </Grid>
                        <Grid item xs={10} sm={10}>
                            <SwitchDesc/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        size={"small"}
                        variant="outlined"
                        label="Ders - Video İsmi"
                        margin="normal"
                        name={"room_aciklama"}
                        defaultValue={bbb.room_aciklama}
                        fullWidth={true}
                    />
                </Grid>

            </Grid>
        </Segment>
    }

    return <form onSubmit={handleSubmitForm}>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                {/* <Snackbar
                style={{backgroundColor: 'teal', color: 'coral'}}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={validation.open}
                autoHideDuration={6000}
                onClose={() => {
                    setValidation({open: false, msg: ''})
                }}
                message={validation.msg}/>*/}

            </Grid>
            <Grid item xs={12} sm={12}>
                <ServerSettingView/>
            </Grid>
            <Grid item xs={12} sm={12}>
                <CategoriesView/>
            </Grid>
            <Grid item xs={12} sm={12}><Divider/></Grid>
            <OtherConfigView/>
            <Button
                size={"huge"}
                type={"submit"}
                fluid
                color={"blue"}
            >KAYDET ve EĞİTİM DÖKÜMANLARINI EKLEME ADIMINA GEÇ </Button>
        </Grid>
    </form>;


}
export default CreateForm
