import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import instance from "../apis";
import {toast, ToastContainer} from 'react-toastify';
import {Link} from 'react-router-dom';
import {CircularProgress} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(https://source.unsplash.com/collection/8885433)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));
const Login = (props) => {
    let checkLogin = 'false'
    let token = ''
    try {
        checkLogin = localStorage.getItem('login')
        token = localStorage.getItem('token');

    } catch (e) {

    }
    const history = props.history;
    if (checkLogin == "true" && token != 'null' && token != null && token != '') {
        window.location.replace("/");
    }

    function MadeWithLove() {
        return (
            <Typography variant="body2" color="textSecondary" align="center">
                {'7/24 Online Eğitim Platformu'}
            </Typography>
        );
    }

    const classes = useStyles();
    const LoadForm = () => {
        const [load, setLoad] = useState()


        function FormLogin() {
            const [inputs, setInputs] = useState({})
            const handleSubmit = () => {
                setLoad(true)
                instance.post('/oauth/token', {
                    password: inputs.password,
                    username: inputs.email,
                    client_id: 2,
                    client_secret: "bBFy2lcvjrI0RpOV0OVfKsqJZKob0QuRaO1LAYTX",
                    grant_type: "password",
                })
                    .then(function (res) {
                        if (res.status == 401) {
                            toast.error('Girilen bilgiler hatalı')
                            return setLoad(false)
                        }

                        if (res.data.access_token) {
                            localStorage.setItem('token', res.data.access_token);
                            localStorage.setItem('login', true);
                            const d = new Date();
                            localStorage.setItem('lifeTime', d.getTime());
                            instance.get('/api/get/user/login', {
                                headers: {'Authorization': "Bearer " + res.data.access_token}
                            })
                                .then(function (res) {
                                        const roles = [];
                                        console.log(res.data.user)
                                        res.data.user.roles.map(role => {
                                            roles.push(role.name)
                                        })
                                        localStorage.setItem('login_token', res.data.login_token);
                                        localStorage.setItem('roles', roles);
                                        localStorage.setItem('user', res.data);
                                        localStorage.setItem('user_id', res.data.id);
                                        history.push('/login')
                                        setLoad(false)
                                    }
                                )
                        } else {
                            toast.error('Girilen bilgiler hatalı')
                            setLoad(false)
                        }
                    })
                    .catch(function (res) {
                            if (res.indexOf != -1) {
                                toast.error('Girilen bilgiler hatalı')
                                return setLoad(false)
                            }
                            toast.error('Bir hata meydana geldi, internet bağlantınızı kontrol edin.')
                            setLoad(false)
                        }
                    )
            }

            const handleInputChange = (event) => {
                let nam = event.target.name;
                let val = event.target.value;
                setInputs(inputs => ({...inputs, [nam]: val}));
            }
            return <form className={classes.form} onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={'Email Address'}
                            name="email"
                            autoComplete="email"
                            autoFocus
                            defaultValue={inputs.email}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={'Password'}
                            type="password"
                            id="password"
                            defaultValue={inputs.password}
                            onChange={handleInputChange}
                            autoComplete="current-password"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            GİRİŞ YAP
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Link to={'/repass'} variant="body2">
                            Şifreni hatırlamıyor musun?
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to={"/register"} variant="body2">
                            Hesabın yok mu? Hemen üye ol
                        </Link>
                    </Grid>
                </Grid>
                <Box mt={5}>
                    <MadeWithLove/>
                </Box>
            </form>
        }

        if (load) {
            return <Grid alignItems={"center"} item container xs={12} sm={8} md={5} component={Paper} elevation={6} alignContent={"center"} justify={"center"}>
                <Grid item>
                    <CircularProgress/>
                </Grid>
            </Grid>
        } else {
            return <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={"paper"} style={{textAlign: "center"}}>
                    <Avatar className={classes.avatar} style={{textAlign: "center"}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Giriş Yap
                    </Typography>
                    <FormLogin/>
                </div>
            </Grid>
        }
    }


    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={false} sm={4} md={7} className={classes.image}/>
            <LoadForm/>
            <ToastContainer/>
        </Grid>
    );


}

export default Login;
