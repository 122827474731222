import React, {useEffect, useState, useContext} from 'react';
import TextField from "@material-ui/core/TextField";
import {toast} from "react-toastify";
import {Paper, MenuItem, CircularProgress, Grid, Switch} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import instance from '../../../apis';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {useParams, useHistory} from 'react-router-dom';
import {BreadsContext, PageContext, PageTitleContext} from "../../../stores/PageStore";
import CropImageViewV2 from "../../CropImageViewV2";
import ProductPage from "../productPages/ProductPage";
import {EditorStore} from "../productPages/EditorStore";
import moment from "moment";
import {Alert} from "@material-ui/lab";
import {Button} from 'semantic-ui-react';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(5, 2),
            color: theme.palette.text.secondary,
        },
        icon: {
            cursor: "pointer",
            fontSize: 18,
            float: 'right',
            "&:hover": {
                color: "red"
            }
        },
        editFile: {
            backgroundColor: "#e3e7f1"
        },
        row: {},
        cropView: {
            position: "relative",
            height: 200
        },
        context: {
            fontWeight: 300,
            color: "#979797"
        },
        divider: {
            marginBottom: 10

        }
    }))
;


const Category = () => {
    const {id} = useParams()
    const history = useHistory()
    const classes = useStyles();
    const [getId, setGetId] = useState(id)
    const [load, setLoad] = useState(true)
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState({})
    const [, setBreads] = useContext(BreadsContext);
    const [pageTitle, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    useEffect(() => {
        instance
            .get(`/api/admin/category/all`)
            .then(function (res) {
                handleCategories(res.data.items)
                setLoad(false)
            })
        const breads = [
            {
                link: null,
                title: 'Kategoriler',
                last: true
            }
        ]
        setBreads(breads)
        setPageTitle('Kategoriler')
        setPage(301)

    }, [])

    function handleCategories(datas) {
        try {
            const data = [];
            datas.map(item => {
                if (item.id)
                    data.push(item)
                item.childs.map(child => {
                    if (child.id) {
                        data.push(child)
                    }
                })
            })
            if (getId != 0) {
                const getCategory = data.filter(x => x.id == getId)[0];
                if (getCategory)
                    setCategory(getCategory)
            }
            return setCategories(data)
        } catch (e) {

        }
    }

    if (load)
        return <CircularProgress/>
    const FormView = () => {
        const [coverImg, setCategoryImg] = useState(category.cover_img)
        const fileSelect = () => {
            const file = document.getElementById("file");
            file.click()
        }

        function coverDelete() {
            if (global.confirm('Silmek istediğinizden emin misiniz?'))
                instance
                    .delete(`/api/admin/cover/img/${category.id}`)
                    .then(function (res) {
                            setCategoryImg(null)
                        }
                    )
        }

        const handleSubmit = async event => {
            setLoad(true)
            event.preventDefault();
            const data = new FormData(event.target);
            const config = {headers: {'Content-Type': 'multipart/form-data'}};
            instance
                .post(`/api/admin/category/${id}`, data, config)
                .then(function (res) {
                        toast.success("Başarıyla Kaydedildi")
                        setCategory(res.data.item)
                        history.push(`/admin/category/new/${res.data.item.id}`)
                        setLoad(false)
                    }
                )
        }


        function ParentCategoriesItem(item) {

            if (category.id != item.id) {
                if (item.parent_id == null) {
                    return <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                }
                return <></>
            }
            return <></>

        }

        const Cover = () => {
            if (coverImg) {
                return <>
                    <Grid item xs={12} sm={4} style={{position: "relative"}}>
                        <img style={{width: 345}} src={`https://lms.rotasinav.com/cover/img/${category.site_id}/${category.cover_img}`}/>
                        <Button
                            style={{position: "absolute", top: 0, right: 0}}
                            size={"small"}
                            variant={"contained"}
                            onClick={() => coverDelete()}
                            color={"secondary"}
                        >
                            <DeleteForeverIcon/>
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={8}>

                    </Grid>
                </>
            } else {
                return <>
                    <Grid container justify={"center"} item xs={12} sm={4}>
                        <CropImageViewV2 width={345} aspect={1.9} file_id={"file"}/>
                    </Grid>
                    <Grid item xs={12} sm={8}>

                    </Grid>
                </>
            }
        }

        function BBBCategory() {
            const [check, setCheck] = useState(category.bbb == 1 ? true : false)

            function BbbPrice() {
                if (!check)
                    return <>Bu kategoride canlı yayın yok </>

                return <Grid container spacing={2} alignContent={"center"} alignItems={"center"}>
                    <Grid item xs={8} sm={8} style={{textAlign: "right"}}>
                        Canlı yayın ek ücretini belirleyin. Burada belirleyeceğiniz miktar kategori fiyatınıza eklenerek müşteriye sunulur.
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <TextField
                            size={"small"}
                            variant={"outlined"}
                            label="Ek ücret"
                            margin="normal"
                            name={"bbb_price"}
                            defaultValue={category.bbb_price}
                            fullWidth={true}
                            type="number"
                        />
                    </Grid>
                </Grid>
            }

            return <Grid container alignContent={"center"} alignItems={"center"}>
                <Grid item xs={2} sm={2}>
                    <Switch
                        checked={check}
                        name="bbb"
                        value={1}
                        onChange={e => setCheck(e.target.checked)}
                    />
                </Grid>
                <Grid item xs={10} sm={10}> <BbbPrice/></Grid>
            </Grid>
        }


        function SelectCagegoryLicense() {
            const [cagegoryLicense, setCagegoryLicense] = useState(category.category_license == 1 ? true : false)
            const [lisansBitis, setLisansBitis] = useState(category.lisans_bitis)
            const [lisansBaslama, setLisansBaslama] = useState(category.lisans_baslama)
            function CagegoryLicense() {
                if (!cagegoryLicense)
                    return <></>
                const lisansBitisChange = (date) => {
                    setLisansBitis(date);
                };
                const lisansBaslamaChange = (date) => {
                    setLisansBaslama(date);
                };
                return <><Grid container spacing={2} alignContent={"center"} alignItems={"center"}>
                    <Grid item xs={12} sm={12}>
                        <Alert color={"info"}>Ana kategoriye lisanslama seçeneği koyulduğu takdirde, alt kategoriler bu lisansı dikkate alacağından alt kategorilere lisans bilgileri girmeyiniz. Alt kategorileri lisanslamanız gerekiyorsa, ana
                            kategoriye lisans bilgisi girmeyiniz. </Alert>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                name="lisans_baslama"
                                label="Lisans Başlama Tarihi"
                                value={lisansBaslama}
                                onChange={lisansBaslamaChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                name="lisans_bitis"
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Lisans Bitiş Tarihi"
                                value={lisansBitis}
                                onChange={lisansBitisChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                </>
            }

            return <Grid container spacing={2} alignContent={"center"} alignItems={"center"}>
                <Grid item xs={1} sm={1}>
                    <Switch
                        checked={cagegoryLicense}
                        name="category_license"
                        value={1}
                        onChange={e => setCagegoryLicense(e.target.checked)}
                    />
                </Grid>
                <Grid item xs={11} sm={3}> Kategori lisansınını aktif et</Grid>
                <Grid item xs={12} sm={8}>
                    <CagegoryLicense/>
                </Grid>
            </Grid>
        }

        return <>
            <Paper className="paper-handler">
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} justify={"center"} alignItems="center">
                        <Cover/>
                    </Grid>
                    <Grid container spacing={2} alignContent={"center"} alignItems={"center"}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                size={"small"}
                                variant={"outlined"}
                                label="Başlık"
                                margin="normal"
                                name={"title"}
                                defaultValue={category.title}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                size={"small"}
                                variant={"outlined"}
                                label="Açıklama"
                                margin="normal"
                                name={"context"}
                                defaultValue={category.context}
                                fullWidth={true}
                                helperText={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                size={"small"}
                                variant={"outlined"}
                                label="Fiyatı"
                                margin="normal"
                                type={"number"}
                                name={"price"}
                                defaultValue={category.price}
                                fullWidth={true}
                                helperText={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                size={"small"}
                                variant={"outlined"}
                                label="İndirimli fiyatı"
                                margin="normal"
                                type={"number"}
                                name={"dis_price"}
                                defaultValue={category.dis_price}
                                fullWidth={true}
                                helperText={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <BBBCategory/>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                size={"small"}
                                variant={"outlined"}
                                fullWidth={true}
                                select
                                label="Kategori"
                                defaultValue={category.parent_id}
                                margin="normal"
                                name="parent_id"
                            >
                                <MenuItem value={0}>Ana Kategori Olsun</MenuItem>
                                {categories.map(item => ParentCategoriesItem(item))}
                            </TextField>
                        </Grid>


                        <Grid item xs={12} sm={4}>
                            <TextField
                                size={"small"}
                                variant={"outlined"}
                                fullWidth={true}
                                select
                                label="Yayın Durumu"
                                defaultValue={category.view}
                                margin="normal"
                                name="view"
                            >
                                <MenuItem value={1}>Kategori Yayında</MenuItem>
                                <MenuItem value={2}>Taslak</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                size={"small"}
                                variant={"outlined"}
                                label="Yerleşim Sırası"
                                margin="normal"
                                name={"view_order"}
                                defaultValue={category.view_order}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <SelectCagegoryLicense/>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Button
                                fluid
                                type={"submit"}
                                color={"primary"}
                                size={"huge"}
                            >BİLGİLERİ KAYDET</Button>
                        </Grid>
                        <Grid item xs={12} sm={4}></Grid>
                    </Grid>
                </form>
            </Paper>
        </>
    }
    return <>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <FormView/>
            </Grid>
            <Grid item xs={12} sm={12}>
                <EditorStore>
                    <ProductPage item={category}/>
                </EditorStore>
            </Grid>
        </Grid>
    </>

}

export default Category;
