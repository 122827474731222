import React from 'react';
import {Switch, Route} from 'react-router-dom';
import Sinavlar from "./Sinavlar";
import Optik from "./Optik";
import Cevaplar from "./Cevaplar";
import Konular from "./Konular";
import Sonuclar from "./Sonuclar";
import Karne from "./Karne";

function SinavRoutes() {
    return (
        <Switch>
            <Route path={'/sinavlar/all'} component={Sinavlar}/>
            <Route path={'/sinav/optik/:sinav_id'} component={Optik}/>
            <Route path={'/sinav/cevaplar/:sinav_id'} component={Cevaplar}/>
            <Route path={'/sinav/konular/:sinav_id'} component={Konular}/>
            <Route path={'/sinav/sonuclar/:sinav_id'} component={Sonuclar}/>
            <Route path={'/sinav/karne'} component={Karne}/>
        </Switch>
    )
}

export default SinavRoutes;
