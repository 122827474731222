import React, {StrictMode} from 'react';
import Add from './Add';

const Index = (props) => {
    return (
        <StrictMode>
            <Add props={props}/>
        </StrictMode>
    )

}

export default Index;
