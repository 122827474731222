import React, {useEffect, useRef, useState, useContext} from 'react';
import {useParams, Link, useHistory} from 'react-router-dom';
import instance from '../apis';
import 'video-react/dist/video-react.css';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import GetAppIcon from '@material-ui/icons/GetApp';
import CircularProgress from '@material-ui/core/CircularProgress';
import {ListItemSecondaryAction, Paper} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import VimeoPlayerView from "./VimeoPlayerView";
import {BreadsContext, PageTitleContext} from "../stores/PageStore";
import {Alert} from '@material-ui/lab';
import VideoView from "./video/VideoView";
import {ConfigContext} from "../Store";
import YouTube from "react-youtube";
import {breadLink} from "../functions/trCase";

const VideoPlayerPage = () => {
    const player = useRef();
    const {pTitle} = useParams();
    const {cTitle} = useParams();
    const {id} = useParams();
    const {pId} = useParams();
    const {videoId} = useParams();
    const [video, setVideo] = useState({attack_files: []});
    const [category, setCategory] = useState({videos: []});
    const [videoIds, setVideoIds] = useState([]);
    const [load, setLoad] = useState(true);
    const [startTime, setStartTime] = useState(-1);
    const [startTimeLoader, setStartTimeLoader] = useState(false);
    const [IDM, setIDM] = useState([]);
    const [qty, setQty] = useState(1)

    const [views, setViews] = useState([]) // izlenen videolar
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [config, setConfig] = useContext(ConfigContext);
    const [loadText, setLoadText] = useState(null)
    const [donwloadFilePath] = useState(null)
    let checkLogin = 'false'
    try {
        checkLogin = localStorage.getItem('login')

    } catch (e) {

    }
    const history = useHistory();
    if (checkLogin !== "true") {
        history.push('/login')
    }
    useEffect(() => {
        const downloaders = ['chrome-extension://cgpbghdbejagejmciefmekcklikpoeel/popup.html', 'chrome-extension://ngpampappnmepgilojfohadhhmbhlaek/captured.html', 'chrome-extension://mffmjlddchdccijipncbjhoabgmphjfb/inject_icon.svg', 'chrome-extension://ngpampappnmepgilojfohadhhmbhlaek/background.js']
        try {
            downloaders.map(item => {
                instance
                    .get(item)
                    .then(function (result) {
                        if (result.data.indexOf('Internet Download Manager') > 0) {
                            IDM.push('Internet Download Manager')
                            setIDM(IDM)
                        }
                        if (result.data.indexOf('stroke-miterlimit:1.41421') > 0) {
                            IDM.push('Inject Simple Vimeo Downloader')
                            setIDM(IDM)
                        }

                    })
                    .catch(error => {

                    })
            })

        } catch (e) {

        }
    }, [])
    const VideoTitle = () => {
        if (video.title) {
            setConfig(({...config, title: video.title}))
            return video.title;
        }
    };
    useEffect(() => {
        if (checkLogin) {
            setLoad(true);
            if (id) {
                setLoadText('Kullanıcı video listesi bilgileri alınıyor...')
                instance.get(`/api/user/views/videos/${id}`)
                    .then(function (res) {
                        const viewsIds = []
                        res.data.user_views.map(item => {
                            viewsIds.push(item.video_id)
                        })
                        if (res.data.user_views.length > 0 && res.data.user_views[0].qty) {
                            setQty(res.data.user_views[0].qty.qty)
                        }
                        setViews(viewsIds) // izlenen video listesi
                        setCategory(res.data);
                        let firstVideo = {};
                        if (videoId) {
                            firstVideo = res.data.videos.filter(x => x.id == videoId)[0]
                        } else {
                            try {
                                firstVideo = res.data.user_views[0].video
                            } catch (e) {
                                firstVideo = res.data.videos[0]
                            }
                        }
                        setCategory(res.data);
                        if (!firstVideo) {
                            setVideo({attack_files: []})
                        } else {

                            setLoadText('Kullanıcı video bilgileri alınıyor...')
                            setVideo(firstVideo); // ilk çalışacak son izlenen video
                            setConfig(({...config, title: firstVideo.title}))
                            instance.get(`/api/user/view/video/${firstVideo.id}`)
                                .then(function (res) {
                                    setStartTime(res.data)
                                    setStartTimeLoader(true)
                                    setLoadText(null)
                                })
                        }
                    })
                setLoadText('Video listesi alınıyor')
                instance.get(`/api/user/videoIds/${id}`)
                    .then(function (res) {
                        setVideoIds(res.data);
                        setLoadText(null)
                    });
                setLoad(false);
            }

        }
        let breads = '';
        if ('2' == '8r7ter8tret') {
            breads = [
                {
                    link: '/my/category/all',
                    title: 'Derslerim'
                },
                {
                    link: `/my/category/${pTitle}/${pId}`,
                    title: breadLink(pTitle)
                },
                {
                    link: '/üçgenler',
                    title: breadLink(cTitle),
                    last: true
                }
            ];
        } else {
            breads = [
                {
                    link: '/categories/all',
                    title: 'Tüm Dersler'
                },
                {
                    link: `/categories/${pTitle}/${pId}`,
                    title: breadLink(pTitle)
                },
                {
                    link: '/üçgenler',
                    title: breadLink(cTitle),
                    last: true
                }
            ];
        }
        setBreads(breads)
        setPageTitle(VideoTitle)
    }, [videoId]);


    if (IDM.length > 0) {
        return IDM.map(item => {
            return <>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}><span style={{fontWeight: 600}}>{item}</span> uzantısı yüklü olduğundan dolayı bu videoyu açamazsınız</Grid>
                    <Grid item xs={12} sm={12}>
                        Uzantı kaldırmak için sağ üstteki uzantının simgesine sağ tıkayın </Grid>
                    <Grid item xs={12} sm={12}> <img src={'/images/IDM.jpg'}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        Sağ tıkladığınızda kaldırma bağlantısını göreceksiniz. </Grid>
                    <Grid item xs={12} sm={12}> <img src={'/images/IDM2.jpg'}/>
                    </Grid>
                </Grid>
            </>
        })
    }

    function videoIdHandle(item_id) {
        setLoad(true)
        instance.get(`/api/get/video/${item_id}`)
            .then(function (res) {
                setVideo(res.data)
                setStartTime(res.data.user_view.sn)
                setLoad(false)
            })
    }

    const VideoCheck = ({video_id}) => {
        if (views.includes(video_id)) {
            return <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete">
                    <CheckIcon/>
                </IconButton>
            </ListItemSecondaryAction>
        }
        return <></>
    }

    const VideoPercent = ({view}) => {
        if (view) {
            const pr = view.dr == 0 || view.dr == null || view.dr == '' ? 0 : Math.round(view.sn / view.dr * 100)
            return <LinearProgress style={{
                height: 2,
                width: "100%",
            }} variant="determinate" value={pr}/>
        } else {
            return <LinearProgress style={{
                height: 2,
                width: "100%",
            }} variant="determinate" value={0}/>;
        }
    }
    window.onload = function () {
        if (document.getElementsByClassName('vp-sidedock').length > 0) {
        }
    }

    const VideoList = () => {
        if (category.videos) {
            return category.videos.map(item => <Link key={item.id} to={`/videos/${pTitle}/${cTitle}/${pId}/${id}/${item.id}`}>
                    <ListItem
                        button
                        selected={video.id == item.id ? true : false}
                        // autoFocus={video.id == item.id ? true : false}
                    >
                        <Grid container key={item.id}>
                            <Grid item xs={9} sm={9}>
                                <ListItemText
                                    primary={item.title}
                                />
                            </Grid>
                            <Grid item xs={3} sm={3}>
                                <VideoCheck video_id={item.id}/>
                            </Grid>
                        </Grid>


                    </ListItem>
                    <VideoPercent view={item.user_view}/>
                </Link>
            );
        }
    };
    const HLS = () => {
        if (loadText != null) return <Grid container>
            <Grid item sm={12} xs={12}>
                <Alert severity="info">{loadText}</Alert>
            </Grid>
        </Grid>
        if (!video.id) {
            return <>Bu derse henüz video yüklenmedi</>;
        }
        if (!videoIds.includes(video.id) && (category.dis_price != null || category.price != null)) {
            return <>Satın almadığınız için bu video görüntülenemiyor.</>;
        }
        if (!startTimeLoader) {
            return <>Video Bekleniyor</>
        }

        function qualityChangeHandle(getQty) {
            if (qty == getQty || (getQty == 1 && qty == '') || (getQty == 1 && qty == null))
                return;
            instance
                .get(`/api/quality/select/${getQty}`)
                .then(function (res) {
                    if (res.status == 200)
                        if (getQty == 1)
                            return setQty('')
                    return setQty(getQty)
                })
        }

        function qualityChange(e) {
            const getQty = e.target.value;

            if (qty == getQty || (getQty == 1 && qty == '') || (getQty == 1 && qty == null))
                return;
            instance
                .get(`/api/quality/select/${getQty}`)
                .then(function (res) {
                    if (res.status == 200) {
                        if (getQty == 1)
                            return setQty(1)
                        return setQty(getQty)
                    }
                })
        }

        if (video.publish_type === 2) {
            const opts = {
                height: '400',
                width: '100%',
                playerVars: {
                    // https://developers.google.com/youtube/player_parameters
                    autoplay: 1,
                    start: startTime
                },

            };
            return <YouTube videoId={video.youtube_id} opts={opts}/>
        } else if (video.publish_type === 3) {
            return <VimeoPlayerView video={video} category_id={id} startTime={startTime} setStartTime={setStartTime}/>

        } else if (video.publish_type === 1 && video.stream_status == 1 && video.upload == 1) {
            return <VideoView player={player} video={video} qualityChange={qualityChange} qty={qty} qualityChangeHandle={qualityChangeHandle} id={id} startTime={startTime}/>
        } else {
            return <>Video Şuan İşleniyor</>
        }

    };


    /*    function attackFileDownload(path, name) {
            setDonwloadFilePath(path)
            instance.get('/api/video/attack/file?path=' + path, {
                    responseType: "blob"
                })
                .then(function (res) {
                    const url  = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href  = url;
                    link.setAttribute('download', name);
                    document.body.appendChild(link);
                    link.click();
                    setDonwloadFilePath(null)
                })
        }*/
    const VideoContent = () => {
        if (loadText != null) return <Grid container>
            <Grid item sm={12} xs={12}>
                <Alert severity="info">{loadText}</Alert>
            </Grid>
        </Grid>
        if (load && video.id) {
            return <CircularProgress/>;
        }
        return <>
            <HLS/>
            <VideoAttactFileList/>
        </>
    }
    const ListItemAttackFile = ({item, index}) => {
        if (donwloadFilePath == item.path) {
            return <CircularProgress color={"secondary"} size={24}
                                     thickness={4}/>
        }
        return <a target={"_blank"} href={`https://lms.rotasinav.com/video/attack/file?path=${item.path}&name=${item.name_org}&user_id=${item.user_id}`}>
            <ListItem key={item.id} button>
                <ListItemText
                    primary={`${index + 1}) ${item.name_org}`}
                />
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                        <a target="_blank" href={`https://lms.rotasinav.com/video/attack/file?path=${item.path}&name=${item.name_org}`}> <GetAppIcon/></a>
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </a>
    }
    const VideoAttactFileList = () => {
        if (!video.id) {
            return <>Bu derse henüz video yüklenmedi</>;
        }
        if (video) {
            if (!videoIds) {
                return <>Satın almadığınız için eklenen dosyaları göremiyorsunuz.</>
            }
            if (!videoIds.includes(video.id) && (category.dis_price != null || category.price != null)) {
                return <>Satın almadığınız için eklenen dosyaları göremiyorsunuz.</>
            }
            if (video.attack_files.length < 1)
                return <></>
            return <>
                <h4>Eklenen İçerikler</h4>
                <List dense={true}>
                    {video.attack_files.map((item, index) => <ListItemAttackFile key={item.id} item={item} index={index}/>)}
                </List>
            </>
        } else {
            return <>Henüz dosya eklenmedi</>
        }
    }
    if (load)
        return <CircularProgress/>
    return <>
        <Grid container spacing={2}>
            <Grid id={'videoContent'} item sm={9} xs={12}>
                <Paper className="paper-handler">
                    <VideoContent/>
                </Paper>
            </Grid>
            <Grid id={"videoList"} item sm={3} xs={12}>
                <Paper className="paper-handler">
                    <List dense={true}>
                        <VideoList/>
                    </List>
                </Paper>
            </Grid>
        </Grid>
    </>;


};

export default VideoPlayerPage;
