import React, {useEffect, useState} from 'react';
import {MenuItem, TextField} from "@material-ui/core";
import intance from '../../apis';

function Brans() {
    const [brans, setBrans]       = useState(null)
    const [branslar, setBranslar] = useState([])
    useEffect(() => {
        intance
            .get('/api/user/branslar')
            .then(function (result) {
                setBranslar(result.data.branslar)
                setBrans(result.data.brans_id)
            })
    }, [])

    function handleChange(e) {
        const brans_id = e.target.value;
        setBrans(brans_id)
        intance.put(`/api/user/set/brans/${brans_id}`)
    }

    const SelectView = () => {
        return <TextField
            id="branslar"
            select
            fullWidth
            label="Branşınız"
            value={brans}
            onChange={handleChange}
            helperText="Lütfen branşınızı seçin."
        >
            {branslar.map(option => (
                <MenuItem key={option.id} value={option.id}>
                    {option.name}
                </MenuItem>
            ))}
        </TextField>
    }
    return <SelectView/>;
}

export default Brans;
