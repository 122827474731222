import React, {useContext, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {Link, useLocation} from 'react-router-dom';
import {BreadsContext} from "../stores/PageStore";
import ReactGA from "react-ga";
import {ConfigContext} from "../Store";
import {YMInitializer} from 'react-yandex-metrika';

const useStyles = makeStyles(theme => ({
    link: {
        display: 'flex',
        color: "#272727"
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
}));


export default function Bread() {
    const [config, setConfig] = useContext(ConfigContext)
    const location = useLocation();
    ReactGA.initialize(config.analytics);
    ReactGA.pageview(location.pathname)

    const [breads] = useContext(BreadsContext);
    const classes = useStyles();
    useEffect(() => {
        let title = '';
        breads.map((item, index) => {
            title += item.title;
            if (index + 1 < breads.length) {
                title += "-";
            }
        })
        if (title !== '')
            setConfig(config => ({...config, title: title}))

    }, [breads])

    if (!breads) {
        return <></>
    }

    function link(item) {
        if (!item.last) {
            return <Link key={item.link} to={item.link} className={classes.link}>
                {item.title}
            </Link>
        } else {
            return <Typography key={item.link} color="textPrimary" style={{color: "#a3a3a3"}} className={classes.link}>
                {item.title}
            </Typography>
        }
    }

    const MetrikaView = () => {
        if (config.metrika == '' || config.metrika == null) {
            return <></>
        }
        return <YMInitializer accounts={[config.metrika]}/>
    }
    return (<>   <MetrikaView/>
        <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to={'/'} className={classes.link}>
                Anasayfa
            </Link>
            {breads.map(item =>
                link(item)
            )}
        </Breadcrumbs>
    </>);
}
