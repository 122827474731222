import React, {useEffect, useState, useContext} from 'react';

import {Paper, Button, TextField, Grid, MenuItem, Divider, CircularProgress} from "@material-ui/core";
import instance from '../../../apis';
import {toast} from "react-toastify";
import {BreadsContext, PageContext, PageTitleContext} from "../../../stores/PageStore";
import {useParams, Link, useHistory} from 'react-router-dom';
import moment from "moment";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import phone from "../../../functions/phone"

const Bitirmeyenler = () => {
    const history = useHistory()
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    const [load, setLoad] = useState(false);
    const [datas, setDatas] = useState([]);
    const [sinav, setSinav] = useState([]);
    const {sinav_id} = useParams()

    useEffect(() => {
        setLoad(true)
        if (sinav_id) {
            instance.get(`/api/admin/sinav/bitirmeyenler/${sinav_id}`)
                .then(function (res) {
                    setLoad(false)
                    setDatas(res.data)
                    setSinav(res.data[0].sinav)
                })

        }
    }, [])

    useEffect(() => {
        setPageTitle('Sınavlar')
        const breads = [
            {
                link: "/admin/esinav/add",
                title: 'Sınavlar',
                last: null
            },
            {
                link: `/admin/esinav/sonuclar/${sinav_id}`,
                title: sinav.name,
                last: null
            },
            {
                link: null,
                title: 'Bitirmeyenler',
                last: true
            }
        ]
        setBreads(breads)
        setPage(13)
    }, [sinav])

    function bitir(id, sinav_id, user_id) {
        instance
            .get(`/api/admin/sinav/bitirmeyenler/bitir/${id}/${sinav_id}/${user_id}`)
            .then(function (result) {
                toast.success('Bitirme işlemi başarılı')
                setDatas(result.data)
            })
    }

    if (load)
        return <CircularProgress/>
    return <>
        <Grid container>
            <Grid item container>
                <Grid item xs={12} sm={2}><b>İSİM</b></Grid>
                <Grid item xs={12} sm={2}><b>SINAV İSMİ</b></Grid>
                <Grid item xs={12} sm={2}><b>İLETİŞİM</b></Grid>
                <Grid item xs={12} sm={2}><b>CEVAP SAYISI</b></Grid>
                <Grid item xs={12} sm={2}><b>SON İŞLEM</b></Grid>
                <Grid item xs={12} sm={2}><b>İŞLEM</b></Grid>
            </Grid>
            {datas.map(item => (
                <Grid key={item.id} item container alignItems={"center"} alignContent={"center"}>
                    <Grid item xs={6} sm={2}>{item.user.name}</Grid>
                    <Grid item xs={6} sm={2}>{item.sinav.name}</Grid>
                    <Grid item xs={6} sm={2}>
                        <WhatsAppIcon style={{fontSize: 16}}/> <a target="_blank" href={`https://api.whatsapp.com/send?phone=${phone(item.user.tel)}`}>
                        {item.user.tel}
                    </a>
                    </Grid>
                    <Grid item xs={2} sm={2}>{item.user_cevaps_count}</Grid>
                    <Grid item xs={2} sm={2}>{moment(item.updated_at).format('DD/MM/YYYY HH:mm')}</Grid>
                    <Grid item xs={2} sm={2}>
                        <Button color={"primary"} variant={"outlined"} size={"small"} onClick={() => bitir(item.id, item.sinav_id, item.user_id)}>BİTİR</Button>
                    </Grid>
                    <Grid item xs={12} sm={12}><Divider/></Grid>
                </Grid>
            ))
            }
        </Grid>
    </>

}

export default Bitirmeyenler;
