import React, {useContext, useEffect, useState} from 'react';
import {BreadsContext, PageContext, PageTitleContext} from "../../../stores/PageStore";
import {CircularProgress, Fade, Grid, Modal, Paper, Backdrop, TextField, IconButton} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import instance from "../../../apis";
import {toast} from "react-toastify";
import useInterval from "@use-it/interval";
import moment from "moment";
import {ReactSortable} from "react-sortablejs";
import ImportExportIcon from '@material-ui/icons/ImportExport';
import EditIcon from '@material-ui/icons/Edit';
import {makeStyles} from "@material-ui/core/styles";
import AddCircleIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 400
    },
}));

const UploadsAdmin = () => {
    const classes = useStyles();
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    const [percent, setPercent] = useState(0)
    const [files, setFiles] = useState([])
    const [category, setCategory] = useState({files: []})
    const [categories, setCategories] = useState([])
    const [loadDelete, setLoadDelete] = useState(0)
    const [fileLoad] = useState(false)
    const [loadFiles, setLoadFiles] = useState(true)
    const [fileUploadStatus, setFileUploadStatus] = useState(0)
    const [reloadCategories, setReloadCategories] = useState(false)
    const [reload, setReload] = useState(true)
    const [loadCategoryDelete, setLoadCategoryDelete] = useState({})

    useEffect(() => {
        const breads = [
            {
                link: '/admin/uploads',
                title: 'Dosyalar / Dosya Yükle',
                last: true
            }
        ];
        setBreads(breads)
        setPage(19)
        setPageTitle('Dosyalar ve yeni dosya ekleme')
    }, [])
    useEffect(() => {
        setLoadFiles(true)
        instance
            .get(`/api/admin/montly/files`)
            .then(function (result) {
                setLoadFiles(false)
                setCategories(result.data)
                if (result.data[0] != null) {
                    setCategory(result.data[0])
                    setFiles(result.data[0].files)
                }
            })
    }, [reloadCategories])

    function selectCategory(item) {
        setCategory(item)
    }

    useInterval(() => {
        if ((fileUploadStatus + 10) <= moment().unix() && fileUploadStatus != 0) {
            setReloadCategories(!reloadCategories)
            setFileUploadStatus(0)
        }
    }, 5000)
    const FileUploadView = () => {


        if (percent > 0 && percent < 99) {
            return <ProgressAttackFile/>
        }
        return <>
            <strong>DERS İÇERİĞİ EKLE (pdf, excell, jpg vb.)</strong>
            <Divider/>
            <Button variant={"outlined"} fullWidth color={"primary"} onClick={() => selectFile()}>DOSYA SEÇ</Button>
            <input
                style={{display: "none"}}
                multiple
                type="file"
                id="file"
            />
        </>
    }

    const selectFile = () => {
        const file = document.getElementById("file");
        file.click()
        file.onchange = function () {
            const inputFiles = file.files;
            for (let x = 0; x < inputFiles.length; x++) {
                const formData = new FormData();
                formData.append("file", inputFiles[x]);
                formData.append("category_id", category.id);
                setLoadFiles(true)
                instance
                    .post('/api/admin/montly/file/upload', formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            onUploadProgress: function (progressEvent) {
                                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                setPercent(percentCompleted)
                            }
                        },
                    )
                    .then(function () {
                        setFileUploadStatus(moment().unix())
                    })
            }
        }
    }


    const ProgressAttackFile = () => {
        if (percent == 0) {
            return <></>
        } else {
            return <>
                <Grid container>
                    <Grid item xs={12} sm={12}> Lütfen işlemler bitene kadar bekleyin.</Grid>
                    <Grid item xs={12} sm={12}> <LinearProgress variant="buffer" value={percent}/></Grid>
                    <Grid item xs={12} sm={12} style={{paddingBottom: 10}}> </Grid>
                    <Grid item xs={12} sm={12}>
                        <Button
                            variant={"outlined"}
                            onClick={() => setPercent(0)}
                            fullWidth
                            color={"secondary"}
                        >YENİ Dosya YÜKLE</Button>
                    </Grid>
                </Grid>
            </>
        }
    }

    function deleteFile(id, path) {
        if (global.confirm('Silmek istediğinizden eminmisiniz?')) {
            setLoadDelete(id)
            instance.delete('/api/admin/montly/file/' + id + '?path=' + path)
                .then(function (result) {
                    setLoadDelete(0)
                    const newFiles = category.files.filter(x => x.id != id)
                    category.files = newFiles
                    setCategory(category => ({...category, category: {files: newFiles}}))
                    if (result.status == 200) {
                        toast.success(result.data.msg)
                    } else if (result.status == 210) {
                        toast.warn(result.data.msg)
                    }
                })
        }
    }

    function DeleteIconView({item}) {
        if (loadDelete == item.id) {
            return <CircularProgress style={{width: 20, height: 20}}/>
        }
        return <IconButton size={"small"} onClick={() => deleteFile(item.id, item.path)}><DeleteIcon style={{cursor: "pointer"}}/></IconButton>
    }

    function deleteCategory(id) {
        if (global.confirm('Silmek istediğinizden eminmisiniz?')) {
            setLoadCategoryDelete(id)
            instance.delete('/api/admin/montly/category/' + id)
                .then(function (result) {
                    setLoadCategoryDelete(0)
                    if (result.status == 200) {
                        setCategories(result.data.items)
                        setCategory({files: []})
                        toast.success(result.data.msg)
                    } else if (result.status == 210) {
                        toast.warn(result.data.msg)
                    }
                })
        }
    }

    function DeleteCategoryIconView({item}) {
        if (loadCategoryDelete == item.id) {
            return <CircularProgress style={{width: 20, height: 20}}/>
        }
        return <IconButton onClick={() => deleteCategory(item.id)} size={"small"}>
            <DeleteIcon/>
        </IconButton>
    }

    function CategoryNameView({item}) {
        if (!item.name)
            return <></>
        return <Button fullWidth variant={category.id == item.id ? "contained" : "outlined"} color={"primary"} onClick={() => selectCategory(item)}>{item.name}</Button>
    }

    const CategoriesView = () => {
        const [list, setList] = useState(categories)
        const [newName, setNewName] = useState()
        const [open, setOpen] = useState(false);
        const [editItem, setEditItem] = useState({})


        function listSortChange(e) {
            const ids = [];
            list.map((item, index) => ids.push({id: item.id, order_view: index}))
            instance
                .post(`/api/admin/montly/category/sorts`, {sorts: JSON.stringify(ids)})
                .catch(function () {

                })
        }

        const handleOpen = (item) => {
            setEditItem(item)
            setOpen(true);

        };
        const handleClose = () => {
            setOpen(false);

        };
        const handleNameChange = e => {
            setNewName(e.target.value)
        }

        function saveNewName() {
            instance
                .post(`/api/admin/montly/category/new/name`, {name: newName, id: editItem.id})
                .then(function (result) {
                    if (result.status == 200) {
                        toast.success(result.data.msg)
                        setCategories(result.data.items)
                        handleClose()
                    }
                })
        }

        function CategoryMenuView() {
            return <>
                <Grid item xs={3} sm={3}><h4>Kategoriler</h4></Grid>
                <Grid item xs={3} sm={3} style={{textAlign: "right"}}>
                    <IconButton onClick={() => handleOpen({})} color={"primary"}>
                        <AddCircleIcon/>
                    </IconButton>
                </Grid>
            </>
        }

        return (<>
            <Grid container justify={"space-between"}>
                <CategoryMenuView/>
            </Grid>
            <ReactSortable
                list={list}
                setList={setList}
                animation={150}
                onSort={listSortChange}
            >
                {list.map((item, index) => <Grid container alignContent={"center"} alignItems={"center"} item key={item.id}>
                    <Grid item xs={1} sm={1}><ImportExportIcon/></Grid>
                    <Grid item xs={1} sm={1}>{item.id}</Grid>
                    <Grid item xs={4} sm={6}><CategoryNameView key={`newName_${item.id}`} id={`newName_${item.id}`} item={item}/></Grid>
                    <Grid item xs={2} sm={2}>{item.exs}</Grid>
                    <Grid item xs={1} sm={1}><IconButton size={"small"} onClick={() => handleOpen(item)}><EditIcon item={item}/></IconButton></Grid>
                    <Grid item xs={1} sm={1}><DeleteCategoryIconView item={item}/></Grid>
                    <Grid item xs={12} sm={12}><Divider/></Grid>
                </Grid>)}
            </ReactSortable>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                className={classes.modal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">Kategori Düzenle</h2>
                        <p id="transition-modal-description">
                            <TextField fullWidth variant={"outlined"} label="İsim" onChange={handleNameChange} defaultValue={editItem.name}/>
                            <div style={{textAlign: "right"}}>
                                <Button onClick={() => saveNewName()} variant={"contained"} color={"primary"}>DEĞİŞİKLİĞİ KAYDET</Button>
                            </div>
                        </p>
                    </div>
                </Fade>
            </Modal>
        </>);
    }
    const FileListView = () => {
        const [list, setList] = useState(category.files)
        const [newName, setNewName] = useState()
        const [open, setOpen] = useState(false);
        const [editItem, setEditItem] = useState({})

        function listSortChange(e) {
            const ids = [];
            list.map((item, index) => ids.push({id: item.id, order_view: index}))
            instance
                .post(`/api/admin/montly/file/sorts/${category.id}`, {sorts: JSON.stringify(ids)})
                .catch(function () {

                })
        }

        function NameView({item}) {
            if (!item.oname)
                return <></>
            return item.oname
        }

        const handleOpen = (item) => {
            setEditItem(item)
            setOpen(true);

        };
        const handleClose = () => {
            setOpen(false);

        };
        const handleNameChange = e => {
            setNewName(e.target.value)
        }

        function saveNewName() {
            instance
                .post(`/api/admin/montly/file/new/name`, {name: newName, id: editItem.id, category_id: category.id})
                .then(function (result) {
                    if (result.status == 200) {
                        toast.success(result.data.msg)
                        setList(result.data.items)
                        handleClose()
                    }
                })
        }

        if (loadFiles)
            return <CircularProgress/>
        if (list.length == 0)
            return <span style={{color: "red"}}>Henüz dosya eklenmedi!</span>
        return (<><ReactSortable
            list={list}
            setList={setList}
            animation={150}
            onSort={listSortChange}
        >
            {list.map((item, index) => <Grid container alignContent={"center"} alignItems={"center"} item key={item.id}>
                <Grid item xs={1} sm={1}><ImportExportIcon/></Grid>
                <Grid item xs={1} sm={1}>{item.id}</Grid>
                <Grid item xs={4} sm={4}><NameView key={`newName_${item.id}`} id={`newName_${item.id}`} item={item}/></Grid>
                <Grid item xs={2} sm={2}>{item.exs}</Grid>
                <Grid item xs={2} sm={2}>
                    <a target="_blank" href={`https://lms.rotasinav.com/file/download?path=${item.path}&site_id=${item.site_id}`}> <CloudDownloadIcon/></a>
                </Grid>
                <Grid item xs={1} sm={1}><IconButton size={"small"} onClick={() => handleOpen(item)}><EditIcon item={item}/></IconButton></Grid>
                <Grid item xs={1} sm={1}><IconButton size={"small"}><DeleteIconView item={item}/></IconButton></Grid>
                <Grid item xs={12} sm={12}><Divider/></Grid>
            </Grid>)}
        </ReactSortable>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                className={classes.modal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">Dosya Düzenle</h2>
                        <p id="transition-modal-description">
                            <TextField fullWidth variant={"outlined"} label="İsim" onChange={handleNameChange} defaultValue={editItem.oname}/>
                            <div style={{textAlign: "right"}}>
                                <Button onClick={() => saveNewName()} variant={"contained"} color={"primary"}>DEĞİŞİKLİĞİ KAYDET</Button>
                            </div>
                        </p>
                    </div>
                </Fade>
            </Modal>
        </>);
    }

    return <>
        <Grid container spacing={2}>
            <Grid container item xs={12} sm={4} justify={"center"} style={{paddingBottom: 10}}>
                <Paper className="paper-handler">
                    <CategoriesView/>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={8}>
                <Paper className="paper-handler">
                    <h4>{category.name}</h4>
                    <FileListView/>
                    <Divider/>
                    <FileUploadView/>
                </Paper>
            </Grid>
        </Grid>

    </>;
}

export default UploadsAdmin;
