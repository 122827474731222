import React, {useContext, useEffect, useRef} from "react";
import {BBBContext} from '../../../../stores/BBBCreate';

import {Button,Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {toast} from "react-toastify";


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}));


const DokumanSendButton = props => {

    const id = props.match.params.id;

    const classes = useStyles();
    const selectedUserList = useRef(null)
    const bbbContext = useContext(BBBContext);

    const {uploadDokuman,} = bbbContext;
    useEffect(() => {
        // getUserList();
    }, [])


    return (
        <Grid container>
            <Grid item xs={12} sm={12} justify="center">
                <Grid item xs={12} sm={6}>
                    <Button
                        type={"submit"}
                        color={"primary"}
                        variant="contained"
                        onClick={() => {
                            uploadDokuman(id).then((res) => {
                                    console.log('RESSS', res);
                                    toast.success('Eklendi')
                                }
                            )
                        }}
                    > EKLE </Button>
                </Grid>
            </Grid>
        </Grid>


    );
};

export default DokumanSendButton;
