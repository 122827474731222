import React, {useContext, useEffect, useState} from 'react';
import {BreadsContext, PageContext, PageTitleContext} from "../../../stores/PageStore";
import instance from "../../../apis";
import {CircularProgress, Grid, Paper, Button, Divider} from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';
import billingCalculate from "../../../functions/billingCalculate";

const Billings = () => {
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    const [load, setLoad] = useState(true);
    const [detailLoad, setDetailLoad] = useState(false);
    const GB = 1073741824;
    const [usage, setUsage] = useState({
        billing_logs: [],
        band: {},
        total: 0,
        bbb_total: 0,
        payment: 0,
        payments: {}
    })
    useEffect(() => {
        const breads = [
            {
                link: null,
                title: 'Faturalandırma',
                last: true,
            }];
        setBreads(breads)
        setPageTitle('Video Düzenle')
        setPage(100)
    }, [])
    useEffect(() => {
        setLoad(true)
        instance.get('/api/admin/billings/usage')
            .then(function (res) {
                const data = res.data.item;
                setUsage(data)
                setLoad(false)
            })
    }, [])
    let detail = {
        display: "none"
    }
    if (detailLoad)
        detail = {}
    if (load)
        return <CircularProgress/>
    const billing = billingCalculate(usage)

    function dayView(date) {
        return moment(date).calendar(date, {
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd',
            lastDay: '[Yesterday]',
            lastWeek: '[Last] dddd',
            sameElse: 'DD/MM/YYYY'
        });
    }

    console.log(dayView('2018-08-07'))
    return <>
        <Paper className="paper-handler">
            <Grid container alignItems={"center"} alignContent={"center"} spacing={1}>
                <Grid item xs={12} sm={12}>
                    <h4>Bakiye ve Kullanım Bilgileriniz</h4>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            Toplam Ödeme <br/>
                            <span style={{fontSize: 24, color: "darkslategrey"}}>{billing.payment} ₺</span>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={7} sm={7}>Disk Boyutu</Grid>
                                <Grid item xs={1} sm={1}>:</Grid>
                                <Grid item xs={4} sm={4}>{(usage.band.data / GB).toFixed(2)} GB</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={7} sm={7}> Transfer</Grid>
                                <Grid item xs={1} sm={1}>:</Grid>
                                <Grid item xs={4} sm={4}>{(usage.band.size / GB).toFixed(2)} GB</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={7} sm={7}>Sınava G. Sayısı</Grid>
                                <Grid item xs={1} sm={1}>:</Grid>
                                <Grid item xs={4} sm={4}>{billing.sinavSayi} Kişi</Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={7} sm={7}>Disk Ücreti</Grid>
                                <Grid item xs={1} sm={1}>:</Grid>
                                <Grid item xs={4} sm={4}>{billing.cost} ₺</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={7} sm={7}>Canlı Yayın Ücreti</Grid>
                                <Grid item xs={1} sm={1}>:</Grid>
                                <Grid item xs={4} sm={4}>{billing.bbbCost} ₺</Grid>
                            </Grid>

                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={7} sm={7}>Sınav Ücreti</Grid>
                                <Grid item xs={1} sm={1}>:</Grid>
                                <Grid item xs={4} sm={4}>{billing.sinavCost} ₺</Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={3} style={{textAlign: "right"}}>
                    <div style={{paddingRight: 20}}>Kalan Bakiye</div>
                    <span style={{fontSize: 24, color: "darkslategrey"}}>{billing.kalan} ₺</span>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12} sm={12} style={{paddingLeft: "40%"}}>
                    <Button onClick={() => setDetailLoad(true)} variant={"outlined"}>Detayları İncele</Button>
                </Grid>
                <Grid style={detail} item xs={12} sm={12}>
                    <Grid container spacing={2} style={{marginBottom: 10}}>
                        <Grid item xs={12} sm={12}>
                            <h4>Günlük Ücretlendirme ve Kullanım Detayları</h4>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider/>
                        </Grid>
                    </Grid>
                    <Grid container style={{marginBottom: 10, fontWeight: "bold", textAlign: "right"}}>
                        <Grid item xs={2} sm={2}>Tarih</Grid>
                        <Grid item xs={2} sm={2}>Disk</Grid>
                        <Grid item xs={2} sm={2}>Transfer</Grid>
                        <Grid style={{fontWeight: 400}} item xs={4} sm={4}>
                            <div style={{textAlign: "center"}}><b>Ücretler</b></div>
                            <Grid container>
                                <Grid item xs={4} sm={4}>Disk</Grid>
                                <Grid item xs={4} sm={4}>Canlı</Grid>
                                <Grid item xs={4} sm={4}>Sınav</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2} sm={2}>Toplam</Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider/>
                        </Grid>
                    </Grid>
                    {usage.billing_logs.map(item => <Grid container alignItems={"center"} style={{textAlign: "right"}} alignContent={"center"}>
                            <Grid item xs={2} sm={2}>{moment(item.created_at).format('DD.MM.YYYY')}</Grid>
                            <Grid item xs={2} sm={2}>{(item.data / GB).toFixed(2)} GB</Grid>
                            <Grid item xs={2} sm={2}>{(item.transfer / GB).toFixed(2)} GB</Grid>
                            <Grid item xs={4} sm={4}>
                                <Grid container>
                                    <Grid item xs={4} sm={4}>{parseFloat(item.price).toFixed(2)} ₺</Grid>
                                    <Grid item xs={4} sm={4}>{parseFloat(item.bbb_price).toFixed(2)} ₺</Grid>
                                    <Grid item xs={4} sm={4}>{parseFloat(item.sinav_price).toFixed(2)} ₺</Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                {(parseFloat(item.bbb_price) + parseFloat(item.price) + parseFloat(item.sinav_price)).toFixed(2)} ₺
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider/>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Paper>
    </>

}

export default Billings;
