import React, {useLayoutEffect, useState} from 'react';
import { Grid, Paper, Button, CircularProgress, } from '@material-ui/core';
import {toast} from "react-toastify";
import Form from './Form';
import instance from '../../../../apis';
import { useParams, useHistory } from 'react-router-dom';
import Users from './Users';
import { connect } from 'react-redux'
import { loadingSet, setEditData } from '../../../../redux/classroomAdd'
import store from '../../../../redux/store'


function Add(props) {
    const history = useHistory();
    const {id} = useParams();

    useLayoutEffect(() => {
        if (id)
            instance
                .get(`/api/admin/classroom/edit/${id}`)
                .then(function (res) {
                    props.setEditData (res.data.data, id);
                    console.log('res.data.data.edit',res.data.data)
                })
    }, [])

    // if (load && id)
    //     return <CircularProgress/>

    function submitForm(e) {
        const target = e.target
        e.preventDefault()
        console.log('STORE', store.getState())
        var data = {
            id: id?id:null,
            ad: store.getState().classroomAdd.ad,
            sube_kod: store.getState().classroomAdd.sube_kod,
            users: store.getState().classroomAdd.selected_users
        }
        instance.post('/api/admin/classroom/'+(id?'update':'save'), data)
            .then(function (res) {
                if (res.data.durum == 1){
                    toast(res.data.msg)
                    history.push('/admin/classroom/list')
                }else{
                    toast(res.data.msg)
                }
            })
        return false
    }

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <Paper className="paper-handler">
                    <form onSubmit={submitForm}>
                        <Form />
                        <div className="acr-padding-2"></div>
                        <div className="acr-padding-2"></div>
                        <Grid item xs={12} sm={12} style={{paddingTop: 30, paddingBottom: 20}}>
                            <Users />
                        </Grid>
                        <div style={{marginTop: 20}}>
                            {<Button type="submit" variant={"contained"} color={"primary"} fullWidth={true}>{!id?'SINIF BİLGİLERİNİ KAYDET':'SINIF BİLGİLERİNİ GÜNCELLE'}</Button>}
                        </div>
                    </form>
                </Paper>
            </Grid>
        </Grid>
    </>
}

const mapStateToProps = (state) => {
};

const mapDispatchToProps = {
	loadingSet, setEditData
}
  

export default connect(mapStateToProps, mapDispatchToProps)(Add);

