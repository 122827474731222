import React, {useEffect, useContext, useState} from 'react';
import {BreadsContext, PageContext, PageTitleContext} from "../../../stores/PageStore";
import {Paper, Grid, CircularProgress, Divider} from "@material-ui/core";
import instance from "../../../apis";
import {toast} from "react-toastify";
import Alert from '@material-ui/lab/Alert';

const ContactFormMessages = () => {
    const [, setBreads] = useContext(BreadsContext);
    const [pageTitle, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    const [items, setItems] = useState([]);
    const [load, setLoad] = useState(false);
    useEffect(() => {

        const breads = [
            {
                link: null,
                title: 'İletişim Formu Gönderileri',
                last: true
            }
        ]
        setBreads(breads)
        setPageTitle('İletişim Formu Gönderileri')
        setPage(27.3)
    }, [])
    useEffect(() => {
        setLoad(true)
        instance
            .get(`/api/admin/contact/form/messages`)
            .then(function (result) {
                setLoad(false)
                if (result.status == 200) {
                    setItems(result.data.items)
                } else {
                    toast.warn('Veriler çekilirken bir hata meydana geldi, lütfen yeniden deneyin.')
                }
            })
            .catch(function () {
                toast.error('Bağlantı hatası, lütfen yeniden deneyin.')
            })
    }, [])

    if (load)
        return <CircularProgress/>

    function MessagesView() {
        if (items.length < 1)
            return <Alert variant="outlined" severity="error">
                Henüz bir mesajınız yok!
            </Alert>
        return items.map((item, index) => <Grid container alignItems={"center"} alignContent={"center"} spacing={1}>
            <Grid item xs={1} sm={1}><b>{index + 1}- </b></Grid>
            <Grid item xs={11} sm={2}><b>{item.name}</b></Grid>
            <Grid item xs={12} sm={2}>{item.tel}</Grid>
            <Grid item xs={12} sm={2}>{item.email}</Grid>
            <Grid item xs={12} sm={5}>{item.msg}</Grid>
            <Grid item xs={12} sm={12}><Divider/></Grid>
        </Grid>)
    }

    return <Paper className="paper-handler">
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}><h2>{pageTitle}</h2></Grid>
            <Grid item xs={12} sm={12}>
                <MessagesView/>
            </Grid>
        </Grid>
    </Paper>
}

export default ContactFormMessages;
