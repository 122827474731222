import React, {useState} from 'react';
import instance from "../../../apis";
import {toast} from "react-toastify";
import {Button, CircularProgress, Grid} from "@material-ui/core";
import {Form, Message, TextArea} from "semantic-ui-react";
import useInterval from "@use-it/interval";
import moment from "moment";

const ContactMessagesView = ({user_id, user_get_id, load, room, setRoom}) => {
    function MessagesView() {
        const [message, setMessage] = useState(null);
        const sendMessage = (e) => {
            e.preventDefault()
            const formData = new FormData(e.target)
            instance
                .post(`/api/contact/messages`, formData)
                .then(function (res) {
                    if (res.status == 200) {
                        setRoom(res.data.room)
                    } else {
                        toast.error('Veriler kaydedilemedi, lütfen tekrar deneyin')
                    }
                })
                .catch(function (res) {
                    toast.error('Veriler gönderilemedi, lütfen tekrar deneyin')
                })
        }
        if (load)
            return <CircularProgress/>
        return <Form onSubmit={sendMessage}>
            <Grid item container spacing={1} alignItems={"center"} alignContent={"center"}>
                <Grid item xs={12} sm={8}>
                    <TextArea onChange={e => setMessage(e.target.value)} value={message} name="message" placeholder='Mesaj yaz...'/>
                    <input type="hidden" name="room_id" value={room.id}/>
                    <input type="hidden" name="user_get_id" value={user_get_id}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Button
                        fullWidth
                        color={"primary"}
                        type="submit"
                        variant={"contained"}
                        size={"large"}
                    >GÖNDER</Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                    {/* <div className="chat-body chat-scroll" id="chat-scroll">*/}
                    <div>
                        <Grid item container spacing={1} alignItems={"center"} alignContent={"center"}>
                            <MessageView/>
                        </Grid>
                    </div>
                </Grid>

            </Grid>
        </Form>
    }

    function MessageView() {
        const [messageUsers, setMessageUsers] = useState(room.users);

        function getMessages() {
            instance
                .get(`/api/get/messages/${room.id}`)
                .then(function (result) {
                    setMessageUsers(result.data.room.users)
                })
        }

        useInterval(() => {
            getMessages()
        }, 5000)


        function UserMessageView({user}) {
            if (user.user_send_id == parseInt(user_id)) {
                return <Grid item xs={12} sm={12} container alignContent={"center"} alignItems={"center"} spacing={2}>
                    <Grid item xs={1} sm={3}></Grid>
                    <Grid item xs={9} sm={9} className="message">
                        <Message positive content={user.message.message}/>
                        <div className="message-tarih">
                            {moment(user.message.created_at).format('DD.MM.YYYY hh.mm')}
                        </div>
                    </Grid>
                </Grid>
            } else {
                return <Grid item xs={12} sm={12} container alignContent={"center"} alignItems={"center"} spacing={2}>
                    <Grid item xs={9} sm={9} className="message">
                        <Message content={user.message.message}/>
                        <div className="message-tarih">{moment(user.message.created_at).format('DD.MM.YYYY hh.mm')}</div>
                    </Grid>
                    <Grid item xs={1} sm={3}></Grid>
                </Grid>
            }
        }

        return messageUsers.map(user => <UserMessageView user={user}/>)
    }

    return <MessagesView/>
}

export default ContactMessagesView;
