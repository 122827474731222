import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import instance from "../../apis";
import {toast} from "react-toastify";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',

    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

export default function UserNote({user}) {
    const classes = useStyles();

    function noteView() {
        if (user.admin_note)
            return user.admin_note.note
        return '';
    }

    function submitForm(e) {
        e.preventDefault()
        const formData = new FormData(e.target)
        instance
            .post(`/api/admin/user/note/${user.id}`, formData)
            .then(function (result) {
                if (result.status === 200)
                    return toast.success('Notunuz başarıyla eklendi.')
                return toast.warn('Ekleme başarız oldu lütfen tekrar deneyin.')
            })
            .catch(function () {
                return toast.error('Bağlantı hatası.')
            })
    }

    return (<form onSubmit={submitForm}>
            <Paper component="form" className={classes.root}>
                <InputBase
                    className={classes.input}
                    placeholder="Senin görebileceğin bir not yaz.."
                    inputProps={{'aria-label': 'search google maps'}}
                    defaultValue={noteView()}
                    name={'note'}
                />
                <Divider className={classes.divider} orientation="vertical"/>
                <IconButton type="submit" color="primary" className={classes.iconButton} aria-label="directions">
                    <SaveIcon/>
                </IconButton>
            </Paper>
        </form>
    );
}
