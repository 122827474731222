import React, {useContext, useEffect, useState} from 'react';
import instance from "../../apis";
import {BreadsContext, PageContext, PageTitleContext} from "../../stores/PageStore";
import {useParams} from 'react-router-dom';
import moment from 'moment';
import {Divider, Grid, CircularProgress, Paper} from '@material-ui/core';
import useInterval from '@use-it/interval';
import coutDownViewer from '../../hooks/coutDownViewer'

function Cevaplar() {
    const {sinav_id} = useParams()
    const [sorular, setSorular] = useState([])
    const [load, setLoad] = useState(true)
    const [sinav, setSinav] = useState({})
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    const [second, setSecond] = useState(0);
    useEffect(() => {
        setLoad(true)
        instance
            .get(`/api/sinav/cevaplar/${sinav_id}`)
            .then(function (result) {
                if (result.status == 210) {
                    return '';
                }
                setSorular(result.data)
            })
        instance
            .get(`/api/sinav/${sinav_id}`)
            .then(function (result) {
                setSinav(result.data)
                setLoad(false)
            })
    }, [])
    useEffect(() => {
        const breads = [
            {
                link: '/sinavlar/all',
                title: 'Sınavlar',
                last: null
            },
            {
                link: null,
                title: load ? <CircularProgress style={{
                    width: 8,
                    height: 8
                }}/> : `${sinav.name} / Cevap Anahtarı`,
                last: true
            },
        ];
        setBreads(breads)
        setPage(17)
        setPageTitle('E-Sınav Sistemi')
        setLoad(false)

    }, [sinav])


    useInterval(() => {
        if (moment(sinav.bitis)
            .unix() > moment()
            .unix()) {
            setSecond(coutDownViewer(moment(sinav.bitis)
                .unix() - moment()
                .unix()))
        } else {
            setSecond(0)
        }
    }, 1000)

    if (!sinav.id) return <CircularProgress/>
    if (!sinav.bitis) {
        return <Paper className="paper-handler">Bu sınava henüz bitiş tarihi eklenmediği için cevaplar yayınlanamaz.</Paper>
    }
    if (!sinav.status || (sinav.status.bitir != 1 || sinav.status.tek == 1)) {
        return <Paper className="paper-handler">Bu sınavı henüz tamamlamadınız.</Paper>
    }
    if (second != 0) {
        return <Paper className="paper-handler">
            <Grid container>
                <Grid item xs={12} sm={12} justify={"center"}>
                    <div style={{
                        textAlign: "center",
                        fontSize: 20
                    }}>
                        <div> Yayınlanma zamanı</div>
                        <Divider/>
                        {moment(sinav.bitis)
                            .format('DD/MM/YYYY HH:mm')}
                    </div>
                    <div style={{textAlign: "center"}}>{second}</div>
                </Grid>
            </Grid>
        </Paper>;
    }
    return <Paper className="paper-handler">
        <Grid container>
            {sorular.map((item, index) => {
                return <Grid key={item.id} item xs={3} sm={1}>
                    <Grid style={{margin: 5}} container spacing={2} alignContent={"center"} alignItems={"center"}>
                        <Grid item xs={3} sm={3}>{item.no}.</Grid>
                        <Grid item xs={9} sm={9}><span style={{
                            color: "red",
                            fontWeight: 600
                        }}>{item.cevap.val}</span></Grid>
                    </Grid>
                    <Divider style={{height: 5}}/>
                </Grid>
            })}
        </Grid>
    </Paper>;
}

export default Cevaplar;
