import React from 'react';
import UserSearch from "./UserSearch";
import {Switch, Route} from 'react-router-dom';
import UserCard from "./UserCard";
import Sites from "./sites/Sites";
import Site from "./sites/Site";

function SuperAdminRoute() {
    let roles = '';
    try {
        roles = localStorage.getItem('roles')
    } catch (e) {
    }
    if (roles) {
        if (roles.includes('superadmin')) {
            return (
                <Switch>
                    <Route path={'/superadmin/user/search'} component={UserSearch}/>
                    <Route path={'/superadmin/get/user/card/:id'} component={UserCard}/>
                    <Route path={'/superadmin/sites/:id'} component={Site}/>
                    <Route path={'/superadmin/sites'} component={Sites}/>
                </Switch>
            )
        } else {
            return <></>;
        }
    } else {
        return <></>;
    }
}

export default SuperAdminRoute;
