import React, {useContext, useState} from 'react';
import {Editor} from '@tinymce/tinymce-react';
import {EditorContext} from "./EditorStore";

function TinyEditor({page}) {
    const [, setEditorBody] = useContext(EditorContext)
    return <Editor
        initialValue={page.body}
        init={{
            height: 500,
            menubar: false,
            plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
                'undo redo | formatselect | bold italic backcolor forecolor | \
                alignleft aligncenter alignright alignjustify table | \
                bullist numlist outdent indent | removeformat | help'
        }}
        onEditorChange={(content, editor) => setEditorBody(content)}
    />
}

export default TinyEditor;
