import React, {useContext, useEffect, useState} from 'react';
import {BreadsContext, PageContext, PageTitleContext} from "../stores/PageStore";
import {Button, TextareaAutosize, TextField, Grid, Divider, Paper, CircularProgress} from "@material-ui/core";
import StayPrimaryPortraitIcon from '@material-ui/icons/StayPrimaryPortrait';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import instance from "../apis";
import {toast} from "react-toastify";

import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import ContactMessagesView from "./admin/contact/ContactMessagesView";

const Contact = () => {
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    const [contact, setContact] = useState({});
    const [load, setLoad] = useState(false)
    const [sendMessage, setSendMessage] = useState(false)
    const [room, setRoom] = useState({users: []});
    const [, setRooms] = useState([]);
    let login = ''
    let user_id = ''
    try {
        login = localStorage.getItem('login');
        user_id = localStorage.getItem('user_id');
    } catch (e) {

    }


    useEffect(() => {
        setLoad(true)
        instance
            .get(`/api/contact/items`)
            .then(function (result) {
                setLoad(false)
                if (result.status === 200) {
                    setContact(result.data.item)
                } else {
                    toast.warn(result.data.msg)
                }
            })
        if (login == 'true') {
            instance
                .get(`/api/contact/messages`)
                .then(function (result) {
                    setLoad(false)
                    if (result.status == 200) {
                        if (result.data.room) {
                            setRoom(result.data.room)
                        }
                        setRooms(result.data.rooms)
                    } else {
                        toast.warn('Veriler çekilirken bir hata meydana geldi, lütfen yeniden deneyin.')
                    }
                })
                .catch(function () {
                    toast.error('Bağlantı hatası, lütfen yeniden deneyin.')
                })
        }

    }, [])
    useEffect(() => {
        const breads = [
            {
                link: null,
                title: 'İletişim',
                last: null
            },

        ];
        setBreads(breads)
        setPage(24)
        setPageTitle('İletişim')
    }, [])

    function formSubmit(e) {
        e.preventDefault()
        const formData = new FormData(e.target);
        instance
            .post(`/api/contact`, formData)
            .then(function (result) {
                if (result.status == 200) {
                    toast.success(result.data.msg)
                    setSendMessage(true)
                } else {
                    toast.warn(result.data.msg)
                }
            })
    }

    if (load) {
        return <CircularProgress/>
    }

    function FormContent() {
        if (login == 'true') {
            return <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <ContactMessagesView user_get_id={room.site_id} load={load} room={room} user_id={user_id} setRoom={setRoom}/>
                </Grid>
            </Grid>
        }
        if (sendMessage) {
            return <div style={{width: "100%", textAlign: "center"}}>
                <CheckCircleOutlineIcon style={{width: 135, height: 135, color: "#94d3ac"}}/>
                <div><Divider/></div>
                En kısa zamanda değerlendirip sizinle iletişime geçeceğiz. İlginize teşekkürler.
            </div>
        }
        return <form onSubmit={formSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <TextField
                        required
                        fullWidth
                        label="İsminiz"
                        name="name"
                        variant={"outlined"}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        required
                        type="email"
                        fullWidth
                        name="email"
                        label="Email"
                        variant={"outlined"}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        required
                        type="number"
                        fullWidth
                        name="tel"
                        label="Telefon"
                        variant={"outlined"}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextareaAutosize
                        id={"msg"}
                        name={"msg"}
                        required
                        className="textarea"
                        rows={5}
                        placeholder="Nasıl yardım edebiliriz?"
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Button type="submit" fullWidth color="primary" variant={"contained"}>GÖNDER</Button>
                </Grid>
                <Grid item xs={12} sm={12}></Grid>
            </Grid>
        </form>

    }


    function PhoneView({tel}) {
        if (tel == '' || tel == null) {
            return <></>
        }
        return <Grid container item xs={12} sm={12}>
            <Grid item xs={2} sm={2}>
                <StayPrimaryPortraitIcon style={{width: 40, height: 40}}/>
            </Grid>
            <Grid item xs={10} sm={10}>
                <a style={{fontSize: 30}} href={`tel:${tel}`}>
                    {tel}
                    {/*<NumberFormat value={phone(tel)} displayType={'text'} format="## ### ### ## ##"/>*/}
                </a>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Divider/>
            </Grid>
        </Grid>
    }

    function FormView() {
        return <>
            <Grid container spacing={2}>
                <Grid conrainer item xs={12} sm={6}>
                    <Paper className="paper-handler">
                        <Grid container spacing={2} justify={"center"} alignItems={"center"} alignContent={"center"}>
                            <PhoneView tel={contact.tel_1}/>
                            <PhoneView tel={contact.tel_2}/>
                            <PhoneView tel={contact.tel_3}/>
                            <Grid item xs={2} sm={2}>
                                <AlternateEmailIcon style={{width: 40, height: 40}}/>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <span style={{fontSize: 24}}>{contact.email}</span>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider/>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid conrainer item xs={12} sm={6}>
                    <Paper className="paper-handler">
                        <FormContent/>
                    </Paper>
                </Grid>
            </Grid>
        </>
    }

    return <>
        <FormView/>
    </>
}

export default Contact;
