import React, {useState, createContext} from 'react';

export const BasketContext = createContext([]);
export const Basket = props => {
    const [basket, setBasket] = useState({products: []})

    return (
        <BasketContext.Provider value={[basket, setBasket]}>
            {props.children}
        </BasketContext.Provider>
    );
}
