import React, {useEffect, useState, createRef} from 'react';

import {Box, ButtonGroup, Divider,} from '@material-ui/core';
import instance from '../../../../apis';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment'
import {CircularProgress, Button, List, Grid, Paper} from '@material-ui/core';
import colors from '../../../../styles/colors';
import Convert from './Convert';
import Confirm from '../../../../components/AlertDialog';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}));


const ListComponent = (props) => {
    const classes = useStyles();
    const [loadContex, setLoadContex] = useState(false)
    const [rooms, setRooms] = useState([])
    const [refresh, setRefresh] = useState(false)
    const alertDialogRef = React.createRef();
    const [deletedID, setDeletedID] = useState({})
    useEffect(() => {
        instance
            .get('/api/admin/bbb/record/list')
            .then(function (res) {
                setLoadContex(false)
                console.log('res.data', res.data.data)
                setRooms(res.data.data);
            })
    }, [])


    const indir = (url) => {
        window.open(url, '_blank');
    }

    const izle = (url) => {
        // alert(JSON.stringify(url.format))
        window.open(url, '_blank');
    }

    if (loadContex) {
        return <CircularProgress/>;
    }

    const convert = ({recordID, meetingID, index}) => {

        var data = {recordID, meetingID}

        instance
            .post('/api/admin/bbb/record/convert', data)
            .then(function (res) {

                var rooms_new = rooms;
                rooms_new[index]['room'] = res.data.data;

                console.log('rooms_new', rooms_new);
                setRooms(rooms_new);
                setRefresh(!refresh);
            })

    }

    const durum = (durum) => {
        switch (durum) {
            case 0:
                return 'Bekliyor...';
            case 1:
                return 'Dönüştürülüyor...';
            case 2:
                return 'Dönüştürüldü...';
            case 3:
                return 'BBB Kaydı Olarak Yayınlanmış...';
        }
    }

    const del = (res) => {
        if (res == true) {
            var data = {recordID: deletedID.recordID}
            instance
                .post('/api/admin/bbb/record/del', data)
                .then(function (res) {
                    var rooms_new = rooms;
                    rooms_new.splice(deletedID.index, 1);
                    setRooms(rooms_new);
                    setRefresh(!refresh);
                })
        }
    }
    return <>
        <Grid container>
            <Confirm ref={alertDialogRef} response={(res) => del(res)} title="Uyarı" metin="Silmek istediğinize emin misiniz?"/>
            <Grid item xs={12} sm={12}>
                <h3>Canlı Sınıf Kayıt Listesi</h3>
                <Paper style={{padding: 15}}>
                    {rooms.length > 0 && <List className={classes.root} subheader={<li/>}>
                        {rooms.map((room, index) =>
                            <Box>
                                <Grid container direction="row" style={{marginBottom: 10, marginTop: 10}}>
                                    <Grid item xs={12} sm={7}>
                                        <Grid container>
                                            <Grid item xs={1} sm={1}>
                                                <span style={{fontSize: 16,}}>{room.room.id}</span>
                                            </Grid>
                                            <Grid item xs={11} sm={11}>
                                                <span style={{fontSize: 16,}}>{room.record.name} <span style={{fontSize: 11}}>{room.room.room_aciklama}</span></span>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <span style={{fontSize: 12}}>{moment(room.room.baslama_tarih).format('DD.MM.YYYY - hh:mm')}</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Grid item>
                                            <span style={{fontSize: 16,}}>{(Math.round(room.record.size / 1024 / 1024) == 0 ? (Math.round(room.record.size / 1024)) + ' Kb' : (Math.round(room.record.size / 1024 / 1024)) + ' Mb')}</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={3} justify="center" style={{paddingTop: 5}}>
                                        <Grid container alignContent="center" alignItems="center" justify="flex-end">
                                            <ButtonGroup color="primary" aria-label="outlined primary button group" size="small">
                                                <Button
                                                    variant="contained"
                                                    style={{backgroundColor: colors.alternateColor[0], color: colors.text}}
                                                    className={classes.button}
                                                    // endIcon={<Close />}
                                                    onClick={() => {
                                                        setDeletedID({recordID: rooms[index]['record']['recordID'], index})
                                                        alertDialogRef.current.open();
                                                    }}
                                                >
                                                    Sil
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    style={{backgroundColor: colors.alternateColor[1], color: colors.text}}
                                                    className={classes.button}
                                                    onClick={() => {
                                                        indir(room.room.bbb_convert_url);
                                                    }}
                                                    // endIcon={<Edit />}
                                                >
                                                    İndir
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        izle(room.record.playback.format.url);
                                                    }}
                                                    variant="contained"
                                                    style={{backgroundColor: colors.btnBgYesil2, color: colors.white}}
                                                    className={classes.button}
                                                    // endIcon={<ArrowRight />}
                                                >
                                                    İzle
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        convert({recordID: room.record.recordID, meetingID: room.record.meetingID, index});
                                                    }}
                                                    variant="contained"
                                                    style={{backgroundColor: colors.btnBgMavi, color: colors.white}}
                                                    className={classes.button}
                                                    // endIcon={<ArrowRight />}
                                                >
                                                    Yayını Dönüştür ve Yayınla
                                                </Button>
                                            </ButtonGroup>
                                        </Grid>
                                    </Grid>
                                    {/* {room.room.bbb_convert > 0 &&<Grid item xs={12} sm ={12} >{JSON.stringify(room.room)}</Grid>} */}
                                    {room.room.bbb_convert == 1 &&
                                    <Grid item xs={12} sm={12}>

                                        <Grid container>
                                            <Grid item style={{marginRight: 15}}>
                                                <CircularProgress size={12}/>
                                            </Grid>
                                            <Grid item xs={11} sm={11}>
                                                <p>{durum(room.room.bbb_convert)}</p>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                    }
                                    {room.room.bbb_convert == 2 &&
                                    <Grid item xs={12} sm={12}>
                                        <Grid container>
                                            <Grid item xs={11} sm={11}>
                                                <p>{durum(room.room.bbb_convert)}</p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    }
                                </Grid>
                                <Divider/>
                            </Box>
                        )}
                    </List>}
                </Paper>
            </Grid>
        </Grid>
    </>

}

export default ListComponent;
