import React, {useEffect, useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    item       : {
        margin    : 2,
        color     : "#333333",
        width     : 23,
        fontSize  : 13,
        textIndent: 1,
        cursor    : "pointer",


    },
    cevap      : {
        webkitBorderRadius: 40,
        borderRadius      : 40,
        border            : 1,
        borderColor       : "#fc913a",
        borderStyle       : "solid",
        '&:hover'         : {
            backgroundColor: '#ccc'
        }
    },
    activeCevap: {
        backgroundColor: "rgba(85,85,85,.4)"
    },
    soru       : {
        textAlign : "center",
        textIndent: 3
    }
}));

function CevapAnahtariYatay(props) {
    const [cevaplar, setCevaplar] = useState([])
    useEffect(() => {
        setCevaplar(props.cevaplar)
        props.reLoadFalse()
    }, [props])
    const classes = useStyles();
    if (!props.sorular) return <></>
    const TableView = () => {
        function getCevap(soru_no) {
            try {
                return cevaplar.filter(x => x.id == soru_no)[0].cevap

            } catch (e) {
                return null
            }
        }

        const cevaps = ['A', 'B', 'C', 'D', 'E']
        return props.sorular.map(soru => <div style={{float: "left"}} className={`${classes.item} ${classes.soru}`} key={soru.id}>
            {soru.no}
            {cevaps.map(cevap => <div
                onClick={() => props.cevapAnahtariCevapSec(cevap, soru)}
                key={cevap}
                className={`${classes.item} ${classes.cevap} ${getCevap(soru.id) == cevap ? classes.activeCevap : ''}`}>{cevap}</div>)}
        </div>)
    }
    return <TableView/>;
}

export default CevapAnahtariYatay;