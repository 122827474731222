import moment from "moment";
import 'moment/locale/tr'
import countdown from 'moment-countdown';// çalışıyor

export default function timer(date = '2020-08-18 10:00') {
    const count = moment(date)
        .locale('tr')
        .countdown()
    const gun   = (count.months * 30) + count.days;
    const time  = {
        days : gun,
        hours: count.hours,
        min  : count.minutes,
        sn   : count.seconds
    }
    return time;
}