import React, {useEffect, useState} from 'react';
import instance from "../../apis";
import {CircularProgress} from "@material-ui/core";
import {Icon, Segment} from "semantic-ui-react";
import Grid from "@material-ui/core/Grid";

const KreditCard = () => {
    const [odeButton, setOdeButton] = useState(null)
    useEffect(() => {
        instance
            .get(`/api/shopier/button`)
            .then(function (res) {
                setOdeButton(res.data)
            })
    }, [])
    if (!odeButton)
        return <CircularProgress/>
    return <>

        <Segment>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={12}></Grid>
                <Grid item xs={12} sm={12}></Grid>
                <Grid item xs={12} sm={12}>
                    <div dangerouslySetInnerHTML={{__html: odeButton}}></div>
                </Grid>
                <Grid item xs={12} sm={12}></Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={4}>
                        <Grid item xs={3} sm={3}>
                            <Icon style={{fontSize: 64, color: "#dbdbdb", textAlign: "center"}} className={"credit card"}></Icon>
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <Icon style={{fontSize: 64, color: "#dbdbdb", textAlign: "center"}} className={"cc visa"}></Icon>
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <Icon style={{fontSize: 64, color: "#dbdbdb", textAlign: "center"}} className={"cc mastercard"}></Icon>
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <Icon style={{fontSize: 64, color: "#dbdbdb", textAlign: "center"}} className={"cc amex"}></Icon>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Segment>
    </>


}

export default KreditCard;
