import React, {useState} from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {Segment, Button} from "semantic-ui-react";
import {Grid} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

function CropImageViewV2({maxWidth, width, aspect, file_id}) {
    const [states, setStates] = useState(
        {
            src: null,
            file_id: file_id,
            crop: {
                unit: '%',
                maxWidth: maxWidth,
                width: width,
                aspect: aspect,

            },
            base64: null
        })
    const fileSelect = (file_id) => {
        const file = document.getElementById(file_id);
        file.click()
    }
    const onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                setStates(states => ({...states, src: reader.result}))
            );

            const read = reader.readAsDataURL(e.target.files[0]);
        }
    };
    // If you setState the crop in here you should return false.
    const onImageLoaded = image => {
        setStates(states => ({...states, imageRef: image, base64: image.src}))
        onCropComplete(image)
    };

    const onCropComplete = crop => {
        makeClientCrop(crop);

    };

    const onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        setStates(states => ({...states, crop: crop}))
    };

    const makeClientCrop = async (crop) => {
        if (states.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(
                states.imageRef,
                states.crop,
                'newFile.jpeg'
            );
            setStates(states => ({...states, croppedImageUrl: croppedImageUrl}))
        }
    }

    function getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            states.crop.x * scaleX,
            states.crop.y * scaleY,
            states.crop.width * scaleX,
            states.crop.height * scaleY,
            0,
            0,
            states.crop.width,
            states.crop.height
        );

        const base64 = canvas.toDataURL()
        setStates(states => ({...states, base64: base64}))

        const getUrl = new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                let fileUrl;
                window.URL.revokeObjectURL(fileUrl);
                fileUrl = window.URL.createObjectURL(blob);
                resolve(fileUrl);
            }, 'image/jpeg');
        });
        return getUrl;
    }

    function SelectFile() {
        if (states.src)
            return <Button
                color='red'
                size={"small"}
                variant={"contained"}
                onClick={() => setStates(states => ({...states, src: null}))}
                style={{position: "absolute", top: 0, right: 10}}
            >
                <DeleteForeverIcon/>
            </Button>
        return <Segment onClick={() => fileSelect(states.file_id)} style={{border: "2px dotted #888888"}}>
            <Grid container justify={"center"} alignItems="flex-end">
                <Grid style={{textAlign: "center", padding: 60,}} item xs={12} sm={12}>
                    <Button type={"button"} basic size={"massive"} color='grey'>Resim Seç</Button>
                </Grid>
            </Grid>
        </Segment>
    }

    return <>
        <Grid container>
            <Grid item xs={12} sm={12} style={{position: "relative"}}>
                {states.src && (
                    <ReactCrop
                        src={states.src}
                        crop={states.crop}
                        ruleOfThirds
                        onImageLoaded={onImageLoaded}
                        onComplete={onCropComplete}
                        onChange={onCropChange}
                    />
                )}
                <SelectFile/>
            </Grid>
        </Grid>
        <input id={states.file_id ? states.file_id : 'file'} type="file" style={{display: "none"}} onChange={onSelectFile}/>
        {states.base64 && (
            <input value={states.base64} style={{display: "none"}} name={states.file_id ? states.file_id : 'file'}/>
        )}
    </>
}


export default CropImageViewV2
