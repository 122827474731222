export const trLower = (str) => {
    if (!str) return str
    var string = str;
    var letters = {"İ": "i", "I": "ı", "Ş": "ş", "Ğ": "ğ", "Ü": "ü", "Ö": "ö", "Ç": "ç"};
    string = string.replace(/(([İIŞĞÜÇÖ]))/g, function (letter) {
        return letters[letter];
    })
    return string.toLowerCase();
}
export const trUpper = (str) => {
    if (!str) return str
    var string = str;
    var letters = {"i": "İ", "ş": "Ş", "ğ": "Ğ", "ü": "Ü", "ö": "Ö", "ç": "Ç", "ı": "I"};
    string = string.replace(/(([iışğüçö]))/g, function (letter) {
        return letters[letter];
    })
    return string.toUpperCase();
}

export const seoLink = (str) => {
    if (!str) return str
    var string = str;
    const strings = string.split(" ")
    string = '';
    strings.map((item, index) => {
        string += trLower(item).replace("/", "-");
        if (strings.length > (index + 1))
            string += "-"
    })
    return string.toLowerCase();
}
export const breadLink = (str) => {
    if (!str) return str
    var string = str;
    const strings = string.split('-')
    string = '';
    strings.map(item => {
        string += trUpper(item.charAt(0)) + item.slice(1) + " "
    })
    return string;

}
