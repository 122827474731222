import React, {useEffect, useState, useContext} from 'react';
import instance from '../../../apis';
import Grid from '@material-ui/core/Grid';
import {Divider, Paper, Tooltip} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import {Link, useParams} from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import {BreadsContext, PageContext, PageTitleContext} from "../../../stores/PageStore";
import GetAppIcon from '@material-ui/icons/GetApp';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {YouTube} from "@material-ui/icons";
import {Icon} from "semantic-ui-react";

const Videos = () => {
    const [myFiles, setMyFiles] = useState([])
    const {category_id} = useParams()
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    const {t} = useTranslation();

    useEffect(() => {

        if (category_id != undefined && category_id != 0) {
            instance.get(`/api/admin/videos/${category_id}`)
                .then(function (res) {
                    setMyFiles(res.data)
                })
        } else if (category_id == null) {
            instance.get(`/api/admin/videos`)
                .then(function (res) {
                    setMyFiles(res.data)
                })
        }


    }, [])
    useEffect(() => {
        const breads = [
            {
                link: null,
                title: 'Videolar',
                last: true
            }
        ]
        setBreads(breads)
        setPage(4)
        setPageTitle('Eklediğim Videolar')
    }, [])
    let user_id = '';
    try {
        user_id = localStorage.getItem('user_id');
    } catch (e) {

    }

    function deleteVideo(id) {
        if (global.confirm('Silmek istediğinizden emin misiniz?')) {
            instance.delete('/api/admin/video/' + id)
                .then(function () {
                    const files = myFiles.filter(x => x.id != id)
                    setMyFiles(files)
                })
        }
    }

    function filePercent(file) {
        return file.percent >= 99 ? 100 : file.percent ? file.percent : 0
    }

    function FileFoundView({item}) {
        if (item.publish_type === 3)
            return <Icon className={"vimeo v"} style={{fontSize: 36, color: "aqua", marginBottom: 10, marginRight: 5}}/>
        if (item.publish_type === 2)
            return <YouTube style={{fontSize: 42, color: "red"}}/>
        if (item.not_found == 1)
            return <Tooltip title={t('Dosya uzak sunucuda bulunamadı, lütfen yeniden yüklemeyi deneyin.')}>
                <CloudUploadIcon style={{fontSize: 42, color: "red"}}/>
            </Tooltip>
        return <Tooltip title={t('Dosya mevcut')}>
            <CloudUploadIcon style={{fontSize: 42, color: "green"}}/>
        </Tooltip>

    }

    function ProgressView({file}) {
        if (file.publish_type === 2)
            return <Paper className="paper-handler">{t('Youtube bağlantısı')}</Paper>
        if (file.publish_type === 3)
            return <Paper className="paper-handler">{t('Vimeo bağlantısı')}</Paper>
        return <Grid container alignItems={"center"} alignContent={"center"}>
            <Grid style={{fontSize: 9}} item xs={2} sm={2}>
                %{filePercent(file)}
            </Grid>
            <Grid item xs={10} sm={10}>
                <Paper className="paper-handler">
                    <LinearProgress variant="determinate" color={"secondary"} value={filePercent(file)}/>
                </Paper>
            </Grid>
        </Grid>
    }

    const FileRow = () => {
        return myFiles.map(file => <Grid key={file.id} spacing={1} alignItems={"center"} container>
            <Grid item xs={12} sm={4}>
                <Paper className="paper-handler">{file.title}</Paper>
            </Grid>
            <Grid item xs={12} sm={2}>
                <ProgressView file={file}/>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Paper className="paper-handler">
                    {
                        file.categories.map((item, index) => {
                            if (file.categories.length > index + 1) {
                                return item.title + ', '
                            } else {
                                return item.title
                            }
                        })}
                </Paper>
            </Grid>
            <Grid item xs={12} sm={2}>
                <Grid container alignItems={"center"} justify={"space-between"} alignContent={"center"}>
                    <Grid item xs={3} sm={3}>
                        <FileFoundView item={file}/>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                        <a href={`https://lms.rotasinav.com/api/admin/video/download/${file.name}${file.exs}`}>
                            <Fab color="primary" size="small" aria-label="Edit">
                                <GetAppIcon/>
                            </Fab>
                        </a>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                        <Link to={`/admin/video/edit/${file.id}/a5d5asd4as`}>
                            <Fab color="primary" size="small" aria-label="Edit">
                                <EditIcon/>
                            </Fab>
                        </Link>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                        <Fab color="secondary" size="small" onClick={() => deleteVideo(file.id)} aria-label="Edit">
                            <DeleteIcon/>
                        </Fab>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={12} sm={12} style={{marginBottom:10}}><Divider/></Grid>
        </Grid>)
    }
    return <FileRow/>

}

export default Videos;
