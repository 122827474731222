import React, {useEffect, useContext, useState} from 'react';
import {BreadsContext, PageContext, PageTitleContext} from "../../../stores/PageStore";
import {TextField, Paper, Grid, Button, CircularProgress, Switch, Divider, FormControlLabel, FormGroup} from "@material-ui/core";
import instance from "../../../apis";
import {toast} from "react-toastify";
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const SalesConfig = () => {
    const [, setBreads] = useContext(BreadsContext);
    const [pageTitle, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    const [load, setLoad] = useState(false);
    const [loadCategories, setLoadCategories] = useState(true);
    const [formItems, setFormItems] = useState({
        easy_license: false,
        lisans_bitis_aktif: false,
        yillik_satis: true,
        all_categories: false,
        finished_at_update_for_all_users: false,
        categories: [],
        easyLicenseSelectedCategories: [],
        easy_license_category_ids: [],
        lisans_bitis: moment().format('YYYY-MM-DD')
    });

    useEffect(() => {
        const breads = [
            {
                link: null,
                title: 'Satın Alma Ayarları',
                last: true
            }
        ]
        setBreads(breads)
        setPageTitle('Satın Alma Ayarları')
        setPage(28)
    }, [])
    useEffect(() => {
        setLoad(true)
        if (loadCategories)
            instance
                .get(`/api/admin/sales/config`)
                .then(function (result) {
                    setLoad(false)
                    if (result.status == 200) {
                        if (result.data.item) {
                            const item = result.data.item;
                            let categories = item.easy_license_category_ids.replace(/"/g, '')
                            const ids = JSON.parse(categories);
                            setFormItems(formItems => ({
                                ...formItems,
                                easy_license: item.easy_license == 1 ? true : false,
                                lisans_bitis_aktif: item.lisans_bitis_aktif == 1 ? true : false,
                                all_categories: item.all_categories == 1 ? false : true,
                                yillik_satis: item.yillik_satis == 1 ? true : false,
                                finished_at_update_for_all_users: item.finished_at_update_for_all_users == 1 ? true : false,
                                easy_license_category_ids: JSON.parse(item.easy_license_category_ids),
                                easyLicenseSelectedCategories: formItems.categories.filter(x => ids.includes(x.id)),
                                lisans_bitis: item.lisans_bitis ? item.lisans_bitis : moment().format('YYYY-MM-DD')
                            }))

                        }
                    } else {
                        toast.warn('Veriler çekilirken bir hata meydana geldi, lütfen yeniden deneyin.')
                    }
                })
                .catch(function () {
                    toast.error('Bağlantı hatası, lütfen yeniden deneyin.')
                })
    }, [loadCategories])
    useEffect(() => {
        instance
            .get(`/api/admin/all/categories/pure`)
            .then(function (result) {
                setFormItems(formItems => ({...formItems, categories: result.data.items}))
                setLoadCategories(true)
            })
    }, [])

    const formSubmit = () => {
        const formData = new FormData()
        formData.append('easy_license', formItems.easy_license)
        formData.append('lisans_bitis', formItems.lisans_bitis)
        formData.append('lisans_bitis_aktif', formItems.lisans_bitis_aktif)
        formData.append('finished_at_update_for_all_users', formItems.finished_at_update_for_all_users)
        formData.append('all_categories', formItems.all_categories)
        formData.append('easy_license_category_ids', JSON.stringify(formItems.easy_license_category_ids))
        instance
            .post(`/api/admin/sales/config`, formData)
            .then(function (res) {
                if (res.status == 200) {
                    toast.success('Değişiklikler kaydedildi.')
                } else {
                    toast.error('Veriler kaydedilemedi, lütfen tekrar deneyin')
                }
            })
            .catch(function (res) {
                toast.error('Bağlantı problemi, veriler gönderilemedi, lütfen tekrar deneyin')
            })
    }

    function SelectCategories() {
        if (!formItems.easy_license)
            return <></>
        if (!formItems.all_categories)
            return <>Tüm kategoriler tanımlanır.</>
        if (!Array.isArray(formItems.categories) || formItems.categories.length < 1)
            return <></>
        return <Autocomplete
            size={"small"}
            multiple
            id="easyLicenseSelectedCategories"
            options={formItems.categories}
            getOptionLabel={(option) => option.title}
            defaultValue={formItems.easyLicenseSelectedCategories}
            filterSelectedOptions
            onChange={handleChangeValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Kategori Seçimi"
                    placeholder="Seçeceğiniz kategoriler hızlı lisanslama için kullanılacaktır."
                />
            )}
        />
    }

    function EasyLicenseLastDate() {
        function YillikAylikSatis() {
            const dt = new Date();
            if (formItems.yillik_satis) {
                dt.setFullYear(dt.getFullYear() + 1)
                return <>Kullanıcılar <span style={{color: "#6a097d", fontWeight: "bold"}}>bir yıllığına</span> lisans satın alır.
                    Lisans bitiş tarihi: <span style={{color: "#00005c", fontWeight: "bold"}}>{moment(dt).format('DD.MM.YYYY')}</span>
                </>
            }
            dt.setMonth(dt.getMonth() + 1)
            return <>Kullanıcılar <span style={{color: "#6a097d", fontWeight: "bold"}}>bir aylığına</span> lisans satın alır.
                Lisans bitiş tarihi: <span style={{color: "#6a097d", fontWeight: "bold"}}>{moment(dt).format('DD.MM.YYYY')}</span>
            </>
        }

        if (!formItems.lisans_bitis_aktif)
            return <>
                <Grid container alignItems={"center"} alignContent={"center"}>
                    <Grid item xs={4} sm={1}>
                        <Switch
                            id={"yillik_satis"}
                            checked={formItems.yillik_satis}
                            onChange={handleChangeChecked}
                            color="primary"
                            name="yillik_satis"
                            inputProps={{'aria-label': 'primary checkbox'}}
                        />
                    </Grid>
                    <Grid item xs={4} sm={11}>
                        <YillikAylikSatis/>
                    </Grid>
                </Grid>
            </>
        return <Grid spacing={2} container alignContent={"center"} alignItems={"center"}>
            <Grid item xs={12} sm={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy"
                        margin="normal"
                        id="easy_license_last_date"
                        name="lisans_bitis"
                        label="Lisans Bitiş Tarihi"
                        value={formItems.lisans_bitis ? formItems.lisans_bitis : new Date()}
                        onChange={lisansBitisChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={8}>
                Lisans bitiş tarihi aktifleştirildiğinde, kategoriye tanımlı lisans bitiş tarihi yok ise bu tarihe bakılır.
            </Grid>
        </Grid>
    }


    const handleChangeChecked = (event) => {
        setFormItems({...formItems, [event.target.name]: event.target.checked});
    };
    const handleChange = (event) => {
        setFormItems({...formItems, [event.target.name]: event.target.value});
    };
    const lisansBitisChange = (date) => {
        setFormItems({...formItems, lisans_bitis: moment(date).format('YYYY-MM-DD')});
    };
    const handleChangeValue = (event, value) => {
        const items = [];
        const itemIds = [];
        value.map((item, index) => {
            items.push(item)
            itemIds.push(item.id)
        })
        setFormItems({...formItems, easyLicenseSelectedCategories: items, easy_license_category_ids: itemIds});
    };

    if (load)
        return <CircularProgress/>

    function AllUserLicenseUpdateText() {
        if (!formItems.finished_at_update_for_all_users) {
            return <></>
        }
        return <>
            Tüm aktif kullanıcıların <b>Lisans Bitiş Tarihini: </b>
            <span style={{color: "red"}}>{moment(formItems.lisans_bitis).format('DD.MM.YYYY')}</span> olarak günceller.
            Bu işlem geri alınamaz.
        </>
    }

    function UserLicenseUpdateView() {
        if (!formItems.lisans_bitis_aktif)
            return <></>
        return <>
            <Grid item xs={12} sm={4}>
                <Grid container justify={"space-between"} alignItems={"center"}>
                    <Grid item xs={2} sm={2}>
                        <Switch
                            id={"finished_at_update_for_all_users"}
                            checked={formItems.finished_at_update_for_all_users}
                            onChange={handleChangeChecked}
                            color="primary"
                            name="finished_at_update_for_all_users"
                            inputProps={{'aria-label': 'primary checkbox'}}
                        />
                    </Grid>
                    <Grid item xs={10} sm={10}><h5>Aktif Lisanları Güncelle</h5>Aktif Kullanıcı Lisans Tarihlerinin Güncellenmesi </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={8}>
                <AllUserLicenseUpdateText/>
            </Grid>
        </>
    }

    return <Paper className="paper-handler">
        <FormGroup>
            <Grid container spacing={2} alignItems={"center"} alignContent={"center"}>
                <Grid item xs={12} sm={12}><h2>{pageTitle}</h2></Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container alignItems={"center"} alignContent={"center"} spacing={1}></Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Grid container alignItems={"center"} alignContent={"center"}>
                        <Grid item xs={2} sm={2}>
                            <Switch
                                id={"easy_license"}
                                checked={formItems.easy_license}
                                onChange={handleChangeChecked}
                                color="primary"
                                name="easy_license"
                                inputProps={{'aria-label': 'primary checkbox'}}
                            />
                        </Grid>
                        <Grid item xs={10} sm={10}>
                            <h5>Hızlı Lisanslama</h5> (Tek butonla kullanıcıları lisanslayın.)
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2} sm={2}>
                    <Grid container alignItems={"center"} alignContent={"center"}>
                        <Grid item xs={4} sm={4}>
                            <Switch
                                id={"all_categories"}
                                checked={formItems.all_categories}
                                onChange={handleChangeChecked}
                                color="primary"
                                name="all_categories"
                                inputProps={{'aria-label': 'primary checkbox'}}
                            />
                        </Grid>
                        <Grid item xs={8} sm={8}>
                            Belli Kategoriler
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SelectCategories/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Grid container justify={"space-between"} alignItems={"center"}>
                        <Grid item xs={2} sm={2}>
                            <Switch
                                id={"lisans_bitis_aktif"}
                                checked={formItems.lisans_bitis_aktif}
                                onChange={handleChangeChecked}
                                color="primary"
                                name="lisans_bitis_aktif"
                                inputProps={{'aria-label': 'primary checkbox'}}
                            />
                        </Grid>
                        <Grid item xs={10} sm={10}><h5>Lisans Bitiş Tarihi</h5> (Lisans bitiş tarihini aktifleştir)</Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <EasyLicenseLastDate/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Divider/>
                </Grid>
                <UserLicenseUpdateView/>
                <Grid item xs={12} sm={12}>
                    <Button onClick={formSubmit} color={'primary'} fullWidth variant={"contained"}>DEĞİŞİKLİKLERİ KAYDET</Button></Grid>
            </Grid>
        </FormGroup>
    </Paper>

}

export default SalesConfig;
