import React, {useState, useLayoutEffect, useContext, useEffect} from 'react';
import {Container, Box, Divider, Grid, TextField, Paper, Button, IconButton, ButtonGroup} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import colors from '../../../../styles/colors';
import ConfirmDialog from '../../../../components/ConfirmDialog'
// import UsersList from './UserList'
import {toast} from "react-toastify";
import instance from '../../../../apis';
import {Link} from "react-router-dom"
import {BreadsContext, PageContext, PageTitleContext} from "../../../../stores/PageStore";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit"
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PeopleOutline from '@material-ui/icons/PeopleOutline';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import Row from './Row';
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        //   backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    li: {
        color: colors.text
    },
}));
const Add = (props) => {
    const classes = useStyles();

    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    const [loading, setLoading] = useState(true);
    const [datas, setDatas] = useState([]);
    const [refresh, setRefresh] = useState(false);

    useLayoutEffect(() => {
        setPageTitle('Sınıflar')
        const breads = [
            {
                link: null,
                title: 'Sınıf Listesi',
                last: true
            }
        ]
        setBreads(breads)
        setPage(31.2)
        setLoading(false)
    }, []);

    useEffect(() => {
      
        instance.get('/api/admin/classroom/list')
        .then(function (res) {
            setDatas(res.data);
        })

    }, [])

    const userList = (index) => {
        console.log(index)
    }
    
    const deleteItem = (deleteItem) => {
        datas.splice(deleteItem.index, 1);
        setDatas(datas);
        setRefresh(!refresh);
    }

    return <> 
      <Grid container spacing={1} container alignItems={"center"} alignContent={"center"}>
            
            <Grid item xs={12} sm={12}>
                <Paper className="paper-handler">
                    <Grid container spacing={1} className={"largeHead"} style={{fontWeight: "bold", fontSize: 13}}>
                        <Grid item xs={12} sm={5}>
                            Sınıf Adı
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            Şube Kodu
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            Öğrenci Sayısı
                        </Grid>
                        <Grid item xs={12} sm={3} style={{textAlign: "center"}}>
                            İşlemler
                        </Grid>
                    </Grid>
                    {datas.map((data, index) => <Row data={data} deleteItem={deleteItem} index={index} props = {props} />)}
                </Paper>
            </Grid>
        </Grid>

      </>
}

export default Add;
