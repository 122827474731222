import React, {useContext, useEffect, useState} from 'react';
import {Grid, Button, Divider, Tooltip,CircularProgress, Paper} from '@material-ui/core';
import instance from '../../apis';
import Moment from "react-moment";
import {Link} from 'react-router-dom';
import {BreadsContext, PageContext, PageTitleContext} from "../../stores/PageStore";
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import BarChartIcon from '@material-ui/icons/BarChart';
import TranslateIcon from '@material-ui/icons/Translate';
import DialpadIcon from '@material-ui/icons/Dialpad';


function Sinavlar() {

    const [sinavs, setSinavs] = useState([])
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    const [load, setLoad] = useState(false)

    useEffect(() => {
        setLoad(true)
        const breads = [
            {
                link: null,
                title: 'Sınavlar',
                last: true
            },

        ];
        setBreads(breads)
        setPage(17)
        setPageTitle('E-Sınav Sistemi')
        instance
            .get(`/api/sinav/all`)
            .then(function (result) {
                setSinavs(result.data)
                setLoad(false)
            })
    }, [])
    if (load) return <CircularProgress/>
    return <Paper className="paper-handler">
        <Grid container spacing={1} alignItems={"center"} justify={"center"}>
            {sinavs.map(item => <React.Fragment key={item.id}>
                <Grid item xs={12} sm={3}>{item.name}</Grid>
                <Grid item xs={2} sm={1}>{item.sorular_count} Soru</Grid>
                <Grid item xs={5} sm={2}><Moment locale="tr" format="DD.MM.YYYY HH.mm" date={item.tarih}/></Grid>
                <Grid item xs={5} sm={2}><Moment locale="tr" format="DD.MM.YYYY HH.mm" date={item.bitis}/></Grid>
                <Grid item xs={3} sm={1} style={{textAlign: "center"}}>
                    <Link to={`/sinav/optik/${item.id}`}>
                        <Tooltip title={'Optik/Cevaplarınız'}>
                            <Button variant={"outlined"} size={"small"} style={{
                                fontSize: 10,
                                color: "#dc6900"
                            }}>
                                <DialpadIcon/>
                            </Button>
                        </Tooltip>
                        <div className={'optikIconText'}>OPTİK</div>
                    </Link>
                </Grid>
                <Grid item xs={3} sm={1} style={{textAlign: "center"}}>
                    <Link to={`/sinav/konular/${item.id}`}>
                        <Tooltip title={'Konular'}><Button variant={"outlined"} size={"small"} style={{fontSize: 10}}>
                            <AccountTreeIcon/></Button></Tooltip>
                        <div className={'optikIconText'}>KONULAR</div>
                    </Link>
                </Grid>
                <Grid item xs={3} sm={1} style={{textAlign: "center"}}>
                    <Link to={`/sinav/cevaplar/${item.id}`}>
                        <Tooltip title={'Cevaplar'}>
                            <Button variant={"outlined"} size={"small"} style={{fontSize: 10}}>
                                <TranslateIcon/>
                            </Button>
                        </Tooltip>
                        <div className={'optikIconText'}>CEVAPLAR</div>
                    </Link>
                </Grid>
                <Grid item xs={3} sm={1} style={{textAlign: "center"}}>
                    <Link to={`/sinav/sonuclar/${item.id}`}>
                        <Tooltip title={'Sonuçlar'}>
                            <Button variant={"outlined"} size={"small"} style={{
                                fontSize: 10,
                                color: "#8b9dc3"
                            }}>
                                <BarChartIcon/>
                            </Button>
                        </Tooltip>
                        <div className={'optikIconText'}>İSTATİSTİK</div>
                    </Link>
                </Grid>
                <Grid item xs={12} sm={12}><Divider/></Grid>
            </React.Fragment>)}
            <Grid xs={12} sm={12}>
                <Grid container alignItems={"center"} style={{
                    marginTop: 20,
                    fontSize: 9
                }}>
                    <Grid xs={2} sm={1}><DialpadIcon/></Grid>
                    <Grid xs={10} sm={2}>Optik Form/Cevap Anahtarı </Grid>
                    <Grid xs={2} sm={1}><AccountTreeIcon/></Grid>
                    <Grid xs={10} sm={2}>Konular</Grid>
                    <Grid xs={2} sm={1}><TranslateIcon/></Grid>
                    <Grid xs={10} sm={2}>Cevaplar</Grid>
                    <Grid xs={2} sm={1}><BarChartIcon/></Grid>
                    <Grid xs={10} sm={2}>İstatistikler/Sonuçlar</Grid>
                </Grid>
            </Grid>
        </Grid>
    </Paper>;
}

export default Sinavlar;
