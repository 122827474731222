import React, {useEffect, useContext} from 'react';
import {BreadsContext, PageContext, PageTitleContext} from "../../../../stores/PageStore";
import {BBBProvider} from '../../../../stores/BBBCreate';
import List from './List';

const Index = (props) => {
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);

    useEffect(() => {
        const breads = [
            {
                link: '/admin/bbb/records',
                title: 'Canlı Sınıf Kayıt Listesi',
                last: true
            }

        ];
        setBreads(breads)
        setPage(20.3)
        setPageTitle('Canlı Sınıf Kayıt Listesi')
    }, [])

    const users = [];

    return <>
        <BBBProvider users={users}>
            <List props={props}/>
        </BBBProvider>
    </>

}

export default Index;
