import React, {useState} from 'react';
import {Divider, Grid, Tooltip} from "@material-ui/core";
import Moment from "react-moment";
import {Link} from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from "@material-ui/core/CircularProgress";
import BallotIcon from '@material-ui/icons/Ballot';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

const SinavRows = (props) => {
    const [time, setTime] = useState(0)

    function MomentView(date) {
        return <Moment format="DD/MM/YYYY HH:mm">
            {date}
        </Moment>
    }

    setTimeout(() => {
        setTime(1)
    }, 4000)
    if (props.sinavs.length < 1 && time == 0) return <CircularProgress/>
    return props.sinavs.map((item, index) => <Grid style={{fontSize: 12}} className="acr-row" container alignItems={"center"} key={index}>
        <Grid item xs={1} sm={1}> {index + 1}</Grid>
        <Grid item xs={6} sm={2}>{item.name}</Grid>
        <Grid item xs={5} sm={1}>
            <Tooltip title="Kazanım ve konu seç">
                <Link to={`/admin/esinav/kazanims/bagla/${item.id}`}><BallotIcon className="acr-icon"/></Link>
            </Tooltip>
            <Tooltip title="Sonuçları incele">
                <Link to={`/admin/esinav/sonuclar/${item.id}`}><FormatListNumberedIcon className="acr-icon"/></Link>
            </Tooltip>
        </Grid>
        <Grid item xs={1} sm={1}><Tooltip placement={"top"} title="Adet Soru" arrow><span>{item.count}</span></Tooltip></Grid>
        <Grid item xs={12} sm={5}>{MomentView(item.tarih)} - {MomentView(item.bitis)}</Grid>
        <Grid item xs={2} sm={1}><Link to={`/admin/esinav/add/${item.id}`}> <EditIcon className="acr-icon"/></Link></Grid>
        <Grid item xs={2} sm={1}><DeleteIcon className="acr-icon" onClick={() => props.sinavDelete(item.id)}/></Grid>
        <Grid xs={12} sm={12}><Divider/></Grid>
    </Grid>)
}


export default SinavRows;
