import React, {useContext, useEffect, useState} from 'react';
import instance from '../apis';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useParams} from 'react-router-dom';
import {BreadsContext, PageContext, PageTitleContext} from "../stores/PageStore";
import Item from "./categories/Item";
import {breadLink} from "../functions/trCase";

const MyCategories = () => {
    const [load, setLoad] = useState(false);
    let {id} = useParams()
    let {pTitle} = useParams()
    const [categories, setCategories] = useState([])
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);

    useEffect(() => {
        setLoad(true)
        if (id) {
            id = `/${id}`;
        } else {
            id = '';
        }
        instance.get(`/api/my/categories${id}`)
            .then(function (res) {
                setCategories(res.data)
                setLoad(false)
            })
    }, [id])

    useEffect(() => {
        let breads = [];
        if (id && id != 'all') {
            breads = [{
                link: '/my/categories',
                title: 'Derslerim'
            }, {
                link: null,
                title: breadLink(pTitle),
                last: true

            }];
        } else {
            breads = [{
                link: null,
                title: 'Derslerim',
                last: true
            }];
        }

        setBreads(breads)
        setPageTitle('Tanımlı Derslerim')
        setPage(9)
    }, [])
    if (load) {
        return <CircularProgress/>
    }
    const Category = () => {
        return categories.map(item => <Item sub={item.parent_id ? true : false} link={'my/category'} item={item}/>)
    }
    return <Grid container spacing={2}>
        <Category/>
    </Grid>
}

export default MyCategories;
