import React, {useContext} from 'react';
import {Divider, Grid, TextField} from "@material-ui/core";
import KreditCards from "./KreditCards";
import {KreditCardContext} from "./KreditCardStore";

function ShopierInfo() {
    const [state, setState] = useContext(KreditCardContext)

    return <Grid container spacing={2} alignItems={"center"} alignContent={"center"}>
        <Grid item xs={12} sm={12}>
            <h4>Shopier Entegrasyon Bilgileri</h4>
        </Grid>
        <Grid item xs={12} sm={12}>
            <TextField
                variant={"outlined"}
                size={"small"}
                value={state.shopier_key}
                name="shopier_key"
                label="API Kullanıcı"
                fullWidth
                required
            />
        </Grid>
        <Grid item xs={12} sm={12}>
            <TextField
                variant={"outlined"}
                size={"small"}
                defaultValue={state.shopier_secret_key}
                name="shopier_secret_key"
                label="API Şifre"
                fullWidth
                required
            />
        </Grid>
        <Grid item xs={12} sm={12}>
            <h4>Otomatik Sipariş Bildirimi (OSB) Ayarları</h4>
        </Grid>
        <Grid item xs={12} sm={12}>
            <TextField
                variant={"outlined"}
                size={"small"}
                value={state.shopier_osb_key}
                name="shopier_osb_key"
                label="OSB Kullanıcı Adı"
                fullWidth
                required
            />
        </Grid>
        <Grid item xs={12} sm={12}>
            <TextField
                variant={"outlined"}
                size={"small"}
                defaultValue={state.shopier_osb_secret_key}
                name="shopier_osb_secret_key"
                label="OSB Şifresi"
                fullWidth
                required
            />
        </Grid>
        <Grid item xs={12} sm={12}> </Grid>


    </Grid>
};

export default ShopierInfo;
