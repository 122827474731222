import React, {useContext, useEffect, useState} from 'react';
import {Switch, Grid} from "@material-ui/core";
import {KreditCardContext} from "./KreditCardStore";

const KreditCards = () => {
    const [state, setState] = useContext(KreditCardContext)
    const [cards, setCards] = useState([
        {id: 1, name: 'Shopier'}
    ])
    if (!state.active)
        return <></>
    return <>
        <h5>Ödeme sağlayıcı hizmetini seçin</h5>
        {cards.map(item => <Grid container spacing={2} alignItems={"center"} alignContent={"center"}>

            <Grid item xs={5} sm={3}>
                <Switch
                    id={"card_id"}
                    checked={state.card_id == item.id ? true : false}
                    onChange={(e) => e.target.checked ? setState(state => ({...state, card_id: item.id})) : setState(state => ({...state, card_id: -1}))}
                    color="primary"
                    inputProps={{'aria-label': 'primary checkbox'}}
                />
            </Grid>
            <Grid item xs={7} sm={9}>{item.name}</Grid>
        </Grid>)}

    </>

}

export default KreditCards;
