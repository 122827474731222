import React, {useContext, useEffect, useState} from 'react';
import instance from "../../apis";
import {Button, Divider, CircularProgress, Grid} from "@material-ui/core";
import {BasketContext} from "../../stores/Basket";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {toast} from 'react-toastify';
import KreditCard from './KreditCard';
import {Segment} from 'semantic-ui-react';

export default function PaymentForm() {
    const [banks, setBanks] = useState([])
    const [baskets, setBaskets] = useContext(BasketContext)
    const [complete, setComplete] = useState(false)
    const [load, setLoad] = useState(true)
    const [card, setCard] = useState({})

    useEffect(() => {
        instance
            .get('/api/payment/config')
            .then(function (res) {
                setCard(res.data.card)
                setBanks(res.data.bank)
                setLoad(false)
            })
    }, [])

    if (load)
        return <CircularProgress/>

    const BanksView = () => {
        return banks.map(bank => <Segment><Grid key={bank.id} container spacing={1}>
                <Grid item xs={4} sm={4}><strong>Hesap Sahibi</strong></Grid>
                <Grid item xs={8} sm={8}>: {bank.name}</Grid>
                <Grid item xs={12} sm={12}> <Divider/></Grid>
                <Grid item xs={4} sm={4}><strong>Banka Adı </strong></Grid>
                <Grid item xs={8} sm={8}>: {bank.bank_name}</Grid>
                <Grid item xs={12} sm={12}> <Divider/></Grid>
                <Grid item xs={4} sm={4}><strong> İban </strong></Grid>
                <Grid item xs={8} sm={8}>: {bank.iban}</Grid>
                <Grid item xs={12} sm={12}> <Divider/></Grid>
                <Grid item xs={4} sm={4}><strong> Açıklama </strong></Grid>
                <Grid item xs={8} sm={8}>: <span style={{color: "red"}}><strong>{baskets.id}</strong></span></Grid>
            </Grid></Segment>
        );
    };


    function sendBasket() {
        instance
            .post('/api/basket/complete')
            .then(function (res) {
                if (res.status == 200) {
                    setComplete(true)
                    setBaskets({products: []})
                } else {
                    toast.error('Bir hata oldu, lütfen site yöneticisi ile irtibaya geçin.')
                }
            })
    }

    if (complete) {
        return <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} style={{textAlign: "center"}}>
                    <CheckCircleOutlineIcon style={{color: '#559900', fontSize: 65}}/>
                </Grid>
                <Grid item xs={12} sm={12} style={{textAlign: "center", fontSize: 24}}>
                    Siparişiniz kaydedildi ödeme yapmanız bekleniyor. Ödemenizi internet bankacılığından, banka şubelerinden, bankamatiklerden kartsız işlem ile yada PTT Şubelerinden yapabilirsiniz.
                </Grid>
            </Grid>
        </>
    }

    function KreditCardView() {
        return <Grid container spacing={3}>
            <Grid item xs={12} sm={12} style={{textAlign: "center"}}>
                <h4>KREDİ KARTIYLA ÖDE</h4>
            </Grid>
            <Grid item xs={12} sm={12} style={{textIndent: 30}}>
                Kredi kartı ödemeleri ile aldığınız lisan ürünleri, hesabınıza otomatik tanımlanır. Ödemenin ardından hemen kullanmaya başlayabilirsiniz.
            </Grid>
            <Grid item xs={12} sm={12} style={{textAlign: "center"}}>
                <KreditCard/>
            </Grid>
        </Grid>
    }

    function HavaleEftView() {
        return <Grid container spacing={3}>
            <Grid item xs={12} sm={12} style={{textAlign: "center"}}>
                <h4>HAVALE/EFT İLE ÖDE</h4>
            </Grid>
            <Grid item xs={12} sm={12} style={{textIndent: 30}}>
                Aşağıdaki hesap numaralarına ödeme yapınız. Açıklama kısmına <span style={{color: "red"}}><strong>{baskets.id}</strong></span> yazmanız yeterlidir.
            </Grid>
            <Grid item xs={12} sm={12}> <BanksView/></Grid>
            <Grid item xs={12} sm={12}> <Divider/></Grid>
            <Grid item xs={12} sm={12}>
                <Button variant={"contained"}
                        onClick={() => sendBasket()}
                        color={"secondary"}
                        fullWidth={true}
                >
                    HAVALE/EFT YAPMAK İÇİN SPARİŞİ KAYDET
                </Button>
            </Grid>
        </Grid>
    }

    if (card.active) {
        return <Grid style={{color: "#323232"}} container spacing={3}>
            <Grid item xs={12} sm={6}>
                <HavaleEftView/>
            </Grid>
            <Grid item xs={12} sm={6} style={{textAlign: "center"}}>
                <KreditCardView/>
            </Grid>
        </Grid>;
    }
    return <Grid style={{color: "#323232"}} container spacing={3}>
        <Grid item xs={12} sm={12}>
            <HavaleEftView/>
        </Grid>
    </Grid>;


}
