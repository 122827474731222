import React, {useEffect, useState, useLayoutEffect} from 'react';
import {Grid, Paper, Button, CircularProgress, } from '@material-ui/core';
import { connect } from 'react-redux'
import { setUnCheckUser } from '../../../../redux/classroomAdd'
import SelectedUserFilter from './SelectedUserFilter'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
		position: 'relative',
		overflow: 'auto',
		maxHeight: 400,
	  },
	  listSection: {
		backgroundColor: 'inherit',
	  },
	  ul: {
		backgroundColor: 'inherit',
		padding: 3,
		borderBottomWidth:1,
		borderBottomColor:'#ddd',
	  },
  }));


function SelectedUserList (props) {
	const classes = useStyles();

	const users = props.classroomAdd.selected_user_find.length>0?props.classroomAdd.selected_user_find:props.classroomAdd.selected_users;

	return <>
		<SelectedUserFilter />
		<List className={classes.root} >
			{users.map((user,index) => 
				<>
					<ListItem key={'selected_'+user.id} button className={classes.ul}>
						<ListItemText onClick={()=>{props.setUnCheckUser(user)}}> {user.name} </ListItemText>
					</ListItem>
					<Divider />
				</>
			)}
		</List>
	</>
}

const mapStateToProps = (state) => {
  return {
    classroomAdd: state.classroomAdd,
  };
};

const mapDispatchToProps = {
	setUnCheckUser,
}
  

export default connect(mapStateToProps, mapDispatchToProps)(SelectedUserList);
