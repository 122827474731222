import React from 'react';
import {Grid} from "@material-ui/core";
import {Link} from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

function KazanimRows(props) {
    if (props.datas.length < 1) return <>Henüz bu konuya kazanım eklenmedi</>
    return props.datas.map(item => <Grid container className="acr-row" alignItems={"center"}>
        <Grid item xs={12} sm={10}>{item.name}</Grid>
        <Grid item xs={6} sm={1}>
            <Link to={`/admin/esinav/kazanims/add/${props.sinav_id}/${item.konu_id}/${item.id}`}>
                <EditIcon className="acr-icon"/></Link>
        </Grid>
        <Grid item xs={6} sm={1}><DeleteIcon className="acr-icon" onClick={() => props.deleteRow(item.id)}/></Grid>
    </Grid>)
}

export default KazanimRows;