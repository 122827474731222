import React from 'react';
import {KreditCardStore} from "./KreditCardStore";
import KreditCardConfig from "./KreditCardConfig";

function index() {
    return <KreditCardStore>
        <KreditCardConfig/>
    </KreditCardStore>;
};

export default index;
