import React, {useContext, useState} from "react";
import {Grid} from "@material-ui/core";
import {Button} from "semantic-ui-react";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CropImageViewV2 from "../../CropImageViewV2";
import instance from "../../../apis";

export default function SelectImage({page}) {
    const [img, setImg] = useState(page.image)

    function imgDelete(e) {
        if (global.confirm('Silmek istediğinizden emin misiniz?'))
            instance
                .delete(`/api/admin/product/page/img/${page.site_id}/${img}`)
                .then(function (res) {
                        setImg(null)
                    }
                )
    }

    if (img) {
        return <>
            <Grid container>
                <Grid item xs={12} sm={12} style={{position: "relative"}}>
                    <img style={{width: "100%"}} src={`https://lms.rotasinav.com/img/view/path/${page.site_id}?path=${page.image}`}/>
                    <Button
                        style={{position: "absolute", top: 0, right: 0}}
                        size={"small"}
                        variant={"contained"}
                        onClick={() => imgDelete()}
                        color={"red"}
                        type={"button"}
                    >
                        <DeleteForeverIcon/>
                    </Button>
                </Grid>
            </Grid>
        </>
    } else {
        return <>
            <CropImageViewV2 width={1360} aspect={4} file_id={"file_2"}/>
        </>
    }


}
