import React, {useContext, useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Link} from 'react-router-dom';
import instance from "../apis";
import {toast, ToastContainer} from 'react-toastify';
import Paper from "@material-ui/core/Paper";
import {CircularProgress, MenuItem} from "@material-ui/core";
import InputMask from 'react-input-mask';

function Copyright() {
    const siteName = window.location.host;

    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="/">
                {siteName}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#fff'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignUp(props) {
    const history = props.history
    const classes = useStyles();
    const [load, setLoad] = useState(false)
    const [sinifs, setSinifs] = useState([])
    const [inputs, setInputs] = useState({})
    useEffect(() => {
        instance
            .get(`/api/classroom/list`)
            .then(function (res) {
                setSinifs(res.data.items)
            })
            .catch(function () {
                //window.location.reload()
            })
    }, [])

    const submit = e => {
        setLoad(true)
        e.preventDefault()
        const data = new FormData(e.target);
        instance
            .post('/api/register', data)
            .then(function (res) {
                setLoad(false)
                if (res.data.err == 1) {
                    setInputs(res.data.inputs)
                    return toast.error(res.data.msg)
                } else {
                    toast.success(res.data.msg)
                    setTimeout(function () {
                        return history.push('/login')
                    }, 2000)
                }
            })
    }
    const LoadForm = () => {
        if (load) {
            return <Grid alignItems={"center"} item container spacing={10} elevation={6} alignContent={"center"} justify={"center"}>
                <Grid item xs={12} sm={12} style={{textAlign: "center"}}></Grid>
                <Grid item xs={12} sm={12} style={{textAlign: "center"}}>
                    <CircularProgress/>
                </Grid>
                <Grid item xs={12} sm={12} style={{textAlign: "center"}}></Grid>
            </Grid>
        } else {
            return <form className={classes.form} onSubmit={submit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            autoComplete="fname"
                            name="name"
                            variant="outlined"
                            required
                            fullWidth
                            id="name"
                            label="Ad Soyad"
                            autoFocus
                            value={inputs.name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputMask
                            mask="(0)999 999 99 99"
                            disabled={false}
                            maskChar=" "
                        >
                            {() => <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="tel"
                                label="Telefon"
                                name="tel"
                                autoComplete="tel"
                                value={inputs.tel}
                                s/>}
                        </InputMask>


                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="email"
                            label="e-Posta Adresi"
                            name="email"
                            autoComplete="email"
                            value={inputs.email}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            name="password"
                            label="Şifre"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={inputs.password}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            name="password_confirm"
                            label="Şifreyi Onayla"
                            type="password_confirm"
                            id="password_confirm"
                            type="password"
                            value={inputs.password_confirm}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            select
                            name="sinif_id"
                            label="Varsa Sınıfınızı Seçiniz"
                            id="sinif_id"
                        >
                            {sinifs.map(item => <MenuItem value={item.id}>{item.ad}</MenuItem>)}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox name="sartlar" value="1" color="primary"/>}
                            label="Sitenin kullanım şartlarını kabul ediyorum."
                        />
                        <Link to={"/sartlar"}><span>ŞARTLAR</span></Link>
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    BİLGİLERİMİ KAYDET
                </Button>
                <Grid container justify="flex-end">
                    <Grid item xs={12} sm={12}>
                        <Link to="/login" variant="body2">
                            Zaten bir hesabım var!
                        </Link>
                    </Grid>
                </Grid>
            </form>
        }
    }
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={"paper"} style={{textAlign: "center"}}>
                <Avatar className={classes.avatar} style={{marginLeft: "44%"}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    ÜYE OL
                </Typography>
                <LoadForm/>
            </div>
            <Box mt={5}>
                <Copyright/>
            </Box>
            <ToastContainer/>
        </Container>
    );
}
