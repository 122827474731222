import React, {useEffect, useState, useContext} from 'react';

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import {CircularProgress, makeStyles, MenuItem, Paper} from "@material-ui/core";
import instance from '../apis';
import {toast} from "react-toastify";
import Moment from 'react-moment';
import {BreadsContext, PageContext, PageTitleContext} from "../stores/PageStore";
import {Button, Segment} from 'semantic-ui-react';

const useStyles = makeStyles(theme => ({
    paper: {
        backgroundColor: "rgb(241,241,241,.3)",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: "100%",
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        padding: 20
    },
    rowActive: {
        backgroundColor: "#fff"
    },
    rowCancel: {
        backgroundColor: "#cbcbcb"
    },
    rowRefresh: {
        backgroundColor: "#f1f1f1"
    }
}));
const Profile = () => {
    const [user, setUser] = useState({categories: [{category: {}}]})
    const classes = useStyles();
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    const [siniflar, setSiniflar] = useState([]);
    const [load, setLoad] = useState(true);

    useEffect(() => {
        instance.get('/api/my/profile')
            .then(function (res) {
                setUser(res.data.user)
                setSiniflar(res.data.siniflar)
                setLoad(false)
            })
        setPageTitle('Hesabım')
        const breads = [
            {
                link: null,
                title: 'Profilim',
                last: true
            }
        ]
        setBreads(breads)
        setPage(10)
    }, [])

    if (load)
        return <CircularProgress/>

    function userSubmitForm(e) {
        const target = e.target
        e.preventDefault()
        const formData = new FormData(target)
        instance.post(`/api/get/user`, formData)
            .then(function (res) {
                toast.success('Bilgileriniz güncelendi')
            })
    }

    const RowItemClasses = (status) => {
        switch (status) {
            case 1:
                return classes.rowActive
                break
            case 2:
                return classes.rowCancel
                break
            case 3:
                return classes.rowRefresh
                break
        }
    }
    const RowItemStatus = (status) => {
        switch (status) {
            case 1:
                return 'Aktif'
                break
            case 2:
                return 'İptal'
                break
            case 3:
                return 'Yenileme'
                break
        }
    }
    const UserCategories = () => {

        if (user.categories.length == 0) {
            return <></>
        }
        const categoryItem = (item, index) => {
            if (!item.category) {
                return <></>
            }

            function ChildsCategoryView({item, index}) {
                if (index % 2 == 0 && index != 0) {
                    return <><Grid key={item.id} item xs={12} sm={6}>{item.title}</Grid>
                        <Grid item xs={12} sm={12}><Divider/></Grid>
                    </>

                }
                return <Grid key={item.id} item xs={12} sm={6}>{item.title}</Grid>

            }

            return <>
                <Grid container key={item.id} alignContent={"center"} alignItems={"center"} spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <span style={{fontSize: 11, fontWeight: "bold"}}>{item.category.title}</span>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Segment raised clearing>
                            <img width="100%" src={`https://lms.rotasinav.com/cover/img/${item.category.site_id}/${item.category.cover_img}`}/>
                        </Segment>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Segment stacked raised>
                            <Grid container alignContent={"center"} alignItems={"center"} style={{fontSize: 9}} spacing={1}>
                                {item.category.childs.map((item, index) => <ChildsCategoryView item={item} index={index}/>)}
                            </Grid>
                        </Segment>
                    </Grid>
                    {/*<Grid item xs={12} sm={12}>
                        <Grid container  alignContent={"center"} alignItems={"center"} justify={"space-between"} spacing={2}>
                            <Grid item xs={6} sm={3}> {MomentView(item.lisans_baslangic)}</Grid>
                            <Grid item xs={6} sm={3} style={{textAlign:"right"}}> {MomentView(item.lisans_bitis)}</Grid>
                        </Grid>
                    </Grid>*/}

                    <Grid item xs={12} sm={12}><Divider/></Grid>
                </Grid>
            </>
        }
        return user.categories.map((item, index) => categoryItem(item, index))
    }

    function MomentView(date) {
        return <Moment format="DD/MM/YYYY">
            {date}
        </Moment>
    }

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <Paper className="paper-handler">
                    <form onSubmit={userSubmitForm}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    size={"small"}
                                    variant={"outlined"}
                                    required
                                    defaultValue={user.name}
                                    name="name"
                                    label="Kullanıcı ismi"
                                    fullWidth={true}
                                    autoComplete="name"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    size={"small"}
                                    variant={"outlined"}
                                    required
                                    defaultValue={user.email}
                                    name="email"
                                    label="Email"
                                    fullWidth={true}
                                    autoComplete="email"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    size={"small"}
                                    variant={"outlined"}
                                    required
                                    defaultValue={user.tel}
                                    name="tel"
                                    label="Telefon"
                                    fullWidth={true}
                                    autoComplete="tel"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    select
                                    name="sinif_id"
                                    label="Varsa Sınıfınızı Seçiniz"
                                    id="sinif_id"
                                    defaultValue={user.classroom_user ? user.classroom_user.classroom_id : -1}
                                >
                                    <MenuItem value={-1}>Bir sınıfa dahil değilim</MenuItem>
                                    {siniflar.map(item => <MenuItem value={item.id}>{item.ad}</MenuItem>)}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    size={"small"}
                                    variant={"outlined"}
                                    name="password"
                                    label="Kullanıcı Şifresi"
                                    fullWidth={true}
                                    autoComplete="password"
                                    helperText={'Kullanıcı şifresini değiştirmek isterseniz bu alanı kullanın'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Button type="submit" fluid color={'blue'} fullWidth={true}>Üyelik Bilgilerini Kaydet</Button>
                            </Grid>
                        </Grid>


                    </form>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={8}>
                <Paper className="paper-handler">
                    <h4>Tanımlı lisanslar</h4>
                    <UserCategories/>
                </Paper>
            </Grid>
        </Grid>
    </>

}

export default Profile;
