import React, {useEffect, useState} from 'react';
import {Button, Divider, Grid} from "@material-ui/core";

function Cevaplar(props) {
    const [seciliCevap, setSeciliCevap] = useState(null)
    useEffect(() => {
        setSeciliCevap(props.cevap)
    }, [props.soru])
    if (props.soru.no == 0) return <>Cevap Seçimi </>
    const cevaps = ['A', 'B', 'C', 'D', 'E'];
    return cevaps.map((item, index) => <div key={index} style={{
        float: "left",
        marginLeft: 4
    }}>
        <Button
            style={{
                fontSize: 11,
                padding : 2,
            }}
            size={"small"}
            color={seciliCevap == item ? "primary" : "default"}
            variant={seciliCevap == item ? "contained" : "outlined"}
            fullWidth
            onClick={() => {
                props.dogruCevap(item);
                setSeciliCevap(item)
            }}>
            {item}
        </Button>
    </div>)
}

export default Cevaplar;