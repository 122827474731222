import React, {useEffect, useState, useContext} from 'react';

import Grid from '@material-ui/core/Grid';
import instance from '../apis';
import {toast, ToastContainer} from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {useParams} from 'react-router-dom';
import Paper from "@material-ui/core/Paper";
import {CircularProgress} from "@material-ui/core";
import {Link} from 'react-router-dom';
import {BreadsContext, PageTitleContext} from "../stores/PageStore";

const RepassUp = (props) => {
    const history = props.history
    const [, setBreads]       = useContext(BreadsContext);
    const [, setPageTitle]    = useContext(PageTitleContext);
    const {code}              = useParams()
    const [verify, setVerify] = useState(false)
    const [load, setLoad]     = useState(false)
    const formSubmit          = e => {
        e.preventDefault()
        const data = new FormData(e.target)
        data.append("code", code);
        instance
            .post('/api/repass/update', data)
            .then(function (res) {
                if (res.data.err == 1) {
                    toast.error(res.data.msg)
                } else {
                    setTimeout(function () {
                        history.push('/login')
                    }, 2000)
                    toast.success(res.data.msg)
                }
            })
    }
    useEffect(() => {
        setLoad(true)
        instance
            .get('/api/repass/code/test/' + code)
            .then(function (res) {
                setVerify(res.data)
                setLoad(false)
            })
        const breads = [{
            link : null,
            title: 'Şifremi Sıfırla',
            last : true
        }];
        setBreads(breads)
        setPageTitle('Şifremi yenile')
    }, [])
    if (load) {
        return <Grid alignItems={"center"} item container xs={12} sm={8} md={5} component={Paper} elevation={6} alignContent={"center"} justify={"center"}>
            <Grid item>
                <CircularProgress/>
            </Grid>
        </Grid>
    }
    if (verify == -1) {
        return <>Doğrulama bağlantısı geçersiz! Yeni bağlantı için <Link to={'/repass'}>tıklayınız </Link></>
    }

    return <>
        <Grid container alignContent={"center"}>
            <Grid item style={{backgroundColor: "#fff"}}>
                <form onSubmit={formSubmit}>
                    <TextField
                        autoComplete="password"
                        name="password"
                        variant="outlined"
                        required
                        fullWidth
                        label="Yeni Şifreniz"
                        autoFocus
                        type={"password"}
                    /> <br/> <br/>
                    <TextField
                        name="password_confirm"
                        variant="outlined"
                        required
                        fullWidth
                        label="Şifrenizi tekrar yazın"
                        autoFocus
                        type={"password"}
                    />
                    <br/>
                    <br/>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        size={"large"}
                    >
                        YENİ ŞİFREYİ KAYDET
                    </Button>
                </form>

            </Grid>
        </Grid>
    </>
}


export default RepassUp;
