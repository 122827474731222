import React, {useContext, useEffect, useState} from 'react';
import instance from "../../apis";
import {BreadsContext, PageContext, PageTitleContext} from "../../stores/PageStore";
import {useParams} from 'react-router-dom';
import {Divider, Grid, CircularProgress, Chip, Paper} from '@material-ui/core';

function Konular() {
    const {sinav_id}            = useParams()
    const [sorular, setSorular] = useState([])
    const [load, setLoad]       = useState(true)
    const [sinav, setSinav]     = useState({})
    const [, setBreads]         = useContext(BreadsContext);
    const [, setPageTitle]      = useContext(PageTitleContext);
    const [, setPage]           = useContext(PageContext);
    useEffect(() => {
        setLoad(true)
        instance
            .get(`/api/sinav/konular/${sinav_id}`)
            .then(function (result) {
                if (result.status == 210) {
                    return '';
                }
                setSorular(result.data)
            })
        instance
            .get(`/api/sinav/${sinav_id}`)
            .then(function (result) {
                setSinav(result.data)
                setLoad(false)
            })
    }, [])
    useEffect(() => {
        const breads = [
            {
                link : '/sinavlar/all',
                title: 'Sınavlar',
                last : null
            },
            {
                link : null,
                title: load ? <CircularProgress style={{
                    width : 8,
                    height: 8
                }}/> : `${sinav.name} / Cevap Anahtarı`,
                last : true
            },
        ];
        setBreads(breads)
        setPage(17)
        setPageTitle('E-Sınav Sistemi')
        setLoad(false)
    }, [sinav])


    if (!sinav.id) return <CircularProgress/>

    return <Paper className="paper-handler">
        <Grid container>
            {sorular.map((item, index) => {
                return <>
                    <Grid item xs={1} sm={1}>{item.no}.</Grid>
                    <Grid item xs={11} sm={11}>
                        <span style={{fontWeight: 600}}></span> {item.konular.length < 1 ? 'Henüz konu eklenmedi' : ''}
                        {item.konular.map(konu => {
                            return <>
                                <Chip label={konu.konu.name}/>

                            </>
                        })}
                        {item.kazanimlar.length < 1 ? '' : <span style={{fontWeight: 600}}> Kazanimlar :</span>}
                        {item.kazanimlar.map(kazanim => {
                            return <Chip label={kazanim.name}/>
                        })}
                    </Grid>
                    <Grid item xs={12} sm={12}> <Divider/></Grid>
                </>
            })}
        </Grid>
    </Paper>;
}

export default Konular;
