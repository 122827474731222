import React, {useEffect, useState} from 'react';

import {Box, ButtonGroup,} from '@material-ui/core';
import instance from '../../../../apis';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment'
import {CircularProgress, Button, List, Grid, Paper} from '@material-ui/core';
import colors from '../../../../styles/colors';
import Confirm from '../../../../components/AlertDialog';
import Url from './Url';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        //   backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    li: {
        color: colors.text
    },
}));


const ListComponent = (props) => {
    const classes = useStyles();
    const [loadContex, setLoadContex] = useState(true)
    const [rooms, setRooms] = useState([])
    const [deletedRoom, setDeletedRoom] = useState({})
    const alertDialogRef = React.createRef();

    useEffect(() => {
        getRooms();
    }, [])


    const getRooms = () => {
        instance
            .get('/api/admin/bbb/room/list')
            .then(function (res) {
                setLoadContex(false)
                // console.log('res.data', res.data)

                setRooms(res.data);
            })
    }

    const roomJoin = (transfer_key) => {
        window.open('https://lms.rotasinav.com/admin/room/join/' + transfer_key, "_blank") || window.location.replace('https://lms.rotasinav.com/admin/room/join/' + transfer_key);
    }

    const roomConfirm = (room) => {
        // console.log ('room',room)
        setDeletedRoom(room);
        alertDialogRef.current.open();
    }

    const roomDelete = (res) => {

        if (res == true) {
            // alert(rooms.indexOf(deletedRoom))

            // console.log ('response', deletedRoom.id);
            instance
                .post('/api/admin/bbb/room/delete', {id: deletedRoom.id})
                .then(function (res) {
                    setLoadContex(false)
                    const newRooms = rooms.filter(x => x.id != deletedRoom.id)

                    setRooms(newRooms);
                })

        }
    }

    if (loadContex) {
        return <CircularProgress/>;
    }

    function tumunuSil() {
        if (window.confirm('Tüm canlı sınıf listesini silmek istediğinize emin misiniz? Bu işlemin geri alınamayacağını unutmayın!!!'))
            instance
                .delete(`/api/admin/bbb/room/delete/all`)
                .then(setRooms([]))
    }

    return <>
        <Grid container>
            <Confirm ref={alertDialogRef} response={(res) => roomDelete(res)} title="Uyarı" metin="Canlı sınıfı sildiğinizde tüm döküman ve materyallerde silinecektir. Silmek istediğinize emin misiniz?"/>
            <Grid item xs={12} sm={12}>
                <Grid container>
                    <Grid item xs={12} sm={10}>
                        <h3>Canlı Sınıf Listesi</h3>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button onClick={() => tumunuSil()} fullWidth variant={"contained"} size={"small"} color={"secondary"}>Tümünü Sil</Button>
                    </Grid>
                </Grid>
                <List className={classes.root} subheader={<li/>}>
                    {rooms.map((room, index) =>
                        <Paper style={{marginBottom: 20}} key={room.id}>
                            <Grid container direction="column" style={{marginBottom: 20, borderRadius: 5}}>
                                <Grid item xs={12} style={{backgroundColor: colors.btnBgYesil2, borderRadius: 5, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, padding: 10}}>
                                    <span style={{fontSize: 16, fontWeight: '600', color: '#fff'}}><b>{moment(room.baslama_tarih).format('DD MMMM YYYY HH:mm')}</b> - {room.room_ad} </span>
                                </Grid>
                                <Grid item xs={12} style={{padding: 10}}>
                                    <Box>
                                        <span style={{fontSize: 16, color: colors.text}}>{room.room_aciklama}</span>
                                        {room.url != null && <Url url={room.url}/>}

                                        <br/>
                                        <h4 style={{color: colors.btnBgMavi2}}>Ders Materyaller</h4>
                                        <ul>
                                            {room.materyals.map((materyal, index) =>
                                                <li><p>{materyal.aciklama}</p></li>
                                            )}
                                        </ul>
                                    </Box>
                                    <Box style={{marginTop: 20}}>

                                        <h4 style={{color: colors.btnBgMavi2}}>Ders Dökümanları</h4>
                                        <ul>
                                            {room.dokumans.map((dokuman, index) =>
                                                <li><p>{dokuman.aciklama}</p></li>
                                            )}
                                        </ul>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container alignContent="center" alignItems="center" justify="flex-end" style={{padding: 10}}>
                                        <ButtonGroup color="primary" aria-label="outlined primary button group" size="small">
                                            <Button
                                                variant="contained"
                                                style={{backgroundColor: colors.alternateColor[0], color: colors.text}}
                                                className={classes.button}
                                                // endIcon={<Close />}
                                                onClick={() => {
                                                    roomConfirm(room);
                                                }}
                                            >
                                                SİL
                                            </Button>
                                            <Button
                                                variant="contained"
                                                style={{backgroundColor: colors.alternateColor[1], color: colors.text}}
                                                className={classes.button}
                                                onClick={() => {
                                                    props.props.history.push('/admin/bbb/room/create/' + room.id);
                                                }}
                                                // endIcon={<Edit />}
                                            >
                                                Düzenle
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    roomJoin(room.transfer_key)
                                                }}
                                                variant="contained"
                                                style={{backgroundColor: colors.btnBgYesil2, color: colors.white}}
                                                className={classes.button}
                                                // endIcon={<ArrowRight />}
                                            >
                                                Başlat
                                            </Button>
                                        </ButtonGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    )}
                </List>
            </Grid>
        </Grid>
    </>

}

export default ListComponent;
