import React, {createContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import instance from '../apis';
import {Snackbar} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {useParams, Link, useHistory} from 'react-router-dom';


export const BBBContext = createContext({});

export const BBBProvider = props => {
    // Initial values are obtained from the props
    const {
        users: initialUsers,
        selectedUser: initialSelectedUsers,
        children
    } = props;

    const history = useHistory()

    // Use State to keep the values
    const [input, setInputs] = useState({
        room_ad: '',
        room_aciklama: '',
        baslama_tarih: new Date(),
        users_tumu: 1,
        webcamsOnlyForModerator: 0,
        muteOnStart: 1,
        lockSettingsDisablePrivateChat: 1,
        material_aciklama: '',

    });
    const [users, setUsers] = useState(initialUsers);
    const [selectedUser, setSelectedUser] = useState([]);
    const [validation, setValidation] = useState({open: false, msg: ''});
    const [materialSelectedFile, setMaterialSelectedFile] = useState(null);
    const [materialUploadedFiles, setMaterialUploadedFiles] = useState([]);
    const [dokumanSelectedFile, setDokumanSelectedFile] = useState(null);
    const [dokumanUploadedFiles, setDokumanUploadedFiles] = useState([]);
    const {id} = useParams();
    useEffect(() => {
        instance
            .get(`/api/admin/bbb/room/edit/${id}`)
            .then(function (res) {
                setMaterialUploadedFiles(res.data.materyals);
                setDokumanUploadedFiles(res.data.dokumans)
            })
    }, [])
    const getUserList = () => {
        instance
            .get('/api/admin/bbb/user/list')
            .then(function (res) {
                setUsers(res.data)
            })
        // setUsers(users.concat([newUser]));
    };

    const addNewUser = userName => {
        const newUser = {id: new Date().getTime().toString(), name: userName};
        setUsers(users.concat([newUser]));
    };

    const createRoom = async () => {
        if (input.room_ad == "") {
            setValidation({open: true, msg: 'Lütfen bir ad giriniz'});
            return;
        }
        if (input.room_aciklama == "") {
            setValidation({open: true, msg: 'Lütfen bir ad giriniz'});
            return;
        }

        return await new Promise((resolve) => {
            instance
                .post('/api/admin/bbb/room/create/v2', {input, selectedUser})
                .then(function (res) {
                    res = res.data;
                    console.log('RES', res);
                    if (res.code == 1) {
                        history.push('/admin/bbb/room/file/upload/' + res.data.id);
                    }
                })
        })
    };

    const uploadMaterial = async (id) => {
        return await new Promise((resolve) => {
            console.log('materialSelectedFile', materialSelectedFile[0]);
            var formData = new FormData();
            formData.append('file', materialSelectedFile[0]);
            formData.append('id', id);
            formData.append('aciklama', input.material_aciklama);

            instance.post('/api/admin/bbb/room/material/upload', formData)
                .then((res) => {
                    console.log('material_upload', res);
                    setMaterialUploadedFiles(materialUploadedFiles.concat(res.data.data));
                    resolve(res.data);
                })
        })
    };

    const uploadDokuman = async (id) => {
        return await new Promise((resolve) => {
            console.log('materialSelectedFile', dokumanSelectedFile[0]);
            var formData = new FormData();
            formData.append('file', dokumanSelectedFile[0]);
            formData.append('id', id);
            formData.append('aciklama', input.dokuman_aciklama);

            instance.post('/api/admin/bbb/room/dokuman/upload', formData)
                .then((res) => {
                    console.log('material_upload', res);
                    setDokumanUploadedFiles(dokumanUploadedFiles.concat(res.data.data));
                    resolve(res.data);
                })
        })
    };


    // Make the context object:
    const bbbContext = {
        validation, setValidation,
        input, setInputs,
        getUserList,
        users,
        setUsers,
        selectedUser,
        setSelectedUser,
        addNewUser,
        createRoom,

        uploadMaterial,
        materialSelectedFile,
        setMaterialUploadedFiles,
        materialUploadedFiles,
        setMaterialSelectedFile,

        uploadDokuman,
        dokumanSelectedFile,
        setDokumanUploadedFiles,
        dokumanUploadedFiles,
        setDokumanSelectedFile,

    };

    // pass the value in BBBprovider and return
    return <BBBContext.Provider value={bbbContext}>{children}</BBBContext.Provider>;
};

export const {BBBConsumer} = BBBContext;

BBBProvider.propTypes = {
    users: PropTypes.array,
    selectedUser: PropTypes.object
};

BBBProvider.defaultProps = {
    users: [],
    selectedUser: {}
};
