import React from 'react';

export default function categoryPrice(item, selectBBB) {
    if (!item.price) {
        return 'Ücretsiz'
    } else {
        if (item.dis_price) {
            return <>
                <del style={{
                    color: "#ff7e67",
                    fontSize: 14,
                    marginRight: 5
                }}>
                    {selectBBB ? parseFloat(item.price) + parseFloat(item.bbb_price ? item.bbb_price : 0) : parseFloat(item.price)}
                </del>
                {selectBBB ? parseFloat(item.dis_price) + parseFloat(item.bbb_price ? item.bbb_price : 0) : parseFloat(item.dis_price)}₺
            </>
        } else {
            return `${selectBBB ? parseFloat(item.price) + parseFloat(item.bbb_price ? item.bbb_price : 0) : parseFloat(item.price)}₺`
        }
    }
}
