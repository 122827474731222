import React, {useEffect, useState, useRef} from 'react';
import {Grid, Paper, Button, CircularProgress, } from '@material-ui/core';
import { connect } from 'react-redux'
import {setUserList, setCheckUser} from '../../../../redux/classroomAdd'
import { Divider } from '@material-ui/core';
import UserFiler from './UserFilter'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
		position: 'relative',
		overflow: 'auto',
		maxHeight: 400,
	  },
	  listSection: {
		backgroundColor: 'inherit',
	  },
	  ul: {
		backgroundColor: 'inherit',
		padding: 3,
		borderBottomWidth:1,
		borderBottomColor:'#ddd',
	  },
  }));


function UserList (props) {
	const classes = useStyles();

	const users = props.classroomAdd.user_find.length>0?props.classroomAdd.user_find:props.classroomAdd.users;

	// const users = (props.classroomAdd.users?props.classroomAdd.users:[]);
	return <>
		<UserFiler />
		<List className={classes.root} >
			{users.map((user,index) =>
				<>
					<ListItem button key={'user_'+user.id} className={classes.ul} style={{paddingRight:10}}>
						<ListItemText onClick={()=>{props.setCheckUser(user)}}> {user.name} </ListItemText>
					</ListItem>
					<Divider />
				</>
			)}
		</List>
		</>
		}

const mapStateToProps = (state) => {
	return {
		classroomAdd: state.classroomAdd,

	};
};

const mapDispatchToProps = {
	setUserList,
  	setCheckUser
}


export default connect(mapStateToProps, mapDispatchToProps)(UserList);
