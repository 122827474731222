import React, {useEffect, useRef, useState, useContext} from 'react';
import instance from '../../../apis';
import {Link, useParams} from 'react-router-dom';
import LinearProgress from "@material-ui/core/LinearProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Grid, ListItemSecondaryAction} from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from '@material-ui/core/ListItemText';
import {ControlBar, CurrentTimeDisplay, ForwardControl, PlaybackRateMenuButton, Player, ReplayControl, TimeDivider, VolumeMenuButton} from "video-react";
import HLSSource from "../../HLSSource";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import List from "@material-ui/core/List";
import {BreadsContext, PageContext, PageTitleContext} from "../../../stores/PageStore";
import useInterval from '@use-it/interval';
import {toast} from "react-toastify";
import YouTube from 'react-youtube';
import Vimeo from '@u-wave/react-vimeo';
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Button} from 'semantic-ui-react';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2, 2),
        textAlign: "center",
        color: "#c2c2c2",
        "&:hover": {
            color: "#f0134d"
        }
    },
    progressBar: {
        height: 20,
        borderRadius: 5,
        webkitBorderRadius: 5
    }

}));

const Edit = (props) => {
    const [rate, setRate] = useState(1)
    const [files, setFiles] = useState({percent: 0})
    const classes = useStyles();
    const [fileLoad, setFileLoad] = useState(true)
    const {file_id} = useParams();
    const [categories, setCategories] = useState([])
    const [getCategoryIds, setGetCategoryIds] = useState([])
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);

    useEffect(() => {
        if (!categories)
            return;
        if (categories.length < 1)
            return;
        instance.get(`/api/admin/video/edit/${file_id}`)
            .then(function (res) {
                const data = res.data;
                const getCategory_ids = []
                const getCategories = []
                data.categories.map(item => {
                    getCategory_ids.push(item.id)
                    if (item.parent_id !== null) {
                        item['title'] = "--- " + item['title'];
                        getCategories.push({id: item.id, title: item.title})
                    } else {
                        getCategories.push({id: item.id, title: item.title})
                    }
                })
                data.category_ids = getCategory_ids
                data.categories = getCategories
                setFiles(data)
                setFileLoad(false)
                res.data.categories.map(item => {
                    getCategoryIds.push(
                        item.id
                    )
                })
                setGetCategoryIds(getCategoryIds)
            })


    }, [rate, categories])

    useEffect(() => {
        instance.get('/api/admin/category/0')
            .then(function (res) {
                const data = handleCategories(res.data.items)
                setCategories(data)
                console.log(data)

            })
    }, [])
    useEffect(() => {
        const breads = [
            {
                link: "/admin/videos",
                title: 'Videolar',
                last: false

            },
            {
                link: null,
                title: 'Video düzenle',
                last: true

            }];
        setBreads(breads)
        setPageTitle('Video Düzenle')
    }, [])

    function handleCategories(datas) {
        console.log(datas)
        try {
            const data = [];
            datas.map(item => {
                if (item.title)
                    data.push({id: item.id, title: item.title})
                item.childs.map(child => {
                    if (child.title) {
                        child['title'] = '--- ' + child['title']
                        data.push({id: child.id, title: child.title})
                    }
                })
            })
            return data
        } catch (e) {
            return
        }

    }

    const Progress = () => {
        const [progressPercent, setProgressPercent] = useState(files.percent)
        const [, setGettime] = useState(1);

        const FileUploadLoad = () => {
            if (files.upload == -1 && progressPercent == 100) {
                return <>
                    Dosyalar uzak sunucuya aktarılıyor. Lütfen bu işlemin bitmesini bekleyiniz.
                    <LinearProgress/>
                </>
            }
            return <></>;
        }
        useInterval(() => {
            instance.get(`/api/admin/video/progess/control/${file_id}`)
                .then(function (res) {
                    setProgressPercent(res.data.percent)
                })
            const time = new Date().getTime();
            const sn = Math.round(time / 1000)
            setGettime(sn)
        }, 5000);
        if (files.publish_type !== 1)
            return <></>
        return <>
            <div style={{clear: "both"}}></div>
            Videonuzun İşlenme Durumu %{progressPercent + 1 > 100 ? 100 : progressPercent}
            <LinearProgress className={classes.progressBar} variant="determinate" value={progressPercent} color="secondary"/>
            <FileUploadLoad/>
        </>
    }


    const FormView = () => {
        const [inputs, setInputs] = useState(files)
        const handleSubmit = event => {
            event.preventDefault();
            const data = new FormData(event.target);
            data.append('category_ids', inputs.category_ids)
            instance.post(`/api/admin/video/${file_id}`, data)
                .then(function (res) {
                        if (res.status == 200) {
                            toast.success('Başarıyla kaydedildi.')
                        } else {
                            toast.warn('Kaydetme başarısız.')
                        }
                    }
                )
        }

        function VideoIdView() {
            if (files.publish_type == 1) {
                return <></>
            }
            let id = '';
            let name = '';
            switch (files.publish_type) {
                case 2:
                    id = files.youtube_id;
                    name = "youtube_id"
                    break;
                case 3:
                    id = files.vimeo_id;
                    name = "vimeo_id"
                    break
            }
            return <Grid item xs={12} sm={12}>
                <TextField
                    required
                    variant={"outlined"}
                    size={"small"}
                    label="Video ID"
                    margin="normal"
                    name={name}
                    defaultValue={id}
                    fullWidth={true}
                />
            </Grid>
        }

        const handleChangeValue = (event, value) => {
            const items = [];
            const itemIds = [];
            value.map((item, index) => {
                items.push(item)
                itemIds.push(item.id)
            })
            setInputs({...inputs, categories: items, category_ids: itemIds});
        };

        function SelectCategories() {
            return <Autocomplete
                size={"small"}
                multiple
                id="caregory_ids"
                options={categories}
                getOptionLabel={(option) => option.title}
                defaultValue={inputs.categories}
                filterSelectedOptions
                onChange={handleChangeValue}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="Kategori Seçimi"
                    />
                )}
            />
            /* return <Select
                 labelWidth="100%"
                 labelId="demo-mutiple-checkbox-label"
                 id="demo-mutiple-checkbox"
                 multiple
                 value={categoryIds}
                 input={<Input/>}
                 renderValue={selected => selected.join(', ')}
                 name="caregory_ids"
             >
                 {categories.map(name => (
                     <MenuItem key={name.id} value={name.id}>
                         <Checkbox checked={categoryIds.indexOf(name.id) > -1}/>
                         <ListItemText primary={name.title}/>
                     </MenuItem>
                 ))}
             </Select>*/
        }

        return <>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <VideoIdView/>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            variant={"outlined"}
                            size={"small"}
                            label="Başlık"
                            margin="normal"
                            name={"title"}
                            defaultValue={inputs.title}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <SelectCategories/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            variant={"outlined"}
                            size={"small"}
                            label="Sıra"
                            margin="normal"
                            name={"view_order"}
                            defaultValue={inputs.view_order}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            variant={"outlined"}
                            size={"small"}
                            fullWidth={true}
                            select
                            label="Bu Video"
                            defaultValue={inputs.free}
                            helperText="Ücretsiz videolar izleyicelerin konu hakkında fikir sahibi olmasını sağlayabilir"
                            margin="normal"
                            style={{textAlign: "left"}}
                            name="free"
                        >
                            <MenuItem value={1}>ÜCRETLİ</MenuItem>
                            <MenuItem value={2}>ÜCRETSİZ</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            variant={"outlined"}
                            size={"small"}
                            label="Açıklama"
                            margin="normal"
                            name={"context"}
                            defaultValue={inputs.context}
                            fullWidth={true}
                            helperText={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Button
                            type={"submit"}
                            size={"huge"}
                            color={"primary"}
                            variant="contained"
                        >Bilgileri Kaydet</Button>
                    </Grid>
                </Grid>

            </form>
        </>
    }


    const PlayerView = () => {

        const player = useRef();
        const [startTime, setStartTime] = useState(600);

        if (!files.user_id) {
            return <></>
        }

        if (files.publish_type == 3) {
            return <><Vimeo responsive={true}
                            video={files.vimeo_id}
                            autoplay={false}
            /></>
        }
        if (files.publish_type == 2) {
            const opts = {
                height: '400',
                width: '100%',
                playerVars: {
                    // https://developers.google.com/youtube/player_parameters
                    autoplay: 0,
                },
            };
            return <YouTube videoId={files.youtube_id} opts={opts}/>
        }

        return <>
            <Player>
                <HLSSource
                    isVideoChild
                    src={`https://lms.rotasinav.com/api/video/${files.site_id}/${files.id}/${files.name}.m3u8`}
                />
                <ControlBar>
                    <ReplayControl seconds={10} order={1.1}/>
                    <ForwardControl seconds={10} order={1.2}/>
                    <CurrentTimeDisplay order={4.1}/>
                    <TimeDivider order={4.2}/>
                    <PlaybackRateMenuButton rates={[0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]} order={7.1}/>
                    <VolumeMenuButton disabled/>
                </ControlBar>
            </Player>
        </>
    }

    useEffect(() => {
        instance.get('/api/admin/video/attack/files/' + file_id)
            .then(function (res) {
                setUploadFiles(res.data)
            })
    }, [])
    const [uploadFiles, setUploadFiles] = useState([])
    const selectFile = () => {
        const file = document.getElementById("file");
        file.click()
        file.onchange = function () {
            const inputFiles = file.files;
            for (let x = 0; x < inputFiles.length; x++) {
                const formData = new FormData();
                formData.append("file", inputFiles[x]);
                formData.append("video_id", file_id);
                instance.post('/api/admin/video/attack/file/upload', formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        onUploadProgress: function (progressEvent) {
                            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setUploadPercent(percentCompleted)
                        }
                    },
                )
                    .then(function (res) {
                        setUploadFiles(res.data)
                    })
            }


        }
    }

    const [uploadPercent, setUploadPercent] = useState(0)
    /*useEffect(() => {
        if (getTime % 2 == 0) {
            instance.get(`/api/admin/video/progess/control/${file_id}`).then(function (res) {
                setProgressPercent(res.data.percent)
                if (files.upload == -1 && res.data.upload == 1) {
                    window.location.reload()
                }
                if (files.stream_status == -1 && res.data.stream_status == 1) {
                    window.location.reload()
                }
            })
        }

    }, [getTime])*/

    const FileUploadView = () => {
        if (uploadPercent > 0) {
            return <ProgressAttackFile/>
        }
        return <>
            <strong>DERS İÇERİĞİ EKLE (pdf, excell, jpg vb.)</strong>
            <Divider/>
            <Button variant={"outlined"} fullWidth color={"yellow"} onClick={() => selectFile()}>Dosya Seç</Button>
            <input
                style={{display: "none"}}
                multiple
                type="file"
                id="file"
            />
        </>
    }

    const ProgressAttackFile = () => {
        if (uploadPercent == 0) {
            return <></>
        } else {
            return <>
                <Grid container>
                    <Grid item xs={12} sm={12}> Lütfen işlemler bitene kadar bekleyin.</Grid>
                    <Grid item xs={12} sm={12}> <LinearProgress className={classes.progressBar} variant="buffer" value={uploadPercent}/></Grid>
                    <Grid item xs={12} sm={12}>
                        <Button
                            variant={"outlined"}
                            onClick={() => setUploadPercent(0)}
                            fullWidth
                            color={"secondary"}
                        >Yeni Dosya Yükle</Button>
                    </Grid>
                </Grid>
            </>
        }
    }

    if (fileLoad) {
        return <CircularProgress disableShrink/>;
    }

    function attackFileDelete(id, path) {
        if (global.confirm('Silmek istediğinizden eminmisiniz?'))
            instance.delete('/api/admin/video/attack/file/' + id + '?path=' + path)
                .then(function () {
                    setUploadFiles(uploadFiles.filter(x => x.id != id))
                })
    }

    function attackFileDownload(path, name) {
        instance.get('/api/admin/video/attack/file?path=' + path, {
            responseType: "blob"
        })
            .then(function (res) {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
            })

    }

    function yenidenStream() {
        if (global.confirm("Videonuz yeniden işlenecek ve bir süre yayında  aksaklık olabilir, onaylıyor musunuz?"))
            instance
                .get(`/api/admin/video/yeniden/stream/${files.id}`)
                .then(function () {
                    toast.success('Videonuz yeniden işleniyor, bu video büyüklüğüne göre biraz zaman alabilir.')
                })
    }

    function ReStreamButtonView() {
        if (files.publish_type !== 1)
            return <></>
        return <Button onClick={() => yenidenStream()} variant={"contained"} color={"primary"}>YENİDEN STREAM ET</Button>
    }

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Paper className="paper-handler">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Grid container justify={"space-between"}>
                                <Grid item>
                                    <Link to={`/admin/video/upload/0`}> <Button variant={"contained"} color={"green"}>Yeni Video Ekle</Button></Link>
                                </Grid>
                                <Grid item>
                                    <ReStreamButtonView/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Progress/>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <FormView/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FileUploadView/>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Paper className="paper-handler">
                    <div style={{textAlign: "right"}}>
                        <Link to={`/admin/video/upload/${file_id}`}>
                            <Button
                                variant={"contained"}
                                color={"orange"} size={"small"}>Yeniden Yükle</Button>
                        </Link>
                    </div>
                    <PlayerView/>
                </Paper>
                <Paper className="paper-handler">
                    <strong>Eklenen İçerikler</strong>
                    <List dense={true}>
                        {
                            uploadFiles.map(item => <>
                                <ListItem key={item.id} button>
                                    <ListItemText onClick={() => attackFileDownload(item.path, item.name_org)}
                                                  primary={item.name_org}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="delete">
                                            <DeleteIcon onClick={() => attackFileDelete(item.id, item.path)}/>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </>)
                        }
                    </List>
                </Paper>
            </Grid>
        </Grid>
    </>
}

export default Edit;
