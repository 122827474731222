import React, {useContext, useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import instance from '../../apis'
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {StepContext} from "./StepStore";
import {makeStyles} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";


const helperTextStyles = makeStyles(theme => ({
    root: {
        margin: 4,
        color: "black"
    },
    error: {
        "&.MuiFormHelperText-root.Mui-error": {
            color: "red"
        }
    }
}));
export default function AddressForm() {
    const helperClasses = helperTextStyles();
    const [adress, setAdress] = useState({counties: [], cities: [], adress: {}})
    const [errors, setErrors] = useState({})
    const [counties, setCounties] = useState([])
    const [load, setLoad] = useState(false)
    useEffect(() => {
        setLoad(true)
        instance
            .get('/api/my/adress')
            .then(function (res) {
                if (res.data.adress == null) {
                    setAdress({counties: res.data.counties, cities: res.data.cities, adress: {}})
                } else {
                    setAdress(res.data)
                }
                setCounties(res.data.counties)
                setLoad(false)
            })
    }, [])

    const [activeStep, setActiveStep] = useContext(StepContext);

    function handleSubmit(e) {
        e.preventDefault()
        const formData = new FormData(e.target)
        instance
            .post('/api/my/adress', formData)
            .then(function (res) {
                if (res.status === 200) {
                    setActiveStep(activeStep + 1);
                } else {
                    setErrors(res.data)
                }
            })
    }

    const FormView = () => {
        const [inputs, setInputs] = useState(adress.adress)
        const [hanldeCounties, setHanldeCounties] = useState(counties)

        const handleChange = (e) => {
            const target = e.target
            setInputs({...inputs, [target.name]: target.value})
        }
        const selectCity = (e) => {
            const target = e.target
            const city_id = target.value
            setInputs({...inputs, [target.name]: city_id})
            instance
                .get(`/api/counties/${city_id}`)
                .then(function (res) {
                    setHanldeCounties(res.data)
                })
        }
        if (load) {
            return <CircularProgress/>
        }
        return <React.Fragment>

            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h6" gutterBottom>
                            Fatura Bilgileri
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size={"small"}
                            variant={"outlined"}
                            required
                            onChange={handleChange}
                            classes={helperClasses.error}
                            id="name"
                            name="name"
                            helperText={errors.name}
                            error={errors.name ? true : false}
                            defaultValue={inputs.name}
                            value={inputs.name}
                            label="Adres Tanımı (Ev adresi, iş adresi  vb.)"
                            fullWidth
                            autoComplete="fname"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size={"small"}
                            variant={"outlined"}
                            required
                            fullWidth={true}
                            select
                            label="Şehir Seçiniz"
                            defaultValue={inputs.city_id}
                            onChange={selectCity}
                            margin="normal"
                            style={{textAlign: "left"}}
                            name="city_id"
                            helperText={errors.name}
                            error={errors.name ? true : false}
                        >
                            {adress.cities.map(item =>
                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                            )}

                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size={"small"}
                            variant={"outlined"}
                            required
                            onChange={handleChange}
                            id="invoice_name"
                            defaultValue={inputs.invoice_name}
                            name="invoice_name"
                            label="Alıcı Adı Soyadı"
                            fullWidth
                            autoComplete="name"
                            helperText={errors.name}
                            error={errors.name ? true : false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size={"small"}
                            variant={"outlined"}
                            required
                            fullWidth={true}
                            select
                            label="İlçe Seçiniz"
                            defaultValue={inputs.county_id}
                            onChange={handleChange}
                            margin="normal"
                            style={{textAlign: "left"}}
                            name="county_id"
                            helperText={errors.name}
                            error={errors.name ? true : false}
                        >
                            {hanldeCounties.map(item =>
                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                            )}

                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size={"small"}
                            variant={"outlined"}
                            id="tc"
                            name="tc"
                            defaultValue={inputs.tc}
                            label="T.C. Kimlik No"
                            fullWidth
                            onChange={handleChange}
                            autoComplete="adress"
                            helperText={errors.name}
                            error={errors.name ? true : false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size={"small"}
                            variant={"outlined"}
                            required
                            onChange={handleChange}
                            id="tel"
                            name="tel"
                            defaultValue={inputs.tel}
                            label="Telefon Numaranız"
                            fullWidth
                            autoComplete="tel"
                            helperText={errors.name}
                            error={errors.name ? true : false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size={"small"}
                            variant={"outlined"}
                            required
                            onChange={handleChange}
                            id="adress"
                            defaultValue={inputs.adress}
                            name="adress"
                            label="Adres"
                            fullWidth
                            autoComplete="adress"
                            helperText={errors.name}
                            error={errors.name ? true : false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size={"small"}
                            variant={"outlined"}
                            onChange={handleChange}
                            id="adres_tarifi"
                            name="adres_tarifi"
                            defaultValue={inputs.adres_tarifi}
                            label="Adres Tarifi (Gerekliyse)"
                            fullWidth
                            autoComplete="adres_tarifi"
                            helperText={errors.name}
                            error={errors.name ? true : false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Button
                            type="submit"
                            style={{float: "right"}}
                            variant="contained"
                            color="primary"
                        >
                            KAYDET VE DEVAM ET
                        </Button>
                    </Grid>
                </Grid>


            </form>
            <div style={{clear: "both"}}></div>
        </React.Fragment>
    }
    return <FormView/>
}
