import React, {useEffect, useState, useParams} from 'react';

import { Box,  ButtonGroup, Divider, } from '@material-ui/core';
import instance from '../../../../apis';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import {CircularProgress, TextField, input,  Button, List, Grid, Paper} from '@material-ui/core';
import colors from '../../../../styles/colors';
import {CopyToClipboard} from 'react-copy-to-clipboard';


const useStyles = makeStyles(theme => ({
    p: {

    },
  }));


const UrlJoinComponent = (props) => {
    const classes = useStyles();
    const id = props.match.params.id
    const [room, setRoom] = useState(null);
    const [ad, setAd] = useState('');
    const [error, setError] = useState(null);
    const [joinStatus, setJoinStatus] = useState(false);

    useEffect(() => {
        getRoom(id);
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            if (ad.trim().length>2 && joinStatus == true){
                join()
            }
        }, 5000);
        return () => clearInterval(interval);
      }, []);
    const getRoom = (id) => {
        instance
            .get('/api/urljoin/check/'+id)
            .then(function (res) {
                // setLoadContex(false)
                setRoom(res.data);
            })
    }
    const join = () => {
        if (ad.trim().length == 0){
            setError('Lütfen Adınızı Soyadınızı giriniz');
        }
        var data = {ad : ad, url : id}
        instance
            .post('/api/urljoin/login', data)
            .then(function (res) {
                // setLoadContex(false)
                if (res.data.durum == 1){
                    window.location = res.data.link;
                }
                // setRoom(res.data);
            })
    }

    return <>
        <Grid container>
            {room!=null&&
            <Grid item xs={12} md={12} style={{marginBottom:10}} >
                <h1>Canlı Sınıf Giriş</h1>
                <h3>{room.data.room_ad}</h3>
                <p style={{fontSize:14}}>{room.data.room_aciklama}</p>
                <p style={{fontSize:14}}><b>Başlama Saati: {moment(room.data.baslama_tarih).format('hh:mm DD MMMM YYYY')}</b></p>
                {room.durum==0?(
                    <p style={{fontSize:14, color:'red'}}><b>Ders başlamamış, başladığında otomatik olarak yönlendirileceksiniz</b></p>
                ):(
                    <p style={{fontSize:14, color:'green'}}><b>{room.durum==0?'Ders başlamamış, başladığında otomatik olarak yönlendirileceksiniz': 'Ders başlamış adınızı soyadınızı girerek katılabilirsiniz'}</b></p>
                )}
                <TextField
                        label="Adınız Soyadınız"
                        margin="normal"
                        name={"room_ad"}
                        defaultValue={ad}
                        onChange={(e) => {setAd(e.target.value); setError(null)}}
                        fullWidth={true}
                    />
                {error != null&&<p style={{fontSize:14, color:'red'}}>{error}</p>}
                <Button
                    style={{marginTop:20, marginBottom:20}}
                    type={"submit"}
                    color={"primary"}
                    variant="contained"
                    onClick={() => {join(); setJoinStatus(true)}}
                >DERSE KATIL</Button>

                <Grid item xs={12} style={{padding: 10}}>
                    {room.data.dokumans.length>0 && <Box style={{marginTop: 20}}>
                        <h4 style={{color: colors.btnBgMavi2}}>Ders Dökümanları</h4>
                        <ul>
                            {room.data.dokumans.map((dokuman, index) =>
                                <li><p>{dokuman.aciklama}</p></li>
                            )}
                        </ul>
                    </Box>}
                </Grid>
            </Grid>}
        </Grid>
    </>
}

export default UrlJoinComponent;
