import React, {createContext, useState} from "react";

export const StepContext = createContext([])

export const Step = props => {
    const [aciteStep, SetActiveStep] = useState(0)
    return (
        <StepContext.Provider value={[aciteStep, SetActiveStep]}>
            {props.children}
        </StepContext.Provider>
    )
}
