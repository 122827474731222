import React, {useEffect, useState, useContext} from 'react';
import instance from '../../apis';
import {Grid} from "@material-ui/core";
import {Paper} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import EditIcon from '@material-ui/icons/Edit';
import Button from "@material-ui/core/Button";
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from "@material-ui/core/TextField";
import {toast} from 'react-toastify';
import {BreadsContext, PageContext, PageTitleContext} from "../../stores/PageStore";


const Banks = () => {
    const [rows, setRows] = useState([])
    const [row, setRow] = useState({})
    const [id, setId] = useState(0);
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);

    const breads = [
        {
            link: null,
            title: 'Banka Hesaplarım',
            last: true

        }];
    useEffect(() => {
        instance
            .get('/api/admin/banks')
            .then(function (res) {
                setRows(res.data)
                setPageTitle('Banka İşlemleri')
                setBreads(breads)
                setPage(7)
            })

    }, [])


    function deleteVideo(id) {
        if (global.confirm('Silmek istediğinizden emin misiniz?')) {
            instance
                .delete('/api/admin/banks/' + id)
                .then(function () {
                    const item = rows.filter(x => x.id != id)
                    setRows(item)
                    toast.warn('Silme Başarılı')
                })
        }
    }

    function edit(id) {
        instance
            .get('/api/admin/bank/' + id)
            .then(function (res) {
                setRow(res.data)
                setId(id)
            })
    }

    const RowsView = () => {
        if (rows.length == 0) {
            return <>Henüz bir banka hesabı eklemediniz.</>
        }
        return rows.map(row => <Paper className={"paper"}>
            <Grid key={row.id} spacing={2} alignItems={"center"} container>
                <Grid item xs={12} sm={4}>
                    {row.name}
                </Grid>
                <Grid item xs={12} sm={8}>
                    {row.bank_name}
                </Grid>
                <Grid item xs={8} sm={10}>
                    {row.iban}
                </Grid>
                <Grid item xs={2} sm={1}>
                    <Fab color="primary" size="small" aria-label="Edit">
                        <EditIcon onClick={() => edit(row.id)}/>
                    </Fab>
                </Grid>
                <Grid item xs={2} sm={1}>
                    <Fab color="secondary" size="small" onClick={() => deleteVideo(row.id)} aria-label="Edit">
                        <DeleteIcon/>
                    </Fab>
                </Grid>
            </Grid>
        </Paper>);
    };

    function FormView() {
        const [inputs, setInputs] = useState(row)

        const handleSubmit = (e) => {
            e.preventDefault()
            const formData = new FormData(e.target)
            instance
                .post(`/api/admin/banks/${id}`, formData)
                .then(function (res) {
                    setRows(res.data)
                    if (id) {
                        setRow(res.data.filter(x => x.id == id)[0])
                        toast.success('Güncelleme Başarılı')

                    } else {
                        toast.success('Ekleme Başarılı')

                    }
                })
        }
        const handleChange = (e) => {
            const target = e.target
            setInputs({
                ...inputs,
                [target.name]: target.value
            })
        }
        return <form onSubmit={handleSubmit}>
            <Grid spacing={2} container>
                <Grid item xs={12} sm={12}>
                    <TextField
                        variant={"outlined"}
                        size={"small"}
                        required
                        onChange={handleChange}
                        id="name"
                        defaultValue={inputs.name}
                        name="name"
                        label="Hesap Sahibi"
                        fullWidth
                        autoComplete="name"
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        variant={"outlined"}
                        size={"small"}
                        required
                        onChange={handleChange}
                        id="bank_name"
                        defaultValue={inputs.bank_name}
                        name="bank_name"
                        label="Banka Adı"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        variant={"outlined"}
                        size={"small"}
                        required
                        onChange={handleChange}
                        id="iban"
                        defaultValue={inputs.iban}
                        name="iban"
                        label="İban Numarası"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Button
                        type="submit"
                        color={"primary"}
                        fullWidth={true}
                        variant={"contained"}
                    >
                        Banka Bilgilerini EKle
                    </Button>
                </Grid>


            </Grid>
        </form>
    }

    const Context = () => {
        return <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Paper className={"paper"}>
                        <FormView/>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <RowsView/>
                </Grid>
            </Grid>
        </>
    }


    return <Context/>
}

export default Banks;
