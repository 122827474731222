import React from 'react';
import {CircularProgress, Divider, TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    root: {
        margin      : 3,
        padding     : 0,
        fontSize    : 9,
        marginBottom: 10
    }
}));

function KonuSec(props) {
    const classes = useStyles();
    if (props.loadKonu) return <CircularProgress/>
    if (!props.soru.id) return <>Konu Seçimi</>
    const selected = props.selectedKonu[props.soru.id] == undefined ? [] : props.selectedKonu[props.soru.id]
    return <>
        <Autocomplete
            size={"small"}
            classes={{
                root: classes.root
            }}
            multiple
            id="konuSec"
            options={props.konular}
            getOptionLabel={option => option.name}
            value={selected}
            filterSelectedOptions
            onChange={props.handleChange}
            renderInput={params => (
                <TextField
                    size={"small"}
                    {...params}
                    variant="outlined"
                    label="Konu Seç"
                    placeholder="Konular"
                    fullWidth
                />
            )}
        />
    </>


}

export default KonuSec;