export default function phone(phone) {
    let newPhone = '';
    if (phone == '' || phone==null) {
        return '';
    }

    if (phone[0] == 0) {
        newPhone = 9;
        phone.split('').map(item => {
            newPhone += item;
        })
    } else if (phone[0] == 5) {
        newPhone = 90;
        phone.split('').map(item => {
            newPhone += item;
        })
    }
    return newPhone
}
