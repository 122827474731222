import React, {useContext} from "react";
import Divider from "@material-ui/core/Divider";
import {Link} from "react-router-dom";

import StyledTreeItem from "./StyledTreeItem";
import {PageContext} from "../stores/PageStore";
import {useTheme} from "@material-ui/core/styles";


function TreeViewMenu({item, index, name}) {
    const [getPage] = useContext(PageContext)
    const theme = useTheme();

    function bgColor(page) {
        return page == getPage ? {
            backgroundColor: '#ffd3b6',
            borderTopRightRadius: theme.spacing(2),
            borderBottomRightRadius: theme.spacing(2),
        } : null
    }

    function DividerView({items, index}) {
        if (index < items.length - 1) {
            return <Divider/>
        } else {
            return <><Divider/></>
        }
    }

    if (item.link == null) {
        return <>
            <StyledTreeItem
                nodeId={item.nodeId}
                labelText={item.label}
                labelIcon={item.icon}>
                <Divider/>
                {item.items.map((childItem, index) => <><TreeViewMenu key={`child_menu_${name}_${index}`} item={childItem} index={index} name={"all"}/> </>)}
            </StyledTreeItem>
            <DividerView items={item.items} index={index}/>
        </>
    } else {
        return <>
            <Link key={`${name}_nav_${index}`} to={item.link}>
                <StyledTreeItem
                    style={
                        bgColor(item.nodeId)
                    }
                    nodeId={item.nodeId}
                    labelText={item.label}
                    labelIcon={item.icon}
                />
            </Link>
            <Divider/>
        </>
    }
}

export default TreeViewMenu;
