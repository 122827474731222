import React from 'react';
import {CircularProgress, TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    root: {
        margin  : 3,
        padding : 0,
        fontSize: 9
    }
}));

function KazanimSec(props) {
    const classes = useStyles();
    if (props.loadKazanim) return <CircularProgress/>
    if (props.kazanimlar.length < 1) return <>Kazanim/Alt Konu Seçimi</>
    const selected = props.selectedKazanim[props.soru.id] == undefined ? [] : props.selectedKazanim[props.soru.id]
    return <>
        <Autocomplete
            size={"small"}
            classes={{root: classes.root}}
            multiple
            id="kazanimSec"
            options={props.kazanimlar}
            getOptionLabel={option => option.name}
            value={selected}
            onChange={props.handleChange}
            filterSelectedOptions

            renderInput={params => (
                <TextField
                    size={"small"}
                    {...params}
                    variant="outlined"
                    label="Kazanım Seç"
                    placeholder="Kazanımlar"
                    fullWidth
                />
            )}
        />
    </>


}

export default KazanimSec;