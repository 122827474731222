export default  {

    primary:    '#33438E',
    success:    '#7EC113',
    info:       '#00AEFF',
    warning:    '#FFBF00',
    danger:     '#FF3E30',
    bg :        '#ffffff',
    white:      '#ffffff',


    header:     '#72788A',
    text:       '#72788A',
    textSub:    '#ADB5CE',

    grey0:      '#bbb',
    grey1:      '#cccccc',
    grey2:      '#f2f2f2',

    btnBgColor: '#cccccc',
    btnBoder:   '#CDD0D9',


    btnTextColor:'#8E8E8E',

    btnBgYesilRgb: 'rgba(105,225,205,1)',
    btnBgYesil: '#69E1CD',
    btnBgYesil2:'#4DBAA2',
    
    btnBgMorRgb:'rgba(154,116,198,1)',
    btnBgMor:   '#9A74C6',
    btnBgMor2:  '#745698',
    
    btnBgKMorRgb:'rgba(130,109,212,1)',
    btnBgKMor:   '#826DD4',
    btnBgKMor2:  '#745698',

    btnBgMaviRgb:'rgba(0,174,255,1)',
    btnBgMavi:   '#00AEFF',
    btnBgMavi2:  '#008dcf',
    btnBgKMavi:  '#8086D7',

    btnBgSariRgb:'rgba(252,189,65,1)',
    btnBgSari:   '#FCBD41',
    btnBgSari2:  '#D1A144',

    btnBgKirmiziRgb:'rgba(255,81,120,1)',
    btnBgKirmizi:   '#FF5178',
    btnBgKirmizi2:  '#D64061',

    colors : [
        'rgba(255,81,120,1)',
        'rgba(252,189,65,1)', 
        'rgba(105,225,205,1)',
        'rgba(154,116,198,1)',
        'rgba(130,109,212,1)',
        'rgba(0,174,255,1)',
    ],
    colorsSecond : [
        '#D64061',
        '#D1A144', 
        '#4DBAA2',
        '#745698',
        '#8086D7',
        '#008dcf',
    ], 

    alternateColor : [
        '#ffffff',
        '#e2e2e2',
        '#bbbbbb',
    ]

    

};

