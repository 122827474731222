import React, {useContext, useEffect, useState} from 'react';
import instance from "../../../apis";
import {useParams, useHistory} from 'react-router-dom';
import {BreadsContext, PageContext, PageTitleContext} from "../../../stores/PageStore";
import {Grid, Paper, Divider, IconButton, Switch} from "@material-ui/core";
import {toast} from "react-toastify";
import Moment from "react-moment";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Button} from 'semantic-ui-react'

import {useTranslation} from "react-i18next";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import billingCalculate from "../../../functions/billingCalculate";

const Egitmen = () => {
    const history = useHistory()
    const {id} = useParams();
    const [getId, setGetId] = useState(id)
    const [data, setData] = useState({})
    const {t} = useTranslation();
    const [load, setLoad] = useState(true)
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    useEffect(() => {
        if (getId != 0) {
            instance
                .get(`/api/admin/egitmenler/${getId}`)
                .then(function (res) {
                    setData(res.data.item)
                    setLoad(false)
                })
        } else {
            setLoad(false)
        }
    }, [getId])
    useEffect(() => {
        let breads = [];
        if (data.length < 1) {
            breads = [
                {
                    link: `/admin/egitmenler`,
                    title: 'Siteler',
                    last: null
                },
                {
                    link: null,
                    title: 'Yeni Eğitmen',
                    last: true
                }
            ]
        } else {
            breads = [
                {
                    link: `/admin/sites`,
                    title: 'Eğitmenler',
                    last: null
                },
                {
                    link: null,
                    title: data.client,
                    last: true
                }
            ]
        }

        setBreads(breads)
        setPageTitle('Satın Alma Ayarları')
        setPage(61)
    }, [data])

    function siteSubmitForm(e) {
        const target = e.target
        e.preventDefault()
        const formData = new FormData(target)
        instance.post(`/api/admin/egitmenler/${getId}`, formData)
            .then(function (res) {
                if (id == 0) {
                    //  history.push(`/admin/sites/${res.data.id}`)
                    //  setLoad(true)
                    //setGetId(res.data.id)
                }

                toast.success('Öğretmen başarıyla eklendi')
            })
    }

    const RowSepets = () => {
        if (!data.sepets) {
            return <></>
        }
        if (data.sepets.length == 0) {
            return <></>
        }

        function sepetOnayla(sepet_id) {
            instance.get(`/api/superadmin/lisans/onayla/${sepet_id}`)
                .then(function (res) {
                    setData(res.data)
                })
        }

        function sepetIptal(sepet_id) {
            instance.get(`/api/superadmin/lisans/iptal/${sepet_id}`)
                .then(function (res) {
                    setData(res.data)
                })
        }


        const SepetDurum = (item) => {
            if (item.active == null) {
                return <Grid item xs={12} sm={2}><Button onClick={() => sepetOnayla(item.id)} size={"small"} variant={"outlined"} color={"blue"}>Onayla</Button></Grid>
            }
            return <Grid item xs={12} onClick={() => sepetIptal(item.id)} sm={2}><Button size={"small"} variant={"outlined"} style={{backgroundColor: "lightslategrey"}}>İptal Et</Button></Grid>

        }
        return data.sepets.map(item => <Paper className="paper" key={item.id}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>ID:{item.id}</Grid>
                    <Grid item xs={12} sm={5}>
                        {item.products.map(product =>
                            <Grid xs={12} sm={12} item>{product.qty} Adet {product.name}</Grid>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={1}>Net: {item.unit_price}</Grid>
                    <Grid item xs={12} sm={1}>KDV:{item.kdv}</Grid>
                    <Grid item xs={12} sm={1}>Toplam: {item.dis_price}</Grid>
                    {SepetDurum(item)}
                    <Grid item xs={12} sm={12}><Divider/></Grid>
                </Grid>
            </Paper>
        )
    }
    if (load) {
        return <CircularProgress/>
    }

    function StatusView() {
        const [status, setStatus] = useState(data.ban_count == 0 ? true : false);
        const handleChangeChecked = (event) => {
            setStatus(event.target.checked);
        };

        function DurumTextView() {
            if (!status) {
                return <> Eğitimci Sisteme Giremez</>

            }
            return <> Eğitimci Aktif</>


        }

        return <Grid container justify={"flex-end"} alignContent={"center"} alignItems={"center"}>
            <Grid item xs={2} sm={2}>
                <Switch
                    id={"finished_at_update_for_all_users"}
                    checked={status}
                    onChange={handleChangeChecked}
                    color="primary"
                    value={status}
                    name="status"
                    inputProps={{'aria-label': 'primary checkbox'}}
                />
            </Grid>
            <Grid item xs={10} sm={10}>
                <DurumTextView/>
            </Grid>
        </Grid>
    }

    function OnayTarihiView() {
        const [tarih, setTarih] = useState(data.tarih)
        const dateChange = (date) => {
            setTarih(moment(date).format('YYYY-MM-DD'));
        };
        return <Grid container alignContent={"center"} alignItems={"center"}>
            <Grid item xs={12} sm={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy"
                        margin="normal"
                        id="easy_license_last_date"
                        name="tarih"
                        label="Onay Tarihi"
                        value={tarih ? tarih : new Date()}
                        onChange={dateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
        </Grid>
    }

    function PaymentsView({data}) {
        if (getId == 0)
            return <></>
        const billing = billingCalculate(data)
        return <>
            <Grid item xs={3} sm={3}>{billing.payment} ₺</Grid>
            <Grid item xs={3} sm={3}>{billing.totalCost} ₺</Grid>
            <Grid item xs={3} sm={3}>{billing.kalan} ₺</Grid>
        </>
    }

    function SiteView() {
        return <form onSubmit={siteSubmitForm}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <TextField
                        size={"small"}
                        variant={"outlined"}
                        required
                        defaultValue={data.name}
                        name="name"
                        label={t('Adı Soyadı')}
                        fullWidth={true}
                        autoFocus
                        autoComplete
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        size={"small"}
                        variant={"outlined"}
                        defaultValue={data.email}
                        name="email"
                        label="e-posta Adresi"
                        type="email"
                        fullWidth={true}
                        autoComplete={false}

                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        size={"small"}
                        variant={"outlined"}
                        name="password"
                        label="Telefon"
                        type="tel"
                        fullWidth={true}
                        autoComplete={false}

                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        size={"small"}
                        variant={"outlined"}
                        name="password"
                        label="Kullanıcı Şifresi"
                        type="password"
                        fullWidth={true}
                        autoComplete={false}

                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Grid container alignContent={"center"} alignItems={"center"} spacing={2}>
                        {/*<Grid item xs={6} sm={6}>
                            <OnayTarihiView/>
                        </Grid>*/}
                        <Grid item xs={12} sm={12}>
                            <StatusView/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Button fluid type="submit" variant={"contained"} color={"blue"} fullWidth={true}>KULLANICI BİLGİLERİNİ KAYDET</Button>
                </Grid>
            </Grid>
        </form>
    }

    function PaymentFormView() {
        if (getId == 0)
            return <></>

        function paymentSubmit(e) {
            const target = e.target
            e.preventDefault()
            const formData = new FormData(target)
            instance.post(`/api/admin/data/payment/${id}`, formData)
                .then(function (res) {
                    setData(res.data.item)
                    toast.success('Ödeme Eklendi')
                })
        }

        return <form onSubmit={paymentSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                    <TextField
                        size={"small"}
                        variant={"outlined"}
                        required
                        name="amount"
                        label={t('Ödeme')}
                        fullWidth={true}
                    />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <TextField
                        size={"small"}
                        variant={"outlined"}
                        name="title"
                        label={t('Açıklama')}
                        fullWidth={true}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Button type=" submit" color={"teal"} fullWidth={true}>{t('ÖDEME EKLE')}</Button>
                </Grid>
            </Grid>
        </form>
    }

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <Paper className=" paper-handler">
                    <SiteView/>
                </Paper>
            </Grid>
            {/*<Grid item xs={12} sm={12}>
                <Paper className=" paper-handler">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>Bakiye Bilgileri</Grid>
                        <PaymentsView data={data}/>
                        <Grid item xs={12} sm={12}><Divider/></Grid>
                        <Grid item xs={12} sm={12}>
                            <PaymentFormView/>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>*/}
        </Grid>
    </>
}

export default Egitmen;
