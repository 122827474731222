import React, {useEffect, useContext, useState} from 'react';
import {BreadsContext, PageContext, PageTitleContext} from "../../../stores/PageStore";
import {Paper, Grid, Avatar, Divider} from "@material-ui/core";
import instance from "../../../apis";
import {toast} from "react-toastify";
import {Link, useParams} from "react-router-dom";
import ContactMessagesView from "./ContactMessagesView";
import Alert from "@material-ui/lab/Alert";

const ContactMasseges = () => {
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    const [reload,] = useState(false);
    const [room, setRoom] = useState({users: []});
    const [users, setUsers] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [load,] = useState(false);

    let login = 'false'
    let user_id = ''
    try {
        user_id = localStorage.getItem('user_id');
        login = localStorage.getItem('login')

    } catch (e) {

    }
    const {room_id} = useParams()

    useEffect(() => {
        const breads = [
            {
                link: null,
                title: 'Mesajlar',
                last: true
            }
        ]
        setBreads(breads)
        setPageTitle('Mesajlar')
        setPage(27.2)
    }, [])
    useEffect(() => {
        if (login == 'true') {
            getData()
        }
    }, [room_id, reload])

    function getData() {
        instance
            .get(`/api/contact/messages`, {
                params: {room_id: room_id}
            })
            .then(function (result) {
                if (result.status == 200) {
                    if (result.data.room) {
                        setRoom(result.data.room)
                        setUsers(result.data.users)
                    }
                    setRooms(result.data.rooms)
                } else {
                    toast.warn('Veriler çekilirken bir hata meydana geldi, lütfen yeniden deneyin.')
                }
            })
            .catch(function () {
                toast.error('Bağlantı hatası, lütfen yeniden deneyin.')
            })
    }

    if (room.length < 1) {
        return <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
                <Paper className="paper-handler"><Alert variant="outlined" severity="error">
                    Henüz bir mesajınız yok!
                </Alert>
                </Paper>
            </Grid>
        </Grid>
    }

    function usersNames(item) {
        const users = []
        item.users = item.users.filter(user => user.send_user.id !== parseInt(localStorage.getItem('user_id')))
        item.users.map((user, index) => {
            users.push(user.send_user.name)
        })
        const uniqUsers = users.filter((v, i, a) => a.indexOf(v) === i);
        let res = '';
        uniqUsers.map((name, index) => {
            res += name;
            if (index + 1 < uniqUsers.length)
                res += ',';
        })
        return res;
    }

    function ResView() {
        return <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
                <Paper className="paper-handler">
                    {rooms.map(item => <Link to={`/admin/contact/messages/${item.id}`}>
                        <Grid
                            container
                            spacing={2}
                            alignItems={"center"}
                            alignContent={"center"}>
                            <Grid item xs={1} sm={1}
                                  className="leftDivider"
                                  style={item.id == room.id ? {backgroundColor: "#27496d"} : {backgroundColor: "#ffffff"}}
                            ></Grid>
                            <Grid item xs={2} sm={2}>
                                <Avatar alt={usersNames(item)} src={`https://avatars.dicebear.com/v2/bottts/${usersNames(item)}.svg`}/>
                            </Grid>
                            <Grid item xs={9} sm={9}>
                                {usersNames(item)}
                            </Grid>
                            <Grid xs={12} sm={12}><Divider/></Grid>
                        </Grid>
                    </Link>)}
                </Paper>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Paper className="paper-handler">
                    <h4>MESAJLAR</h4>
                    <ContactMessagesView load={load} room={room} user_id={user_id} setRoom={setRoom}/>
                </Paper>
            </Grid>
        </Grid>
    }

    try {
        return <ResView/>
    } catch (e) {
        return <></>
    }

}

export default ContactMasseges;
