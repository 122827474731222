import React, {useContext, useEffect, useState} from 'react';
import {Paper, Grid, Divider} from "@material-ui/core";
import useInterval from "@use-it/interval";
import instance from "../../apis";
import {Link} from "react-router-dom";
import {ConfigContext} from "../../Store";
import {BreadsContext, PageContext, PageTitleContext} from "../../stores/PageStore";

const OnlineUsers = () => {
    const [onlines, setOnlines] = useState([])
    const [config] = useContext(ConfigContext);
    const roles = localStorage.getItem('roles');
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    useEffect(() => {
        if (!izin())
            return;
        getUsers()
    }, [])
    useEffect(() => {
        const breads = [
            {
                link: null,
                title: 'Ders İzleyenler',
                last: true
            }
        ]
        setBreads(breads)
        setPageTitle('Ders izleyen kullanıcılar')
        setPage(207)
    }, [])

    function getUsers() {
        return instance
            .get(`/api/online/users`)
            .then(function (res) {
                if (res.status === 200 && res.data.online) {
                    setOnlines(res.data.online)
                }
            })
    }

    function izin() {
        if (!roles)
            return false;
        if (roles.includes('superadmin')) {
            return true;
        }
        switch (config.online_users) {
            case 1:
                return true
                break
            case 2:
                if (roles.includes('siteadmin') || roles.includes('admin')) {
                    return true;
                }
                break
            case 3:
                if (roles.includes('siteadmin')) {
                    return true;
                }
                break
        }
        return false
    }

    if (!izin())
        return <></>

    function UsersView() {
        useInterval(() => {
            if (!izin())
                return;
            getUsers()
        }, 30000)
        return onlines.map((online, index) => <Grid key={online.user.id} container spacing={2}>
            <Grid item xs={1} sm={1}>{index + 1}</Grid>
            <Grid item xs={11} sm={3}>{online.user.name}</Grid>
            <Grid item xs={12} sm={3}>
                <Link target="_blank"
                      to={`/watch/${online.video.id}`}>
                    {online.video.title}
                </Link>
            </Grid>
            <Grid item xs={12} sm={12}><Divider/></Grid>
        </Grid>)
    }

    return <Paper className="paper-handler">
        <h4>Şuan sitemizde izlenen ders sayısı: <span style={{color: "red"}}>{onlines.length}</span></h4>
        <UsersView/>
    </Paper>
}

export default OnlineUsers;
