import React, {useContext, useEffect, useState} from 'react';
import instance from "../../apis";
import {BreadsContext, PageContext, PageTitleContext} from "../../stores/PageStore";
import {useParams} from 'react-router-dom';
import {Divider, Grid, CircularProgress, Tooltip, Paper} from '@material-ui/core';
import {toast} from 'react-toastify';
import useInterval from "@use-it/interval";
import moment from 'moment';
import coutDownViewer from "../../hooks/coutDownViewer";
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

function Sonuclar() {
    const {sinav_id} = useParams()
    const [reload, setReload] = useState(false)
    const [load, setLoad] = useState(true)
    const [coutDown, setCoutDown] = useState('')
    const [, setStatus] = useState(200)
    const [err, setErr] = useState({})
    const [sinav, setSinav] = useState({})
    const [sorular, setSorular] = useState([])
    const [datas, setDatas] = useState([])
    const [, setKonular] = useState([])
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    useEffect(() => {
        setLoad(true)
        instance
            .get(`/api/sinav/sonuclar/${sinav_id}`)
            .then(function (result) {
                if (result.status == 210) {
                    toast.warn(result.data.msg);
                    setErr({
                        msg: result.data.msg,
                        time: result.data.time
                    })
                    setStatus(210)
                } else {
                    const soruData = Object.values(result.data.sorular);
                    setSorular(soruData)
                    setKonular(Object.values(result.data.konular))
                    setSinav(result.data.sinav)
                    setDatas(result.data.datas)
                }
                setLoad(false)
            })
    }, [reload])
    useEffect(() => {
        const breads = [
            {
                link: '/sinavlar/all',
                title: 'Sınavlar',
                last: null
            },
            {
                link: null,
                title: load ? <CircularProgress style={{
                    width: 8,
                    height: 8
                }}/> : `${sinav.name} / Sınav Sonuçları`,
                last: true
            },
        ];
        setBreads(breads)
        setPage(17)
        setPageTitle('E-Sınav Sistemi')
        setLoad(false)
    }, [sinav])

    useInterval(() => {
        if ((moment(err.time)
            .unix() > moment()
            .unix())) {
            const viewer = coutDownViewer(moment(err.time)
                .unix() - moment()
                .unix())
            setCoutDown(viewer)
            if (viewer == '') {
                setReload(true)
                console.log(reload)
            }
        } else {
            setCoutDown('')
        }
    }, 1000)
    if (err.msg) return <>{err.msg}</>
    if (coutDown != '') return <div style={{textAlign: "center"}}>{coutDown}</div>;
    if (sorular.length < 1) return <CircularProgress/>
    if (!sinav.statistic) return <CircularProgress/>
    // if (sinav.status.tek == 1 || sinav.status.bitir != 1) return 'Bu sınavı henüz tamamlamadınız!';
    return <>
        <Paper className={"paper-handler"}>
            <Grid container spacing={1} alignContent={"center"} alignItems={"center"}>
                <Grid item xs={6} sm={2}><b>Sınav Adı</b></Grid>
                <Grid className={"sonucGrid"} item xs={6} sm={10}>{sinav.name}</Grid>
                <Grid item xs={12} sm={12}><Divider/></Grid>

                <Grid item xs={6} sm={2}><b>Soru Sayısı</b></Grid>
                <Grid className={"sonucGrid"} item xs={6} sm={10}>{sinav.statistic.soru_sayisi}</Grid>
                <Grid item xs={12} sm={12}><Divider/></Grid>

                <Grid item xs={6} sm={2}><b>S. Giren Kişi</b></Grid>
                <Grid className={"sonucGrid"} item xs={6} sm={10}>{sinav.statistic.sinava_giren_sayisi}</Grid>
                <Grid item xs={12} sm={12}><Divider/></Grid>

                <Grid item xs={6} sm={2}><b>S. Tammlyn Kişi</b></Grid>
                <Grid className={"sonucGrid"} item xs={6} sm={10}>{sinav.statistic.sinavi_tamamlayan_sayi}</Grid>
            </Grid>
        </Paper>
        <Paper className={"paper-handler"}>
            <Grid className={"martop-15 "} container spacing={1} alignContent={"center"} alignItems={"center"}>
                <Grid className={"sonucGrid"} item xs={2} sm={2}><Tooltip title="GENEL SIRALAMA"><b>G.SIRA</b></Tooltip></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}><Tooltip title="SEÇTİĞİNİZ BRANŞA GÖRE SIRALAMA"><b>B.SIRA</b></Tooltip></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}><b>NET</b></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}><b>DOĞ</b></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}><b>YAN</b></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}><b>BOŞ</b></Grid>

                <Grid item xs={12} sm={12}><Divider/></Grid>

                <Grid className={"sonucGrid"} item xs={2} sm={2}>{datas.sira}</Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}>{datas.bransSira}</Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}>{sinav.net_sayisi}</Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}>{sinav.dogru_cevap_sayisi}</Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}>{sinav.yanlis_sayisi}</Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}>{sinav.bos_sayisi}</Grid>
            </Grid>
        </Paper>
        <Paper className={"paper-handler"}>
            <Grid container spacing={1} alignContent={"center"} alignItems={"center"}>
                <Grid className={"sonucGrid"} item xs={2} sm={2}><Tooltip title="SORU NO"><strong>NO</strong></Tooltip></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}><Tooltip title="DOĞRU/YANLIŞ"><strong>D/Y</strong></Tooltip></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}><Tooltip title="DOĞRU CEVAP"><strong>CVP</strong></Tooltip></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}><Tooltip title="SENİN CEVABIN"><strong>SCVP</strong></Tooltip></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}><Tooltip title="DOĞRU YAPAN KİŞİ SAYISI"><strong>DCS</strong></Tooltip></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}><Tooltip title="CEVAPLAYAN KİŞİ SAYISI"><strong>CS</strong></Tooltip></Grid>
            </Grid>
            {sorular.map((item, index) => {
                return <>
                    <Grid className={'sonuc-satir'} style={
                        item.durum == 1 ? {backgroundColor: "#deff8b"} : item.user_cevap == -2 ? {backgroundColor: "#fff", color: '#758184'} : {backgroundColor: "#fbceb5"}} key={`soru_${item.id}`} item xs={12} sm={12}>
                        <Grid container spacing={1} alignContent={"center"} alignItems={"center"}>
                            <Grid className={"sonucGrid"} item xs={2} sm={2}>{item.soru_no}</Grid>
                            <Grid className={"sonucGrid"} item xs={2} sm={2}>
                                {(item.user_cevap == -2 || item.user_cevap == '' || item.user_cevap == null) ? <BlockIcon style={{fontSize: 12}}/> : item.durum == 1 ? <CheckCircleIcon style={{fontSize: 12, color: "green"}}/> :
                                    <CancelIcon style={{fontSize: 12, color: "red"}}/>}
                            </Grid>
                            <Grid className={"sonucGrid"} item xs={2} sm={2}>{item.dogru_cevap}</Grid>
                            <Grid className={"sonucGrid"} item xs={2} sm={2}>
                                {(item.user_cevap != -2 || item.user_cevap == '' || item.user_cevap == null) ? item.user_cevap : <BlockIcon style={{fontSize: 12}}/>}
                            </Grid>
                            <Grid className={"sonucGrid"} item xs={2} sm={2}>{item.dogru_cevaplayan_sayi}</Grid>
                            <Grid className={"sonucGrid"} item xs={2} sm={2}>{item.cevaplayan_sayi}</Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}> <Divider/></Grid>
                </>
            })}
        </Paper>
        <Paper className={"paper-handler"}>
            <Grid container spacing={1} alignContent={"center"} alignItems={"center"}>
                <Grid className={"sonucGrid"} item xs={2} sm={2}><b>KONU</b></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}><b>NET</b></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}><b>TOP</b></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}><b>DOĞ</b></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}><b>YAN</b></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}><b>BOŞ</b></Grid>
            </Grid>
            {sinav.konu_analiz.map((item, index) => {
                return <>
                    <Grid container alignContent={"center"} alignItems={"center"} key={`konu_${index}`}>
                        <Grid item style={{paddingTop: 10}} xs={12} sm={12}><b>{index + 1}.</b> {item.konu.name}</Grid>
                        <Grid className={"sonucGrid"} item xs={2} sm={2}></Grid>
                        <Grid className={"sonucGrid"} item xs={2} sm={2}>{item.net}</Grid>
                        <Grid className={"sonucGrid"} item xs={2} sm={2}>{item.yanlis + item.dogru + item.bos}</Grid>
                        <Grid className={"sonucGrid"} item xs={2} sm={2}>{item.dogru}</Grid>
                        <Grid className={"sonucGrid"} item xs={2} sm={2}>{item.yanlis}</Grid>
                        <Grid className={"sonucGrid"} item xs={2} sm={2}>{item.bos}</Grid>
                        <Grid item xs={12} sm={12}> <Divider/></Grid>
                    </Grid>
                </>
            })}
        </Paper>
    </>;
}

export default Sonuclar;
