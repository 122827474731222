import React, {useContext, useEffect} from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import instance from '../../apis';
import {BreadsContext, PageContext, PageTitleContext} from "../../stores/PageStore";

function AddVimeoVideo(props) {
    const history          = props.history
    const hanldeSubmit     = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        instance.post('/api/admin/vupload', formData)
            .then(function (res) {
                history.push(`/admin/video/edit/${res.data}`)
            })
    }
    const [, setBreads]    = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage]      = useContext(PageContext);
    useEffect(() => {
        const breads = [
            {
                link : null,
                title: 'Yeni Video Ekle',
                last : true
            }
        ]
        setBreads(breads)
        setPageTitle('Yeni Video Ekle')
        setPage(3)
    }, [])
    const Context = () => {

        return (
            <form onSubmit={hanldeSubmit}>
                <TextField name="id" id="id" placeholder={"Video ID"} fullWidth={true} style={{fontSize: 16}}/>
                <br/>
                <br/>
                <Button fullWidth={true} variant={"contained"} color={"primary"} type={"submit"}>KAYDET ve DEVAM ET</Button>
            </form>
        );
    }

    return <Context/>
}

export default AddVimeoVideo;