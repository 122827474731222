import React, {useContext} from 'react';
import {Divider, Grid} from "@material-ui/core";
import KreditCards from "./KreditCards";
import ShopierInfo from "./ShopierInfo";
import {KreditCardContext} from "./KreditCardStore";
import {Segment} from 'semantic-ui-react';

function KreditCardInfo() {
    const [state, setState] = useContext(KreditCardContext)
    if (!state.card_id || state.card_id === -1 || !state.active)
        return <></>

    function CardInfo() {
        if (state.card_id == 1)
            return <ShopierInfo/>
        return <></>
    }

    return <Segment>
        <CardInfo/>
    </Segment>
};

export default KreditCardInfo;
