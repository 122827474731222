import React, {useContext, useEffect, useState} from 'react';
import {BreadsContext, PageContext, PageTitleContext} from "../stores/PageStore";
import {CircularProgress, Grid, Paper} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Button from "@material-ui/core/Button";
import instance from "../apis";

function MontlyFiles() {
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    const [categories, setCategories] = useState([])
    const [yetkisiz, setYetkisiz] = useState(false)
    const [category, setCategory] = useState({files: []})
    const [fileLoad] = useState(false)
    const [loadFiles, setLoadFiles] = useState(false)
    useEffect(() => {
        const breads = [
            {
                link: '',
                title: 'Eklenen Dosyalar',
                last: true
            }
        ];
        setBreads(breads)
        setPage(23)
        setPageTitle('Eklenen Dosyalar')
    }, [])

    useEffect(() => {
        setLoadFiles(true)
        instance
            .get(`/api/montly/files`)
            .then(function (result) {
                setLoadFiles(false)
                if (result.status == 210) {
                    setYetkisiz(true)
                } else {
                    setCategories(result.data)
                }
                if (result.data[0] != null) {
                    setCategory(result.data[0])
                }
            })
    }, [])

    function selectCategory(item) {
        setCategory(item)
    }

    if (fileLoad) {
        return <CircularProgress disableShrink/>;
    }


    function CategoryView() {
        return categories.map((item, index) => <Grid item xs={12} sm={12} key={index}>
            <Button
                fullWidth
                variant={category.id == item.id ? "contained" : "outlined"}
                color={"primary"}
                onClick={() => selectCategory(item)}>
                {item.name}
            </Button>
        </Grid>)
    }

    function FileListView() {
        if (loadFiles)
            return <CircularProgress/>
        if (!category.files)
            return <span style={{color: "red"}}>Henüz dosya eklenmedi!</span>
        return category.files.map((item, index) => <Grid container alignContent={"center"} alignItems={"center"} item key={item.id}>
            <Grid item xs={1} sm={1}>{index + 1}</Grid>
            <Grid item xs={7} sm={7}>{item.oname}</Grid>
            <Grid item xs={2} sm={2}>{item.exs}</Grid>
            <Grid item xs={2} sm={2}>
                <a target="_blank" href={`https://lms.rotasinav.com/file/download?path=${item.path}&site_id=${item.site_id}`}>
                    <CloudDownloadIcon/>
                </a>
            </Grid>
            <Grid item xs={12} sm={12}><Divider/></Grid>
        </Grid>)
    }

    if (yetkisiz)
        return <Paper className="paper-handler"><span style={{color: "red"}}>Henüz bu sayfaya erişim yetkiniz bulunmuyor</span></Paper>
    return <>
        <Grid container spacing={2}>
            <Grid container item xs={12} sm={4} alignContent={"center"} alignItems={"center"} justify={"center"} style={{paddingBottom: 10}}>
                <Paper className="paper-handler">
                    <CategoryView/>
                </Paper>
            </Grid>

            <Grid item xs={12} sm={8}>
                <Paper className="paper-handler">
                    <FileListView/>
                </Paper>
            </Grid>
        </Grid>
    </>;
}

export default MontlyFiles;
