import React, {useEffect, useState, useContext} from 'react';
import instance from '../../apis';
import {useParams} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {toast} from 'react-toastify';
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from '@material-ui/core/CircularProgress';
import {Paper, Switch} from "@material-ui/core";
import Moment from 'react-moment';
import Divider from "@material-ui/core/Divider";
import {BreadsContext, PageContext, PageTitleContext} from "../../stores/PageStore";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const UserCard = () => {
    const [user, setUser] = useState({
        sepets: [],
        site: {}
    })
    const {t} = useTranslation();

    const [inputs, setInputs] = useState({roles: [], user: {roles: []}, site: {clients: []}})
    const [load, setLoad] = useState(false)
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const {id} = useParams()
    const [, setPage] = useContext(PageContext);

    useEffect(() => {
        setLoad(true)
        instance.get(`/api/superadmin/get/user/card/${id}`)
            .then(function (res) {
                const data = res.data;
                setUser(res.data.user)
                const roles = [];
                const userRoles = [];
                const role_ids = [];
                data.roles.map(role => {
                    roles.push({id: role.id, name: role.name})
                })
                data.user.roles.map(role => {
                    userRoles.push({id: role.id, name: role.name})
                    role_ids.push(role.id)
                })
                const site_id = data.user.site_id && data.user.site_id != "null" ? JSON.parse(data.user.site_id) : [];
                const user_clients = data.clients.filter(x => site_id.includes(x.id))
                const siteStatus = data.user.site ? data.user.site.status === 1 ? true : false : false
                setInputs({roles: roles, user: {roles: userRoles, role_ids: role_ids, site_id: site_id, user_clients: user_clients}, site: {clients: data.clients, status: siteStatus}})
                setLoad(false)
            })
        const breads = [
            {
                link: null,
                title: 'Kullanıcı kartı',
                last: true
            }
        ]
        setBreads(breads)
        setPageTitle(user.name)
        setPage(51)
    }, [])

    function userSubmitForm(e) {
        const target = e.target
        e.preventDefault()
        const formData = new FormData(target)
        formData.append('roles', JSON.stringify(inputs.user.role_ids))
        formData.append('site_id', JSON.stringify(inputs.user.site_id))
        formData.append('siteStatus', inputs.site.status ? 1 : -1)
        instance.post(`/api/superadmin/get/user/card/${id}`, formData)
            .then(function (res) {
                toast.success('Kullanıcı bilgileri Güncelendi')
            })
    }


    function MomentView(date) {
        return <Moment format="DD/MM/YYYY">
            {date}
        </Moment>
    }


    const RowSepets = () => {
        if (!user.sepets) {
            return <></>
        }
        if (user.sepets.length == 0) {
            return <></>
        }

        function sepetOnayla(sepet_id) {
            instance.get(`/api/superadmin/lisans/onayla/${sepet_id}`)
                .then(function (res) {
                    setUser(res.data)
                })
        }

        function sepetIptal(sepet_id) {
            instance.get(`/api/superadmin/lisans/iptal/${sepet_id}`)
                .then(function (res) {
                    setUser(res.data)
                })
        }


        const SepetDurum = (item) => {
            if (item.active == null) {
                return <Grid item xs={12} sm={2}><Button onClick={() => sepetOnayla(item.id)} size={"small"} variant={"outlined"} color={"secondary"}>Onayla</Button></Grid>
            }
            return <Grid item xs={12} onClick={() => sepetIptal(item.id)} sm={2}><Button size={"small"} variant={"outlined"} style={{backgroundColor: "lightslategrey"}}>İptal Et</Button></Grid>

        }
        return user.sepets.map(item => <Paper className="paper" key={item.id}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>ID:{item.id}</Grid>
                    <Grid item xs={12} sm={5}>
                        {item.products.map(product =>
                            <Grid xs={12} sm={12} item>{product.qty} Adet {product.name}</Grid>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={1}>Net: {item.unit_price}</Grid>
                    <Grid item xs={12} sm={1}>KDV:{item.kdv}</Grid>
                    <Grid item xs={12} sm={1}>Toplam: {item.dis_price}</Grid>
                    {SepetDurum(item)}
                    <Grid item xs={12} sm={12}><Divider/></Grid>
                </Grid>
            </Paper>
        )
    }
    const handleChangeChecked = (event) => {
        setInputs({...inputs, site: {...inputs.site, [event.target.name]: event.target.checked}});
    };

    function SelectRoles() {
        const handleChangeValue = (event, value) => {
            const items = [];
            const itemIds = [];
            value.map((item, index) => {
                items.push(item)
                itemIds.push(item.id)
            })
            if (itemIds.includes(3)) {
                setInputs({...inputs, site: {...inputs.site, status: true}, user: {...inputs.user, roles: items, role_ids: itemIds}});
            } else {
                setInputs({...inputs, site: {...inputs.site, status: false}, user: {...inputs.user, roles: items, role_ids: itemIds}});
            }
        };
        return <Autocomplete
            size={"small"}
            multiple
            id="roles"
            options={inputs.roles}
            getOptionLabel={(option) => option.name}
            defaultValue={inputs.user.roles}
            filterSelectedOptions
            onChange={handleChangeValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={t("Kullanıcı Rolleri")}
                    placeholder={t("Rolü olmayan kullanıcı öğrencidir")}
                />
            )}
        />

    }

    function SelectAdminSites() {
        if (!inputs.user.role_ids)
            return <></>
        if (!inputs.user.role_ids)
            return <></>
        const handleChangeValue = (event, value) => {
            const items = [];
            const itemIds = [];
            value.map((item, index) => {
                items.push(item)
                itemIds.push(item.id)
            })
            setInputs({...inputs, user: {...inputs.user, user_clients: items, site_id: itemIds}});
        };
        return <Grid item xs={12} sm={12}><Autocomplete
            size={"small"}
            multiple
            id="site_id_for_admin"
            options={inputs.site.clients}
            getOptionLabel={(option) => option.client}
            defaultValue={inputs.user.user_clients}
            filterSelectedOptions
            onChange={handleChangeValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Öğretmen olduğu siteler"
                />
            )}
        /></Grid>
    }

    if (load) {
        return <CircularProgress/>
    }
    const apiOnay = (date) => {
        setInputs({...inputs, site: {...inputs.site, tarih: moment(date).format('YYYY-MM-DD')}});
    };

    function SiteYoneticisiAyarView() {
        if (!inputs.site.status) {
            return <></>
        }
        return <>
            <Grid item xs={12} sm={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        format="dd.MM.yyyy"
                        margin="normal"
                        id="tarih"
                        name="tarih"
                        label="Onay Tarihi"
                        value={inputs.site ? inputs.site.tarih : new Date()}
                        onChange={apiOnay}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    variant={"outlined"}
                    size={"small"}
                    fullWidth={true}
                    select
                    label="Site seçin"
                    defaultValue={user.site ? user.site.id : null}
                    helperText="Kullanıcının yönettiği siteyi seçiniz."
                    margin="normal"
                    style={{textAlign: "left"}}
                    name="client_id"
                >
                    {inputs.site.clients.map(item => <MenuItem value={item.id}>{item.client}</MenuItem>)}

                </TextField>
            </Grid>
        </>
    }

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Paper className="paper-handler">
                    <form onSubmit={userSubmitForm}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <SelectRoles/>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Grid container alignContent={"center"} alignItems={"center"}>
                                    <Grid item xs={2} sm={1}>
                                        <Switch
                                            id={"status"}
                                            checked={inputs.site.status}
                                            onChange={handleChangeChecked}
                                            color="primary"
                                            name="status"
                                            inputProps={{'aria-label': 'primary checkbox'}}
                                        />
                                    </Grid>
                                    <Grid item xs={2} sm={2}>Site Yöneticisi</Grid>
                                    <SiteYoneticisiAyarView/>
                                </Grid>

                            </Grid>

                            <SelectAdminSites/>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    size={"small"}
                                    variant={"outlined"}
                                    required
                                    defaultValue={user.name}
                                    name="name"
                                    label="Kullanıcı ismi"
                                    fullWidth={true}
                                    autoComplete="name"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    size={"small"}
                                    variant={"outlined"}
                                    required
                                    defaultValue={user.email}
                                    name="email"
                                    label="Email"
                                    fullWidth={true}
                                    autoComplete="email"
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <TextField
                                    size={"small"}
                                    variant={"outlined"}
                                    required
                                    defaultValue={user.tel}
                                    name="tel"
                                    label="Telefon"
                                    fullWidth={true}
                                    autoComplete="tel"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    size={"small"}
                                    variant={"outlined"}
                                    name="password"
                                    label="Kullanıcı Şifresi"
                                    fullWidth={true}
                                    autoComplete="password"
                                    helperText={'Kullanıcı şifresini değiştirmek isterseniz bu alanı kullanın'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Button type="submit" variant={"contained"} color={"secondary"} fullWidth={true}>ÜYELİK BİLGİLERİNİ KAYDET</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Paper className="paper-handler">
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12}>
                <h4>SİPARİŞLER</h4>
                <RowSepets/>
            </Grid>
        </Grid>
    </>
}

export default UserCard;
