import React, {useState, useContext} from "react";
import Moment from "react-moment";
import Divider from "@material-ui/core/Divider";
import 'moment/locale/tr'
import timer from '../hooks/timer';
import countdown from 'moment-countdown';
import {NoticeContext} from "../Store";
// çalışıyor
export default function AllTimer(props) {
    const {initials} = props
    const [timeRender, setTimeRender] = useState(false)
    const [notice] = useContext(NoticeContext);
    if (notice.yaklasan_sinavlar === -1)
        return <></>
    const TimerView = ({name, date, fontSize, bgColor}) => {
        setTimeRender(false)
        const time = timer(date);
        const gun = time['days'];
        const hour = time['hours'];
        const min = time['min'];
        const sn = time['sn'];

        function kalanZaman() {
            if (gun <= 0) {
                return `${hour} SAAT`
                if (hour <= 0) {
                    return `${min} Dk`
                    if (min <= 0) {
                        return `${sn} Sn`
                    }
                }
            }
            return `${gun} GÜN`
        }

        return <div style={{
            fontSize: fontSize,
            textAlign: "center",
            paddingTop: 10,
            backgroundColor: bgColor
        }}>
            <div className="text-primary">{name}</div>
            <div className="text-info" style={{fontSize: 12}}><Moment locale="tr" format="DD.MM.YYYY HH.mm" date={date}/></div>
            <div className="text-muted" style={{fontSize: 11}}>Kalan Süre <span style={{
                color: '#ff4e50',
                fontSize: 14
            }}>  {kalanZaman()} </span>
            </div>
            <Divider style={{height: 2}}/>
        </div>
    }
    /*  {timer(date)['hours']} Saat {timer(date)['min']} dk {timer(date)['sn']} sn*/

    const SinavTimer = () => {
        if (!initials.first_sinav) return <></>
        return <TimerView bgColor={"rgba(223,227,238,0.8)"} name={initials.first_sinav.name} fontSize={22} date={initials.first_sinav.tarih}/>
    }
    const GenelSinavTimer = () => {
        return <>
            <div style={{
                textAlign: "center",
                padding: 0,
                marginTop: 15,
                marginBottom: 0
            }}><img src={"https://www.osym.gov.tr/images/osym-logo.png"}/></div>
            <TimerView timeRender={timeRender} bgColor={"rgba(255,255,255,.8)"} name={'GENEL YETENEK-GENEL KÜLTÜR'} fontSize={14} date={'2020-09-06 10:00'}/>
            <TimerView bgColor={"rgba(255,255,255,.8)"} name={'EĞİTİM BİLİMLERİ'} fontSize={14} date={'2020-09-06 14:30'}/>
            <TimerView bgColor={"rgba(255,255,255,.8)"} name={'ÖABT'} fontSize={14} date={'2020-09-20 10:00'}/>

        </>
    }
    return <>
        <SinavTimer/>
        <GenelSinavTimer/>
    </>
}
