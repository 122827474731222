import React, {useEffect, useState, useContext} from 'react';
import Paper from "@material-ui/core/Paper";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import makeStyles from "@material-ui/core/styles/makeStyles";
import instance from '../../../apis';
import Grid from '@material-ui/core/Grid';
import {BreadsContext, PageContext, PageTitleContext} from "../../../stores/PageStore";
import {Button, LinearProgress, TextField} from "@material-ui/core";
import {Dropdown, Icon} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {useParams} from 'react-router-dom';
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
    videoDiv: {
        padding: theme.spacing(10, 5),
        textAlign: "center",
        color: "#c2c2c2",
        "&:hover": {
            color: "#f0134d"
        },
        height: 375
    },
    progressBar: {
        height: 20,
        borderRadius: 5,
        webkitBorderRadius: 5
    }
}));

const Upload = (props) => {
    const {t} = useTranslation();
    const history = props.history;
    const classes = useStyles();
    const {id} = useParams();
    const [uploadPercent, setUploadPercent] = useState(0)
    const [videoType, setVideoType] = useState('server')
    const [file, setFile] = useState({})
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);

    const [load, setLoad] = useState(false);
    useEffect(() => {
        const breads = [
            {
                link: null,
                title: 'Yeni Video Ekle',
                last: true
            }
        ]
        setBreads(breads)
        setPageTitle('Yeni video ekle')
        setPage(3)
    }, [])
    const selectFile = () => {
        const file = document.getElementById("file");
        file.click()
        file.onchange = function () {
            setLoad(true)
            let formData = new FormData();
            const inputFile = file.files[0];
            setFile(inputFile)
            formData.append("file", inputFile);
            formData.append("id", id);
            let percentCompleted = 0;
            instance.post('/api/admin/video/upload', formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (ev: progressEvent) => {
                        const progress = ev.loaded / ev.total * 100;
                        setUploadPercent(progress)
                    },
                }
            )
                .then(function (res) {
                    setLoad(false)
                    history.push(`/admin/video/edit/${res.data.file_id}`)
                })
        }
    }
    const LoadView = () => {
        if (!load) {
            return <></>;
        }
        return <LinearProgress/>

    }
    const Context = () => {
        const Progress = () => {
            if (uploadPercent == 0 || uploadPercent == 100) {
                return <></>
            } else {
                return <>
                    <Grid container>
                        <Grid item xs={12} sm={12}>Lütfen işlemler bitene kadar bekleyin.</Grid>
                        <Grid item xs={12} sm={12}> <LinearProgress className={classes.progressBar} variant="buffer" value={uploadPercent}/></Grid>
                        <Grid item xs={12} sm={12}> {file.name}</Grid>
                    </Grid>
                </>
            }
        }

        function youtubeLinkSubmit(e) {
            e.preventDefault()
            const formData = new FormData(e.target)
            instance
                .post(`/api/admin/video/upload/youtube`, formData)
                .then(function (res) {
                    if (res.status = 200) {
                        history.push(`/admin/video/edit/${res.data.file_id}`)
                    }
                    if (res.status == 210)
                        toast.warn(t('Bir sorun meydana geldi, lütfen tekrar deneyin'))
                })
                .catch(function (res) {
                    toast.error(t('Bağlantı hatası, lütfen tekrar deneyin'))
                })
        }

        function vimeoLinkSubmit(e) {
            e.preventDefault()
            const formData = new FormData(e.target)
            instance
                .post(`/api/admin/video/upload/vimeo`, formData)
                .then(function (res) {
                    if (res.status = 200) {
                        history.push(`/admin/video/edit/${res.data.file_id}`)
                    }
                    if (res.status == 210)
                        toast.warn(t('Bir sorun meydana geldi, lütfen tekrar deneyin'))
                })
                .catch(function (res) {
                    toast.error(t('Bağlantı hatası, lütfen tekrar deneyin'))
                })
        }

        function YotubeLinkView() {
            return <>
                <form onSubmit={youtubeLinkSubmit} n>
                    <TextField
                        variant={"outlined"}
                        fullWidth
                        label={t('Youtube linki...')}
                        name={"link"}
                    />
                    <br/>
                    <br/>
                    <br/>
                    <Button style={{fontSize: 24}} type={'submit'} variant={"outlined"} color={"primary"}>{t('KAYDET')}</Button>
                </form>
            </>
        }

        function VimeoLinkView() {
            return <>
                <form onSubmit={vimeoLinkSubmit} n>
                    <TextField
                        variant={"outlined"}
                        fullWidth
                        label={t('Vimeo linki...')}
                        name={"link"}
                    />
                    <br/>
                    <br/>
                    <br/>
                    <Button style={{fontSize: 24}} type={'submit'} variant={"outlined"} color={"primary"}>{t('KAYDET')}</Button>
                </form>
            </>
        }

        function videoTypeSelect(event, data) {
            setVideoType(data.value)
        }

        const videoTypeList = [
            {key: 'server', value: 'server', text: t('Sunucuya Kaydet'), image: <Icon className={"server"}></Icon>},
            {key: 'youtube', value: 'youtube', text: t('Youtube bağlantısı ekle'), image: <Icon className={"youtube"}></Icon>},
            {key: 'vimeo', value: 'vimeo', text: t('Vimeo bağlantısı ekle'), image: <Icon className={"vimeo v"}></Icon>}
        ]

        function VideoUploadContentView() {
            switch (videoType) {
                case 'server':
                    return <>

                        <h3>{t('Videoyu kendi sunucuma yükle')}</h3>
                        <Progress/>
                        <CloudUploadIcon onClick={() => selectFile()} style={{
                            fontSize: 120,
                            cursor: "pointer"
                        }}/>
                        <div style={{clear: "both"}}></div>
                        <span style={{
                            fontSize: 14,
                            cursor: "pointer"
                        }} onClick={() => selectFile()}>{t('Yüklemek istediğiniz videoyu seçin')}</span>
                        <br/>
                        <LoadView/>
                    </>
                    break;
                case 'youtube':
                    return <YotubeLinkView/>

                    break
                case 'vimeo':
                    return <VimeoLinkView/>
                    break
            }

        }

        return <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Dropdown
                        placeholder={t('Yayınlama seçenekleri')}
                        fluid
                        selection
                        defaultValue={videoType}
                        options={videoTypeList}
                        onChange={videoTypeSelect}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    {id && id!=0&& <><Alert variant="filled" severity="error">Eğer yeni bir video dosyası yüklerseniz, eski video dosyalarınız tamamen silinir. Bu işlem geri alınamaz!!!</Alert></>}
                    <Paper className={`paper-handler ${classes.videoDiv}`}>

                        <VideoUploadContentView/>
                    </Paper>
                </Grid>
            </Grid>
            <input id="file" style={{display: "none"}} type='file'/>
        </>
    }

    return <Context/>
}

export default Upload;
