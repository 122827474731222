import React, {useEffect, useContext, useState} from 'react';
import {BreadsContext, PageContext, PageTitleContext} from "../../../stores/PageStore";
import {TextField, Paper, Grid, Button, CircularProgress} from "@material-ui/core";
import instance from "../../../apis";
import {toast} from "react-toastify";

const ContactConfig = () => {
    const [, setBreads] = useContext(BreadsContext);
    const [pageTitle, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    const [config, setConfig] = useState({});
    const [load, setLoad] = useState(false);
    useEffect(() => {
        const breads = [
            {
                link: null,
                title: 'İletişim Ayarları',
                last: true
            }
        ]
        setBreads(breads)
        setPageTitle('İletişim Ayarları')
        setPage(27.1)
    }, [])
    useEffect(() => {
        setLoad(true)
        instance
            .get(`/api/admin/contact/config`)
            .then(function (result) {
                setLoad(false)
                if (result.status == 200) {
                    if (result.data.item) {
                        setConfig(result.data.item)
                    }
                } else {
                    toast.warn('Veriler çekilirken bir hata meydana geldi, lütfen yeniden deneyin.')
                }
            })
            .catch(function () {
                toast.error('Bağlantı hatası, lütfen yeniden deneyin.')
            })
    }, [])

    const fields = [
        {name: 'tel_1', default: config.tel_1, type: 'number', label: 'Telefon 1',},
        {name: 'tel_2', default: config.tel_2, type: 'number', label: 'Telefon 2', helper: null},
        {name: 'tel_3', default: config.tel_3, type: 'number', label: 'Telefon 3', helper: null},
        {name: 'email', default: config.email, type: 'text', label: 'e-mail Adresi', helper: null},
    ]

    function TextFieldViews() {
        return fields.map(item => <Grid item xs={12} sm={12}><TextField
            variant={"outlined"}
            label={item.label}
            name={item.name}
            fullWidth
            defaultValue={item.default}
            placeholder={item.placeholder}
            helperText={item.helper}
            size={"small"}
            type={item.type}
        /></Grid>)
    }

    const formSubmit = (e) => {
        setLoad(true)
        e.preventDefault()
        const formData = new FormData(e.target)
        instance
            .post(`/api/admin/contact/config`, formData)
            .then(function (res) {
                if (res.status == 200) {
                    setConfig(res.data.item);
                    toast.success('Değişiklikler kaydedildi.')
                } else {
                    toast.error('Veriler kaydedilemedi, lütfen tekrar deneyin')
                }
                setLoad(false)
            })
            .catch(function (res) {
                toast.error('Veriler gönderilemedi, lütfen tekrar deneyin')
                setLoad(false)

            })
    }


    if (load)
        return <CircularProgress/>
    return <Paper className="paper-handler">
        <form method="post" onSubmit={formSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}><h2>{pageTitle}</h2></Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container alignItems={"center"} alignContent={"center"} spacing={1}>
                    </Grid>
                </Grid>
                <TextFieldViews/>
                <Grid item xs={12} sm={12}>
                    <Button type="submit" color={'primary'} fullWidth variant={"contained"}>DEĞİŞİKLİKLERİ KAYDET</Button></Grid>
            </Grid>
        </form>
    </Paper>

}

export default ContactConfig;
