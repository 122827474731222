import React, {useContext, useEffect, useState} from 'react';
import instance from '../apis';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useParams} from 'react-router-dom';
import {BreadsContext, PageContext, PageTitleContext} from "../stores/PageStore";
import Item from "./categories/Item";
import {breadLink} from "../functions/trCase";

const AllCategories = () => {
    const [load, setLoad] = useState(true);

    let {id} = useParams();
    let {pTitle} = useParams();
    const [categories, setCategories] = useState([]);
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    useEffect(() => {
        setLoad(true);
        if (!id) {
            id = 'all';
        }
        instance.get('/api/categories/' + id)
            .then(function (res) {
                setCategories(res.data);
                setLoad(false)
            })
        let breads = [];
        if (id && id != 'all') {
            breads = [{
                link: '/categories/all',
                title: 'Tüm Dersler'
            }, {
                link: null,
                title: breadLink(pTitle),
                last: true

            }];
        } else {
            breads = [{
                link: null,
                title: 'Tüm Dersler',
                last: true
            }];
        }
        setPageTitle('Tüm Dersler')
        setBreads(breads)
        setPage(8)
    }, [id]);


    const Category = () => {
        return categories.map(item => <Item sub={item.parent_id ? true : false} link={'categories'} item={item}/>)
    };
    if (load) {
        return <CircularProgress/>
    }
    return <Grid container spacing={2}>
        <Category/>
    </Grid>
};

export default AllCategories;
