import React from 'react';
import {Switch, Route} from 'react-router-dom';
import Add from "./Add";
import KazanimRoutes from "./kazanims/KazanimRoutes";
import AdminSonuclar from "./AdminSonuclar";
import Bitirmeyenler from "./Bitirmeyenler";

function ESinavRoutes() {
    return (
        <Switch>
            <Route path={'/admin/esinav/kazanims'} component={KazanimRoutes}/>
            <Route path={'/admin/esinav/add/:sinav_id'} component={Add}/>
            <Route path={'/admin/esinav/add'} component={Add}/>
            <Route path={'/admin/esinav/bitirmeyenler/:sinav_id'} component={Bitirmeyenler}/>
            <Route path={'/admin/esinav/sonuclar/:sinav_id'} component={AdminSonuclar}/>

        </Switch>
    )
}

export default ESinavRoutes;
