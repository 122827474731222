import { createStore, applyMiddleware, compose } from "redux";
import {createLogger, logger} from 'redux-logger';
import thunk from 'redux-thunk';

import reducers from "./index";

const middleware = []
middleware.push(thunk);
middleware.push(logger);

const store = createStore(
    reducers, 
    // applyMiddleware(thunk)
    {},
    compose(applyMiddleware(...middleware)),
);

export default store;