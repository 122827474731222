import React, {createContext, useState} from "react";

export const KreditCardContext = createContext([])

export const KreditCardStore = props => {
    const [state, setState] = useState({})
    return (
        <KreditCardContext.Provider value={[state, setState]}>
            {props.children}
        </KreditCardContext.Provider>
    )
}
