import React, {useEffect, useState} from 'react';

import {Box, ButtonGroup, Divider,} from '@material-ui/core';
import instance from '../../../../apis';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment'
import {CircularProgress, Button, List, Grid, Paper} from '@material-ui/core';
import colors from '../../../../styles/colors';
import {CopyToClipboard} from 'react-copy-to-clipboard';


const useStyles = makeStyles(theme => ({
    p: {},
}));


const UrlComponent = (props) => {
    const classes = useStyles();
    const [copy, setCopy] = useState(false)
    const siteName = window.location.host;

    return <>
        <Grid container style={{marginTop: 10}}>
            <Grid item direction="row">
                <Grid style={{marginBottom: 10}}>
                    <p style={{fontSize: 14}}>Canlı Sınıf URL: <b>{siteName}/#/join/{props.url}</b></p>
                </Grid>
                <Grid>
                    <CopyToClipboard text={`${siteName}/#/join/${props.url}`}
                                     onCopy={() => setCopy(true)}>
                        <Button
                            variant="contained"
                            style={{backgroundColor: colors.grey0, color: colors.white, fontSize: 14}}
                            className="success"
                        >URL'yi Kopyala</Button>
                    </CopyToClipboard>

                </Grid>

            </Grid>
        </Grid>

    </>

}

export default UrlComponent;
