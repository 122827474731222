import React, {useContext} from "react";
import instance from "../apis";
import {toast} from "react-toastify";

export default function addBasket(item, canli) {
    const checkLogin = localStorage.getItem('login')
    if (checkLogin == "false") {
        return global.alert('Lütfen giriş yapınız.');
    }
    instance.post(`/api/add/basket/${item.id}/${canli ? 1 : -1}`)
        .then(function (res) {
            if (res.status) {
                //  toast.success('Sepete Eklendi')
                return item;
            } else {
                toast.warn('Bir hata oluştu')
                return {}
            }
        })

}
