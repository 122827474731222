import React, {useContext} from 'react';
import CardMedia from "@material-ui/core/CardMedia";
import categoryDefaultImages from "../images/cihanhoca.jpg"
import {ConfigContext, LogoContext} from "../Store";

function CategoryCardMedia({image, site_id}) {
    const [logo] = useContext(LogoContext);
    const [cofig] = useContext(ConfigContext);

    function src() {
        if (!image || image == undefined || image == '') {
            if (cofig.site_id === 1)
                return categoryDefaultImages
            return "data:image/png;base64," + logo
        }

        return `https://lms.rotasinav.com/cover/img/${site_id}/${image}`
    }

    function MediaView() {
        if (!image || image == undefined || image == '') {
            return <CardMedia
                style={{
                    minHeight: 65,
                    textAlign: "center",
                    width: "100%"
                }}
                image={src()}
            />
        }
        return <CardMedia
            style={{
                height: 0,
                paddingTop: '56.25%'
            }}
            image={src()}
        />
    }

    return <MediaView/>;

}

export default CategoryCardMedia;
