import React from "react";

export  default function coutDownViwer(sn) {
    const gun    = Math.trunc(sn / (3600 * 24));
    const saat   = Math.trunc((sn % (3600 * 24)) / 3600);
    const dk     = Math.trunc(((sn) % 3600) / 60);
    const second = Math.trunc((sn % 60))
    const dkView = dk < 10 ? `0${dk}.` : `${dk}.`;
    const snView = second < 10 ? `0${second}` : second;
    const sa     = saat ? `${saat}.` : '00.';
    const saView = sa < 10 && sa != 0 && sa != '' ? `0${sa}` : sa;
    const View   = () => {
        function Tarih() {
            return <span style={{fontSize: 32}}>{`${saView}${dkView}${snView}`}</span>
        }
        if (gun > 0) {
            return <>
                Kalan Süre
                <div style={{
                    fontSize: 16,
                    color   : "red"
                }}>{gun} Gün
                </div>
                <Tarih/>
            </>
        } else {
            return <Tarih/>
        }
    }
    return View;
}