import React, {useContext, useEffect, useState} from 'react';
import {Divider, Grid, lighten, LinearProgress, Paper, withStyles} from "@material-ui/core";
import {BreadsContext, PageContext, PageTitleContext} from "../../stores/PageStore";
import instance from "../../apis";
import _ from 'lodash'
import {makeStyles} from "@material-ui/core/styles";
//import BarChartView from './BarChartView';
const styles = makeStyles({
    root: {
        position: 'relative',
    },
    top: {
        color: '#eef3fd',
    },
    progressRed: {
        color: '#6798e5',
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
    },
});
const RedProgressBar = withStyles({
    root: {
        backgroundColor: lighten('#fe346e', 0.5),
    },
    bar: {
        borderRadius: 20,
        backgroundColor: '#fe346e',
    },
    analiz_satir: {
        margin: 20
    }
})(LinearProgress);
const GreenProgressBar = withStyles({
    root: {
        backgroundColor: lighten('#21bf73', 0.5),
    },
    bar: {
        borderRadius: 20,
        backgroundColor: '#21bf73',
    },
})(LinearProgress);

function basariOrani(net, dogru, yanlis, bos) {
    const basari = Number(100 * (net / (dogru + yanlis + bos))).toFixed(0);
    return basari < 0 ? 0 : basari;
}

const Karne = () => {
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    const [, setLoad] = useState(true)
    const [sinavAnalizleri, setSinavAnalizleri] = useState([])
    const [analizKonu, setAnalizKonu] = useState([])
    const [toplam, setToplam] = useState({})
    const [reload,] = useState(false)
    const classes = styles();

    useEffect(() => {
        setLoad(true)
        instance
            .get(`/api/sinav/user/karne`)
            .then(function (result) {
                if (result.status == 200) {
                    const analizKonu = result.data.analiz_konu;
                    const arrayKonu = _.values(analizKonu);
                    arrayKonu.map(item => {
                        console.log(item)
                        item['oran'] = basariOrani(item.net, item.dogru, item.yanlis, item.bos)
                    })
                    arrayKonu.sort(function (a, b) {
                        return b.oran - a.oran
                    });
                    setAnalizKonu(arrayKonu)
                    setToplam({
                        net: result.data.net,
                        dogru: result.data.dogru,
                        yanlis: result.data.yanlis,
                        bos: result.data.bos,
                    })
                    setSinavAnalizleri(result.data.sinav_analizleri)
                }
                setLoad(false)
            })
    }, [reload])
    useEffect(() => {
        const breads = [
            {
                link: null,
                title: 'Karnem',
                last: null
            },

        ];
        setBreads(breads)
        setPage(17.1)
        setPageTitle('Karne')
    }, [])


    const data = [
        {
            name: 'Page A', uv: 4000, female: 2400, male: 2400,
        },
        {
            name: 'Page B', uv: 3000, female: 1398, male: 2210,
        },
        {
            name: 'Page C', uv: 2000, female: 9800, male: 2290,
        },
        {
            name: 'Page D', uv: 2780, female: 3908, male: 2000,
        },
        {
            name: 'Page E', uv: 1890, female: 4800, male: 2181,
        },
        {
            name: 'Page F', uv: 2390, female: 3800, male: 2500,
        },
        {
            name: 'Page G', uv: 3490, female: 4300, male: 2100,
        },
    ];


    const ProgressView = ({basari}) => {
        if (basari < 40) {
            return <RedProgressBar
                variant="determinate"
                value={basari}/>
        } else if (basari > 80) {
            return <GreenProgressBar
                variant="determinate"
                value={basari}/>
        } else {
            return <LinearProgress
                variant="determinate"
                value={basari}/>
        }

    }
    return <>
        <Grid style={{fontSize: 11}} container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Paper style={{paddingLeft: 10, paddingRight: 10,}} className={"paper-handler"}>
                    <Grid className="" container className="gridCenter hiddenHeader" alignContent={"center"} alignItems={"center"}>
                        <Grid xs={2} sm={2} item><b>ADI</b></Grid>
                        <Grid xs={2} sm={2} item><b>B/G SIRA</b></Grid>
                        <Grid xs={1} sm={1} item><b>TOP</b></Grid>
                        <Grid xs={1} sm={1} item><b>NET</b></Grid>
                        <Grid xs={1} sm={1} item><b>DOĞ</b></Grid>
                        <Grid xs={1} sm={1} item><b>YAN</b></Grid>
                        <Grid xs={1} sm={1} item><b>BOŞ</b></Grid>
                        <Grid xs={3} sm={3} item><b>BAŞARI ORANI</b></Grid>
                        <Grid xs={12} sm={12} item><Divider/></Grid>
                    </Grid>
                    <Grid container className="gridCenter" alignContent={"center"} alignItems={"center"}>
                        <Grid xs={12} sm={12} item>
                            {sinavAnalizleri.map((item, index) => <>
                                <Grid key={item.id} style={index % 2 == 0 && index != 0 ? {backgroundColor: "#ccc"} : {backgroundColor: '#fff'}}
                                      container spacing={1} alignItems={"center"} alignContent={"center"}>
                                    <Grid xs={4} sm={2} item style={{textAlign: "left"}}>
                                        <div className="mobileHeader"><b>ADI</b></div>
                                        {item.name}
                                    </Grid>
                                    <Grid xs={5} sm={2} item>
                                        <div className="mobileHeader"><b>BIRANŞ/GENEL SIRA</b></div>
                                        {`${item.brans_sira}/${item.genel_sira}`}</Grid>
                                    <Grid xs={3} sm={1} item>
                                        <div className="mobileHeader"><b>TOPLAM</b></div>
                                        {item.dogru_cevap_sayisi + item.yanlis_sayisi + item.bos_sayisi}</Grid>
                                    <Grid item xs={12} sm={12} className="mobileHeader"><Divider/></Grid>
                                    <Grid xs={3} sm={1} item>
                                        <div className="mobileHeader"><b>NET</b></div>
                                        {item.net_sayisi}</Grid>
                                    <Grid xs={3} sm={1} item>
                                        <div className="mobileHeader"><b>DOĞRU</b></div>
                                        {item.dogru_cevap_sayisi}</Grid>
                                    <Grid xs={3} sm={1} item>
                                        <div className="mobileHeader"><b>YANLIŞ</b></div>
                                        {item.yanlis_sayisi}</Grid>
                                    <Grid xs={3} sm={1} item>
                                        <div className="mobileHeader"><b>BOŞ</b></div>
                                        {item.bos_sayisi}</Grid>
                                    <Grid item xs={12} sm={12} className="mobileHeader"><Divider/></Grid>
                                    <Grid xs={12} sm={3} item>
                                        <Grid container alignContent={"center"} alignItems={"center"}>
                                            <Grid item xs={12} className="mobileHeader" sm={12}><b>BAŞARI ORANI</b></Grid>
                                            <Grid item xs={4} sm={4}>
                                                %{basariOrani(item.net_sayisi, item.dogru_cevap_sayisi, item.yanlis_sayisi, item.bos_sayisi)}
                                            </Grid>
                                            <Grid xs={8} sm={8} item>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={basariOrani(item.net_sayisi, item.dogru_cevap_sayisi, item.yanlis_sayisi, item.bos_sayisi)}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} sm={12} item><Divider/></Grid>
                                </Grid>
                            </>)}
                        </Grid>
                    </Grid>
                    <Grid container className="gridCenter" alignContent={"center"} alignItems={"center"}>
                        <Grid xs={4} sm={4} style={{textAlign: "right"}} item>TOPLAM</Grid>
                        <Grid xs={1} sm={1} item>{toplam.top}</Grid>
                        <Grid xs={1} sm={1} item>{toplam.net}</Grid>
                        <Grid xs={1} sm={1} item>{toplam.dogru}</Grid>
                        <Grid xs={1} sm={1} item>{toplam.yanlis}</Grid>
                        <Grid xs={1} sm={1} item>{toplam.bos}</Grid>
                        <Grid xs={12} sm={3} item>
                            <Grid container alignContent={"center"} alignItems={"center"}>
                                <Grid item xs={12} className="mobileHeader" sm={12}><b>GENEL BAŞARI ORANI</b></Grid>
                                <Grid item xs={4} sm={4}>
                                    %{basariOrani(toplam.net, toplam.dogru, toplam.yanlis, toplam.bos)}
                                </Grid>
                                <Grid xs={8} sm={8} item>
                                    <LinearProgress
                                        color={"secondary"}
                                        variant="determinate"
                                        value={basariOrani(toplam.net, toplam.dogru, toplam.yanlis, toplam.bos)}/>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid xs={12} sm={12} item><Divider/></Grid>

                </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Paper style={{paddingLeft: 10, paddingRight: 10,}} className={"paper-handler"}>
                    <Grid container className="gridCenter" alignContent={"center"} alignItems={"center"}>
                        <Grid xs={1} sm={1} item></Grid>
                        <Grid xs={2} sm={3} style={{textAlign: "left"}} item><b>ADI</b></Grid>
                        <Grid xs={2} sm={2} item><b>TOP</b></Grid>
                        <Grid xs={1} sm={1} item><b>NET</b></Grid>
                        <Grid xs={1} sm={1} item><b>DOĞ</b></Grid>
                        <Grid xs={1} sm={1} item><b>YAN</b></Grid>
                        <Grid xs={1} sm={1} item><b>BOŞ</b></Grid>
                        <Grid xs={4} sm={2} item><b>BAŞARI ORANI</b></Grid>
                        <Grid xs={12} sm={12} item><Divider/></Grid>
                    </Grid>
                    <Grid container container className="gridCenter" alignContent={"center"} alignItems={"center"}>
                        <Grid item xs={12} sm={12}>
                            {analizKonu.map((item, index) => <>
                                <Grid className={classes.analiz_satir} key={item.id} style={(index + 1) % 2 == 0 && index != 0 ? {backgroundColor: "#ffe8df"} : {backgroundColor: '#fff'}}
                                      container alignItems={"center"} alignContent={"center"}>
                                    <Grid xs={1} sm={1} item style={{textAlign: "left"}}>{index + 1}</Grid>
                                    <Grid xs={5} sm={3} item style={{textAlign: "left"}}>
                                        <div className="mobileHeader"><b>ADI</b></div>
                                        {item.name}
                                    </Grid>
                                    <Grid xs={6} sm={2} item>
                                        <div className="mobileHeader">TOPLAM</div>
                                        {item.dogru + item.yanlis + item.bos}
                                    </Grid>
                                    <Grid item xs={12} sm={12} className="mobileHeader"><Divider/></Grid>
                                    <Grid xs={3} sm={1} item>
                                        <div className="mobileHeader"><b>NET</b></div>
                                        {item.net}</Grid>
                                    <Grid xs={3} sm={1} item>
                                        <div className="mobileHeader"><b>DOĞRU</b></div>
                                        {item.dogru}</Grid>
                                    <Grid xs={3} sm={1} item>
                                        <div className="mobileHeader"><b>YANLIŞ</b></div>

                                        {item.yanlis}</Grid>
                                    <Grid xs={3} sm={1} item>
                                        <div className="mobileHeader"><b>BOŞ</b></div>

                                        {item.bos}</Grid>
                                    <Grid item xs={12} sm={12} className="mobileHeader"><Divider/></Grid>
                                    <Grid xs={12} sm={2} item>
                                        <Grid container alignContent={"center"} alignItems={"center"}>
                                            <Grid item xs={12} className="mobileHeader" sm={12}><b>BAŞARI ORANI</b></Grid>
                                            <Grid item xs={4} sm={4}>
                                                %{item.oran}
                                            </Grid>
                                            <Grid xs={8} sm={8} item>
                                                <ProgressView basari={item.oran}/>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid xs={12} sm={12} item>
                                    <div className="mobileDividerDiv">
                                        <Divider style={{backgroundColor: "darkblue"}}/>
                                    </div>
                                    <div className="dividerDiv">
                                        <Divider className="mobileDivider"/>
                                    </div>
                                </Grid>
                            </>)}
                        </Grid>
                    </Grid>
                </Paper>

            </Grid>
            {/*   <Grid item xs={12} sm={12}>
                <BarChartView data={data} />
            </Grid>*/}
        </Grid>

    </>
}

export default Karne;
