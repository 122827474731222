import React from 'react';
import {Grid, Paper} from '@material-ui/core';
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

function ShopierOrderSuccess() {
    return (
        <Paper className="paper-handler">
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} style={{textAlign: "center"}}>
                    <CheckCircleOutlineIcon style={{color: '#559900', fontSize: 65}}/>
                </Grid>
                <Grid item xs={12} sm={12} style={{textAlign: "center", fontSize: 24}}>
                    Ödemeniz başarıyla gerçekleşti, kısa bir süre sonra aldğınız ürünü kullanmaya başlayabilirsiniz. İlginize teşekkür ederiz.
                </Grid>
            </Grid>
        </Paper>
    );
};

export default ShopierOrderSuccess;
