import React, { useContext, useEffect, useRef } from "react";
import { BBBContext } from '../../../../stores/BBBCreate';

import ArrowRight from '@material-ui/icons/ArrowRight';
import {  List, ListItem, ListItemText, Grid,  ListItemIcon} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
}));



const MaterialList = props => {
  const classes = useStyles();
  const selectedUserList = useRef(null)
  const bbbContext = useContext(BBBContext);

  const { materialUploadedFiles, } = bbbContext;
  useEffect(() => {
    // getUserList();
  }, [])




  return (
        <Grid container>

            <Grid item xs={12} sm={12} justify="center">
              <h3 >Materyal Listesi ({materialUploadedFiles.length})</h3>

              <List className={classes.root} subheader={<li />}>

              {materialUploadedFiles.map((file, index) =>
                <ListItem key={"file-"+index} button onClick={() => {
                //   var newSelectedUser = selectedUser;
                //   newSelectedUser.push(user)
                //   newSelectedUser = _.orderBy(newSelectedUser, ['name'],['asc']);
                //   setSelectedUser(newSelectedUser)
                //   // setSelectedUser(prevArray => [...prevArray, user])
                //   var index = _.findIndex(users, {id : user.id});
                //   users.splice(index, 1);
                //   setUsers(users);
                }}>
                  <ListItemText primary={file.aciklama}  />
                  <ListItemIcon>
                  <ArrowRight />
                  </ListItemIcon>
                </ListItem>
              )}
              </List>
            </Grid>
      </Grid>


  );
};

export default MaterialList;
