import React, {useEffect, useState, useContext} from 'react';
import instance from '../../apis';
import {useParams} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {toast} from 'react-toastify';
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles, Paper, Tooltip} from "@material-ui/core";
import Moment from 'react-moment';
import Divider from "@material-ui/core/Divider";
import {BreadsContext, PageContext, PageTitleContext} from "../../stores/PageStore";
import DeleteIcon from '@material-ui/icons/Delete';


const useStyles = makeStyles(theme => ({
    paper: {
        backgroundColor: "rgb(241,241,241,.3)",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: "100%",
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        padding: 20
    },
    rowActive: {
        backgroundColor: "#fff"
    },
    rowCancel: {
        backgroundColor: "#cbcbcb"
    },
    rowRefresh: {
        backgroundColor: "#f1f1f1"
    }
}));
const UserCard = () => {
    const [categories, setCategories] = useState([])
    const [userCategories, setUserCategories] = useState([])
    const [user, setUser] = useState({
        categories: [],
        sepets: []
    })
    const [load, setLoad] = useState(true)
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const {id} = useParams()
    const classes = useStyles();
    const [, setPage] = useContext(PageContext);
    const [siniflar, setSiniflar] = useState([]);


    useEffect(() => {
        instance.get(`/api/admin/get/user/card/${id}`)
            .then(function (res) {
                setUser(res.data.user)
                setSiniflar(res.data.siniflar)
                setUserCategories(res.data.user.categories)
                instance.get('/api/admin/category/0')
                    .then(function (res) {
                        handleCategories(res.data.items)
                        setLoad(false)
                    })
            })
        const breads = [
            {
                link: null,
                title: 'Kullanıcı kartı',
                last: true
            }
        ]
        setBreads(breads)
        setPageTitle(user.name)
        setPage(1.1)
    }, [])

    function userSubmitForm(e) {
        const target = e.target
        e.preventDefault()
        const formData = new FormData(target)
        instance.post(`/api/admin/get/user/card/${id}`, formData)
            .then(function (res) {
                toast.success('Kullanıcı bilgileri Güncelendi')
            })
    }

    function categorySubmitForm(e) {
        const target = e.target
        e.preventDefault()
        const formData = new FormData(target)
        instance.post(`/api/admin/get/user/card/add/category/${id}`, formData)
            .then(function (res) {
                toast.success('Kullanıcı bilgileri Güncelendi')
                setUserCategories(res.data)
            })
    }

    function handleCategories(datas) {

        const data = [];
        datas.map(item => {
            data.push(item)
            item.childs.map(child => {
                child['title'] = '--- ' + child['title']
                data.push(child)
            })
        })
        if (data) {
            return setCategories(data)
        }

    }

    const SelectCategories = () => {
        const [categoryIds, setCategoryIds] = useState([])
        if (!Array.isArray(categories)) {
            return <></>
        }
        if (categories.length < 1) {
            return <></>
        }
        const categoryChange = event => {
            setCategoryIds(event.target.value);
        };
        return <Grid item xs={12} sm={12}>
            <form onSubmit={categorySubmitForm}>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <InputLabel id="demo-mutiple-checkbox-label">Kategori Seç</InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl size={"small"} fullWidth={true} style={{textAlign: "left"}}>
                            <Select
                                labelId="demo-mutiple-checkbox-label"
                                id="demo-mutiple-checkbox"
                                multiple
                                value={categoryIds}
                                input={<Input/>}
                                renderValue={selected => selected.join(', ')}
                                name="category_ids"
                                onChange={categoryChange}
                            >
                                {categories.map(name => (
                                    <MenuItem key={name.id} value={name.id}>
                                        <Checkbox checked={categoryIds.indexOf(name.id) > -1}/>
                                        <ListItemText primary={name.title}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Button type="submit" variant={"contained"} color={"secondary"} fullWidth={true}>KULLANICI HESABINA ÜRÜN EKLE</Button>
                    </Grid>
                </Grid>
            </form>
        </Grid>
    }

    function MomentView(date) {
        return <Moment format="DD.MM.YYYY">
            {date}
        </Moment>
    }

    const RowItemClasses = (status) => {
        switch (status) {
            case 1:
                return classes.rowActive
                break
            case 2:
                return classes.rowCancel
                break
            case 3:
                return classes.rowRefresh
                break
        }
    }
    const RowItemStatus = (status) => {
        switch (status) {
            case 1:
                return 'Aktif'
                break
            case 2:
                return 'İptal'
                break
            case 3:
                return 'Yenileme'
                break
        }
    }

    function userCategoryDelete(id, user_id) {
        if (global.confirm('Emin misiniz?'))
            instance
                .delete(`/api/admin/user/category/${user_id}/${id}`)
                .then(function () {
                    setUserCategories(userCategories.filter(x => x.id != id))
                })
    }

    const UserCategories = () => {
        if (!user.categories) {
            return <></>
        }

        function kilitle(e, item_id) {
            const checked = e.target.checked
            console.log(checked)
        }

        return userCategories.map((item, index) => <>
            <Grid className={RowItemClasses(item.status)} alignItems={"center"} key={item.id} container>
                <Grid item xs={12} sm={1}> {index + 1}</Grid>
                <Grid item xs={12} sm={5}> {item.category ? item.category.title : ''}</Grid>
                <Grid item xs={12} sm={1}> {RowItemStatus(item.status)}</Grid>
                <Grid item xs={6} sm={2}> {MomentView(item.lisans_baslangic)}</Grid>
                <Grid item xs={6} sm={2}> {MomentView(item.lisans_bitis)}</Grid>
                <Grid item xs={6} sm={1}> <Tooltip title={'Kilitle'}><Checkbox color={"secondary"} onChange={(e) => kilitle(e, item.id)}/></Tooltip></Grid>
                <Grid item xs={6} sm={1}>
                    <IconButton size={"small"}>
                        <DeleteIcon style={{
                            color: "red",
                            cursor: "pointer"
                        }} onClick={() => userCategoryDelete(item.id, user.id)}/>
                    </IconButton>
                </Grid>
            </Grid>
            <Grid cs={12} sm={12}><Divider/></Grid>
        </>)
    }
    const RowSepets = () => {
        if (!user.sepets) {
            return <></>
        }
        if (user.sepets.length == 0) {
            return <></>
        }

        function sepetOnayla(sepet_id) {
            instance.get(`/api/admin/lisans/onayla/${sepet_id}`)
                .then(function (res) {
                    setUser(res.data)
                })
        }

        function sepetIptal(sepet_id) {
            instance.get(`/api/admin/lisans/iptal/${sepet_id}`)
                .then(function (res) {
                    setUser(res.data)
                })
        }

        const SepetDurum = (item) => {
            if (item.active == null) {
                return <Grid item xs={12} sm={2}><Button onClick={() => sepetOnayla(item.id)} size={"small"} variant={"outlined"} color={"secondary"}>Onayla</Button></Grid>
            }
            return <Grid item xs={12} onClick={() => sepetIptal(item.id)} sm={2}><Button size={"small"} variant={"outlined"} style={{backgroundColor: "lightslategrey"}}>İptal Et</Button></Grid>

        }
        return user.sepets.map(item => <Paper className="paper-handler" key={item.id}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>ID:{item.id}</Grid>
                    <Grid item xs={12} sm={5}>
                        {item.products.map(product =>
                            <Grid xs={12} sm={12} item>{product.qty} Adet {product.name}</Grid>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={1}>Net: {item.unit_price}</Grid>
                    <Grid item xs={12} sm={1}>KDV:{item.kdv}</Grid>
                    <Grid item xs={12} sm={1}>Toplam: {item.dis_price}</Grid>
                    {SepetDurum(item)}
                    <Grid item xs={12} sm={12}><Divider/></Grid>
                </Grid>
            </Paper>
        )
    }
    if (load)
        return <CircularProgress/>
    return <>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <Paper className="paper-handler">
                    <form onSubmit={userSubmitForm}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    size={"small"}
                                    variant={"outlined"}
                                    required
                                    defaultValue={user.name}
                                    name="name"
                                    label="Kullanıcı ismi"
                                    fullWidth={true}
                                    autoComplete="name"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    size={"small"}
                                    variant={"outlined"}
                                    required
                                    defaultValue={user.email}
                                    name="email"
                                    label="Email"
                                    fullWidth={true}
                                    autoComplete="email"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    size={"small"}
                                    variant={"outlined"}
                                    required
                                    defaultValue={user.tel}
                                    name="tel"
                                    label="Telefon"
                                    fullWidth={true}
                                    autoComplete="tel"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    select
                                    name="sinif_id"
                                    label="Varsa Sınıfınızı Seçiniz"
                                    id="sinif_id"
                                    defaultValue={user.classroom_user ? user.classroom_user.classroom_id : -1}
                                >
                                    <MenuItem value={-1}>Bir sınıfa dahil değilim</MenuItem>
                                    {siniflar.map(item => <MenuItem value={item.id}>{item.ad}</MenuItem>)}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    size={"small"}
                                    variant={"outlined"}
                                    name="password"
                                    label="Kullanıcı Şifresi"
                                    fullWidth={true}
                                    autoComplete="password"
                                    helperText={'Kullanıcı şifresini değiştirmek isterseniz bu alanı kullanın'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Button type="submit" variant={"contained"} color={"secondary"} fullWidth={true}>ÜYELİK BİLGİLERİNİ KAYDET</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={8}>
                <Paper className="paper-handler">
                    <Grid container spacing={2} alignContent={"center"} alignItems={"center"}>
                        <SelectCategories/>
                        <Grid cs={12} sm={12}><Divider/></Grid>
                        <Grid cs={1} sm={1}></Grid>
                        <Grid item xs={11} sm={5}> <strong>İsim</strong></Grid>
                        <Grid item xs={12} sm={1}> <strong>Durum</strong></Grid>
                        <Grid item xs={6} sm={2}><strong>Başlangıç</strong></Grid>
                        <Grid item xs={6} sm={2}><strong>Bitiş</strong></Grid>
                        <UserCategories/>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Paper className="paper-handler">
                    <h4>SİPARİŞLER</h4>
                    <RowSepets/>
                </Paper>
            </Grid>
        </Grid>
    </>


}

export default UserCard;
