import React, {useEffect, useState} from 'react';
import {Grid, Paper, Button, CircularProgress, } from '@material-ui/core';
import { connect } from 'react-redux'
import instance from '../../../../apis';
import {setSelectedSearch, selectedSearchCancel} from '../../../../redux/classroomAdd'
import styled from 'styled-components';



const TextField = styled.input`
  height: 34px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:'#ddd';
`;


function SelectedUserFilter (props) {


  return  <>
    <Grid container direction="row">
      <TextField id="search" type="text" placeholder="Arama" value={props.search} onChange={e => props.setSelectedSearch(e.target.value)} />
      <p>{JSON.stringify(props.users)}</p>
      <ClearButton type="button" onClick={() => {props.selectedSearchCancel() }} style={{backgroundColor:'#ddd'}}>X</ClearButton>
    </Grid>
</>
}

const mapStateToProps = (state) => {
  return {
    search: state.classroomAdd.selected_search,
  };
};

const mapDispatchToProps = {
  setSelectedSearch,
  selectedSearchCancel
}
  

export default connect(mapStateToProps, mapDispatchToProps)(SelectedUserFilter);
