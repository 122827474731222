import React, {useEffect, useContext, useState} from 'react';
import {BreadsContext, PageContext, PageTitleContext} from "../../stores/PageStore";
import {TextField, Paper, Grid, Button, CircularProgress, Switch, FormLabel, RadioGroup, FormControlLabel, Radio, FormControl,} from "@material-ui/core";
import instance from "../../apis";
import {toast} from "react-toastify";
import CropImageView from "../CropImageView";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import {ConfigContext} from "../../Store";

const Config = () => {
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    const [config, setConfig] = useContext(ConfigContext);
    const [logo, setLogo] = useState()
    const [load, setLoad] = useState(false)

    useEffect(() => {
        setLoad(true)
        instance
            .get(`/api/admin/genel/ayar`)
            .then(function (result) {
                setLoad(false)
                if (result.status == 200) {
                    setConfig(result.data.item)

                    setLogo(result.data.logo)
                } else {
                    toast.warn('Veriler çekilirken bir hata meydana geldi, lütfen yeniden deneyin.')
                }
            })
            .catch(function () {
                toast.error('Bağlantı hatası, lütfen yeniden deneyin.')
            })
    }, [])
    useEffect(() => {
        const breads = [
            {
                link: null,
                title: 'Site Ayaları',
                last: true
            }
        ]
        setBreads(breads)
        setPageTitle('Genel Site Ayarları')
        setPage(6)
    }, [])
    const fields = [
        {name: 'title', default: config.title, label: 'Sayfa Başlığı (title)', placeholder: 'Sekmede görünen başlıktır', helper: 'En fazla 69 karakter olmalıdır.',},
        {name: 'keywords', default: config.keywords, label: 'Anahtar Kelimeler (keywords)', placeholder: 'Örn: uzaktan eğitim, lms, canlı sınıf,uzem', helper: 'Her kelimenin arasında virgül olmalı, en fazla 250 karakter'},
        {name: 'description', default: config.description, label: 'Kısa açıklama (description), genel bilgi', placeholder: 'Örn: Yeni nesil uzaktan eğitim sistemi...', helper: 'En fazla 169 karakter olmalıdır.'},
        {name: 'slogan', default: config.slogan, label: 'Slogan', placeholder: 'Örn:Birlikte Başaracağız', helper: 'En fazla 250 karakter girebilirsiniz. Panel sayfanızda ilk girişte yayınlanır.'},
        {
            name: 'meta',
            default: config.meta,
            label: 'Meta Etiketi',
            placeholder: 'Örn: google-site-verification/*7FG9xDbXOrfVsBdfxoCu6PleDCxVenXKz5jXAQUgzxc',
            helper: `name:content şeklinde aralarında "/*" olmalıdır, birden fazla meta etiketi eklemek için virgülle "," ayırın`
        },

        {name: 'analytics', default: config.analytics, label: 'Google Analytics', placeholder: 'Örn:UA-11757673-8', helper: 'Yalnızca örnekte gösterildiği gibi izleme kimliğini giriniz.'},
        {name: 'metrika', default: config.metrika, label: 'Yandex Metrika', placeholder: 'Önr: 44147844', helper: 'Yandex metrika tag number'},
        {name: 'facebook', default: config.facebook, label: 'Facebook Adresi', helper: null},
        {name: 'twitter', default: config.twitter, label: 'Twitter Adresi', helper: null},
        {name: 'youtube', default: config.youtube, label: 'Youtube Adresi', helper: null},
        {name: 'instagram', default: config.instagram, label: 'Instagram Adresi', helper: null},
        {name: 'linkedin', default: config.linkedin, label: 'LinkedIn Adresi', helper: null},
        {name: 'telegram', default: config.telegram, label: 'Telegram Adresi', helper: null},
    ]

    function TextFieldViews() {
        return fields.map(item => <Grid item xs={12} sm={12}><TextField
            variant={"outlined"}
            label={item.label}
            name={item.name}
            fullWidth
            defaultValue={item.default}
            placeholder={item.placeholder}
            helperText={item.helper}
            size={"small"}
        /></Grid>)
    }

    const formSubmit = (e) => {
        setLoad(true)
        e.preventDefault()
        const formData = new FormData(e.target)
        instance
            .post(`/api/admin/genel/ayar`, formData)
            .then(function (res) {
                if (res.status == 200) {
                    toast.success('Değişiklikler kaydedildi.')
                    window.location.reload()
                } else {
                    toast.error('Veriler kaydedilemedi, lütfen tekrar deneyin')
                }
            })
            .catch(function (res) {
                toast.error('Veriler gönderilemedi, lütfen tekrar deneyin')
            })
    }

    const fileSelect = () => {
        const file = document.getElementById("file");
        file.click()
    }
    const LogoSelect = () => {
        return <>
            <Button
                variant={"contained"}
                onClick={() => fileSelect()}
                color={"secondary"}
            >
                LOGO SEÇ
            </Button>
            <CropImageView width={180} aspect={3.75}/>

        </>
    }

    function imgDelete() {
        if (global.confirm('Silmek istediğinizden emin misiniz?'))
            instance
                .delete(`/api/admin/logo`)
                .then(function (res) {
                        setLogo(null)
                    }
                )
    }

    const Logo = () => {
        if (logo) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                <>
                    <Grid item xs={12} sm={2}>
                        <LogoSelect/>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button
                            variant={"contained"}
                            onClick={() => imgDelete()}
                            color={"secondary"}
                        > <DeleteForeverIcon/></Button>
                    </Grid>
                </>
            );
            return <>
                <Grid item xs={12} sm={2}>
                    <LogoSelect/>
                </Grid>
                <Grid item xs={10} sm={2}>
                    <img style={{width: "100%"}} src={`data:image/png;base64, ${logo}`}/>
                </Grid>
                <Grid item xs={2} sm={1}>
                    <Button
                        size={"small"}
                        variant={"contained"}
                        onClick={() => imgDelete()}
                        color={"secondary"}
                    >
                        <DeleteForeverIcon/>
                    </Button>
                </Grid>

            </>
        } else {
            return <>
                <Grid container alignItems="flex-start" item xs={12} sm={2}>
                    <LogoSelect/>
                </Grid>
            </>
        }
    }
    if (load)
        return <CircularProgress/>

    function OnlineUsersView() {
        const [value, setValue] = useState(config.online_users)
        return <Grid item xs={12} sm={12}>
            <FormControl component="fieldset">
                <FormLabel component="legend" style={{fontWeight: 'bold', color: '#000', marginBottom: 10}}>Çevrimiçi Kullanıcıları</FormLabel>
                <RadioGroup aria-label="gender" name="online_users" value={value} onChange={e => setValue(parseInt(e.target.value))}>
                    <FormControlLabel value={1} control={<Radio/>} label="Tüm kullanıcılar görebilir"/>
                    <FormControlLabel value={2} control={<Radio/>} label="Sadece yöneticiler görebilir"/>
                    <FormControlLabel value={3} control={<Radio/>} label="Yalnızca ben görebilirim"/>
                </RadioGroup>
            </FormControl>
        </Grid>
    }

    return <Paper className="paper-handler">
        <form method="post" onSubmit={formSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}><h2>Genel Ayarlar</h2></Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container alignItems={"center"} alignContent={"center"} spacing={1}>
                        <Logo/>
                    </Grid>
                </Grid>
                <TextFieldViews/>
                <OnlineUsersView/>
                <Grid item xs={12} sm={12}>
                    <Button type="submit" color={'primary'} fullWidth variant={"contained"}>DEĞİŞİKLİKLERİ KAYDET</Button></Grid>
            </Grid>
        </form>
    </Paper>

}

export default Config;
