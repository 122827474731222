import React, {useContext, useEffect, useState} from 'react';
import {Button, CircularProgress, Grid, Paper, TextField} from '@material-ui/core';
import {Link, useParams} from 'react-router-dom';
import {BreadsContext, PageTitleContext} from "../../../../stores/PageStore";
import instance from "../../../../apis";
import Cevaplar from "./Cevaplar";
import CevapAnahtariYatay from "../CevapAnahtariYatay";
import KonuSec from "./KonuSec";
import KazanimSec from "./KazanimSec";
import {toast} from 'react-toastify';


function Bagla() {
    const {sinav_id}                            = useParams()
    const [, setPageTitle]                      = useContext(PageTitleContext);
    const [, setBreads]                         = useContext(BreadsContext);
    const [sinav, setSinav]                     = useState({});
    const [load, setLoad]                       = useState(false);
    const [loadKonu, setLoadKonu]               = useState(false);
    const [loadKazanim, setLoadKazanim]         = useState(false);
    const [reLoad, setReLoad]                   = useState(false);
    const [soru, setSoru]                       = useState({no: 0});
    const [konular, setKonular]                 = useState([]);
    const [kazanimlar, setKazanimlar]           = useState([]);
    const [newKazanimlar, newSetKazanimlar]     = useState([]);
    const [cevaplar, setCevaplar]               = useState([])
    const [selectedKonu, setSelectedKonu]       = useState([]);
    const [selectedKazanim, setSelectedKazanim] = useState([]);
    const [circleText, setCircleText]           = useState(null);
    const [notSave, setNotSave]                 = useState(null);
    useEffect(() => {
        setLoad(true)
        const seciliKonular    = []
        const seciliKazanimlar = []
        setCircleText('Sorular ve cevaplar yükleniyor')

        instance
            .get(`/api/admin/sinav/${sinav_id}`)
            .then(function (result) {
                const data = result.data;
                setSinav(data)
                const cevaps = [];
                data.sorular.map(item => {
                    cevaps.push({
                                    id   : item.id,
                                    cevap: item.cevap ? item.cevap.val : null
                                })
                    seciliKonular[item.id]    = item.konular.map(konu => konu.konu_id)
                    seciliKazanimlar[item.id] = item.kazanimlar.map(kazanim => kazanim.kazanim_id)
                })
                setCircleText('Sorular yüklendi')
                setCevaplar(cevaps)
                setCircleText('Seçili konu ve kazanımlar yükleniyor')

                instance
                    .get(`/api/admin/kazanimlarkonular`)
                    .then(function (result) {
                        const kon = result.data.konular;
                        const kaz = result.data.kazanimlar;
                        setKonular(kon)
                        setKazanimlar(kaz)
                        setLoadKonu(false)
                        seciliKonular.map((seciliKonu, index) => {
                            selectedKonu[index] = kon.filter(x => seciliKonu.includes(x.id))
                        })
                        seciliKonular.map((seciliKonu, index) => {
                            selectedKazanim[index] = kaz.filter(x => seciliKonu.includes(x.id))
                        })
                        setSelectedKonu(selectedKonu)
                        setSelectedKazanim(selectedKazanim)
                        setLoad(false)
                        setCircleText('Seçili konu ve kazanımlar yüklendi')

                    })
            })

        setPageTitle(`Sınava Konu  ve Kazanım Seç`)
        const breads = [
            {
                link : `/admin/esinav/add`,
                title: 'Sınavlar',
            }, {
                link : null,
                title: ' Konu ve Kazanım Seç',
                last : true
            }
        ]
        setBreads(breads)
    }, [])


    useEffect(() => {
        try {
            if (selectedKonu[soru.id].length > 0) {
                let konu_ids = []
                selectedKonu[soru.id].map(selectKonu => {
                    konu_ids.push(selectKonu.id)
                })
                const newKazanimlar = kazanimlar.filter(x => konu_ids.includes(x.konu_id) ? x : null)
                newSetKazanimlar(newKazanimlar)
                setNotSave('...')
            }

        } catch (e) {

        }


    }, [selectedKonu, soru])


    const Questtions = () => {
        if (!sinav.sorular) return <></>
        const Question = () => {
            return sinav.sorular.map(item => <Grid key={item.id} item xs={12} sm={1}>
                <Button onClick={() => setSoru(item)} color={soru.id == item.id ? "primary" : 'default'}
                        style={{
                            fontSize: 11,
                            padding : 2,
                            minWidth: 50
                        }}
                        variant={soru.id == item.id ? "contained" : "outlined"} size={"small"}>
                    {item.no}
                </Button>
            </Grid>)
        }
        return <Grid container spacing={1}>
            <Question/>
        </Grid>
    }

    function cevapAnahtariCevapSec(cevap, soru) {
        setSoru(soru)
        cevaplar.filter(x => x.id == soru.id)[0].cevap = cevap
        setCevaplar(cevaplar)
        setReLoad(true)
        setNotSave('...')

    }

    function dogruCevap(cevap) {
        cevaplar.filter(x => x.id == soru.id)[0].cevap = cevap
        setCevaplar(cevaplar)
        setReLoad(true)
        setNotSave('...')
    }

    function cevap() {
        if (!soru.id) return null
        return cevaplar.filter(x => x.id == soru.id)[0].cevap
    }

    function reLoadFalse() {
        setReLoad(false)
    }

    function konuChange(e, v) {
        setSelectedKonu({
                            ...selectedKonu,
                            [soru.id]: v
                        })
    }

    function kazanimChange(e, v) {
        setSelectedKazanim({
                               ...selectedKazanim,
                               [soru.id]: v
                           })
    }

    function formElement(items) {
        let formItems = [];
        Object.keys(items)
            .map(function (key, index) {
                formItems.push({[key]: items[key]});
            });

        return JSON.stringify(formItems)
    }

    useEffect(() => {
        if (notSave != null) {
            setTimeout(() => {
                save()
            }, 10000)
        }
    }, [notSave])

    function save() {
        const formData = new FormData()
        formData.append('konular', formElement(selectedKonu))
        formData.append('kazanimlar', formElement(selectedKazanim))
        formData.append('cevaplar', JSON.stringify(cevaplar))
        formData.append('sinav_id', soru.sinav_id)
        instance
            .post('/api/admin/sinav/set/all/setting', formData)
            .then(function (result) {
                toast.success('Değişiklikler kaydedildi')
                setNotSave(null)
            })
    }

    if (load) return <Grid container alignItems={"center"}>
        <Grid item xs={2} sm={1}><CircularProgress/></Grid> <Grid item xs={10} sm={11}>{circleText}</Grid></Grid>
    return <Paper className="paper-handler">
        <Grid container>
            <Grid item xs={12} sm={4}>
                <h3>{sinav.name}</h3>
            </Grid>
            <Grid item xs={12} style={{textAlign: "right"}} sm={8}>
                <Link to={`/admin/esinav/kazanims/add/${sinav_id}`}> <Button variant={"outlined"} size={"small"} color={"secondary"}>YENİ KONU VE KAZANIM EKLE</Button></Link>
                <Button onClick={() => save()} variant={"outlined"} size={"small"} color={"primary"}>KAYDET {notSave}</Button>
            </Grid>
        </Grid>
        <Grid container spacing={1} alignItems={"flex-start"} justify={"space-between"}>
            <Grid item xs={4} sm={8}>
                <Grid container spacing={1}>
                    <Questtions/>
                </Grid>
            </Grid>
            <Grid item xs={8} sm={4}>
                <Grid container spacing={1} alignItems={"center"}>
                    <Grid item xs={12} sm={12}>
                        <Cevaplar soru={soru} cevap={cevap()} dogruCevap={dogruCevap} cevaplar={cevaplar}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <KonuSec handleChange={konuChange} loadKonu={loadKonu} soru={soru} konular={konular} selectedKonu={selectedKonu}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <KazanimSec handleChange={kazanimChange} loadKazanim={loadKazanim} soru={soru} kazanimlar={newKazanimlar} selectedKazanim={selectedKazanim}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
                <CevapAnahtariYatay reLoad={reLoad} reLoadFalse={reLoadFalse} cevap={cevap()} cevapAnahtariCevapSec={cevapAnahtariCevapSec} cevaplar={cevaplar} sorular={sinav.sorular}/>
            </Grid>
        </Grid>

    </Paper>


}

export default Bagla;
