import React, {useContext, useEffect} from 'react';
import {Grid, Paper, Divider} from '@material-ui/core';
import {BreadsContext, PageContext, PageTitleContext} from "../../../../stores/PageStore";

import {BBBProvider} from '../../../../stores/BBBCreate';
import MaterialList from './MaterialList';
import MaterialSendButton from './MaterialSendButton';
import MaterialUpload from './MaterialUpload';
import DokumanList from './DokumanList';
import DokumanSendButton from './DokumanSendButton';
import DokumanUpload from './DokumanUpload';
import {useParams} from 'react-router-dom';
import {Button} from "semantic-ui-react";

const Index = (props) => {

    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    const {transferKey} = useParams()
    useEffect(() => {
        const breads = [
            {
                link: '#',
                title: 'Canlı Sınıf Ekle',
                last: false
            },
            {
                link: '/admin/bbb/create',
                title: 'Materyal Ekle',
                last: true
            },
        ];
        setBreads(breads)
        setPage(20.1)
        setPageTitle('Materyal Ekle')

    }, [])
    const roomJoin = (transfer_key) => {
        window.open('https://lms.rotasinav.com/admin/room/join/' + transferKey, "_blank") || window.location.replace('https://lms.rotasinav.com/admin/room/join/' + transferKey);
    }
    return (
        <>
            <BBBProvider {...props}>

                <Paper className="paper" style={{marginLeft: 0}}>
                    <Grid container spacing={1} direction="row">
                        <Grid item xs={12} sm={10}>
                            <h2>Canlı Ders İçin Materyali Ekleme</h2>
                            <ul>
                                <li><p>Canlı dersinizde kullanacınız sunum dosyaları <b>(yüklediğiniz dosyalar otomatik olarak canlı dersinize yüklenecektir.)</b></p></li>
                                <li><p> Öğrenciler indiremeyecek ve canlı tahtanın dışında göremeyecektir)</p></li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button color="orange" onClick={() => roomJoin()}>HEMEN BAŞLA</Button>
                        </Grid>
                    </Grid>
                    <Grid container direction='row'>
                        <Grid item xs={12} sm={12}>
                            <MaterialUpload {...props}/>
                        </Grid>
                        <Divider/>
                        <Grid item xs={12} sm={12} style={{marginTop: 30}}>
                            <MaterialList/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <MaterialSendButton {...props}/>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper className="paper" style={{marginLeft: 0}}>
                    <Grid container spacing={1} direction="row">
                        <Grid item xs={12} sm={12}>
                            <h2>Canlı Ders'e Ait Öğrenciler İçin Döküman Ekleme</h2>
                            <ul>
                                <li><p>Yapılan dersle ilgili öğrencilerle paylaşmak istediğiniz dosyaları yükleyebilirsiniz</p></li>
                                <li><p>Öğrencilerin ekranlarında indirme linkleri görülecektir</p></li>
                            </ul>
                        </Grid>
                    </Grid>
                    <Grid container direction='row'>
                        <Grid item xs={12} sm={12}>
                            <DokumanUpload {...props}/>
                        </Grid>
                        <Divider/>
                        <Grid item xs={12} sm={12} style={{marginTop: 30}}>
                            <DokumanList/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <DokumanSendButton {...props}/>
                        </Grid>
                    </Grid>
                </Paper>
            </BBBProvider>
        </>
    );
}


export default Index;
