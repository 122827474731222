import React, {useContext, useEffect, useState} from 'react';
import {BreadsContext, PageTitleContext} from "../../../../stores/PageStore";
import instance from "../../../../apis";
import {Grid, Paper, Input, Button, CircularProgress} from '@material-ui/core';
import KonuRows from "./KonuRows";
import {useParams, Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import Divider from "@material-ui/core/Divider";
import KazanimRows from "./KazanimRows";

function Add() {
    const {sinav_id}                    = useParams()
    const {konu_id}                     = useParams()
    const {kazanim_id}                  = useParams()
    const [, setPageTitle]              = useContext(PageTitleContext);
    const [, setBreads]                 = useContext(BreadsContext);
    const [konular, setKonular]         = useState([]);
    const [kazanimlar, setKazanimlar]   = useState([]);
    const [konu, setKonu]               = useState({id: 0}); // düzenleme için kullanılır
    const [seciliKonu, setSeciliKonu]   = useState({id: 0}); // kazanım ekleme için kullanılır
    const [kazanim, setKazanim]         = useState({id: 0});
    const [loadKazanim, setLoadKazanim] = useState(false);
    const [loadKonu, setLoadKonu]       = useState(false);

    useEffect(() => {
        setPageTitle(`Konu ve Kazanım Ekle`)
        const breads = [
            {
                link : `/admin/esinav/add`,
                title: 'Sınavlar',
            },
            {
                link : `/admin/esinav/kazanims/bagla/${sinav_id}`,
                title: 'Konu ve Kazanım Seç',
            }, {
                link : null,
                title: 'Yeni Konu ve Kazanım Ekle',
                last : true
            }
        ]
        setBreads(breads)
    }, [])
    useEffect(() => {
        setLoadKonu(true)
        instance
            .get(`/api/admin/konular`)
            .then(function (result) {
                setKonular(result.data)
                setLoadKonu(false)
            })
    }, [])
    useEffect(() => {
        if (konu_id && konular.length > 0 && !kazanim_id) {
            setKonu(konular.filter(x => x.id == konu_id)[0])
        }

    }, [konu_id])
    useEffect(() => {
        if (kazanim_id != 0 && kazanim_id && kazanimlar.length > 0) {
            setKazanim(kazanimlar.filter(x => x.id == kazanim_id)[0])
        }
    }, [kazanim_id])

    function konuSec(konu_id, getKonular = null) {
        setLoadKazanim(true)
        setKonu({id: 0})
        instance
            .get(`/api/admin/kazanimlar/${konu_id}`)
            .then(function (result) {
                setKazanimlar(result.data)
                setLoadKazanim(false)
            })
        if (getKonular) {
            setSeciliKonu(getKonular.filter(x => x.id == konu_id)[0])

        } else {
            setSeciliKonu(konular.filter(x => x.id == konu_id)[0])

        }
    }

    const KonuFormView    = () => {
        if (konu.id == 0) {
            return <form onSubmit={submitForm}>
                <Grid container alignItems={"center"} spacing={1}>
                    <Grid item xs={12} sm={8}>
                        <textarea name="name" rows={5} placeholder="Birden fazla eklemek için alt alta yazın" style={{width: "100%"}}></textarea>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Button fullWidth variant={"contained"} type="submit" color={"primary"} size={"small"}>Kaydet</Button>
                    </Grid>
                </Grid>
            </form>
        }
        return <form onSubmit={submitForm}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={8}>
                    <Input name="name" fullWidth defaultValue={konu.name}/>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button fullWidth variant={"contained"} type="submit" color={"primary"} size={"small"}>Kaydet</Button>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Link onClick={() => setKonu({id: 0})} to={`/admin/esinav/kazanims/add/${sinav_id}`}><Button fullWidth variant={"contained"} color={"secondary"} size={"small"}>YENİ</Button></Link>
                </Grid>
            </Grid>
        </form>
    }
    const KazanimFormView = () => {
        if (seciliKonu.id == 0) return <><span className="text-danger">Lütfen kazanım eklemek istediğiniz konuyu seçin</span></>
        const FormHeader = () => {
            return <>
                <Grid xs={12} sm={12} item>{seciliKonu.name} Kazanımları</Grid>
                <input type="hidden" value={seciliKonu.id} name="konu_id"/>
            </>
        }
        if (kazanim.id == 0) {
            return <form onSubmit={submitFormKazanim}>
                <Grid container alignItems={"center"} spacing={1}>
                    <FormHeader/>
                    <Grid item xs={12} sm={8}>
                        <textarea name="name" rows={5} placeholder="Birden fazla eklemek için alt alta yazın" style={{width: "100%"}}></textarea>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Button fullWidth variant={"contained"} type="submit" color={"primary"} size={"small"}>Kaydet</Button>
                    </Grid>
                </Grid>
            </form>
        }
        return <form onSubmit={submitFormKazanim}>
            <Grid container spacing={1}>
                <FormHeader/>
                <Grid item xs={12} sm={8}>
                    <Input name="name" fullWidth defaultValue={kazanim.name}/>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button fullWidth variant={"contained"} type="submit" color={"primary"} size={"small"}>Kaydet</Button>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Link onClick={() => setKazanim({id: 0})} to={`/admin/esinav/kazanims/add/${sinav_id}/${seciliKonu.id}/0`}><Button fullWidth variant={"contained"} color={"secondary"} size={"small"}>YENİ</Button></Link>
                </Grid>
            </Grid>
        </form>
    }

    function submitForm(e) {
        e.preventDefault()
        const formData = new FormData(e.target)
        instance
            .post(`/api/admin/konular/${konu.id}`, formData)
            .then(function (result) {
                setKonular(result.data)
                if (konu.id) {
                    setKonu(result.data.filter(x => x.id == konu.id)[0])
                    toast.success('Konu Güncellendi')
                } else {
                    toast.success('Konu/Konular Eklendi')
                }
            })
    }

    function submitFormKazanim(e) {
        e.preventDefault()
        const formData = new FormData(e.target)
        instance
            .post(`/api/admin/kazanimlar/${kazanim.id}`, formData)
            .then(function (result) {
                setKazanimlar(result.data)
                if (kazanim.id) {
                    setKazanim(result.data.filter(x => x.id == kazanim.id)[0])
                    toast.success('Kazanım Güncellendi')
                } else {
                    toast.success('Kazanım/Kazanımlar Eklendi')
                }
            })
    }

    function deleteRow(id) {
        if (global.confirm('Silmek istediğinizden emin misiniz?'))
            instance
                .delete(`/api/admin/konular/${id}`)
                .then(function () {
                    setKonular(konular.filter(x => x.id != id))
                    toast.warn('Konu Silindi')

                })
    }

    function deleteRowKazanim(id) {
        if (global.confirm('Silmek istediğinizden emin misiniz?'))
            instance
                .delete(`/api/admin/kazanimlar/${id}`)
                .then(function () {
                    setKazanimlar(kazanimlar.filter(x => x.id != id))
                    toast.warn('Kazanım Silindi')

                })
    }

    const KazanimlarView = () => {
        if (loadKazanim) return <CircularProgress/>
        return <>
            <KazanimFormView/>
            <br/>
            <Divider/>
            <KazanimRows deleteRow={deleteRowKazanim} sinav_id={sinav_id} datas={kazanimlar}/>
        </>
    }
    const KonularView    = () => {
        if (loadKonu) return <CircularProgress/>
        return <>
            <KonuFormView/>
            <br/>
            <Divider/>
            <KonuRows konuSec={konuSec} deleteRow={deleteRow} sinav_id={sinav_id} konular={konular}/>
        </>
    }
    return (<Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
            <Paper className="paper">
                <KonularView/>
            </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
            <Paper className="paper">
                <KazanimlarView/>
            </Paper>
        </Grid>
    </Grid>);
}

export default Add;