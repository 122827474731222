import React, {useState, createContext} from 'react';

export const EditorContext = createContext([]);
export const EditorStore = props => {
    const [editorBody, setEditorBody] = useState({})
    return (
        <EditorContext.Provider value={[editorBody, setEditorBody]}>
            {props.children}
        </EditorContext.Provider>
    );
}
