import React from 'react';
//import {render} from 'react-snapshot';
//import ReactDOM from 'react-dom';
//import {hydrate, render} from "react-dom";
import './index.css';
import './theme.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-toastify/dist/ReactToastify.css';
import {Store} from './Store';
import {Basket} from "./stores/Basket";
import {render} from 'react-snapshot';
import store from "./redux/store";
import { Provider } from "react-redux";
render(
    <Provider store={store}>
        <Store>
            <Basket>
                <App/>
            </Basket>
        </Store>
    </Provider>
    , document.getElementById('root'));
/*ReactDOM.hydrate(
    <Store>
        <Basket>
            <App/>
        </Basket>
    </Store>
    , document.getElementById('root'));*/

/*const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(<Store>
        <Basket>
            <App/>
        </Basket>
    </Store>, rootElement);
} else {
    render(<Store>
        <Basket>
            <App/>
        </Basket>
    </Store>, rootElement);
}*/
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
