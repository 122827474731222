import React, {useState, createContext} from 'react';

export const BreadsContext    = createContext([]);
export const PageTitleContext = createContext([]);
export const PageContext      = createContext([]);

export const PageStore = props => {
    const [breads, setBreads]       = useState([])
    const [pageTitle, setPageTitle] = useState(null)
    const [page, setPage]           = useState(null)

    return (
        <BreadsContext.Provider value={[breads, setBreads]}>
            <PageTitleContext.Provider value={[pageTitle, setPageTitle]}>
                <PageContext.Provider value={[page, setPage]}>
                    {props.children}
                </PageContext.Provider>
            </PageTitleContext.Provider>
        </BreadsContext.Provider>
    );
}
