import React, {useContext, useEffect, useState} from 'react';
import {CircularProgress, Grid, Paper} from "@material-ui/core";
import addBasket from "../../functions/addBasket";
import {Button} from "semantic-ui-react";
import categoryPrice from "../../functions/categoryPrice";
import {BasketContext} from "../../stores/Basket";
import {useParams} from "react-router-dom"
import instance from "../../apis";
import {toast} from "react-toastify";
import Item from "../categories/Item";
import {BreadsContext, PageContext, PageTitleContext} from "../../stores/PageStore";

const ProductPage = () => {
    const {id} = useParams()
    const [item, setItem] = useState({product_page: {}})
    const [, setBreads] = useContext(BreadsContext);
    const [pageTitle, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    const [basket, setBasket] = useContext(BasketContext);
    const [load, setLoad] = useState(true);
    useEffect(() => {
        setLoad(true)
        instance
            .get(`/api/category/${id}`)
            .then(function (res) {
                setLoad(false)
                if (res.status === 200)
                    return setItem(res.data.item)
                toast.warn('Bilgiler çekilemedi')

            })
            .catch(function (res) {
                toast.error('Bir hata meydana geldi')
                setLoad(false)
            })
    }, [id])

    useEffect(() => {
        if (item.id) {
            const breads = [
                {
                    link: null,
                    title: item.title,
                    last: true
                }
            ]
            if (item.parent) {
                setPageTitle(`${item.parent.title} - ${item.title}`)
            } else {
                setPageTitle(item.title)
            }
            setBreads(breads)
            setPage(600)
        }

    }, [item])
    if (load)
        return <CircularProgress/>

    const page = item.product_page

    if (!item.id)
        return <CircularProgress/>

    function CategoryBBBView({item}) {
        const [states, setStates] = useState({selectBBB: item.bbb === 1 ? true : false});

        function addBasketItem(item) {
            const getItem = addBasket(item, states.selectBBB)
            basket.products.push(getItem);
            setBasket(basket => ({...basket, basket}));
        }

        function AddBasketButtonView() {
            return <Button fluid color={"orange"} onClick={() => addBasketItem(item)} size={"massive"}>SEPETE EKLE</Button>

        }

        function SelectedBBBView() {
            if (states.selectBBB) {
                return <>
                    <Button color={"red"} active>Canlı</Button>
                    <Button.Or text={"-"}/>
                    <Button
                        onClick={() => setStates(states => ({...states, selectBBB: false}))}
                    >Kayıttan</Button>
                </>
            }
            return <>
                <Button
                    onClick={() => setStates(states => ({...states, selectBBB: true}))}
                >Canlı</Button>
                <Button.Or text={"-"}/>
                <Button color={"grey"} active>Kayıttan</Button>
            </>
        }

        function PriceView() {
            return <Grid item xs={12} sm={4} style={{fontSize: 60, color: "#1fab89", textAlign: "center"}}>
                {categoryPrice(item, states.selectBBB)}
            </Grid>
        }

        if (item.bbb == 1)
            return <Grid container alignContent={"center"} alignItems={"center"}>
                <Grid item xs={12} sm={5} style={{textAlign: "center"}}>
                    <Button.Group size='massive' fluid compact>
                        <SelectedBBBView/>
                    </Button.Group>
                </Grid>
                <PriceView/>
                <Grid item xs={12} sm={3}>
                    <AddBasketButtonView/>
                </Grid>
            </Grid>

        return <Grid container alignContent={"center"} alignItems={"center"}>
            <Grid item xs={12} sm={5} style={{textAlign: "center"}}>
                <Button size="massive" color="grey" disabled>Kayıttan</Button>
            </Grid>
            <PriceView/>
            <Grid item xs={12} sm={3}>
                <AddBasketButtonView/>
            </Grid>
        </Grid>
    }

    function ItemsView() {
        if (item.childs.length > 0)
            return <>
                <h3>{pageTitle} kategorisine ait dersler</h3>
                <Grid container spacing={2}>{item.childs.map(child => <Item item={child}/>)}</Grid></>
        return <></>
    }

    return <>
        <Paper className="paper-handler">
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}><h3>{pageTitle} Dersleri</h3></Grid>
                <Grid item xs={12} sm={12}>
                    <img style={{width: "100%"}} src={`https://lms.rotasinav.com/img/view/path/${page.site_id}?path=${page.image}`}/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <div dangerouslySetInnerHTML={{__html: page.body}}></div>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <CategoryBBBView item={item}/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <ItemsView/>
                </Grid>
            </Grid>
        </Paper>
    </>


}

export default ProductPage;
