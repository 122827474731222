import React from 'react';
import {Switch, Route} from "react-router-dom";
import {File} from "../stores/File";
import MyCategories from "./MyCategories";
import Basket from "./Basket";
import Profile from "./Profile";
import {Step} from "./CheckOut/StepStore";
import SinavRoutes from "./esinav/SinavRoutes";
import BBBRoutes from "./bbb/BBBRoutes";
import Billings from "./admin/billings/Billings";

const Home = (props) => {
    let checkLogin = 'false'
    try {
        checkLogin = localStorage.getItem('login')
    } catch (e) {

    }
    const history = props.history;
    if (checkLogin !== "true") {
        history.push('/login')
    }
    return (
        <Switch>
            <File>
                <Route path={'/my/billings'} component={Billings}/>
                <Route path={'/my/categories'} component={MyCategories}/>
                <Route path={'/my/category/:pTitle/:id'} component={MyCategories}/>
                <Step>
                    <Route path={'/my/basket'} component={Basket}/>
                </Step>
                <Route path={'/my/profile'} component={Profile}/>
                <Route path={'/sinavlar/all'} component={SinavRoutes}/>
                <Route path={'/sinav'} component={SinavRoutes}/>
                <Route path={'/canli'} component={BBBRoutes}/>

            </File>
        </Switch>
    );
}

export default Home;
