import React, {useEffect, useState} from "react";
import instance from "../../apis";
import {ControlBar, CurrentTimeDisplay, ForwardControl, PlaybackRateMenuButton, Player, ReplayControl, TimeDivider, VolumeMenuButton, BigPlayButton, MenuButton} from "video-react";
import HLSSource from "../HLSSource";
import mask from "../../images/mask.png";
import useInterval from '@use-it/interval'

export default function VideoView(props) {
    const {player, video, startTime, id, qualityChange, qty, qualityChangeHandle} = props
    const [getTime, setGettime] = useState(1);
    const [src, setSrc] = useState(`https://lms.rotasinav.com/api/video/${video.site_id}/${video.id}/${video.name}.m3u8`)
    useEffect(() => {
        goCurrentTime()
    }, [getTime])
    useEffect(() => {
        if (qty === '' || qty === null || qty === 1) {
            return setSrc(`https://lms.rotasinav.com/api/video/${video.site_id}/${video.id}/${video.name}.m3u8`)
        }
        setSrc(`https://lms.rotasinav.com/api/video/${video.site_id}/${video.id}/${video.name}_${qty}p.m3u8`)
    }, [qty])
    useEffect(() => {

    }, [])
    useInterval(() => {
        const time = new Date().getTime();
        const sn = Math.round(time / 1000)
        if (sn % 3 == 0) {
            setGettime(sn)
        }
    }, 5000);

    function goCurrentTime() {
        try {
            let sn = player.current.video.video.currentTime
            if (sn == 0) {
                sn = 1
            }
            if (sn != 1) {
                instance.get(`/api/user/view/video/${video.id}`, {
                    params: {
                        sn: sn,
                        dr: player.current.video.video.duration,
                        category_id: id
                    }
                })
            }
        } catch (e) {

        }
    }

    const qtys = JSON.parse(video.qty);
    if (!video.qty.includes(qty)) {
        qualityChangeHandle(1)
    }
    return <div style={{position: "relative"}}>
        <Player style={{zIndex: -1}} ref={player} startTime={startTime} preload={"auto"} autoPlay={true}>
            <HLSSource
                isVideoChild
                // src={"https://104vod-adaptive.akamaized.net/exp=1581632137~acl=%2F381415503%2F%2A~hmac=b6d1afcdefd9265aab95d716b15279dfbe06c2b7e720052bcaf8ed1dcae7c4d1/381415503/sep/video/1599566832,1599566828,1599566824,1599566821,1599566820/master.json?base64_init=1"}
                src={src}
            />

            <BigPlayButton position="center"/>
            <ControlBar>
                <ReplayControl seconds={10} order={1.1}/>
                <ForwardControl seconds={10} order={1.2}/>
                <CurrentTimeDisplay order={4.1}/>
                <TimeDivider order={4.2}/>
                <PlaybackRateMenuButton rates={[0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]} order={7.1}/>
                <VolumeMenuButton/>
                <select order={7.2} style={{zIndex: 1}} tabIndex={0} style={{padding: 0, fontSize: 9, width: 26}} defaultValue={qty} className="video-react-select" onChange={qualityChange}>
                    <option value={1}>OTO</option>
                    {qtys.map(item => <option value={item}>{item}</option>)}
                </select>
            </ControlBar>
        </Player>
        <img width="100%" src={mask} style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            textAling: "center",
            zIndex: 999,
            display: "none"
        }}/>
    </div>;
}
