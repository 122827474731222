import React, {useState, useContext, useEffect} from 'react';

import Grid from '@material-ui/core/Grid';
import instance from '../apis';
import {toast} from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {BreadsContext, PageTitleContext} from "../stores/PageStore";

const Repass = () => {
    const [email, setEmail] = useState('')
    const [, setBreads]     = useContext(BreadsContext);
    const [, setPageTitle]  = useContext(PageTitleContext);
    useEffect(() => {
        setPageTitle('Şifremi Sıfırla')
        const breads = [{
            link : null,
            title: 'Şifremi Sıfırla',
            last : true
        }];
        setBreads(breads)
    }, [])
    const formSubmit = e => {
        e.preventDefault()
        const data = new FormData(e.target)
        instance
            .post('/api/repass', data)
            .then(function (res) {
                if (res.data.err == 1) {
                    toast.error(res.data.msg)
                    setEmail(res.data.email)
                } else {
                    toast.success(res.data.msg)
                }
            })
    }

    return <>
        <Grid container alignContent={"center"}>
            <Grid item style={{backgroundColor: "#fff"}}>
                <form onSubmit={formSubmit}>
                    <TextField
                        autoComplete="email"
                        name="email"
                        variant="outlined"
                        required
                        fullWidth
                        label="e-Posta adresinizi yazınız"
                        autoFocus
                        defaultValue={email}
                        helperText="Şifre yenileme bağlantısı mail adresinize gönderilecektir."
                    />
                    <br/>
                    <br/>
                    <br/>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        size={"large"}
                    >
                        YENİLEME BAĞLANTISI GÖNDER
                    </Button>
                </form>

            </Grid>
        </Grid>
    </>
}


export default Repass;
