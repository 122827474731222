import React, {useEffect, useState, useContext} from 'react';
import {Paper, Chip, Divider, CircularProgress, Grid, Tooltip, IconButton} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import instance from '../../../apis';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import {Link} from 'react-router-dom';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import {BreadsContext, PageContext, PageTitleContext} from "../../../stores/PageStore";
import PagesIcon from '@material-ui/icons/Pages';
import AddCircleIcon from "@material-ui/icons/AddCircle";

const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(5, 2),
            color: theme.palette.text.secondary,
        },
        icon: {
            cursor: "pointer",
            fontSize: 18,
            float: 'right',
            "&:hover": {
                color: "red"
            }
        },
        editFile: {
            backgroundColor: "#e3e7f1"
        },
        row: {},
        cropView: {
            position: "relative",
            height: 200
        },
        context: {
            fontWeight: 300,
            color: "#979797"
        },
        divider: {
            marginBottom: 10

        }
    }))
;


const Categories = (props) => {
    const classes = useStyles();
    const [id, setId] = useState(-1)
    const [loadContex, setLoadContex] = useState(false)
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState({})
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    useEffect(() => {
        setLoadContex(true)
        setId(props.match.params.id)
        instance
            .get('/api/admin/category/0')
            .then(function (res) {
                handleCategories(res.data.items)
                setLoadContex(false)
            })
        const breads = [
            {
                link: null,
                title: 'Kategoriler',
                last: true
            }
        ]
        setBreads(breads)
        setPageTitle('Kategoriler')
        setPage(302)

    }, [])

    function edit(id) {
        setLoadContex(true)
        setId(props.match.params.id)
        instance
            .get(`/api/admin/category/${id}`)
            .then(function (res) {
                setCategory(res.data)
                setId(id)
                setLoadContex(false)
            })
    }

    function handleCategories(datas) {
        try {
            const data = [];
            datas.map(item => {
                data.push(item)
                item.childs.map(child => {
                    child['title'] = '--- ' + child['title']
                    data.push(child)
                })
            })
            return setCategories(data)
        } catch (e) {

        }
    }


    function sil(id) {
        if (global.confirm('Silmek istediğinizden emin misiniz?')) {
            setLoadContex(true)
            instance
                .delete(`/api/admin/category/${id}`)
                .then(function () {
                        const res = categories.filter(x => x.id != id)
                        setLoadContex(false)
                        setCategories(res)
                    }
                )
        }
    }

    const videoCount = (item) => {
        let count = 0;
        count = count + item.videos_count
        if (item.childs) {
            item.childs.map(child => {
                count = count + child.videos_count
            })
        }
        return count
    }
    const usersCount = (item) => {
        let count = 0;
        count = count + item.users_count
        if (item.childs) {
            item.childs.map(child => {
                count = count + child.users_count
            })
        }
        return count
    }
    const CategoryItem = ({item, index}) => {
        function BBBview() {
            return <>{item.bbb ? <span style={{color: "#f85f73"}}>Canlı</span> : 'Kayıttan'}
                {item.bbb_price ? ` - ${item.bbb_price}₺` : item.bbb ? <span style={{color: "#f85f73"}}> Yok </span> : <></>}</>
        }

        function ItemPrice() {
            if (item.dis_price)
                return <>
                    <del>{item.price}</del>
                    <br/>
                    <span style={{color: "#40a798"}}>{item.dis_price}</span>
                </>
            return <>
                {item.price ? `${item.price}₺` : <span style={{color: "#f85f73"}}> Ücretsiz </span>}
            </>

        }

        function ItemYayin() {
            if (item.view == 1)
                return <span>Yayında</span>
            return <span style={{color: "#c3195d"}}>Taslak</span>
        }

        return <Grid
            style={item.parent_id == null || item.parent_id == '' ? {color: "#143a52", fontSize: 14} : {color: "#6e828a", fontSize: 11}}
            key={item.id}
            container
            spacing={2}
            alignContent={"center"}
            alignItems={"center"}>
            <Grid item xs={1} sm={1}>
                {index + 1}
            </Grid>
            <Grid item xs={7} sm={2}>
                {item.title}

            </Grid>
            <Grid item xs={2} sm={1}>
                <Tooltip title={"Video Sayısı"}>
                    <Chip size={"small"} style={{fontSize: 9}} label={videoCount(item)}></Chip>
                </Tooltip>
            </Grid>
            <Grid item xs={2} sm={1}>
                <Tooltip title={"Öğrenci Sayısı"}>
                    <Chip color={"primary"} size={"small"} style={{fontSize: 9}} label={usersCount(item)}></Chip>
                </Tooltip>
            </Grid>
            <Grid item xs={4} sm={2}>
                <ItemPrice/>
            </Grid>

            <Grid item xs={4} sm={2}>
                <BBBview/>
            </Grid>
            <Grid item xs={4} sm={1}>
                <ItemYayin/>
            </Grid>
            <Grid item xs={12} sm={2}>
                <Grid container>
                    <Grid item xs={4} sm={4}>
                        <Link to={`/admin/category/new/${item.id}`}> <IconButton size={"small"}><EditIcon/></IconButton></Link>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <Link to={`/admin/cat/videos/${item.id}/a5d5asd4as`}>
                            <Tooltip title={`Bu kategoriye ait videoları görüntüle`}>
                                <IconButton size={"small"}><VideoLibraryIcon/></IconButton>
                            </Tooltip>
                        </Link>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <IconButton size={"small"} onClick={() => sil(item.id)}><DeleteForeverIcon/></IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid style={{display: "none"}} className={classes.context} item xs={12} sm={12}>
                {item.context}
            </Grid>
            <Grid item xs={12} sm={12}>
                <Divider className={classes.divider}/>
            </Grid>
        </Grid>
    }
    const CategoryList = () => {
        return categories.map((item, index) => {
            return <Grid key={item.id} container alignItems={"center"} alignContent={"center"}>
                <Grid xs={12} sm={12} item style={{
                    fontWeight: 600,
                    color: "#3f4d71"
                }}>
                    <CategoryItem item={item} index={index}/>
                </Grid>
            </Grid>

        })
    }
    const CategoryView = () => {
        return <>
            <Paper className="paper-handler">
                <Grid
                    className="largeHead"
                    style={{fontSize: 14, fontWeight: "bold"}}
                    container
                    alignContent={"center"}
                    spacing={2}
                    alignItems={"center"}>
                    <Grid item xs={1} sm={1}>

                    </Grid>
                    <Grid item xs={2} sm={2}>
                        Başlık
                    </Grid>
                    <Grid item xs={1} sm={1}>Video S.</Grid>
                    <Grid item xs={1} sm={1}>Öğr S.</Grid>
                    <Grid item xs={1} sm={2}>
                        Fiyat
                    </Grid>

                    <Grid item xs={2} sm={2}>
                        Canlı Ders
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        Durum
                    </Grid>
                    <Grid item xs={12} sm={2} style={{textAlign: "center"}}>
                        İşlemler
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Divider className={classes.divider}/>
                    </Grid>
                </Grid>
                <CategoryList/>
            </Paper>
        </>
    }

    if (loadContex) {
        return <CircularProgress/>;
    }
    return <Grid container alignItems={"center"} alignContent={"center"}>
        <Grid item xs={12} sm={12}>
            <Grid container>
                <Grid item xs={12} sm={12} style={{textAlign: "right"}}>
                    <Link to={`/admin/category/new/0`}>
                        <IconButton color={"primary"}><AddCircleIcon style={{fontSize: 32}}/></IconButton>
                    </Link>
                </Grid>
            </Grid>
        </Grid>
        <Grid xs={12} sm={12} item><CategoryView/></Grid>
    </Grid>

}

export default Categories;
