import React from 'react';
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import CastForEducationIcon from "@material-ui/icons/CastForEducation";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import SearchIcon from "@material-ui/icons/Search";
import Label from "@material-ui/icons/Label";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import CategoryIcon from "@material-ui/icons/Category";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CastConnectedIcon from "@material-ui/icons/CastConnected";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from '@material-ui/icons/Settings';
import SaveIcon from '@material-ui/icons/Save';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import PaymentIcon from '@material-ui/icons/Payment';
import TreeViewMenu from "../components/TreeViewMenu";

import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Divider from "@material-ui/core/Divider";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import StyledTreeItem from "../components/StyledTreeItem";

const Navbar = ({menu}) => {
    const {t} = useTranslation();

    const UserNavArr = [
        {
            link: '/my/categories',
            nodeId: 9,
            label: "Derslerim",
            icon: RecentActorsIcon,
            items: []
        },
        {
            link: '/montly/files',
            nodeId: 23,
            label: "Eklenen Dosyalar",
            icon: FileCopyIcon,
            items: []
        },
        {
            link: '/canli/room/list',
            nodeId: 26,
            label: "Canlı Sınıf",
            icon: CastConnectedIcon,
            items: []
        },
        {
            link: '/my/profile',
            nodeId: 10,
            label: "Hesabım",
            icon: AccountBoxIcon,
            items: []
        },
        {
            link: '/logout',
            nodeId: 20,
            label: "Çıkış Yap",
            icon: ExitToAppIcon,
            items: []
        }
    ];
    const AllNavArr = [
        {
            link: null,
            nodeId: 17,
            label: "Sınavlar",
            icon: DynamicFeedIcon,
            items: [
                {
                    link: '/sinavlar/all',
                    nodeId: 17,
                    label: "E-Sınavlar",
                    icon: PictureAsPdfIcon,
                },
                {
                    link: '/sinav/karne',
                    nodeId: 17.1,
                    label: "Karnem",
                    icon: CastForEducationIcon,
                }
            ]
        },
        {
            link: '/categories/all',
            nodeId: 8,
            label: "Tüm Dersler",
            icon: OndemandVideoIcon,
            items: []
        },
    ];
    const SuperAdminArr = [
        {
            link: null,
            nodeId: 50,
            label: "Süper Admin",
            icon: Label,
            items: [
                {
                    link: '/superadmin/user/search',
                    nodeId: 51,
                    label: "Kullanıcı Ara",
                    icon: SearchIcon,
                    items: []
                },
                {
                    link: '/superadmin/sites',
                    nodeId: 52,
                    label: t('Siteler'),
                    icon: SearchIcon,
                    items: []
                },
            ]
        }
    ]
    const SiteAdminArr = [
        {
            link: null,
            nodeId: 0.1,
            label: "Site Yönetimi",
            icon: Label,
            items: [
                {
                    link: '/admin/user/search',
                    nodeId: 1.1,
                    label: "Kullanıcı Ara",
                    icon: SearchIcon,
                    items: []
                },
                {
                    link: null,
                    nodeId: 5.1,
                    label: "Site Ayarları",
                    icon: Label,
                    items: [
                        {
                            link: '/admin/config',
                            nodeId: 6,
                            label: "Genel Ayarlar",
                            icon: SettingsIcon,
                        },
                        {
                            link: '/admin/contact/config',
                            nodeId: 27.1,
                            label: "İletişim Ayarları",
                            icon: ArtTrackIcon,
                        },
                        {
                            link: '/admin/sales/config',
                            nodeId: 28,
                            label: "Satın Alma",
                            icon: PaymentIcon,
                            items: []
                        },
                        {
                            link: '/admin/banks',
                            nodeId: 7,
                            label: "Banka Hesapları",
                            icon: AccountBalanceIcon,
                            items: []
                        },
                        {
                            link: '/admin/kreditcard/config',
                            nodeId: 5.4,
                            label: "Kredi Kartı Ayarları",
                            icon: AccountBalanceIcon,
                            items: []
                        },
                    ]
                },
                {
                    link: null,
                    nodeId: 60,
                    label: "Eğitmenler",
                    icon: Label,
                    items: [
                        {
                            link: '/admin/egitmenler/0',
                            nodeId: 61,
                            label: "Yeni Eğitmen Ekle",
                            icon: ArtTrackIcon,
                        },
                        {
                            link: '/admin/egitmenler',
                            nodeId: 62,
                            label: "Eğitmenler",
                            icon: SettingsIcon,
                        },
                    ]
                },
                {
                    link: null,
                    nodeId: 27,
                    label: "İletişim ve Destek",
                    icon: Label,
                    items: [
                        {
                            link: '/admin/contact/messages',
                            nodeId: 27.2,
                            label: "Mesajlar",
                            icon: QuestionAnswerIcon,
                        },
                        {
                            link: '/admin/contact/form/messages',
                            nodeId: 27.3,
                            label: "Form Mesajları",
                            icon: QuestionAnswerIcon,
                        },
                    ]
                },
            ]
        }

    ];
    const AdminNavArr = [
        {
            link: null,
            nodeId: 30,
            label: "Eğitmen Paneli",
            icon: Label,
            items: [
                {
                    link: null,
                    nodeId: 31.1,
                    label: "Sınıflar",
                    icon: Label,
                    items: [
                        {
                            link: '/admin/classroom/add',
                            nodeId: 31.2,
                            label: "Yeni Sınıf Ekle",
                            icon: AddAlarmIcon,
                        },
                        {
                            link: '/admin/classroom/list',
                            nodeId: 31.3,
                            label: "Sınıf Listesi",
                            icon: AddAlarmIcon,
                        }
                    ]
                },
                {
                    link: null,
                    nodeId: 20.4,
                    label: "Canlı Sınıf",
                    icon: Label,
                    items: [
                        {
                            link: '/admin/bbb/room/create/0',
                            nodeId: 20.1,
                            label: "Yeni Sınıf Ekle",
                            icon: AddAlarmIcon,
                        },
                        {
                            link: '/admin/bbb/room/list',
                            nodeId: 20.2,
                            label: "Canlı Sınıf Listesi",
                            icon: QueuePlayNextIcon,
                        },
                        {
                            link: '/admin/bbb/record/list',
                            nodeId: 20.3,
                            label: "Canlı Kayıt İşlemleri",
                            icon: SaveIcon,
                        }
                    ]
                },

                {
                    link: null,
                    nodeId: 2,
                    label: "Video",
                    icon: Label,
                    items: [
                        {
                            link: '/admin/video/upload/0',
                            nodeId: 3,
                            label: "Yeni Video Ekle",
                            icon: CloudUploadIcon,
                        },
                        {
                            link: '/admin/videos',
                            nodeId: 4,
                            label: "Eklediklerim",
                            icon: LibraryAddIcon,
                            items: []
                        },

                    ]
                },
                {
                    link: null,
                    nodeId: 300,
                    label: "Kategori",
                    icon: Label,
                    items: [
                        {
                            link: '/admin/category/new/0',
                            nodeId: 301,
                            label: "Yeni Kategori Ekle",
                            icon: CategoryIcon,
                            items: []
                        },
                        {
                            link: '/admin/categories',
                            nodeId: 302,
                            label: "Kategoriler",
                            icon: CategoryIcon,
                            items: []
                        },

                    ]
                },
                {
                    link: null,
                    nodeId: 12,
                    label: "Sınavlar",
                    icon: Label,
                    items: [
                        {
                            link: '/admin/esinav/add',
                            nodeId: 13,
                            label: "Sınav Ekle Düzenle",
                            icon: EventAvailableIcon,
                        },
                    ]
                },
                {
                    link: '/admin/uploads',
                    nodeId: 19,
                    label: "Diğer Dosyalar",
                    icon: CloudUploadIcon,
                }
            ]
        }


    ];
    let checkLogin = 'false';
    try {
        checkLogin = localStorage.getItem('login');
    } catch (e) {
    }
    switch (menu) {
        case  'UserNavArr':
            if (checkLogin != "true") {
                return <>
                    <Link to={"/login"}> <StyledTreeItem nodeId="1" labelText="Giriş Yap" labelIcon={LockOpenIcon}/></Link>
                    <Divider/>
                    <Link to={"/register"}> <StyledTreeItem nodeId="2" labelText="Üye Ol" labelIcon={AddCircleIcon}/></Link>
                    <Divider/>

                </>
            }
            return UserNavArr.map((item, index) => <TreeViewMenu key={`user_menu_${index}`} item={item} index={index} name={"user"}/>)
            break;
        case  'AllNavArr':
            return AllNavArr.map((item, index) => <TreeViewMenu key={`all_menu_${index}`} item={item} index={index} name={"all"}/>)
            break;
        case  'SuperAdminArr':
            return SuperAdminArr.map((item, index) => <TreeViewMenu key={`superAdmin_menu_${index}`} item={item} index={index} name={"superadmin"}/>)
            break;
        case  'SiteAdminArr':
            return SiteAdminArr.map((item, index) => <TreeViewMenu key={`siteAdmin_menu_${index}`} item={item} index={index} name={"siteadmin"}/>)
            break;
        case  'AdminNavArr':
            return AdminNavArr.map((item, index) => <TreeViewMenu key={`admin_menu_${index}`} item={item} index={index} name={"admin"}/>)
            break;
    }


}

export default Navbar;


