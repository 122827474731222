import axios from 'axios';

const instance = axios.create(
    {
        baseURL: "https://lms.rotasinav.com",

    }
);

try {
    if (localStorage.getItem('token') !== '' && localStorage.getItem('token') !== 'null' && localStorage.getItem('token') !== null) {
        if (localStorage.getItem('token')) {
            instance.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('token');
        }
    }

} catch (e) {

}


export default instance
