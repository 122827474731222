import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import Review from './Review';
import {StepContext} from "./StepStore";

const useStyles = makeStyles(theme => ({
    appBar : {
        position: 'relative',
    },
    layout : {
        width                                             : 'auto',
        marginLeft                                        : theme.spacing(2),
        marginRight                                       : theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width      : "100%",
            marginLeft : 'auto',
            marginRight: 'auto',
        },
        backgroundColor                                   : "rgb(241,241,241,.3)"
    },
    paper  : {
        marginTop                                         : theme.spacing(0),
        marginBottom                                      : theme.spacing(3),
        padding                                           : theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop   : theme.spacing(0),
            marginBottom: theme.spacing(6),
            padding     : theme.spacing(3),
        },
        backgroundColor                                   : "rgb(241,241,241,.3)"
    },
    stepper: {
        padding        : theme.spacing(3, 0, 5),
        backgroundColor: "rgb(241,241,241,.3)"
    },
    buttons: {
        display       : 'flex',
        justifyContent: 'flex-end',
    },
    button : {
        marginTop : theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));

const steps = ['Ürünler', 'Fatura Bilgileri', 'Ödeme Sayfası'];

function getStepContent(step) {
    switch (step) {
        case 0:
            return <Review/>;
        case 1:
            return <AddressForm/>;
        case 2:
            return <PaymentForm/>;
        default:
            throw new Error('Unknown step');
    }
}

export default function Checkout() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useContext(StepContext);
    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };
    return (
        <React.Fragment>
            <main className={classes.layout}>
                <Paper className={"paper"}>
                    <Stepper activeStep={activeStep} className={classes.stepper}>
                        {steps.map(label => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <React.Fragment>
                        {getStepContent(activeStep)}
                        <div className={classes.buttons}>
                            {activeStep !== 0 && (
                                <Button onClick={handleBack} className={classes.button}>
                                    GERİ
                                </Button>
                            )}

                        </div>
                    </React.Fragment>
                </Paper>
            </main>
        </React.Fragment>
    );
}
