import React, {useEffect, useState, useContext} from 'react';
import {BreadsContext, PageContext, PageTitleContext} from "../../stores/PageStore";

import {Container, Box, Typography, ProTip, Divider, ListSubheader,} from '@material-ui/core';
import instance from '../../apis';
import {makeStyles} from '@material-ui/core/styles';
import AttachFile from '@material-ui/icons/AttachFile';

import moment from 'moment'
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import colors from '../../styles/colors';
import {CircularProgress, Icon, Button, List, ListItem, ListItemText, Grid, Paper, ListItemIcon} from '@material-ui/core';

import screenSize from '../../functions/screenSize';
import {Alert} from "@material-ui/lab";
import {Link} from "react-router-dom";


const useStyles = makeStyles(theme => ({
    // root: {
    //   width: '100%',
    //   backgroundColor: theme.palette.background.paper,
    //   position: 'relative',
    //   overflow: 'auto',
    // },
    date: {
        backgroundColor: 'yellow',
        [theme.breakpoints.down('xs')]: {
            backgroundColor: 'yellow',
        },

    },
    dateContent: {
        backgroundColor: 'yellow',
        [theme.breakpoints.down('xs')]: {
            backgroundColor: 'yellow',
        },

    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}));


const RoomList = () => {
    const classes = useStyles();
    const [loadContex, setLoadContex] = useState(true)
    const [rooms, setRooms] = useState([])
    const [classroom, setClassroom] = useState({})
    const [sinavs, setSinavs] = useState([])
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    const [load, setLoad] = useState(false)
    const {height, width} = screenSize();
    useEffect(() => {
        const breads = [
            {
                link: null,
                title: load ? <CircularProgress style={{
                    width: 8,
                    height: 8
                }}/> : `Canlı Sınıf / Liste`,
                last: true
            },
        ];
        setBreads(breads)
        setPage(21)
        setPageTitle('Canlı Sınıf')
    }, [])

    useEffect(() => {
        instance
            .get('/api/bbb/room/list')
            .then(function (res) {
                setLoadContex(false)
                setRooms(res.data.rooms);
                setClassroom(res.data.classroom);
                setLoadContex(false)
            })
    }, [])


    const roomJoin = (id) => {
        var data = {id}
        instance
            .post('/api/bbb/room/transfer', data)
            .then(function (res) {
                setLoadContex(false)
                if (res.data == 'yasakli-giris') {
                    alert('Bu derse giriş izniniz yoktur.');
                } else {
                    window.open('https://lms.rotasinav.com/room/join/' + res.data, "_blank") || window.location.replace('https://lms.rotasinav.com/room/join/' + res.data);
                }

            })
    }

    if (loadContex) {
        return <CircularProgress/>;
    }

    const dateComponent = ({date, index}) => {
        return (
            <Paper>
                <Grid style={{background: colors.alternateColor[index % 2], color: colors.btnBgMavi2, borderRadius: 4, width: 100, boxShadow: 0, padding: 5, borderWidth: 1, borderColor: '#000'}}
                      container direction="column" justify="center" alignItems="center">

                    <Grid item>
                        <h4><b>{moment(date).format('DD MMM')}</b></h4>
                    </Grid>
                    <Grid item><h5>{moment(date).format('HH:mm')}</h5></Grid>
                </Grid>
            </Paper>
        )
    }

    // eğer kullanıcı bir sınıf seçmiş ise yalnızca o sınafa ait canlı dersleri gösterelim
    function SelectClassroomSelectView() {
        if (classroom)
            return <><Alert> Sadece <b>{classroom.ad} </b> sınıfına ait canlı yayınları görüyorsunuz, sınıfınızı
                <Link to={'/my/profile'}> <b>profilinizden</b></Link> değiştirebilirsiniz..</Alert></>
        return <></>
    }

    return <>
        <Grid container alignItems="flex-start" justify="flex-start" alignContent="flex-start">
            <Grid container justify={"space-between"}>
                <Grid item xs={12} sm={3}><h3>Canlı Sınıf Listesi</h3></Grid>
                <Grid item xs={12} sm={7}>
                    <SelectClassroomSelectView/>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
                {rooms.length == 0 &&
                <Paper style={{padding: 15}}>
                    <Typography>
                        <p>Planlanan bir canlı sınıf bulunmamaktadır</p>
                    </Typography>
                </Paper>
                }
            </Grid>
            <Grid item xs={12} sm={12}>
                {rooms.length > 0 &&
                <Box>
                    {rooms.map((room, index) =>
                            <Box display={width < 1000 ? null : 'flex'} p={1}>
                                {width > 1000 && <Box p={1} flexShrink={0} xs={12} sm={6}>
                                    {dateComponent({date: room.baslama_tarih, index: index})}
                                </Box>}
                                <Box p={1} width="100%" xs={12} sm={6}>
                                    <Paper style={{padding: 15}}>
                                        {width < 1000 &&
                                        <Box style={{marginBottom: 20}}>
                                            <p style={{background: colors.alternateColor[index % 2], color: colors.btnBgMavi2}}>
                                                <h4><b>{moment(room.baslama_tarih).format('DD MMMM YYYY')} - {moment(room.baslama_tarih).format('hh:mm')}</b></h4>
                                            </p>
                                            <Divider/>
                                        </Box>
                                        }
                                        <h3>{room.room_ad}</h3>
                                        <p style={{fontSize: 14}}>
                                            {room.room_aciklama}
                                        </p>
                                        <br/>
                                        {room.dokumans.length > 0 &&
                                        <Box>
                                            <h4>Ders Dökümanları</h4>
                                            <Divider/>
                                            <List>
                                                {room.dokumans.map((dokuman, index) =>
                                                    <ListItem button onClick={() => {
                                                        window.open('https://lms.rotasinav.com/room/' + dokuman.file, '_blank')
                                                    }}>
                                                        <ListItemIcon style={{width: 20}}>
                                                            <AttachFile style={{width: 20}}/>
                                                        </ListItemIcon>
                                                        <ListItemText> {dokuman.aciklama}</ListItemText>
                                                    </ListItem>
                                                )}
                                            </List>
                                        </Box>}
                                        <Button
                                            style={{marginTop: 20, backgroundColor: colors.btnBgKirmizi, color: '#fff', borderRadius: 0, padding: 5}}
                                            type={"button"}
                                            variant="contained"
                                            onClick={() => {
                                                roomJoin(room.id)
                                            }}
                                        > Derse Katıl </Button>
                                    </Paper>
                                </Box>

                            </Box>
                        // <Grid container xs={12} sm={12} drection="row">
                        //     <Grid item xs style={{width:100}}>

                        //     </Grid>
                        //     <Grid item  xs={11} flexGrow>

                        //     </Grid>
                        // </Grid>
                    )}
                </Box>

                    // <VerticalTimeline
                    // layout={"1-column"}
                    // style={{width:'100%', alignItems:'flex-start'}}
                    // >
                    //     {rooms.map((room, index) =>
                    //     <VerticalTimelineElement
                    //     style={{borderBottomWidth:15, borderBottomColor:colors.colors[index%6]}}
                    //         className="vertical-timeline-element--work"
                    //         contentStyle={{ background: colors.alternateColor[index%2], color: colors.text, marginLeft:120,
                    //     }}
                    //         contentArrowStyle={{ borderRight: `7px solid ` + colors.alternateColor[index%2] }}
                    //         // date="2011 - present"
                    //         icon={dateComponent({date: room.baslama_tarih,  index})}
                    //     >
                    //         <h3 className="vertical-timeline-element-title">{room.room_ad}</h3>
                    //         <p>
                    //         {room.room_aciklama}
                    //         </p>
                    //         <br />
                    //         {room.dokumans.length>0&&
                    //         <Box>
                    //             <h4>Ders Dökümanları</h4>
                    //             <Divider />
                    //             <List
                    //             >
                    //                 {room.dokumans.map((dokuman, index) =>

                    //                 <ListItem button onClick={() => {
                    //                     window.open('https://lms.rotasinav.com/room/dokuman/'+dokuman.file, '_blank');
                    //                 }}>
                    //                     <ListItemIcon style={{width:20}}>
                    //                         <AttachFile style={{width:20}} />
                    //                     </ListItemIcon>
                    //                     <ListItemText> {dokuman.aciklama}</ListItemText>
                    //                 </ListItem>
                    //             )}
                    //             </List>
                    //         </Box>}
                    //         <Button
                    //             style={{marginTop:20, backgroundColor:colors.btnBgKirmizi, color:'#fff', borderRadius:0, padding:5}}
                    //             type={"button"}
                    //             variant="contained"
                    //             onClick={() => {
                    //                 roomJoin(room.id)
                    //                 }}
                    //             > Derse Katıl </Button>
                    //     </VerticalTimelineElement>
                    //      )}
                    //     </VerticalTimeline>
                }

            </Grid>
        </Grid>
    </>

}

export default RoomList;
