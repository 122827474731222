import React from 'react';
import {TextField, Grid, CircularProgress} from "@material-ui/core";
import { connect } from 'react-redux'
import { setName, setSubeKod } from '../../../../redux/classroomAdd'
import store from '../../../../redux/store'


function Form(props) {

    
    return <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
            <TextField
                variant={"outlined"}
                size={"small"}
                required
                defaultValue={props.classroomAdd.ad}
                name="ad"
                ic="ad"
                label="Sınıf Adı"
                fullWidth={true}
                autoComplete="ad"
                onChange ={(e) => {
                    props.setName(e.target.value)
                }}
            />
        </Grid>
        <Grid item xs={12} sm={12}>
            <TextField
                variant={"outlined"}
                size={"small"}
                defaultValue={props.classroomAdd.sube_kod}
                name="sube_kod"
                id="sube_kod"
                label="Şube Kodu (varsa)"
                fullWidth={true}
                onChange ={(e) => {
                    props.setSubeKod(e.target.value)
                }}
            />
        </Grid>
    </Grid>

}


const mapStateToProps = (state) => {
    return {
        classroomAdd: state.classroomAdd,
        
    }
};

const mapDispatchToProps = {
	setName, setSubeKod
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Form);


