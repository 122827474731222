import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class AlertDialog extends Component {
    constructor(props){
        super(props)
        this.state = {
            status :false
        }
        this.close = this.close.bind(this);
    }
  

    open = (props) => {
        this.setState({status : true});
    };

    close = (res) => {
        this.setState({status : false});
    };

    render () {
        return (
        <div>
        <Dialog
            open={this.state.status}
            // onClose={this.close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title"> {this.props.title}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {this.props.metin}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => {this.close(false); this.props.response(false)}} color="primary">
                Hayır
            </Button>
            <Button onClick={() => {this.close(false); this.props.response(true)}} color="primary" autoFocus>
                Evet
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
    }
}