import React, {useEffect, useState, useContext} from 'react';
import instance from '../../apis';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {Link} from 'react-router-dom';
import {Backdrop, Fade, makeStyles, Modal, Paper, Chip} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Tooltip from '@material-ui/core/Tooltip';
import {toast} from 'react-toastify';
import DeleteIcon from '@material-ui/icons/Delete';
import Badge from '@material-ui/core/Badge';
import {BreadsContext, PageContext, PageTitleContext} from "../../stores/PageStore";
import UserNote from "../admin/UserNote";

const useStyles = makeStyles(theme => ({
    row: {
        "&:hover": {
            backgroundColor: "#f1f1f1"
        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 400
    },
}));
const UserSearch = () => {
    const classes = useStyles();
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    const [getKw, setGetKw] = useState()
    const [saleConfig, setSaleConfig] = useState({})
    const [open, setOpen] = useState(false);
    const [users, setUsers] = useState([])
    useEffect(() => {
        const breads = [
            {
                link: null,
                title: 'Kullanıcı Ara',
                last: true
            }
        ]
        instance.get('/api/superadmin/users')
            .then(function (res) {
                setUsers(res.data.users)
                setSaleConfig(res.data.sale_config)
            })

        setBreads(breads)
        setPageTitle('Kullanıcı Ara')
        setPage(51)
    }, [])

    const [rows, setRows] = useState({
        users: [],
        sepets: []
    })


    const RowUsers = () => {
        if (rows.users.length == 0) {
            return <></>
        }
        return rows.users.map((item, index) => <UserItem item={item} index={index}/>)
    }

    function sepetOnayla(sepet_id) {
        instance.get(`/api/superadmin/lisans/onayla/${sepet_id}/${getKw}`)
            .then(function (res) {
                setRows(res.data)
            })
    }

    function sepetIptal(sepet_id) {
        instance.get(`/api/superadmin/lisans/iptal/${sepet_id}/${getKw}`)
            .then(function (res) {
                setRows(res.data)
            })
    }

    const SepetDurum = (item) => {
        if (item.active == null) {
            return <Grid item xs={12} sm={2}><Button onClick={() => sepetOnayla(item.id)} size={"small"} variant={"outlined"} color={"secondary"}>Onayla</Button></Grid>
        }
        return <Grid item xs={12} onClick={() => sepetIptal(item.id)} sm={2}><Button size={"small"} variant={"outlined"} style={{backgroundColor: "lightslategrey"}}>İptal Et</Button></Grid>
    }

    const userExpressCategory = (user_id, name) => {
        if (saleConfig.easy_license != 1)
            return setOpen(true);
        if (global.confirm('Emin misiniz?'))
            instance.get(`/api/superadmin/user/express/category/${user_id}`)
                .then(function (res) {
                    if (res.status == 200) {
                        toast.success(`${name} kullanıcısı şuan aktif`);
                        setUsers(res.data.users);
                    } else {
                        toast.warn(res.data.msg);
                    }
                })
    }
    const userDelete = (user_id, name) => {
        if (global.confirm('Emin misiniz?'))
            instance.delete(`/api/superadmin/user/${user_id}`)
                .then(function (res) {
                    toast.warn(`${name} kullanıcısı sistemden silindi`)
                    setUsers(users.filter(x => x.id != user_id))
                })
    }
    const HizliOnayButton = item => {
        if (item.express > 0) {
            return <Badge badgeContent={item.express} color="primary">
                <Button variant={"outlined"} color={"secondary"} size={"small"} onClick={() => userExpressCategory(item.id, item.name)}>Tekrar LİSANSLA</Button>
            </Badge>

        }
        return <Button variant={"outlined"} color={"primary"} size={"small"} onClick={() => userExpressCategory(item.id, item.name)}>Hızlı LİSANSLAMA</Button>
    }
    const UserItem = ({item, index}) => {
        return <Grid className={classes.row} key={index} container spacing={2} key={item.id} alignContent={"center"} alignItems={"center"}>
            <Grid item xs={6} sm={2}> <Link to={`/superadmin/get/user/card/${item.id}`}> {index + 1}) {item.id} - {item.name}</Link></Grid>
            <Grid item xs={6} sm={2}>
                {item.roles.map(role => <Chip label={role.name}/>)}
            </Grid>
            <Grid item xs={6} sm={2}>{item.email}</Grid>
            <Grid item xs={5} sm={1}> {item.tel}</Grid>
            <Grid item xs={12} sm={5}>
                <Grid container alignItems={"center"} alignContent={"center"}>
                    <Grid item xs={12} sm={11}>
                        <UserNote user={item}/>
                    </Grid>
                    <Grid item xs={12} sm={1} style={{textAlign: "right"}}>
                        <DeleteIcon style={{
                            color: "red",
                            cursor: "pointer"
                        }} onClick={() => userDelete(item.id, item.name)}/>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12}><Divider/></Grid>
        </Grid>
    }
    const AllUsers = () => {
        if (users) {
            return users.map((item, index) => <UserItem key={item.id} item={item} index={index}/>)
        } else {
            return <>Kullanıcı verisi yükleniyor...</>
        }
    }
    const UserSearchView = () => {
        return <><h3>SİPARİŞLER</h3>
            <RowSepets/>
            <Divider style={{height: 10}}/>
            <h3>BULUNAN KULLANICILAR</h3>
            <RowUsers/>
        </>
    }
    const RowSepets = () => {
        return rows.sepets.map(item => <Paper className="paper-handler" key={item.id}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={2}>ID:{item.id}</Grid>
                    <Grid item xs={12} sm={2}>İsim:{item.user.name}</Grid>
                    <Grid item xs={12} sm={3}>
                        {item.products.map(product =>
                            <Grid xs={12} sm={12} item>{product.qty} Adet {product.name}</Grid>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={1}>Net: {item.unit_price}</Grid>
                    <Grid item xs={12} sm={1}>KDV:{item.kdv}</Grid>
                    <Grid item xs={12} sm={1}>Toplam: {item.dis_price}</Grid>
                    {SepetDurum(item)}
                    <Grid item xs={12} sm={12}><Divider/></Grid>
                </Grid>
            </Paper>
        )
    }
    const handleClose = () => {
        setOpen(false);
    };
    const SearchInput = () => {
        const [kw, setKw] = useState()

        function submitForm(e) {
            e.preventDefault()
            const formData = new FormData(e.target)
            instance.post(`/api/superadmin/user/search`, formData)
                .then(function (res) {
                    setRows(res.data)
                    setGetKw(e.target)
                })
        }

        function handleChange(e) {
            setKw(e.target.value)
        }

        return <form onSubmit={submitForm}>
            <TextField
                required
                onChange={handleChange}
                id="kw"
                defaultValue={kw}
                name="kw"
                label="ID, Tel, İsim, Email, Sipariş no"
                placeholder="Ara..."
                fullWidth={true}
            />
            <div style={{clear: "both"}}></div>
            <br/>
            <Button
                type="submit"
                fullWidth={true}
                variant={"contained"}
                color={"primary"}
            >Kullanıcı Ara</Button>
        </form>
    }
    return <>
        <Paper className="paper-handler">
            <SearchInput/>
        </Paper>
        <Paper className="paper-handler">
            <UserSearchView/>
        </Paper>
        <Paper className="paper-handler">
            <h3>SON EKLENEN 50 KULLANICI</h3>
            <AllUsers/>
        </Paper>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            className={classes.modal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <h2 id="transition-modal-title">Hızlı Lisanslama</h2>
                    <p id="transition-modal-description">
                        Bu opsiyonu kullanabilmek için satın alma ayarlarından "Hızlı Lisanslama" ayarlarını yapılandırmalsınız.
                        <div style={{textAlign: "right"}}>
                            <Link to={`/superadmin/sales/config`}>
                                <Button size={"small"} variant={"contained"} color={"primary"}>Satın Alma Ayarları</Button>
                            </Link>
                        </div>
                    </p>
                </div>
            </Fade>
        </Modal>
    </>
}

export default UserSearch;
