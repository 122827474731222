import React, {useContext, useEffect, useState} from "react";
import instance from "../../../apis";
import {Grid, Paper} from "@material-ui/core";
import TinyEditor from "./TinyEditor";
import {EditorContext} from "./EditorStore";
import {Button} from 'semantic-ui-react';
import SelectImage from "./SelectImage";
import {toast} from "react-toastify";

export default function ProductPage({item}) {
    const [editorBody, setEditorBody] = useContext(EditorContext)
    const [page, setPage] = useState(item ? item.product_page : {})

    useEffect(() => {
        if (item.product_page)
            setEditorBody(item.product_page.body)
    }, [])
    if (!page)
        return <></>
    if (!page.id)
        return <>Tanıtım sayfası, bu kategoriyi kaydettiğinizde aktif olacak.</>


    function submitForm(e) {

        e.preventDefault()
        const formData = new FormData(e.target)
        formData.append('body', editorBody)
        instance
            .post(`/api/admin/product/page/${page.id}`, formData)
            .then(function () {
                toast.success('Değişikliler başarıyla kaydedildi sayfa yenileniyor.')
                window.location.reload(2)
            })
    }


    return <Paper className="paper-handler">
        <form onSubmit={submitForm}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}><h3>Tanıtım Sayfası</h3></Grid>
                <Grid item xs={12} sm={12}><SelectImage page={page}/></Grid>
                <Grid item xs={12} sm={12}><TinyEditor page={page}/></Grid>
                <Grid item xs={12} sm={12}><Button fluid color={"teal"} type="submit">Değişiklikleri Kaydet</Button></Grid>
            </Grid>
        </form>
    </Paper>

}
