import React, {useContext, useState} from 'react';
import addBasket from "../../functions/addBasket";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import Grid from "@material-ui/core/Grid";
import categoryPrice from "../../functions/categoryPrice";
import {Button} from "semantic-ui-react";
import Card from "@material-ui/core/Card";
import {Link, useParams} from "react-router-dom";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import CategoryCardMedia from "../CategoryCardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import {makeStyles} from "@material-ui/core/styles";
import {red} from "@material-ui/core/colors";
import {BasketContext} from "../../stores/Basket";
import {Divider} from "@material-ui/core";
import {seoLink, trLower, trUpper} from "../../functions/trCase";

const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));
const Item = ({item, sub, link}) => {
    const [basket, setBasket] = useContext(BasketContext);
    let {id} = useParams();
    let checkLogin = 'false'
    try {
        checkLogin = localStorage.getItem('login')
    } catch (e) {

    }
    const classes = useStyles();

    const Body = ({Body}) => {
        try {
            if (Body.length > 80) {
                return Body.substr(0, 90) + "..."
            }
            return Body
        } catch (e) {

        }
        return <></>

    };
    const Title = ({Title}) => {
        try {
            return Title.substr(0, 1)
        } catch (e) {

        }
        return <></>

    };

    function categoryLink(item, parent_title) {
        if (id && id != 'all' || sub) {
            return `/videos/${seoLink(parent_title)}/${seoLink(item.title)}/${item.parent_id}/${item.id}`
        } else {
            return `/${link}/${seoLink(item.title)}/${item.id}`
        }
    }

    function parentTitle(item) {
        if (item.parent) {
            return item.parent.title
        } else {
            return null
        }
    }


    function CategoryBBBView({item}) {
        const [states, setStates] = useState({selectBBB: item.bbb === 1 ? true : false});

        function addBasketItem(item) {
            const getItem = addBasket(item, states.selectBBB)
            basket.products.push(getItem);
            setBasket(basket => ({...basket, basket}));
        }

        function AddBasketButtonView() {
            return <IconButton
                className={clsx(classes.expand)} onClick={() => addBasketItem(item)} aria-label="Sepete Ekle">
                <AddShoppingCartIcon/>
            </IconButton>
        }

        function SelectedBBBView() {
            if (states.selectBBB) {
                return <>
                    <Button color={"red"} active>Canlı</Button>
                    <Button.Or text={"-"}/>
                    <Button
                        onClick={() => setStates(states => ({...states, selectBBB: false}))}
                    >Kayıttan</Button>
                </>
            }
            return <>
                <Button
                    onClick={() => setStates(states => ({...states, selectBBB: true}))}>Canlı</Button>
                <Button.Or text={"-"}/>
                <Button color={"grey"} active>Kayıttan</Button>
            </>
        }

        function PriceView() {
            return <Grid item xs={4} sm={4} style={{fontSize: 18, color: "#1fab89", textAlign: "right"}}>
                {categoryPrice(item, states.selectBBB)}
            </Grid>
        }

        if (item.bbb == 1)
            return <Grid container alignContent={"center"} alignItems={"center"}>
                <Grid item xs={6} sm={6}>
                    <Button.Group size='mini' compact>
                        <SelectedBBBView/>
                    </Button.Group>
                </Grid>
                <PriceView/>
                <Grid item xs={2} sm={2} style={{textAlign: "right"}}>
                    <AddBasketButtonView/>
                </Grid>
            </Grid>

        return <Grid container alignContent={"center"} alignItems={"center"}>
            <Grid item xs={6} sm={6}>
                <Button size={"mini"} color="grey" disabled>Kayıttan</Button>
            </Grid>
            <PriceView/>
            <Grid item xs={2} sm={2} style={{textAlign: "right"}}>
                <AddBasketButtonView/>
            </Grid>
        </Grid>
    }

    function InceleView() {
        if (item.product_page_count > 0)
            return <Link to={`/product/page/${item.id}`}>
                <Button size={"mini"} color={"teal"} style={{position: "absolute", top: 0, right: 0}}>İncele</Button>
            </Link>
        return <></>

    }

    return <Grid item xs={12} lg={4} sm={3}>
        <Card className={classes.card} style={{position: "relative"}}>
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                        <Title Title={item.title}/>
                    </Avatar>
                }
                style={{marginTop: 15, fontWeight: "bold"}}
                title={item.title}
            />
            <InceleView/>
            <Link to={categoryLink(item, parentTitle(item))}>
                <CategoryCardMedia image={item.cover_img} site_id={item.site_id}/>
            </Link>
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    <Body Body={item.context}/>
                </Typography>
            </CardContent>
            <Divider/>
            <CardActions disableSpacing>
                <CategoryBBBView item={item}/>
            </CardActions>
        </Card>
    </Grid>
}

export default Item;
