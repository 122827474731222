import React, {useEffect, useContext} from 'react';
import {BreadsContext, PageContext, PageTitleContext} from "../../../../stores/PageStore";
import {BBBContext, BBBProvider, BBBConsumer} from '../../../../stores/BBBCreate';
import Create from './Create';

const Index = (props) => {
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);

    useEffect(() => {
        const breads = [
            {
                link: '/admin/bbb/create',
                title: 'Yeni Sınıf Ekle',
                last: true
            },
        ];
        setBreads(breads)
        setPage(20.1)
        setPageTitle('Yeni Sınıf Ekle')
    }, [])

    const users = [];

    return <BBBProvider users={users} {...props}>
        <Create/>
    </BBBProvider>


}

export default Index;
