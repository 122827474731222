import React, {useEffect, useState} from 'react';
import instance from "../apis";
import Vimeo from "@u-wave/react-vimeo";
import mask from "../images/mask.png";
import moment from "moment";
import useInterval from '@use-it/interval';
import {CircularProgress, Grid} from "@material-ui/core";

//import {Detector} from "react-detect-offline";


function VimeoPlayerView({video, category_id, startTime, setStartTime}) {
    const VimoPlayerView = () => {
        const [pause, setPause]                   = useState(false)
        const [playerPause, setPlayerPause]       = useState(false)
        const [end, setEnd]                       = useState(false)
        const [error, setError]                   = useState(false)
        const [timeWatch, setTimeWatch]           = useState(moment()
                                                                 .unix())
        const [status, setStatus]                 = useState({
                                                                 sn  : startTime,
                                                                 dkSn: dkSn(startTime)
                                                             })
        const [connection, setConnection]         = useState(true);
        const [refreshConnect, setRefreshConnect] = useState(1);
        useEffect(() => {
            instance.get(`/api/user/view/video/${video.id}`, {
                params: {
                    sn         : status.sn,
                    dr         : status.duration,
                    category_id: category_id
                }
            })

        }, [status])


        useInterval(() => {
            if (!connection) {
                return setRefreshConnect(refreshConnect + 1)
            }
        }, [5000])
        const [timeRender, setTimeRender] = useState(null)
       /* useInterval(() => {
            let bekle = 25;
            const now = moment()
                .unix() //silme



            if ((timeWatch < (now - bekle) && playerPause == false && !end) || (error && (timeWatch < (now - bekle)))) {
                setTimeRender(status.sn)
                setPause(false)
                setStartTime(status.sn - bekle)
            }
            if (timeWatch < (now - bekle)) {
                setTimeRender(-1)
            }

            if (error) {
            }
            // console.log(timeWatch[timeWatch.length - 1])
        }, 4000)*/
        /*useInterval(() => {
            setPause(false)
            setTimeRender(-1)
        }, 10000)*/
        if (!video.id) {
            return <>Video yükleniyor <CircularProgress style={{width: 18}}/></>
        }

        function currentTime(e) {
            const now = moment()
                .unix()
            setTimeWatch(now)
            const sn = Number(e.seconds.toFixed());
            if (timeWatch != now && sn % 5 == 0) {
                setStatus({
                              sn     : e.seconds,
                              dration: e.duration,
                              dkSn   : dkSn(e.seconds)
                          })
            }


        }

        function dkSn(sn) {
            const saat   = Math.trunc(sn / 3600);
            const dk     = Math.trunc((sn - saat * 3600) / 60);
            const second = Math.trunc((sn % 60))
            const dkView = dk < 10 ? `0${dk}` : dk;
            const snView = second < 10 ? `0${second}` : second;
            const sa     = saat ? `${saat}.` : '';
            const saView = sa < 10 && sa != 0 && sa != '' ? `0${sa}` : sa;
            return `${saView}${dkView}.${snView}`;
        }

        //  return <iframe src="https://player.vimeo.com/video/76979871"  width="640" height="360" frameBorder="0" allowFullScreen time="50" allow="autoplay; encrypted-media"></iframe>
        /* return <Detector
             render={({online}) => {
                 if (online) {
                     setConnection(true)
                     if ((status.sn == -1 || status.sn != '') && !status.sn) {
                         return <></>
                     }*/
        return <div className="playerVimeo">
            <Vimeo
                start={status.sn}
                video={video.vimeo_id}
                responsive={true}
                autoplay={true}
                onTimeUpdate={e => currentTime(e)}
                onPause={() => setPause(true)}
                onError={() => setError(true)}
                onPlay={() => setPause(false)}
                playsinline={false}
                autopause={false}
                transparent={false}
                paused={playerPause}
                className="vimeoPlayer"
                //onLoad={()=>pla}
                // onPlay={() => setPause(false)}
                //onLoaded={(e) =>  console.log({load: e})}
                //onError={e => console.log(e)}
                //onReady={() => setLoaded(false)}
                onEnd={() => setEnd()}
                // onTimeUpdate={e => console.log({time: e})}
                // onSeeked={e => console.log({seek: e})}
                //onCuePoint={e => console.log({time: e})}
            />
            <span style={{fontSize: 9}}>Önceki girişinizde kaldınız zaman: {dkSn(startTime)}</span>

        </div>
        /*} else {
            setConnection(false)
            return <Grid container style={{
                width : "100%",
                height: 400,
            }} alignItems={"center"} justify={"center"} alignContent={"center"}>
                <Grid item style={{textAling: "center"}}>
                    İnternet bağlantınız yok. <br/>Yeniden bağlanmayı deniyoruz, {refreshConnect}. deneme
                </Grid>
            </Grid>
        }
    }}
/>*/

    }

    return <div style={{position: "relative"}}>
        <VimoPlayerView/>
    </div>;
}

export default VimeoPlayerView;

