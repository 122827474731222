import React, {useEffect, useState, useContext} from 'react';
import instance from '../../apis';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {Link} from 'react-router-dom';
import {Backdrop, Fade, makeStyles, Modal, Paper} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Tooltip from '@material-ui/core/Tooltip';
import {toast} from 'react-toastify';
import DeleteIcon from '@material-ui/icons/Delete';
import Badge from '@material-ui/core/Badge';
import {BreadsContext, PageContext, PageTitleContext} from "../../stores/PageStore";
import UserNote from "./UserNote";
import {Button} from "semantic-ui-react";

const useStyles = makeStyles(theme => ({
    row: {
        "&:hover": {
            backgroundColor: "#f1f1f1"
        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 400
    },
}));
const UserSearch = () => {
    const classes = useStyles();
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    const [getKw, setGetKw] = useState()
    const [saleConfig, setSaleConfig] = useState({})
    const [open, setOpen] = useState(false);
    const [users, setUsers] = useState([])
    useEffect(() => {
        const breads = [
            {
                link: null,
                title: 'Kullanıcı Ara',
                last: true
            }
        ]
        instance.get('/api/admin/users')
            .then(function (res) {
                setUsers(res.data.users)
                setSaleConfig(res.data.sale_config)
            })

        setBreads(breads)
        setPageTitle('Kullanıcı Ara')
        setPage(1.1)
    }, [])

    const [rows, setRows] = useState({
        users: [],
        sepets: []
    })


    console.log(getKw)
    const RowUsers = () => {
        if (rows.users.length == 0) {
            return <></>
        }
        return rows.users.map((item, index) => <UserItem item={item} index={index}/>)
    }

    function sepetOnayla(sepet_id) {
        instance.get(`/api/admin/lisans/onayla/${sepet_id}`, {
            params: {
                kw: getKw
            }
        })
            .then(function (res) {
                setRows(res.data)
            })
    }

    function sepetIptal(sepet_id) {
        instance.get(`/api/admin/lisans/iptal/${sepet_id}`, {
            params: {
                kw: getKw
            }
        })
            .then(function (res) {
                setRows(res.data)
            })
    }

    const SepetDurum = (item) => {
        if (item.active == null) {
            return <Grid item xs={12} sm={2}><Button onClick={() => sepetOnayla(item.id)} size={"small"} color={"green"}>Onayla</Button></Grid>
        }
        return <Grid item xs={12} onClick={() => sepetIptal(item.id)} sm={2}><Button size={"small"} color="red">İptal Et</Button></Grid>
    }


    const userDelete = (user_id, name) => {
        if (global.confirm('Emin misiniz?'))
            instance.delete(`/api/admin/user/${user_id}`)
                .then(function (res) {
                    toast.warn(`${name} kullanıcısı sistemden silindi`)
                    setUsers(users.filter(x => x.id != user_id))
                })
    }


    const HizliOnayButton = ({item}) => {
        const [data, setData] = useState(item);
        const userExpressCategory = (user_id, name) => {
            if (saleConfig.easy_license != 1)
                return setOpen(true);
            if (global.confirm('Emin misiniz?'))
                instance.get(`/api/admin/user/express/category/${user_id}`)
                    .then(function (res) {
                        if (res.status == 200) {
                            toast.success(`${name} kullanıcısı şuan aktif`);
                            setData(res.data.item)
                        } else {
                            toast.warn(res.data.msg);
                        }
                    })
        }

        function lisansSil(user) {
            if (global.confirm('Eklenen lisansları siler! emin misiniz?'))
                instance
                    .get(`/api/admin/user/license/delete/${user.id}`)
                    .then(function (res) {
                        if (res.status == 200) {
                            toast.success(`${user.name} kullanıcısının lisansı silindi`);
                            setData(res.data.item)
                        } else {
                            toast.warn(res.data.msg);
                        }
                    })

        }

        function LisansSilView() {
            if (!data.licenses)
                return <></>
            if (data.licenses.length < 1)
                return <></>
            return <Tooltip title="Tanımlı tüm lisanları siler, kategori lisansları buna dahil değildir." placement={"top"}>
                <Button color={"red"} size={"mini"} onClick={() => lisansSil(data)}>SİL</Button>
            </Tooltip>
        }

        if (data.express > 0) {
            return <>
                <Tooltip title="Ön tanımlı ayarlarla aktif eder." placement={"top"}>
                    <Badge badgeContent={data.express} color="primary">
                        <Button size={"mini"} onClick={() => userExpressCategory(data.id, data.name)}>TEKRAR LİSANSLA</Button>
                    </Badge>
                </Tooltip>
                <LisansSilView/>
            </>

        }
        return <Button color="green" size={"mini"} onClick={() => userExpressCategory(data.id, data.name)}>HIZLI LİSANSLAMA</Button>
    }
    const UserItem = ({item, index}) => {
        return <Grid className={classes.row} key={index} container spacing={2} key={item.id} alignContent={"center"} alignItems={"center"}>
            <Grid item xs={6} sm={2}> <Link to={`/admin/get/user/card/${item.id}`}> {index + 1}) {item.id} - {item.name}</Link></Grid>
            <Grid item xs={6} sm={2}>
                <HizliOnayButton item={item}/>
            </Grid>
            <Grid item xs={6} sm={2}>{item.email}</Grid>
            <Grid item xs={5} sm={1}> {item.tel}</Grid>
            <Grid item xs={12} sm={5}>
                <Grid container alignItems={"center"} alignContent={"center"}>
                    <Grid item xs={12} sm={11}>
                        <UserNote user={item}/>
                    </Grid>
                    <Grid item xs={12} sm={1} style={{textAlign: "right"}}>
                        <DeleteIcon style={{
                            color: "red",
                            cursor: "pointer"
                        }} onClick={() => userDelete(item.id, item.name)}/>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12}><Divider/></Grid>
        </Grid>
    }
    const AllUsers = () => {
        if (users) {
            return users.map((item, index) => <UserItem key={item.id} item={item} index={index}/>)
        } else {
            return <>Kullanıcı verisi yükleniyor...</>
        }
    }
    const UserSearchView = () => {
        return <><h3>SİPARİŞLER</h3>
            <RowSepets/>
            <Divider style={{height: 10}}/>
            <h3>BULUNAN KULLANICILAR</h3>
            <RowUsers/>
        </>
    }
    const RowSepets = () => {

        return rows.sepets.map(item => <Paper className="paper-handler" key={item.id}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={2}>ID:{item.id}</Grid>
                    <Grid item xs={12} sm={2}>İsim:{item.user.name}</Grid>
                    <Grid item xs={12} sm={3}>
                        {item.products.map(product =>
                            <Grid xs={12} sm={12} item>{product.qty} Adet {product.name}</Grid>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={1}>Net: {item.unit_price}</Grid>
                    <Grid item xs={12} sm={1}>KDV:{item.kdv}</Grid>
                    <Grid item xs={12} sm={1}>Toplam: {item.dis_price}</Grid>
                    {SepetDurum(item)}
                    <Grid item xs={12} sm={12}><Divider/></Grid>
                </Grid>
            </Paper>
        )
    }
    const handleClose = () => {
        setOpen(false);
    };
    const SearchInput = () => {
        const [kw, setKw] = useState()

        function submitForm(e) {
            e.preventDefault()
            const formData = new FormData(e.target)
            instance.post(`/api/admin/user/search`, formData)
                .then(function (res) {
                    setRows(res.data)
                    setGetKw(kw)
                })
        }

        function handleChange(e) {
            setKw(e.target.value)
        }

        return <form onSubmit={submitForm}>
            <TextField
                required
                onChange={handleChange}
                id="kw"
                defaultValue={kw}
                name="kw"
                label="ID, Tel, İsim, Email, Sipariş no"
                placeholder="Ara..."
                fullWidth={true}
            />
            <div style={{clear: "both"}}></div>
            <br/>
            <Button
                type="submit"
                fullWidth={true}
                variant={"contained"}
                color={"primary"}
            >Kullanıcı Ara</Button>
        </form>
    }
    return <>
        <Paper className="paper-handler">
            <SearchInput/>
        </Paper>
        <Paper className="paper-handler">
            <UserSearchView/>
        </Paper>
        <Paper className="paper-handler">
            <h3>SON EKLENEN 50 KULLANICI</h3>
            <AllUsers/>
        </Paper>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            className={classes.modal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <h2 id="transition-modal-title">Hızlı Lisanslama</h2>
                    <p id="transition-modal-description">
                        Bu opsiyonu kullanabilmek için satın alma ayarlarından "Hızlı Lisanslama" ayarlarını yapılandırmalsınız.
                        <div style={{textAlign: "right"}}>
                            <Link to={`/admin/sales/config`}>
                                <Button size={"small"} variant={"contained"} color={"primary"}>SATIN ALMA AYARLARI</Button>
                            </Link>
                        </div>
                    </p>
                </div>
            </Fade>
        </Modal>
    </>
}

export default UserSearch;
