import React, {useContext, useEffect, useState} from 'react';
import {IconButton, Badge, Tooltip} from "@material-ui/core";
import useInterval from "@use-it/interval";
import instance from "../../apis";
import CastIcon from '@material-ui/icons/Cast';
import {Link} from "react-router-dom";
import {ConfigContext} from "../../Store";

const OnlineUserCount = () => {
    const [onlineUser, setOnlineUser] = useState(0);
    const [config] = useContext(ConfigContext);
    const roles = localStorage.getItem('roles');

    useEffect(() => {
        if (!izin())
            return
        getUsers()
    }, [])
    useInterval(() => {
        if (!izin())
            return
        getUsers()
    }, 30000)

    function getUsers() {
        return instance
            .get(`/api/online/user/count`)
            .then(function (res) {
                if (res.status === 200 && res.data.online) {
                    setOnlineUser(res.data.online)
                }
            })
    }

    function izin() {
        if (!roles)
            return false;
        if (roles.includes('superadmin')) {
            return true;
        }
        switch (config.online_users) {
            case 1:
                return true
                break
            case 2:

                if (roles.includes('siteadmin') || roles.includes('admin')) {
                    return true;
                }
                break
            case 3:
                if (roles.includes('siteadmin')) {
                    return true;
                }
                break
        }
        return false
    }

    if (!izin())
        return <></>

    return <Link to={`/online/users`}>
        <IconButton aria-label="" color="inherit">
            <Tooltip title={`${onlineUser}  ders izleniyor`}>
                <Badge badgeContent={onlineUser} color="secondary">
                    <CastIcon/>
                </Badge>
            </Tooltip>
        </IconButton>
    </Link>


}

export default OnlineUserCount;
