import React, {useContext, useEffect, useState} from 'react';
import clsx from 'clsx';
import {fade, makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {Link, useHistory} from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import MoreIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Bread from "./Bread";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import {BasketContext} from '../stores/Basket';
import TreeView from '@material-ui/lab/TreeView';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import VideoPlayerPage from "./VideoPlayerPage";
import AllCategories from "./AllCategories";
import RepassUp from "./RepassUp";
import Repass from "./Repass";
import Sartlar from "./Sartlar";
import Home from "./Home";
import HomePage from "./HomePage";
import {Switch, Route, useLocation} from 'react-router-dom';
import {PageTitleContext} from "../stores/PageStore";
import AdminRoute from "./admin/AdminRoute";
import instance from '../apis';
import AllTimer from "./AllTimer";
import CancelIcon from '@material-ui/icons/Cancel';
import mask from "../images/mask.png"
import useInterval from '@use-it/interval';
import {toast} from "react-toastify";
import MontlyFiles from "./MontlyFiles";
import {InputBase, Tooltip} from "@material-ui/core";
import linkClear from "../functions/linkClear";
import Contact from "./Contact";
import Navbar from "./Navbar";
import MetaTags from 'react-meta-tags';
import {InitialsContext, ConfigContext, LogoContext} from "../Store";
import {Icon} from "semantic-ui-react";
import UrlJoin from './admin/bbb/UrlJoin'
import SuperAdminRoute from "./superadmin/SuperAdminRoute";
import {useTranslation} from "react-i18next";
import OnlineUserCount from "./video/OnlineUserCount";
import OnlineUsers from "./video/OnlineUsers";
import ProductPage from "./productPages/ProductPage";
import Bakimdayiz from "./bakim/Bakimdayiz";
import ShopierOrderSuccess from "./CheckOut/ShopierOrderSuccess";
import {Alert} from "@material-ui/lab";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: "#272727"
    },
    sideBar: {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: "bottom",
    },
    sideBarLink: {
        // backgroundColor: "rgba(250, 250, 250, 0.8)",
        color: "#272727",
        padding: 2,
        margin: 2,
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: "100%",// `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        textDecoration: "none",

    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        // width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(0),
        },
        [theme.breakpoints.up('xs')]: {
            width: theme.spacing(0),
        },

    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    grow: {
        flexGrow: 1,
    },

    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));


const useStylesTree = makeStyles(
    {
        root: {
            height: 264,
            flexGrow: 1,
            maxWidth: 400,
        },
    });
let mql = {matches: false};
try {
    mql = window.matchMedia(`(min-width: 800px)`);
} catch (e) {
}

export default function Main() {
    const location = useLocation();
    let history = useHistory()
    const [basket] = useContext(BasketContext)
    const classes = useStyles();
    const classesTree = useStylesTree();
    const [open, setOpen] = useState(mql.matches);
    const [pageTitle] = useContext(PageTitleContext);
    const [initials, setInitials] = useContext(InitialsContext);
    const [config] = useContext(ConfigContext);
    const [logo] = useContext(LogoContext);
    let checkLogin = 'false'
    try {
        checkLogin = localStorage.getItem('login')

    } catch (e) {

    }
    useEffect(() => {
        mql.addListener(mediaQueryChanged);
        window.dataLayer = window.dataLayer || [];
        instance
            .get('/api/last/view')
            .then(function (result) {
                if (!result.data.video)
                    return;
                toast.info(`Son kaldığınız ders:${result.data.video.title}, gitmek için tıklayınız`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    onClick: function () {
                        history.push(`/video/${result.data.category.parent.id}/${result.data.category.id}/${result.data.id}/${linkClear(result.data.category.parent.title)}/${linkClear(result.data.category.title)}/${result.data.video.id}`)
                    }
                });
            })

    }, [])
    activePageStatus()
    useInterval(() => {
        activePageStatus()
        instance
            .get('/api/user/login/information')
            .then(function (result) {
                if (result.status == 206) {
                    toast.error('Farklı bir yerden oturum açtığınız için çıkış yapıldı.')
                    return history.push('/logout')
                } else {
                    if (result.status === 200 && result.data.msg) {
                        if (checkLogin) {
                            if (roles != result.data.roles) {
                                toast.error('Bilgiler arasında tutartsızlık var!')
                                return history.push('/logout')
                            }
                            if (roles.indexOf('admin') == -1) {
                                if (login_token != result.data.login_token) {
                                    toast.error('Farklı bir yerden oturum açtığınız için çıkış yapıldı.')
                                    return history.push('/logout')
                                }
                            }

                        }
                        if (result.data.sac) {
                            if (result.data.sac.bakim != initials.sac.bakim || result.data.sac.bakim_start != initials.sac.bakim_start)
                                setInitials(initials => ({...initials, sac: result.data.sac}))
                            if (!result.data.sac.bakim && initials.sac.bakim == 1)
                                history.push('/')
                        }
                    }

                    if (result.data.msg == "Unauthenticated." && checkLogin) {
                        return history.push('/logout')
                    }
                }
            })
        const d = new Date();
        try {
            localStorage.setItem('lifeTime', d.getTime());

        } catch (e) {

        }

    }, 10000);
    const login_token = localStorage.getItem('login_token');

    function activePageStatus() {
        let lifeTime = new Date();
        try {
            lifeTime = localStorage.getItem('lifeTime')

        } catch (e) {

        }
        let d = new Date();
        //todo 5dk sonra otorum sonlandırır, join link varsa yapmaz
        /*  if (parseInt(lifeTime) < (d.getTime() - 300000)) {
              if (location.pathname.indexOf('join') != -1) {
                  // canlı sınıf harici girişlerde kullanıcı girişe yönlendirilir
                  // siteye çift girişi engelleyebilmek için otorum kapatılır
                  toast.error('Lütfen  giriş yapınız!')
                  return history.push('/logout')
              }
          }*/
    }

    const handleDrawerOpen = () => {
        setOpen(!open);
    };
    const mediaQueryChanged = () => {
        setOpen(mql.matches);
    }

    let roles = '';
    try {
        roles = localStorage.getItem('roles');
        // bakım modu aktif ise kullanıcıları yönlendiriyoruz.
        if (!roles.indexOf('admin') != -1) {
            if (initials.sac.bakim == 1 && location.pathname != '/bakimdayiz') {
                history.push('/bakimdayiz')
            }
        }
    } catch (e) {

    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const handleProfileMenuOpen = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };
    const handleMobileMenuOpen = event => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    function BilingMenuView() {
        if (!roles)
            return <></>
        if (roles.includes('siteadmin'))
            return <Link to={'/my/billings'}><MenuItem onClick={handleMenuClose}>Faturalandırma</MenuItem></Link>
        return <></>
    }

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <Link to={'/my/profile'}><MenuItem onClick={handleMenuClose}>Hesabım</MenuItem></Link>
            <BilingMenuView/>
        </Menu>
    );

    const ShopIcon = () => {

        return <Link style={{color: "#e5e5e5"}} to={'/my/basket'}>
            <IconButton aria-label="" color="inherit">
                <Badge badgeContent={Array.isArray(basket.products) ? basket.products.length : ''} color="secondary">
                    <ShoppingCartIcon/>
                </Badge>
            </IconButton>
        </Link>
    }

    const MessageIcon = () => {
        return <Link to={'/contact'} style={{color: "inherit"}}>
            <IconButton aria-label="" color="inherit">
                <Badge badgeContent={0} color="secondary">
                    <MailIcon/>
                </Badge>
            </IconButton>
        </Link>
    }
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <OnlineUserCount/>
            </MenuItem>
            <MenuItem>
                <ShopIcon/>
                <Link to={'/my/basket'} style={{color: "inherit"}}><p>Sepet</p></Link>
            </MenuItem>
            <MenuItem>
                <MessageIcon/>
                <Link to={'/contact'} style={{color: "inherit"}}><p>İletişim</p></Link>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label=""
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle/>
                </IconButton>
                <Link to={'/profile'} style={{color: "inherit"}}><p>Profil</p></Link>
            </MenuItem>
            <BakimStartTime/>
        </Menu>
    );
    let nodeId = [];
    const AdminNav = () => {
        try {
            if (roles.includes('admin')) {
                return <Navbar menu={'AdminNavArr'}/>
            } else {
                return <></>
            }
        } catch (e) {
            return <></>
        }
    }
    const SuperAdminNav = () => {
        try {
            if (roles.includes('superadmin')) {
                return <Navbar menu={'SuperAdminArr'}/>
            } else {
                return <></>
            }
        } catch (e) {
            return <></>
        }
    }
    const SiteAdminNav = () => {
        try {
            if (roles.includes('siteadmin')) {
                return <Navbar menu={'SiteAdminArr'}/>

            } else {
                return <></>
            }
        } catch (e) {
            return <></>
        }
    }
    const AllNav = () => {
        return <Navbar menu={'AllNavArr'}/>
    }

    const UserNav = () => {
        return <Navbar menu={'UserNavArr'}/>
    }

    function BaseSearchView() {
        const [baseSearchKw, setBaseSearchKw] = useState('')
        const [searchDatas, setSearchDatas] = useState({videos: []})

        function baseSearchInputChange(e) {
            const kw = e.target.value;
            setBaseSearchKw(kw)
            if (kw.length >= 3) {
                instance
                    .get(`/api/base/search?kw=${kw}`)
                    .then(function (result) {
                        setSearchDatas(result.data)
                    })
            }
        }

        function SearchListVideos() {
            if (searchDatas.videos.length < 1) {
                return <></>
            }
            return <>
                <div className="baseSearchTitle">VİDEOLAR</div>
                <Divider/>
                {searchDatas.videos.map(item => <Grid className="baseSearchText" key={item.id} container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <Link to={`/video/${item.categories[0].parent.id}/${item.categories[0].id}/${item.user_id}/${linkClear(item.categories[0].parent.title)}/${linkClear(item.categories[0].title)}/${item.id}`}>
                            {item.title}
                        </Link>
                    </Grid>
                </Grid>)}</>
        }

        return <div className="baseSearchContent">
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <SearchIcon/>
                </div>
                <InputBase
                    placeholder="Ara..."
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    value={baseSearchKw}
                    onChange={baseSearchInputChange}
                    inputProps={{'aria-label': 'search'}}
                />

            </div>
            <div style={baseSearchKw.length >= 3 ? {display: "block"} : {display: "none"}} className="baseSearchArea">
                <SearchListVideos/>
                <CancelIcon style={{fontSize: 36}} onClick={() => setBaseSearchKw('')} className="baseSearchAreaCloseButton"/>
            </div>

        </div>
    }

    function draweStatus() {
        if (open == true && mql.matches == false) {
            setOpen(false)
        }

    }

    function LogoView() {

        if (logo) {
            return <img width="180" height="48" src={"data:image/png;base64," + logo}/>
        } else {
            return <></>
        }
    }

    function OtherMetaTags() {
        if (config.meta) {
            const tags = config.meta.split(',')
            if (Array.isArray(tags) && tags.length > 0) {
                return tags.map(item => {
                    const tag = item.split('/*')
                    if (Array.isArray(tag) && tag.length > 0) {
                        return <meta name={tag[0]} content={tag[1]}/>
                    } else {
                        return <></>
                    }
                })
            } else {
                return <></>
            }
        } else {
            return <></>
        }
        return <></>

    }

    function SosyalView() {
        function InstagramView() {
            if (config.instagram)
                return <a href={config.instagram}><Icon className="instagram big"/></a>
            return <></>
        }

        function YoutubeView() {
            if (config.youtube)
                return <a href={config.youtube}><Icon className="youtube big"/></a>
            return <></>
        }

        function FacebookView() {
            if (config.facebook)
                return <a href={config.facebook}><Icon className="facebook big"/></a>
            return <></>
        }

        function LinkedinView() {
            if (config.linkedin)
                return <a href={config.linkedin}><Icon className="linkedin big"/></a>
            return <></>
        }

        function TwitterView() {
            if (config.twitter)
                return <a href={config.twitter}><Icon className="twitter big"/></a>
            return <></>
        }

        function TelegramView() {
            if (config.telegram)
                return <a href={config.telegram}><Icon className="telegram plane big"/></a>
            return <></>
        }

        return <>
            <InstagramView/>
            <TwitterView/>
            <FacebookView/>
            <YoutubeView/>
            <LinkedinView/>
            <TelegramView/>
        </>
    }

    function CopyrightView() {
        if (config.copyright === 1)
            return <>&copy; <a href="https://rotasinav.com">rotasinav.com</a></>
        return <></>
    }

    function VersionView() {
        return <>v{initials.sac.version}</>
    }

    if (config === null) {
        return <></>;
    }

    function BakimStartTime() {
        if (initials.sac.bakim_start)
            return <Grid alignContent={"center"} alignItems={"center"} style={{textAlign: "center"}} container>
                <Grid item xs={12} sm={12}>
                    <span style={{color: "#ff5200"}}>
                        Planlı bakım zamanı
                         <Tooltip title="Çalışma bittikten sonra sayfayı yenilemelisiniz.">
                                <Icon className="question circle"></Icon>
                         </Tooltip>
                    </span>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <span style={{color: "#ffb6b9"}}>{initials.sac.bakim_start}</span>
                </Grid>
            </Grid>
        return <></>
    }

    return (<>
            <MetaTags>
                <title>{config.title}</title>
                <meta name="keywords" content={config.keywords}/>
                <meta name="description" content={config.description}/>
                <meta property="og:title" content={config.title}/>
                <meta property="og:description" content={config.description}/>
                <OtherMetaTags/>
            </MetaTags>
            <div className={`${classes.root} `}>

                <CssBaseline/>

                <Drawer
                    style={{position: "relative"}}
                    variant="persistent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                            [classes.sideBar]: open,
                        }),
                    }}
                    open={open}
                >

                    <TreeView

                        className={classesTree.root}
                        defaultExpanded={nodeId}
                        defaultCollapseIcon={<ArrowDropDownIcon/>}
                        defaultExpandIcon={<ArrowRightIcon/>}
                        defaultEndIcon={<div style={{width: 24}}/>}
                    >
                        <SuperAdminNav/>
                        <SiteAdminNav/>
                        <AdminNav/>
                        <AllNav/>
                        <UserNav/>
                        <AllTimer initials={initials}/>
                        <div className="footer">
                            <div className="sosyal">
                                <SosyalView/>
                            </div>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <CopyrightView/>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <VersionView/>
                                </Grid>
                            </Grid>
                        </div>
                    </TreeView>
                </Drawer>
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <div className="logo-content">
                            <Link to={'/'}>
                                <LogoView/>
                            </Link>
                        </div>
                        <Typography className="page-title" variant="h6" noWrap>
                            {pageTitle}
                        </Typography>
                        <BaseSearchView/>
                        <div className={classes.grow}/>
                        <div className={classes.sectionDesktop}>
                            <BakimStartTime/>
                            <OnlineUserCount/>
                            <ShopIcon/>
                            <MessageIcon/>
                            <IconButton
                                edge="end"
                                aria-label=""
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit"
                            >
                                <AccountCircle/>
                            </IconButton>
                        </div>
                        <div className={classes.sectionMobile}>
                            <IconButton
                                aria-label="Devamı"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                            >
                                <MoreIcon/>
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                {renderMobileMenu}
                {renderMenu}

                <main onClick={() => draweStatus()} className={`${classes.content} main-class`}>
                    <div className={open && !mql.matches ? "main-disabled" : ""}>
                        <img className={open && !mql.matches ? "mask-display" : "mask-disabled"} src={mask}></img>
                        <Bread/>
                        {roles && (roles.indexOf('siteadmin') != -1 || roles.indexOf('superadmin') != -1) && initials.bakiye < 100 &&
                        <Alert color={"error"}>Bakiyeniz : {initials.bakiye.toFixed(2)}₺, 100₺'nin altına düştüğü için bu uyarıyı alıyorsunuz, sitenizin kapanmaması için lütfen bakiyenizi arttrın.</Alert>}
                        <div style={{paddingTop: 10}}>
                            <Switch>
                                <Route path={'/product/page/:id'} component={ProductPage}/>
                                <Route path={'/video/:pId/:id/:pTitle/:cTitle/:videoId'} component={VideoPlayerPage}/>
                                <Route path={'/watch/:videoId'} component={VideoPlayerPage}/>
                                <Route path={'/videos/:pTitle/:cTitle/:pId/:id/:videoId'} component={VideoPlayerPage}/>
                                <Route path={'/videos/:pTitle/:cTitle/:pId/:id'} component={VideoPlayerPage}/>
                                <Route path={'/categories/:pTitle/:id'} component={AllCategories}/>
                                <Route path={'/categories/:id'} component={AllCategories}/>
                                <Route path={'/repass/:code'} component={RepassUp}/>
                                <Route path={'/repass'} component={Repass}/>
                                <Route path={'/sartlar'} component={Sartlar}/>
                                <Route path={'/bakimdayiz'} component={Bakimdayiz}/>
                                <Route path={'/admin'} component={AdminRoute}/>
                                <Route path={'/superadmin'} component={SuperAdminRoute}/>
                                <Route path={'/my'} component={Home}/>
                                <Route path={'/sinav'} component={Home}/>
                                <Route path={'/montly/files'} component={MontlyFiles}/>
                                <Route path={'/sinavlar'} component={Home}/>
                                <Route path={'/canli'} component={Home}/>
                                <Route path={'/contact'} component={Contact}/>
                                <Route path={'/join/:id'} component={UrlJoin}/>
                                <Route path={'/online/users'} component={OnlineUsers}/>
                                <Route path={'/shopier/order/success'} component={ShopierOrderSuccess}/>
                                <Route path={'/'} component={HomePage}/>
                            </Switch>
                        </div>
                    </div>
                    <div style={{clear: "both"}}></div>

                </main>

            </div>
            {/* <div className="footer">
                <Grid container itemalignContent={"center"} justify={"center"} alignItems={"center"}>
                    <Grid item xs={12} sm={4}>
                        <h3>COPRIGHT</h3>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <h3>İLETİŞİM</h3>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <h3>SON EKLENEN DERSLER</h3>
                    </Grid>
                </Grid>
            </div>*/}

        </>
    );
}
