import _ from "lodash";

export default function billingCalculate(site) {
    const payments = [];
    const costa = [];
    const bbb_cost = [];
    const sinav_cost = [];
    const sinav_sayi = [];
    site.payments.map(item => payments.push(parseFloat(item.amount)))
    site.billing_logs.map(item => costa.push(parseFloat(item.price)))
    site.billing_logs.map(item => bbb_cost.push(parseFloat(item.bbb_price)))
    site.billing_logs.map(item => sinav_cost.push(parseFloat(item.sinav_price)))
    site.billing_logs.map(item => sinav_sayi.push(parseFloat(item.sinav_sayi)))
    const totalPayment = _.sum(payments).toFixed(2);
    const bbbCost = _.sum(bbb_cost).toFixed(2);
    const cost = _.sum(costa).toFixed(2);
    const sinavCost = _.sum(sinav_cost).toFixed(2);
    const sinavSayi = _.sum(sinav_sayi);
    const totalCost = (parseFloat(cost) + parseFloat(bbbCost) + parseFloat(sinavCost)).toFixed(2);
    const kalan = (totalPayment - totalCost).toFixed(2);
    return {payment: totalPayment, totalCost: totalCost, kalan: kalan, bbbCost: bbbCost, cost: cost, 'sinavCost': sinavCost, sinavSayi: sinavSayi}
}
