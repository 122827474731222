import React, {useContext, useEffect, useState} from 'react';
import './App.css';
import {HashRouter as Router, Switch, Route} from 'react-router-dom';
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import './i18n';
import Register from "./pages/Register";
import {BasketContext} from "./stores/Basket";
import instance from './apis';
import {ToastContainer} from "react-toastify";
import {PageStore} from "./stores/PageStore";
import Main from "./pages/Main";
import {hot} from 'react-hot-loader/root';
import {InitialsContext, ConfigContext, LogoContext, NoticeContext} from "./Store";
import {CircularProgress, Grid} from "@material-ui/core";

function App() {
    const [, setBasket] = useContext(BasketContext)
    const [, setInitials] = useContext(InitialsContext)
    const [, setConfig] = useContext(ConfigContext)
    const [, setLogo] = useContext(LogoContext)
    const [, setNotice] = useContext(NoticeContext)
    const [load, setLoad] = useState(true)
    useEffect(() => {
        instance.get('/api/baskets')
            .then(function (res) {
                if (res.data) {
                    if (res.data.msg != 'Unauthenticated.')
                        setBasket(res.data)
                }
            })
        getConfig()
    }, [])

    function getConfig() {
        instance.get('/api/initials')
            .then(function (res) {
                if (res.data) {
                    setInitials(res.data);
                    if (res.data.config != null) {
                        setConfig(res.data.config);
                        setLogo(res.data.logo)
                        setNotice(res.data.notice)
                    }
                    setLoad(false)
                }
            })
    }

    if (load)
        return <Grid style={{height: "100vh", width: "100%"}} container alignContent={"center"} alignItems={"center"} justify={"center"}>
            <Grid style={{width: "100%"}} item xs={12} sm={12}>
                <CircularProgress className="appProgress"/>
            </Grid>
        </Grid>
// pre-static build suspense desteklemyor
    return <>
        <Router>
            <Switch>
                <Route path={'/login'} component={Login}/>
                <Route path={'/register'} component={Register}/>
                <Route path={'/logout'} component={Logout}/>
                <PageStore>
                    <Route path={'/'} component={Main}/>
                </PageStore>
            </Switch>
        </Router>
        <ToastContainer position="top-right"/>
    </>;
}

export default hot(App);
