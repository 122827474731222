import React, {useCallback, useMemo, useContext} from 'react';
import {Grid, TextField, Input, inputChange, Button} from '@material-ui/core';
import {useDropzone} from 'react-dropzone'
import { BBBContext } from '../../../../stores/BBBCreate';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };

  const activeStyle = {
    borderColor: '#2196f3'
  };

  const acceptStyle = {
    borderColor: '#00e676'
  };

  const rejectStyle = {
    borderColor: '#ff1744'
  };

const DokumanUpload = (props) => {

    const id = props.match.params.id;
    const { dokumanSelectedFile, setDokumanSelectedFile, input, setInputs } = useContext(BBBContext)

    const onDrop = useCallback(acceptedFiles => {
        setDokumanSelectedFile(acceptedFiles)
    }, [])
    const {getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject} = useDropzone({onDrop})
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
      }), [
        isDragActive,
        isDragReject
      ]);
    return (
        <>
            <Grid container spacing={1} direction="row">
                <Grid item xs={12} sm={12}>
                    <TextField
                        label="Döküman Adı/Açıklaması"
                        margin="normal"
                        name={"dokuman_aciklama"}
                        defaultValue={input.dokuman_aciklama}
                        onChange={(e) => {
                            setInputs({...input, dokuman_aciklama: e.target.value})
                        }}
                        fullWidth={true}
                    />
                </Grid>
                <Grid xs={12} sm={12}>
                    <div {...getRootProps({style})} >
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                            <p>Dosyanızı bu alana bırakın...</p> :
                            <p>Doküman dosyasını bu alana sürükleyip bırakarak yükleyebilirsiniz</p>
                        }
                    </div>
                </Grid>
            </Grid>
        </>
    );
}


export default DokumanUpload;
