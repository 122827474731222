import React, {useEffect, useState, useContext} from 'react';

import {Paper, Button, TextField, Grid, MenuItem} from "@material-ui/core";
import instance from '../../../apis';
import {toast} from "react-toastify";
import {BreadsContext, PageContext, PageTitleContext} from "../../../stores/PageStore";
import {useParams, Link, useHistory} from 'react-router-dom';
import SinavRows from "./SinavRows";
import moment from "moment";

const Add = () => {
    const history = useHistory()
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    const [sinav, setSinav] = useState({});
    const [sinavs, setSinavs] = useState([]);
    const {sinav_id} = useParams()
    useEffect(() => {
        instance.get(`/api/admin/sinav`)
            .then(function (res) {
                setSinavs(res.data)
            })

    }, [])
    useEffect(() => {
        if (sinav_id) {
            instance.get(`/api/admin/sinav/${sinav_id}`)
                .then(function (res) {
                    setSinav(res.data)
                })

        }
    }, [sinav_id])

    useEffect(() => {
        setPageTitle('Sınavlar')
        const breads = [
            {
                link: null,
                title: 'Yeni Sınav Ekle',
                last: true
            }
        ]
        setBreads(breads)
        setPage(13)
    }, [])

    function submitForm(e) {
        const target = e.target
        e.preventDefault()
        const url = sinav.id ? `/api/admin/sinav/${sinav.id}` : '/api/admin/sinav';
        const formData = new FormData(target)
        instance.post(url, formData)
            .then(function (res) {
                toast.success('Sınav bilgileri kaydedildi')
                setSinavs(res.data)
                //setSinav(res.data.filter(x => x.id == sinav.id)[0])
            })
    }

    function yeniSinav() {
        setSinav({})
        history.push('/admin/esinav/add')
    }

    function sinavDelete(id) {
        if (global.confirm('Silmek istediğinizden emin misiniz?'))
            instance
                .delete(`/api/admin/sinav/${id}`)
                .then(function () {
                    const res = sinavs.filter(x => x.id != id)
                    setSinavs(res)
                })
    }

    const AddNewLessonButton = () => {
        if (!sinav.id) return <></>
        return <Button color={"secondary"} size={"small"} style={{float: "right"}} variant={"outlined"} onClick={() => yeniSinav()}>Yeni Sınav Ekle</Button>
    }
    const Context = () => {
        return <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <Paper className="paper-handler">
                        <AddNewLessonButton/>

                        <form onSubmit={submitForm}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        size={"small"}
                                        variant={"outlined"}
                                        required
                                        defaultValue={sinav.name}
                                        name="name"
                                        label="Sınav İsmi"
                                        fullWidth={true}
                                        autoComplete="name"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        size={"small"}
                                        variant={"outlined"}
                                        required
                                        defaultValue={sinav.sorular_count ? sinav.sorular_count : 75}
                                        name="count"
                                        label="Soru Sayısı"
                                        fullWidth={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        variant={"outlined"}
                                        id="date"
                                        label="Başlangıç"
                                        type="datetime-local"
                                        defaultValue={sinav.tarih ? moment(sinav.tarih)
                                            .format('YYYY-MM-DDTHH:mm') : moment()
                                            .format('YYYY-MM-DDTHH:mm')}
                                        name="tarih"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        variant={"outlined"}
                                        id="bitis"
                                        label="Bitiş"
                                        type="datetime-local"
                                        defaultValue={sinav.bitis ? moment(sinav.bitis)
                                            .format('YYYY-MM-DDTHH:mm') : moment()
                                            .format('YYYY-MM-DDTHH:mm')}
                                        name="bitis"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        size={"small"}
                                        variant={"outlined"}
                                        defaultValue={sinav.cron ? sinav.cron : -1}
                                        name={"cron"}
                                        fullWidth
                                        select>
                                        <MenuItem value={-1}>Sınavı bitiş tarihine göre otomatik tamamla</MenuItem>
                                        <MenuItem value={1}>Sınav Tamamlandı</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Button type="submit" variant={"contained"} color={"secondary"} fullWidth={true}>SINAV BİLGİLERİNİ KAYDET</Button>
                                </Grid>
                            </Grid>

                        </form>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Paper className="paper-handler">
                        <Grid container>
                            <SinavRows sinavs={sinavs} sinavDelete={sinavDelete}/>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    }


    return <Context/>

}

export default Add;
