import React, {useContext, useEffect, useState, forwardRef, createRef} from 'react';
import {Grid, Button, Divider, Paper} from '@material-ui/core';
import instance from '../../apis';
import {useParams} from 'react-router-dom';
import {BreadsContext, PageContext, PageTitleContext} from "../../stores/PageStore";
import useInterval from '@use-it/interval';
import {toast} from 'react-toastify';
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Brans from "../users/Brans";

function Optik() {
    const {sinav_id} = useParams()
    const [cevaplar, setCevaplar] = useState([])
    const [autoSave, setAutoSave] = useState(false)
    const [wait, setWait] = useState(true)
    const [render, setRender] = useState(false)
    const [load, setLoad] = useState(true)
    const [sinav, setSinav] = useState({})
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    const [second, setSecond] = useState(0);

    const BreadsLoad = forwardRef(() => (
        <CircularProgress style={{
            width: 8,
            height: 8
        }}/>
    ))
    const ref = createRef()
    useEffect(() => {
        setLoad(true)
        instance
            .get(`/api/sinav/user/cevaplar/${sinav_id}`)
            .then(function (result) {
                result.data.map(item => {
                    cevaplar[item.soru_id] = item.val
                })
                setCevaplar(cevaplar)
                setLoad(false)
            })
        instance
            .get(`/api/sinav/${sinav_id}`)
            .then(function (result) {
                setSinav(result.data)
                setLoad(false)
            })
    }, [])
    useEffect(() => {
        const breads = [
            {
                link: '/sinavlar/all',
                title: 'Sınavlar',
                last: null
            },
            {
                link: null,
                title: load ? <BreadsLoad ref={ref}/> : `${sinav.name} / Cevaplarınız`,
                last: true
            },

        ];
        setBreads(breads)
        setPage(17)
        setPageTitle('E-Sınav Sistemi')

    }, [sinav])
    setTimeout(() => {
        setWait(false)
    }, 3000)
    useInterval(() => {
        if (autoSave) {
            save()
        }
    }, 20000);

    function dksn(sn) {
        const gun = Math.trunc(sn / (3600 * 24));
        const saat = Math.trunc((sn % (3600 * 24)) / 3600);
        const dk = Math.trunc(((sn) % 3600) / 60);
        const second = Math.trunc((sn % 60))
        const dkView = dk < 10 ? `0${dk}.` : `${dk}.`;
        const snView = second < 10 ? `0${second}` : second;
        const sa = saat ? `${saat}.` : '00.';
        const saView = sa < 10 && sa != 0 && sa != '' ? `0${sa}` : sa;
        const View = () => {
            function Tarih() {
                return <span style={{fontSize: 32}}>{`${saView}${dkView}${snView}`}</span>
            }

            if (gun > 0) {
                return <>
                    Kalan Süre
                    <div style={{
                        fontSize: 16,
                        color: "red"
                    }}>{gun} Gün
                    </div>
                    <Tarih/>
                </>
            } else {
                return <Tarih/>
            }
        }
        return View;
    }

    useInterval(() => {
        if (moment(sinav.tarih)
            .unix() > moment()
            .unix()) {
            setSecond(dksn(moment(sinav.tarih)
                .unix() - moment()
                .unix()))
        } else {
            setSecond(0)
            setRender(true)
        }
    }, 1000)
    if (second != 0) {
        return <>
            <div style={{textAlign: "center"}}>Sınavın başlamasına
                <Divider/>
                {second}
            </div>
        </>
    }

    function save(set = {}) {
        setAutoSave(false)
        const now = moment()
            .format('x');
        const sinavBitis = moment(sinav.bitis)
            .format('x')
        if (sinav.status && sinav.status.tek != 1) {// tek seferlik süresiz izin
            if ((sinav.bitis && (sinavBitis < now))) {
                return alert('Üzgünüz  bu sınavın süresi doldu');
            }
            if (sinav.status && sinav.status.bitir == 1) {
                return alert(`Üzgünüz  bu sınav için ${moment(sinav.status.bitir_date)
                    .format('DD/MM/YYYY HH:mm')} tarihinde cevaplarınız kaydedildi.`);
            }
        }


//set ==1 ise sınavı bitir
        const bitir = set.bitir ? `?bitir=${set.bitir}` : '';
        if (bitir != '' && !global.confirm('Sınavı bitirmek istediğinizden emin misiniz?')) {
            return;
        }

        instance
            .post(`/api/sinav/user/cevaplar/${sinav_id}${bitir}`, {data: {...cevaplar}})
            .then(function (result) {
                if (result.status == 210) {
                    toast.error(result.data.msg)
                } else {
                    toast.success('Değişiklikler kaydedildi.')
                }
            })
    }

    if (second != 0 || load || wait) return <CircularProgress/>

    if (!sinav.sorular) return <></>
    const TableView = () => {
        const cevapSec = (cevap, soru_id) => {
            setAutoSave(true)
            cevaplar[soru_id] = cevap
            setCevaplar(cevaplar)
            setRender(!render)
        }

        const cevaps = ['A', 'B', 'C', 'D', 'E']
        return sinav.sorular.map(soru => <Grid container className={'optikRow'} key={soru.id}>
            <Grid item xs={2} sm={2}
                  className={`optikItem optikSoru  ${cevaplar[soru.id] == null ? 'optikActiveSoru' : 'optikActiveSoruNull'}`}
                  onClick={() => cevapSec(null, soru.id)}>{soru.no}</Grid>
            {cevaps.map(cevap => <Grid item xs={2} sm={2}
                                       onClick={() => cevapSec(cevap, soru.id)}
                                       key={`${soru.id}_${cevap}`}
                                       className={`optikItem optikCevap  ${cevaplar[soru.id] == cevap ? 'optikActiveCevap' : ''}`}>{cevap}</Grid>)}
        </Grid>)
    }

    return <Paper className="paper-handler">
        <Brans/>
        <TableView/>
        <Divider style={{marginTop: 10}}/>
        <Button fullWidth variant={"contained"} color={"primary"} onClick={() => save({bitir: 1})}>SINAVI BİTİR</Button>
    </Paper>;
}

export default Optik;
