import React, {useContext, useEffect, useState} from 'react';
import instance from "../../../apis";
import {Grid, Paper, Divider, IconButton} from "@material-ui/core";
import {Link} from "react-router-dom"
import {BreadsContext, PageContext, PageTitleContext} from "../../../stores/PageStore";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit"
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ArchiveIcon from '@material-ui/icons/Archive';
import billingCalculate from "../../../functions/billingCalculate";
import AddCircleIcon from '@material-ui/icons/AddCircle';

const Sites = () => {
    const [sites, setSites] = useState([])
    const [, setBreads] = useContext(BreadsContext);
    const [pageTitle, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    useEffect(() => {
        instance
            .get('/api/superadmin/sites')
            .then(function (res) {
                setSites(res.data.items)
            })
    }, [])
    useEffect(() => {
        const breads = [
            {
                link: null,
                title: 'Siteler',
                last: true
            }
        ]
        setBreads(breads)
        setPageTitle('Satın Alma Ayarları')
        setPage(52)
    }, [])

    function siteBand(site) {
        if (!site.band)
            return 0;
        let size = site.band.size / 1073741824
        size = size.toFixed(2)
        return `${size} GB`
    }

    function PaymentsView({site}) {
        const billing = billingCalculate(site)
        return <>
            <Grid item xs={3} sm={3}>{billing.payment} ₺</Grid>
            <Grid item xs={3} sm={3}>{billing.cost} ₺</Grid>
            <Grid item xs={3} sm={3}>{billing.kalan} ₺</Grid>
        </>
    }

    return <>
        <Grid container>
            <Grid item xs={12} sm={12} style={{textAlign: "right"}}>
                <Link to={`/superadmin/sites/0`}>
                    <IconButton color={"primary"}><AddCircleIcon style={{fontSize: 32}}/></IconButton>
                </Link>
            </Grid>
        </Grid>
        <Grid container spacing={1} container alignItems={"center"} alignContent={"center"}>
            <Grid item xs={12} sm={12}>
                <Paper className="paper-handler">
                    <Grid container spacing={1} className={"largeHead"} style={{fontWeight: "bold", fontSize: 13}}>
                        <Grid item xs={12} sm={2}>
                            Sahip
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid container>
                                <Grid item xs={3} sm={3}>Bandwith</Grid>
                                <Grid item xs={3} sm={3}>Ödeme</Grid>
                                <Grid item xs={3} sm={3}>Harcama</Grid>
                                <Grid item xs={3} sm={3}>Kalan</Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            e-posta/Telefon
                        </Grid>
                        <Grid item xs={12} sm={2}>Site Adresi</Grid>
                        <Grid item xs={4} sm={1}>Onay Tarihi</Grid>
                        <Grid item xs={8} sm={1} style={{textAlign: "center"}}>
                            İşlemler
                        </Grid>
                    </Grid>
                    {sites.map(site => <Grid container spacing={1} alignItems={"center"} alignContent={"center"}>
                        <Grid item xs={12} sm={2}>
                            {!site.user ? 'YOK' : site.user.name}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid container>
                                <Grid item xs={3} sm={3}>{siteBand(site)}</Grid>
                                <PaymentsView site={site}/>
                            </Grid>

                        </Grid>
                        <Grid item xs={12} sm={2}>
                            {!site.user ? '' : site.user.email}<br/>
                            {!site.user ? '' : site.user.tel}
                        </Grid>
                        <Grid item xs={12} sm={2}>{site.client}</Grid>
                        <Grid item xs={4} sm={1}>{moment(site.tarih).format('DD.MM.YYYY')}</Grid>
                        <Grid item xs={8} sm={1}>
                            <Grid container container spacing={1} alignItems={"center"} alignContent={"center"}>
                                <Grid item xs={4} sm={4}>
                                    <Link
                                        to={`/superadmin/sites/${site.id}`}
                                    >
                                        <IconButton><EditIcon/></IconButton>
                                    </Link>
                                </Grid>
                                <Grid item xs={4} sm={4}><DeleteForeverIcon/></Grid>
                                <Grid item xs={4} sm={4}><ArchiveIcon/></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}><Divider/></Grid>
                    </Grid>)}
                </Paper>
            </Grid>
        </Grid>
    </>
}

export default Sites;
