import React, {useEffect, useContext} from 'react';
import {BreadsContext, PageContext, PageTitleContext} from "../../../../stores/PageStore";
import { BBBContext, BBBProvider, BBBConsumer } from '../../../../stores/BBBCreate';
import List from './List';

const Index = (props) => {
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);

    useEffect(() => {
        const breads = [
            {
                link: '/admin/bbb/list',
                title: 'Canlı Sınıf Listesi',
                last: true
            },
        ];
        setBreads(breads)
        setPage(20.2)
        setPageTitle('Canlı Sınıf Listesi')
    }, [])

    const users = [];

    return <> 
    <BBBProvider users={users}>
        
        <List props={props} />
        
      </BBBProvider>
    </>

}

export default Index;
