import React, {useEffect, useContext} from 'react';
import {BreadsContext, PageTitleContext} from "../stores/PageStore";
import {ConfigContext} from "../Store";


const Sartlar = () => {
    const siteName = window.location.host;

    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [config] = useContext(ConfigContext)
    useEffect(() => {
        const breads = [{
            link: null,
            title: 'Site kullanım şartları',
            last: true
        }];
        setPageTitle('Hizmet Şartları')
        setBreads(breads)
    }, [])
    const Context = () => {
        return <>
            <div>
                <p><strong>{siteName} {config.title} ÜYELİK ve SÖZLEŞMESİ</strong></p>
                <p>İşbu Üyelik sözleşmesi site kurallarını düzenlemekte olup bu sözleşme kabul edilmeden siteye Üye olmak mümkün değildir. İşbu Üyelik sözleşmesi ile, aşağıda belirtilen site kullanım kurallarına uyacağınızı
                    kabul ve taahhüt etmiş
                    olacağınızdan dikkatle okunması gerekmektedir. Bu aşamayı geçmeniz hali aşağıdaki maddeleri kabul ettiğiniz anlamına gelir.</p>
                <p><strong>1.GİRİŞ</strong></p>
                <p>Bu site {siteName} tarafından işletilmektedir ve sitenin yapımı ile ilgili içerdiği malzemelerin tüm hakları {siteName}'e aittir. Bu sitedeki kod, yazılım, veritabanı ve veritabanındaki tüm
                    bilgilerin malzemelerin
                    tüm
                    hakları {siteName}'e aittir ve bu bilgiler uluslararası telif hakları ve ticari marka kanunları tarafından korunmaktadır. Bu bilgileri değiştiremez, kopyalayamaz, çoğaltamaz, yeniden yayımlayamaz,
                    başka bir bilgisayara
                    yükleyemez, postalayamaz, iletemez ya da dağıtamazsınız.</p>
                <p><strong>2.GENEL KURALLAR</strong></p>
                <ol>
                    <ol>
                        <li>{siteName}'e Üye olurken vereceğiniz kişisel bilgiler başka bir amaç için kullanılamaz, diğer kişi ve kurumlara verilemez.
                            <li>{siteName} Üyeliği kişiseldir. Başkasına ödünç verilemez, devredilemez. Bu durumda oluşabilecek olan sorunlardan {siteName} sorumlu değildir.</li>
                            <li>{siteName} 'e Üye olurken seçtiğiniz şifre sadece sizin tarafınızdan bilinmektedir. Şifrenizin seçimi ve korunması size aittir. {siteName} şifre kullanımından doğacak
                                sorunlardan sorumlu
                                değildir.
                            </li>
                            <li>{siteName}'a Üye olurken verilen bilgilerin doğru ve geçerli bilgiler olduğu kabul edilir. Ancak, bilgilerin doğru olmadığı açık bir şekilde belli olan durumlarda (örn.
                                Adı - Soyadı kısmına
                                girilen Türkçe isim olmadığı kabul görecek girişler) {siteName}, bu Üyenin Üyeliğini askıya alma hakkını saklı tutar.
                            </li>
                            <li>{siteName}'da oluşturulan bağlantı sayfası bilgileri tüm {siteName} Üyelerine açıktır. Bu alanlarda özel bilgi vermemek, sayfalar 24 saat editörlerimizce kontrol edilse
                                de, Üyelerin
                                sorumluluğundadır.
                            </li>
                            <li>{siteName} üzerinden verilen hizmetlerden faydalanan internet kullanıcılarının yazılımlarına ve verilerine izinsiz olarak ulaşılması ve/veya bunların kullanılması, üçüncü kişi
                                ve kuruluşların
                                kullanımına sunulması halinde {siteName} sorumluluk kabul etmez. Moderatör ve {siteName} haricinde bir kullanıcı, başka bir kullanıcının forum ve web alanlarını, istediği gibi
                                kullanmasını engelleyemez.
                            </li>
                        </li>
                    </ol>
                </ol>
                <p><strong>3.WEB SİTESİ KULLANIM KURALLARI</strong></p>
                <p>Kullanıcılar, aralarında etkileşimi mümkün kılan fonksiyonları kullanırken aşağıdaki koşullara uymayı kabul etmiş sayılır. Üyeliğin ardından kullanıcının bu kurallara uymadığına dair bir başka Üyeden {siteName}
                    yönetimine
                    şikâyet gelmesi ya da {siteName} tarafından böyle bir durumun tespiti durumunda, kullanıcının gönderdiği tüm mesaj ve bilgiler {siteName} tarafından incelenebilir, forum yazıları ve mesajları
                    herhangi bir uyarıda
                    bulunulmadan silinebilir; bu kişinin Üyeliği askıya alınabilir ve gerekirse yasal prosedür için bu bilgiler ilgili kurumlarla paylaşılabilir. {siteName} her zaman için
                    tek taraflı olarak
                    verilen
                    hizmeti sürekli veya geçici durdurabilir.</p>
                <ol>
                    <ol>
                        <li>Kullanıcı suç teşkil edecek, yasal açıdan takip gerektirecek ya da yerel, ülke çapında ya da uluslararası düzeyde yasalara ters düşecek, ya da yalnızca diğer bir kullanıcıyı rahatsız
                            edecek bir durum
                            yaratan, ya da bu durumlardan herhangi birini teşvik eden, hiçbir tür yasadışı, tehditkâr, hakaret, küfür içeren, küçük düşürücü, kaba, pornografik, rahatsız edici ya da ahlaka aykırı bilgi
                            postalayamaz ya da iletemez.
                        </li>
                        <li>Kullanıcı bir başkasının gizlilik hakkını ya da yayın haklarını çiğneyen ya da telif hakları, ticari marka hakları veya başka mülkiyet hakları tarafından korunan ya bu tarif edilen
                            sınıflara giren
                            malzemelerden uyarlananlar da dahil olmak üzere; sahibinden ya da haklarını elinde tutandan önceden izin almaksızın, başkalarının haklarını çiğneyen ya da ters düşen, hiç bir bilgi, yazılım ya da
                            başka malzeme
                            postalayamaz
                            ya da iletemez.
                        </li>
                        <li>Bir virüs veya başka bir zararlı unsur içeren hiç bir tür bilgi, yazılım ya da başka bir malzeme postalayamaz ya da iletemez.</li>
                        <li>{siteName} site dahilindeki interaktif alanlarda Üyelerin yazdıklarından sorumlu değildir; buralarda yazılan görüşleri paylaşmak durumunda değildir. {siteName} üzerinde
                            siyasi ve dini
                            içerikli
                            yazışmalar ve tartışmalar kesinlikle yasaktır. {siteName} link verdiği, banner tanıtımı yaptığı sitelerin içeriklerinden sorumlu değildir.
                        </li>
                        <li>{siteName} üzerinde direk pazarlama faaliyetleri yalnızca izin verilen forumların panolarında kısa duyurular şeklinde yapılabilir. Her türlü MLM / Çok Katmanlı Pazarlama
                            faaliyeti ile SPAM mesaj
                            gönderimi kesinlikle yasaktır.
                        </li>
                        <li>{siteName}, Üyelerinin verdiği ve veri tabanında bulunan bilgileri, istatistiksel bilgilere dönüştürmek, reklam ve pazarlama alanında kullanmak, site kullanıcılarının genel
                            eğilimlerini
                            belirlemek,
                            içeriğini ve servislerini zenginleştirmek için kullanabilme hakkına sahiptir ancak kişilere ait bilgiler tek tek kullanılmayacaktır.
                        </li>
                        <li>Teknik aksaklıklardan dolayı {siteName} te hata ya da kesinti olabilir. {siteName}, hizmetin bu şekilde kesilmesinden sorumlu değildir.</li>
                    </ol>
                </ol>
                <p><strong>4.ÜYELİK</strong></p>
                <ol>
                    <ol>
                        <li>{siteName} 'e Üye olurken verilen e-posta adresinin Üyeye ait ve aktif bir e-posta adresi olma zorunluluğu vardır. Üyelikle ilgili tüm yazışmalarda sisteme Üye olurken verilen
                            e-posta adresi esas
                            alınır ve bu adres kullanılır. {siteName} verilen e-postanın doğruluğuna inanır ve buna göre davranır. Yanlış ve yalan beyan edilen e-postalardan oluşabilecek hatalardan {siteName}
                            sorumlu değildir.
                        </li>

                        <li>Bu sayfada yer alan maddeler, değişikliklerin bu sayfadan belirtilmesi şartıyla {siteName} tarafından istendiği zaman değiştirilebilir. {siteName} her zaman için söz
                            konusu tarifede, {siteName} internet sitesinde ve Üyelik durumlarında tek taraflı olarak değişiklik yapabilir. {siteName} 'un internet, e-posta, www servisi ile yayınladığı tarifeler yeterli bir
                            bilgilendirme
                            kapsamındadır.
                        </li>
                        <li>Sisteme üye olduktan sonra bazı hizmetlerden yararlanmak ücrete tabidir. Ücret kullananın isteği doğrultusunda üyelik yükseltmeye yönelik talep edilir.</li>
                    </ol>
                </ol>
                <ol>
                </ol>
                <p><strong>5.GÜVENLİK</strong></p>
                <ol>
                    <ol>
                        <li>{siteName} güvenlik konusunda son derece titiz çalışmaktadır.</li>
                        <li>{siteName}'te iletişim bilgileri hem online hem de offline durumundayken koruma altında bulunmaktadır. Kullanıcı ve Üye bilgileri üçüncü şahısların gözünden ve müdahalesinden</li>
                        korunmaktadır.
                        İletişim
                        bilgilerinizi, ancak sizin izin verdiğiniz bağlantılarınız, izin verdiğiniz sürece görebilir.
                        <li>Kendi güvenliğinizle ilgili olarak diğer Üyelere göndereceğiniz mesajlarda asla kredi kartı numaranızı yazmayınız. {siteName}, gönderdiğiniz mesajlarla aktarılan bilgilerin</li>
                        alıcı tarafındaki
                        güvenliğini hiçbir koşulda garanti edemez.
                        <li>Gizliliğinizle ilgili olarak {siteName}, Gizlilik Politikası sayfasındaki başlıkları güvence altına alır.</li>
                    </ol>
                </ol>

                <p><strong>6.DOSYA PAYLAŞIMI</strong></p>
                <ol>
                    <ol>
                        <li>Telif hakkı olan dosyaların paylaşımı yasaktır.</li>
                        <li>Telif hakkı olduğu bildirilen yada telif hakkı olduğu tespit edilen dosyalar uyarı verilmeden silinecektir.</li>
                        <li>Her dosyadan yükleyen kişi sorumludur. {siteName} sitesi hiç bir şekilde sorumluluk almaz</li>
                    </ol>
                </ol>
                <p><strong>7.KİŞİSEL VERİLERİN KORUNMASI VE İŞLENMESİ</strong></p>
                <ol>
                    <li><a target="_blank" href="http://www.mevzuat.gov.tr/MevzuatMetin/1.5.6698.pdf">Bilgi güvenliği yasası gereği</a> {siteName} girdiğiniz tüm kişisel bilgileri ve öğrencilere ait bilgileri 3. şahıslarla
                        paylaşılmamasını taahhüt
                        eder.
                    </li>
                    <li><b>KİŞİSEL VERİLERİN KORUNMASI KANUNU</b>
                        <p> (<em> <b>MADDE 5</b>
                            <b>f) </b>İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri
                            işlenmesinin zorunlu olması.
                        </em>)
                        </p>
                        İlgili yasa gereği {siteName} ve Üyeler Kişisel bilgileri işleyebilirler. Fakat hiçbir suretle paylaşımını yapamazlar.
                    </li>
                    <li>
                        {siteName}.com üyeleri işledikleri kişisel verileri hiç bir suretle paylaşamaz.
                    </li>
                    <li>{siteName}.com üyeleri birbirlerinin verilerini asla göremezler, bu durum Yönetici-Öğretmen ilişkisi için geçerli değildir.</li>
                    <li>{siteName} hiç bir kişisel bilginin farklı amaçla saklanmasını ve dağıtılmasını tasvip etmez. Aksi şekilde davranan üyelerden sorumlu tutulamaz.</li>
                    <li>{siteName} üyelerin internet kafelerde yada halka açık alanlarda ve yerlerde oturumu açık bırakılan bilgisayarlardan kişisel bilgilerin sızdırılmasından sorumlu değildir.</li>
                </ol>
                <p><strong>8.ÜYELİĞİN KULLANILMAMASI</strong></p>
                <p>
                    {siteName} 'e Üye olarak kabul etmiş sayılacağınız, yukarıdaki kuralları içeren anlaşma, sistemi kullandığınız sürece geçerlidir.
                </p>
                <p>Sistemi kullanmadığınız takdirde anlaşma hükümleri geçersizdir.</p>
            </div>
        </>
    }

    return <Context/>

}

export default Sartlar;
