import React, {useContext, useEffect, useState} from 'react';
import instance from '../apis';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Item from "./categories/Item";
import {breadLink} from "../functions/trCase";
import {BreadsContext, PageContext, PageTitleContext} from "../stores/PageStore";

const HomePage = () => {
    const [load, setLoad] = useState(true);
    const [categories, setCategories] = useState([]);
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    useEffect(() => {
        instance.get('/api/categories/all')
            .then(function (res) {
                setCategories(res.data);
                setLoad(false)
            })

    }, []);
    useEffect(() => {
        const breads = [];
        setBreads(breads)
        setPageTitle('Tanımlı Derslerim')
        setPage(-1)
    }, [])

    if (load) {
        return <CircularProgress/>
    }
    const Category = () => {
        return categories.map(item => <Item sub={item.parent_id ? true : false} link={'categories'} item={item}/>)
    };
    return <Grid container spacing={2}>
        <Category/>
    </Grid>
};

export default HomePage;
