import React from 'react';
import {Switch, Route} from 'react-router-dom';
import Add from "./Add";
import Bagla from "./Bagla";

function KazanimRoutes() {
    return (
        <Switch>
            <Route path={'/admin/esinav/kazanims/bagla/:sinav_id'} component={Bagla}/>
            <Route path={'/admin/esinav/kazanims/add/:sinav_id/:konu_id/:kazanim_id'} component={Add}/>
            <Route path={'/admin/esinav/kazanims/add/:sinav_id/:konu_id'} component={Add}/>
            <Route path={'/admin/esinav/kazanims/add/:sinav_id'} component={Add}/>
        </Switch>
    )
}

export default KazanimRoutes;