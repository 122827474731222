import React, {useContext, useEffect, useState} from 'react';
import instance from "../../../apis";
import {BreadsContext, PageContext, PageTitleContext} from "../../../stores/PageStore";
import {useParams, Link} from 'react-router-dom';
import {Divider, Grid, CircularProgress, Tooltip, Paper, Button, TextField} from '@material-ui/core';
import {toast} from 'react-toastify';
import moment from 'moment';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';

function AdminSonuclar() {
    const {sinav_id} = useParams()

    const [load, setLoad] = useState(true)
    const [reload, setReload] = useState(false)
    const [hesapla, setHesapla] = useState(false)
    const [status, setStatus] = useState(200)
    const [msg, setMsg] = useState(null)
    const [addUser, setAddUser] = useState(null)
    const [sinav, setSinav] = useState({})
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    useEffect(() => {
        setLoad(true)
        instance
            .get(`/api/admin/sinav/sonuclar/${sinav_id}`)
            .then(function (result) {
                if (result.status == 210) {
                    toast.warn(result.data.msg);
                    setMsg(result.data.msg)
                    setStatus(210)
                } else {
                    setSinav(result.data)
                }
                setLoad(false)
                setReload(false)
            })
    }, [reload])
    useEffect(() => {
        const breads = [
            {
                link: '/admin/esinav/add',
                title: 'Sınavlar',
                last: null
            },
            {
                link: null,
                title: load ? <CircularProgress style={{
                    width: 8,
                    height: 8
                }}/> : `${sinav.name} / Sınav Sonuçları`,
                last: true
            },

        ];
        setBreads(breads)
        setPage(17)
        setPageTitle('E-Sınav Sistemi')
        setLoad(false)
    }, [sinav, reload])

    function yenidenHesapla() {

        if (global.confirm(`Genel sınav bilgilerini yeniden hesaplar!`)) {
            setHesapla(true)
            instance
                .get(`/api/admin/sinav/sonuc/yeniden/hesapla/${sinav.id}`)
                .then(function (result) {
                    setReload(true)
                    setHesapla(false)
                    toast.success('Hesaplama tamamlandı.');
                })
        }
    }

    function tekseferlikIzin(user_id) {
        if (global.confirm(`Öğrencinin sınava yeniden girebilmesini onaylıyor musunuz?`)) {
            instance
                .get(`/api/admin/sinav/user/izin/tekseferlik/${sinav.id}/${user_id}`)
                .then(function (result) {
                    setReload(true)
                    toast.success('Sınava giriş için ullanıcıya tek seferlik izin verildi')
                })
        }
    }

    function hesapsiz(user_id) {
        if (global.confirm(`Öğrencinin sınavı hesaplanmayacaktır,bu işlemden sonra YENİDEN HESAPLAMA kullanmalısınız!`)) {
            instance
                .get(`/api/admin/sinav/user/hesapsiz/${sinav.id}/${user_id}`)
                .then(function (result) {
                    setReload(true)
                    toast.success('Öğrenci isttatistiklerinden çıkartıldı!')
                })
        }

    }

    function addHandleAddUser(e) {
        setAddUser(e.target.value)

    }

    function addUserPost() {
        instance
            .get(`/api/admin/sinav/user/add/manuel/${sinav.id}/${addUser}`)
            .then(function () {
                toast.success('Kullanıcı başarıyla eklendi')

            })
    }

    function YenidenHesaplaButton() {
        if (hesapla) return <Button fullWidth color={"secondary"} variant={"outlined"} size={"small"}>Hesaplanıyor <CircularProgress style={{height: 16, width: 16}}/></Button>
        return <Button onClick={() => yenidenHesapla()} fullWidth color={"secondary"} variant={"outlined"} size={"small"}>Yeniden Hesapla</Button>
    }

    function sonucSil(user_id, sinav_id) {
        if (global.confirm('Öğrencinin tüm sınav bilgileri arşivlenecek ve bu listede görünmez. Onaylıyor musunuz?')) {
            instance.delete(`/api/admin/user/sinav/arsive/${user_id}/${sinav_id}`)
                .then(function (result) {
                    toast.success('Arşivleme işlemi başarılı')
                    sinav.analiz = sinav.analiz.filter(x => x.sinav_id != sinav_id && x.user_id != user_id)
                    setSinav(sinav)
                    //   setReload(!reload)
                })
        }
    }

    if (status == 210) return <>{msg}</>
    if (!sinav.statistic) return <CircularProgress/>
    const basari = sinav.statistic.net > 0 ? Math.round((sinav.statistic.net / sinav.statistic.soru_sayisi) * 100) : 0;
    return <>
        <Paper className={"paper-handler"}>
            <Grid container spacing={1} alignContent={"center"} alignItems={"center"} justify={"space-between"}>
                <Grid style={{textAlign: "left"}} container item xs={10} sm={4}>
                    <Grid style={{textAlign: "left"}} item xs={8} sm={10}>
                        <TextField
                            size={"small"}
                            fullWidth
                            variant={"outlined"}
                            name="user_id"
                            label="Kullanıcı ID"
                            onChange={addHandleAddUser}
                            value={addUser}
                            placeholder="Sınava kullanıcı ekle"
                            helperText="Sınava girmeyen kullanıcılar içindir."
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <Button variant={"outlined"} color={"primary"} onClick={() => addUserPost()}>EKLE</Button>
                    </Grid>
                </Grid>

                <Grid style={{textAlign: "right"}} item xs={12} sm={2}>
                    <YenidenHesaplaButton/>
                </Grid>
            </Grid>
        </Paper>
        <Paper className={"paper-handler"}>
            <Grid container spacing={1} alignContent={"center"} alignItems={"center"}>
                <Grid item xs={6} sm={2}><b>Sınav Adı</b></Grid>
                <Grid className={"sonucGrid"} item xs={6} sm={10}>{sinav.name}</Grid>
                <Grid item xs={12} sm={12}><Divider/></Grid>

                <Grid item xs={6} sm={2}><b>Soru Sayısı</b></Grid>
                <Grid className={"sonucGrid"} item xs={6} sm={10}>{sinav.statistic.soru_sayisi}</Grid>
                <Grid item xs={12} sm={12}><Divider/></Grid>
                <Grid item xs={6} sm={2}><b>S. Tammlyn Kişi</b></Grid>
                <Grid className={"sonucGrid"} item xs={6} sm={10}>{sinav.statistic.sinavi_tamamlayan_sayi}</Grid>
                <Grid item xs={12} sm={12}><Divider/></Grid>
                <Grid item xs={6} sm={2}><b>Bitirmeyenler</b></Grid>
                <Grid className={"sonucGrid"} item xs={6} sm={10}>
                    <Link style={{cursor: "pointer"}} to={`/admin/esinav/bitirmeyenler/${sinav.id}`}>
                        <Button variant={"outlined"} size={"small"} color={"secondary"}>İNCELE</Button>
                    </Link>
                </Grid>
            </Grid>
        </Paper>
        <Paper className={"paper-handler"}>
            <Grid className={"martop-15 "} container spacing={1} alignContent={"center"} alignItems={"center"}>
                <Grid className={"sonucGrid"} item xs={2} sm={2}><b>TOP</b></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}><b>NET</b></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}><b>DOĞ</b></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}><b>YAN</b></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}><b>BOŞ</b></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}><b>B.ORN</b></Grid>
                <Grid item xs={12} sm={12}><Divider/></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}>{sinav.statistic.soru_sayisi}</Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}>{sinav.statistic.net}</Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}>{sinav.statistic.dogru}</Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}>{sinav.statistic.yanlis}</Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}>{sinav.statistic.bos}</Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}>%{basari}</Grid>
            </Grid>
        </Paper>
        <Paper className={"paper-handler"}>
            <Grid className={"largeHead"} container alignContent={"center"} alignItems={"center"}>
                <Grid className={"sonucGrid"} item xs={2} sm={1}><Tooltip title="GENEL SIRA"><strong>S.NO</strong></Tooltip></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={1}><Tooltip title="BIRANŞ SIRASI"><strong>BS.NO</strong></Tooltip></Grid>
                <Grid className={"sonucGrid"} item xs={6} sm={2}><Tooltip title="KİŞİ"><strong>ADI SOYADI</strong></Tooltip></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={1}><Tooltip title="NET"><strong>NET</strong></Tooltip></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={1}><Tooltip title="DOĞRU "><strong>D</strong></Tooltip></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={1}><Tooltip title="YANLIŞ"><strong>Y</strong></Tooltip></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={1}><Tooltip title="BOŞ"><strong>B</strong></Tooltip></Grid>
                <Grid className={"sonucGrid"} item xs={8} sm={2}><Tooltip title="SINAVI BİTİRME ZAMANI"><strong>Bitirme Zamanı</strong></Tooltip></Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={2}>
                    <Tooltip title="TEK SEFERLİK SÜRESİZ İZİN VER"><strong>TEK</strong></Tooltip>
                    /
                    <Tooltip title="HESAPSIZ; SINAV İSTATİSTİKLERİNE ve SIRALAMAYA DAHİL EDİLMEZ"><strong>HSİZ</strong></Tooltip>
                    /
                    <Tooltip title="ÖĞRENCİNİN TÜM SINAV BİLGİLERİ ARŞİVLENİR"><strong>ARŞV</strong></Tooltip>
                </Grid>
                <Grid className={"sonucGrid"} item xs={2} sm={1}></Grid>
            </Grid>
            {sinav.analiz.map((item, index) => {
                if (!item.user) {
                    return;
                }
                const status = sinav.users_status.filter(x => x.user_id == item.user_id)[0];
                return <Grid className={'sonuc-satir'} style={index % 2 == 0 ? {backgroundColor: "#f1f1f6"} : {backgroundColor: "#fff"}} key={`sinav_admin_sonuc_${item.user_id}`} item xs={12} sm={12}>
                    <Grid container alignContent={"center"} alignItems={"center"}>
                        <Grid className={"sonucGrid"} item xs={2} sm={1}>{item.genel_sira}</Grid>
                        <Grid className={"sonucGrid"} item xs={2} sm={1}>{item.brans_sira}</Grid>
                        <Grid className={"sonucGrid"} item xs={6} sm={2}>
                            <b>{item.user.name}</b>
                            <div style={{fontSize: 9}}>{item.brans ? item.brans.name : null}</div>
                            <Divider/>
                        </Grid>
                        <Grid className={"sonucGrid"} item xs={2} sm={1}>{item.net_sayisi}</Grid>
                        <Grid className={"sonucGrid"} item xs={2} sm={1}>{item.dogru_cevap_sayisi}</Grid>
                        <Grid className={"sonucGrid"} item xs={2} sm={1}>{item.yanlis_sayisi}</Grid>
                        <Grid className={"sonucGrid"} item xs={2} sm={1}>{item.bos_sayisi}</Grid>
                        <Grid className={"sonucGrid"} item xs={8} sm={2}>{moment(item.user_tamamlama_zamani)
                            .format('DD/MM/YYYY HH:mm')}</Grid>
                        <Grid className={"sonucGrid"} item xs={2} sm={2}>
                            <Tooltip title="Tek seferlik izin ver">
                                <AvTimerIcon style={status && status.tek == 1 ? {color: "red"} : {color: "none"}} className={"acr-icon"} onClick={() => tekseferlikIzin(item.user.id)}/>
                            </Tooltip>
                            /
                            <Tooltip title="HESAPSIZ">
                                <ExitToAppIcon style={status && status.hesapsiz == 1 ? {color: "red"} : {color: "none"}} className={"acr-icon"} onClick={() => hesapsiz(item.user.id)}/>
                            </Tooltip>
                            /
                            <Tooltip title="ARŞİVLE">
                                <DeleteSweepIcon className={"acr-icon"} onClick={() => sonucSil(item.user.id, item.sinav_id)}/>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}> <Divider/></Grid>
                </Grid>
            })}
        </Paper>
    </>;
}

export default AdminSonuclar;
