import instance from '../apis';
import {toast} from "react-toastify";

export function loadingSet(status) {
    return (dispatch) => {
        if (status == true){
            dispatch({ type: "LOADING_TRUE"})
        }else{
            dispatch({ type: "LOADING_FALSE"})
        }
    }  
};

export function setUserList(users) {
    return (dispatch) => {
        dispatch({ type: "CLASSROOM_ADD_SET_USER_LIST", payload : {users} })
    }  
};

export function setSearch(text) {
    return (dispatch, getState) => {
        var user_find = getState().classroomAdd.users.filter(item => item.name && item.name.toLowerCase().includes(text.toLowerCase()));
        dispatch({ type: "CLASSROOM_ADD_SET_SEARCH", payload : {user_find, text}})
    }  
};

export function searchCancel() {
    return (dispatch, getState) => {
        dispatch({ type: "CLASSROOM_ADD_SEARCH_CANCEL"})
    }  
};

export function setSelectedSearch(text) {
    return (dispatch, getState) => {
        var selected_user_find = getState().classroomAdd.selected_users.filter(item => item.name && item.name.toLowerCase().includes(text.toLowerCase()));
        dispatch({ type: "CLASSROOM_ADD_SET_SELECTED_SEARCH", payload : {selected_user_find, text}})
    }  
};

export function selectedSearchCancel() {
    return (dispatch, getState) => {
        dispatch({ type: "CLASSROOM_ADD_SELECTED_SEARCH_CANCEL"})
    }  
};


export function setName(value) {
    return (dispatch) => {
        dispatch({ type: "CLASSROOM_ADD_SET_NAME", payload : {value} })
    }  
};
export function setSubeKod(value) {
    return (dispatch) => {
        dispatch({ type: "CLASSROOM_ADD_SET_SUBE_KOD", payload : {value} })
    }  
};

export function setCheckUser(item) {
    return (dispatch, getState) => {
        var index = getState().classroomAdd.users.findIndex(x => x.id == item.id)
        var transfer_user = getState().classroomAdd.users.find(x => x.id == item.id)
        var new_users = getState().classroomAdd.users;
        new_users.splice(index, 1);

        var selected_users = getState().classroomAdd.selected_users;
        selected_users.push(transfer_user);

        dispatch({ type: "CLASSROOM_ADD_CHECK_USER", payload : {new_users, selected_users} })
        // seçilenlere atalım  
    }  
};
export function setUnCheckUser(item) {
    return (dispatch, getState) => {
        var index = getState().classroomAdd.selected_users.findIndex(x => x.id == item.id)
        var transfer_user = getState().classroomAdd.selected_users.find(x => x.id == item.id)
        var selected_users = getState().classroomAdd.selected_users;
        selected_users.splice(index, 1);

        var users = getState().classroomAdd.users;
        users.push(transfer_user);

        dispatch({ type: "CLASSROOM_ADD_UNCHECK_USER", payload : {selected_users, users} })
    }  
};

export function setEditData(data, edit_id) {
    return (dispatch) => {
        dispatch({ type: "CLASSROOM_ADD_SET_EDIT_DATA", payload : {users: data.user_list, selected_users: data.selected_users, edit_id: edit_id, ad: data.ad, sube_kod:data.sube_kod} })
    }  
};


const initialState = {
    
    edit_id : null, 

    users: [],
    user_find : [],
    search: '',

    selected_users : [],
    selected_user_find : [],
    selected_search: '',
    
    ad: '',
    sube_kod : '',
    loading: true,
};
  
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'CLASSROOM_ADD_SET_USER_LIST':
            return {...state, users: action.payload.users, user_find : action.payload.users, loading:false}
        case 'CLASSROOM_ADD_SET_SEARCH':
            return {...state, user_find: action.payload.user_find, search: action.payload.text}
        case 'CLASSROOM_ADD_SET_SELECTED_SEARCH':
            return {...state, selected_user_find: action.payload.selected_user_find, selected_search: action.payload.text}
        case 'CLASSROOM_ADD_SELECTED_SEARCH_CANCEL':
            return {...state, text: '', selected_user_find:[], selected_search:''}
        case 'CLASSROOM_ADD_SEARCH_CANCEL':
            return {...state, text: '', user_find:[], search:''}
        case 'CLASSROOM_ADD_SET_NAME':
            return {...state, ad: action.payload.value}
        case 'CLASSROOM_ADD_SET_SUBE_KOD':
            return {...state, sube_kod: action.payload.value}
        case 'CLASSROOM_ADD_CHECK_USER':
            return {...state, users: action.payload.new_users, user_find:action.payload.new_users, selected_user: action.payload.selected_users, selected_user_find: action.payload.selected_users}
        case 'CLASSROOM_ADD_UNCHECK_USER':
            return {...state, users: action.payload.users, user_find:action.payload.users, selected_user: action.payload.selected_users, selected_user_find: action.payload.selected_users}
        case 'CLASSROOM_ADD_SET_EDIT_DATA':
            return {...state, users: action.payload.users, selected_users : action.payload.selected_users, edit_id: action.payload.edit_id, ad: action.payload.ad, sube_kod: action.payload.sube_kod, loading:false}
        case 'LOADING_FALSE':
            return {...state, loading:false}
        case 'LOADING_TRUE':
            return {...state, loading:true}
        default:
            return state
    }

}
  
