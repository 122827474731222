import React, {useState, createContext} from 'react';

export const UserContext = createContext([]);
export const InputContext = createContext([]);
export const InitialsContext = createContext([]);
export const ConfigContext = createContext([]);
export const LogoContext = createContext([]);
export const NoticeContext = createContext([]);
export const OnlineUserContext = createContext([]);
export const Store = props => {
    const [user, setUser] = useState({})
    const [initials, setInitials] = useState({})
    const [config, setConfig] = useState({})
    const [logo, setLogo] = useState({})
    const [notice, setNotice] = useState({})
    const [onlineUser, setOnlineUser] = useState(0)
    const [inputs, setInputs] = useState({
        search_type: 61,
        variable_type: 61
    })

    return (
        <UserContext.Provider value={[user, setUser]}>
            <InitialsContext.Provider value={[initials, setInitials]}>
                <ConfigContext.Provider value={[config, setConfig]}>
                    <InputContext.Provider value={[inputs, setInputs]}>
                        <LogoContext.Provider value={[logo, setLogo]}>
                            <NoticeContext.Provider value={[notice, setNotice]}>
                                <OnlineUserContext.Provider value={[onlineUser, setOnlineUser]}>
                                    {props.children}
                                </OnlineUserContext.Provider>
                            </NoticeContext.Provider>
                        </LogoContext.Provider>
                    </InputContext.Provider>
                </ConfigContext.Provider>
            </InitialsContext.Provider>
        </UserContext.Provider>
    );
}

