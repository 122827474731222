import React from 'react';
import Videos from "./video/Videos";
import Edit from "./video/Edit";
import Upload from "./video/Upload";
import AddVimeoVideo from "./AddVimeoVideo";
import Category from "./category/Category";
import Config from "./Config";
import Banks from "./Banks";
import UserSearch from "./UserSearch";
import UserCard from "./UserCard";
import {Switch, Route} from 'react-router-dom';
import ESinavRoutes from "./esinav/eSinavRoutes";
import UploadsAdmin from "./uploads/UploadsAdmin";
import BBBCreateComponent from "./bbb/Create/index";
import BBBListComponent from "./bbb/List/index";
import BBBFileUpload from './bbb/FileUpload';
import BBBRecordListComponent from './bbb/Record/';

import MontlyUpload from "./montlyFiles/MontlyUpload";
import ContactConfig from "./contact/ContactConfig";
import ContactMasseges from "./contact/ContactMessages";
import ContactFormMessages from "./contact/ContactFormMessages";
import SalesConfig from "./sales/SalesConfig";
import Egitmenler from "./egitmen/Egitmenler";
import Egitmen from "./egitmen/Egitmen";

import ClassroomAdd from './classroom/add/index';
import ClassroomList from './classroom/list/index';
import ClassroomOgrenciList from './classroom/ogrenciList';
import Categories from "./category/Categories";
import index from "./sales/kreditCard";


function AdminRoute() {
    let roles = '';
    try {
        roles = localStorage.getItem('roles')
    } catch (e) {

    }
    if (roles) {
        if (roles.includes('admin')) {
            return (
                <Switch>
                    <Route path={'/admin/cat/videos/:category_id'} component={Videos}/>
                    <Route path={'/admin/videos'} component={Videos}/>
                    <Route path={'/admin/video/edit/:file_id'} component={Edit}/>
                    <Route path={'/admin/video/upload/:id'} component={Upload}/>
                    <Route path={'/admin/video/vupload'} component={AddVimeoVideo}/>
                    <Route path={'/admin/categories'} component={Categories}/>
                    <Route path={'/admin/category/new/:id'} component={Category}/>
                    <Route path={'/admin/config'} component={Config}/>
                    <Route path={'/admin/contact/config'} component={ContactConfig}/>
                    <Route path={'/admin/contact/messages/:room_id'} component={ContactMasseges}/>
                    <Route path={'/admin/contact/messages'} component={ContactMasseges}/>
                    <Route path={'/admin/contact/form/messages'} component={ContactFormMessages}/>
                    <Route path={'/admin/banks'} component={Banks}/>
                    <Route path={'/admin/user/search'} component={UserSearch}/>
                    <Route path={'/admin/get/user/card/:id'} component={UserCard}/>
                    <Route path={'/admin/esinav'} component={ESinavRoutes}/>
                    <Route path={'/admin/uploads'} component={UploadsAdmin}/>
                    <Route path={'/admin/montly/files'} component={MontlyUpload}/>
                    <Route path={'/admin/egitmenler/:id'} component={Egitmen}/>
                    <Route path={'/admin/egitmenler'} component={Egitmenler}/>
                    <Route path={'/admin/sales/config'} component={SalesConfig}/>
                    {/*bbbb*/}
                    <Route path={'/admin/bbb/room/create/:room_id'} component={BBBCreateComponent}/>
                    <Route path={'/admin/bbb/room/create'} component={BBBCreateComponent}/>
                    <Route path={'/admin/bbb/room/list'} component={BBBListComponent}/>
                    <Route path={'/admin/bbb/record/list'} component={BBBRecordListComponent}/>
                    {/*<Route path={'/admin/bbb/room/edit/:id'} component={BBBEditComponent}/>*/}
                    <Route path={'/admin/bbb/room/file/upload/:id/:transferKey'} component={BBBFileUpload}/>
                    <Route path={'/admin/bbb/room/file/upload/:id'} component={BBBFileUpload}/>
                    {/* classroom */}
                    <Route path={'/admin/classroom/add/:id'} component={ClassroomAdd}/>
                    <Route path={'/admin/classroom/add'} component={ClassroomAdd}/>
                    <Route path={'/admin/classroom/list'} component={ClassroomList}/>
                    <Route path={'/admin/classroom/ogr/list/:id'} component={ClassroomOgrenciList}/>
                    <Route path={'/admin/kreditcard/config'} component={index}/>
                </Switch>
            )
        } else {
            return <></>;
        }
    } else {
        return <></>;
    }
}

export default AdminRoute;
