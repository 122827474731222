import React from 'react';
import {Switch, Route} from 'react-router-dom';
import RoomList from "./RoomList";


function BBBRoutes() {
    return (
        <Switch>
            <Route path={'/canli/room/list'} component={RoomList}/>
        </Switch>
    )
}

export default BBBRoutes;
