import React, {useContext, useEffect} from 'react';
import {BreadsContext, PageContext, PageTitleContext} from "../../stores/PageStore";
import {Grid, Paper} from '@material-ui/core';
import {InitialsContext} from "../../Store";
import bakim from '../../images/bakim.webp';
import setInterval from '@use-it/interval';

const Bakimdayiz = () => {
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    const [initials] = useContext(InitialsContext)
    useEffect(() => {
        const breads = [
            {
                link: '',
                title: 'Bakımdayız',
                last: true
            },
        ];
        setBreads(breads)
        setPage(20.1)
        setPageTitle('Yeni Sınıf Ekle')

        // instance
        //     .get('/api/admin/bbb/room/list')
        //     .then(function (res) {
        //         setLoadContex(false)
        //     })
    }, [])
    return <>
        <Paper className="paper-handler" style={{position: 'relative', color: "#27496d"}}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <img width="100%" src={bakim}/>
                </Grid>
                <Grid item xs={12} sm={12}></Grid>
            </Grid>
            <Grid container spacing={2} className="bakimText">
                <Grid item xs={12} sm={12}>
                    <h1>Planlı bakım çalışması</h1>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <h3>Tahmini erişim zamanı : <span style={{color: "#b80d57"}}>{initials.sac.bakim_end}</span></h3>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <h4>Anlayışınız için teşekkür ederiz</h4>
                </Grid>
            </Grid>
        </Paper>
    </>

}

export default Bakimdayiz;
