import React, {useContext, useEffect, useState} from 'react';
import instance from "../../../apis";
import {Grid, Paper, Divider, IconButton} from "@material-ui/core";
import {Link} from "react-router-dom"
import {BreadsContext, PageContext, PageTitleContext} from "../../../stores/PageStore";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit"
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ArchiveIcon from '@material-ui/icons/Archive';
import billingCalculate from "../../../functions/billingCalculate";
import AddCircleIcon from '@material-ui/icons/AddCircle';

const Egitmenler = () => {
    const [datas, setDatas] = useState([])
    const [, setBreads] = useContext(BreadsContext);
    const [, setPageTitle] = useContext(PageTitleContext);
    const [, setPage] = useContext(PageContext);
    useEffect(() => {
        instance
            .get('/api/admin/egitmenler')
            .then(function (res) {
                setDatas(res.data.items)
            })
    }, [])
    useEffect(() => {
        const breads = [
            {
                link: null,
                title: 'Eğitmenler',
                last: true
            }
        ]
        setBreads(breads)
        setPageTitle('Eğitmenler')
        setPage(62)
    }, [])

    function siteBand(data) {
        if (!data.band)
            return 0;
        let size = data.band.size / 1073741824
        size = size.toFixed(2)
        return `${size} GB`
    }


    return <>
        <Grid container>
            <Grid item xs={12} sm={12} style={{textAlign: "right"}}>
                <Link to={`/admin/egitmenler/0`}>
                    <IconButton color={"primary"}><AddCircleIcon style={{fontSize: 32}}/></IconButton>
                </Link>
            </Grid>
        </Grid>
        <Grid container spacing={1} container alignItems={"center"} alignContent={"center"}>
            <Grid item xs={12} sm={12}>
                <Paper className="paper-handler">
                    <Grid container spacing={1} className={"largeHead"} style={{fontWeight: "bold", fontSize: 13}}>
                        <Grid item xs={12} sm={2}>
                            Adı Soyadı
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            e-posta/Telefon
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            Kayıt Tarihi
                        </Grid>
                        <Grid item xs={8} sm={3} style={{textAlign: "center"}}>
                            İşlemler
                        </Grid>
                    </Grid>
                    {datas.map(data => <Grid container spacing={1} alignItems={"center"} alignContent={"center"}>
                        <Grid item xs={12} sm={2}>
                            {data.name}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            {data.email}<br/>{data.tel}
                        </Grid>
                        <Grid item xs={4} sm={2}>{moment(data.created_at).format('DD.MM.YYYY')}</Grid>
                        <Grid item xs={8} sm={3}>
                            <Grid container container spacing={1} alignItems={"center"} alignContent={"center"}>
                                <Grid item xs={4} sm={4}>
                                    <Link
                                        to={`/admin/egitmenler/${data.id}`}
                                    >
                                        <IconButton><EditIcon/></IconButton>
                                    </Link>
                                </Grid>
                                <Grid item xs={4} sm={4}><DeleteForeverIcon/></Grid>
                                <Grid item xs={4} sm={4}><ArchiveIcon/></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}><Divider/></Grid>
                    </Grid>)}
                </Paper>
            </Grid>
        </Grid>
    </>
}

export default Egitmenler;
