import React, {useState} from 'react';
import {Container, Box, Divider, Grid, TextField, Paper, Button, IconButton, ButtonGroup} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import colors from '../../../../styles/colors';
import ConfirmDialog from '../../../../components/ConfirmDialog'
import instance from '../../../../apis';
import {toast} from "react-toastify";


const Row = (props) => {
   const [open, setOpen ] = useState(false);
   const [deleteData, setDeleteID ] = useState({});
   const handleClose = (answer) => {
        setOpen(false)
        if (answer == 1){
            const data = {
                id : deleteData.id,
            }
            instance.post('/api/admin/classroom/delete', data)
                .then(function (res) {
                    toast.success('Sınıf silindi.');
                    props.deleteItem(deleteData)
                })

        }
    }
    return <Grid container spacing={1} alignItems={"center"} alignContent={"center"}>
                <ConfirmDialog title={"Uyarı"} msg={"Silmek istediğinizden emin misiniz?"} handleClose = {handleClose} open={open}/>
                <Grid item xs={12} sm={5}>
                    {props.data.ad}
                </Grid>
                <Grid item xs={12} sm={2}>
                    {props.data.sube_kod}
                </Grid>
                <Grid item xs={12} sm={2}>
                    {props.data.count}
                </Grid>
                <Grid item xs={12} sm={3}>
                    <ButtonGroup color="primary" aria-label="outlined primary button group" size="small">
                        <Button
                            variant="contained"
                            style={{backgroundColor: colors.grey2, color: colors.text}}
                            onClick={() => {
                                setDeleteID({index:props.index, id:props.data.id});
                                setOpen(true);
                            }}
                        >
                            <span style={{fontWeight:'bold'}}>Sil</span>
                        </Button>
                        <Button
                            variant="contained"
                            style={{backgroundColor: colors.grey1, color: colors.text}}
                            onClick={() => {
                                props.props.history.push('/admin/classroom/add/' + props.data.id);
                            }}
                        >
                            <span style={{fontWeight:'bold'}}>Düzenle</span>
                        </Button>
                        {/* <Button
                            onClick={() => {
                                props.props.history.push('/admin/classroom/ogr/list/' + props.data.id);
                                
                            }}
                            variant="contained"
                            style={{backgroundColor: colors.grey0, color: colors.white}}
                        >
                            <span style={{fontWeight:'bold'}}>Öğrenci Listesi</span>
                        </Button> */}
                    </ButtonGroup>
                </Grid>
                <Grid item xs={12} sm={12}><Divider/></Grid>
            </Grid>
}


export default Row;
