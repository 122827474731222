import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {BasketContext} from "../../stores/Basket";
import instance from "../../apis";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Divider, Input} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {StepContext} from "./StepStore";
import DeleteIcon from '@material-ui/icons/Delete';
import {Segment} from 'semantic-ui-react';

const useStyles = makeStyles(theme => ({
    listItem: {
        padding: theme.spacing(1, 0),
    },
    total: {
        fontWeight: '700',
    },
    title: {
        marginTop: theme.spacing(2),
    },
    icon: {
        cursor: "pointer",
        '&:hover': {
            color: "red"
        }
    },
    price: {
        textAlign: "right"
    }

}));

export default function Review() {
    const [basket, setBasket] = useContext(BasketContext)
    const [getQtys, setGetQtys] = useState()
    const [activeStep, setActiveStep] = useContext(StepContext);
    const nextStep = () => {
        setActiveStep(activeStep + 1);
    }
    useEffect(() => {
        setActiveStep(0)
        instance
            .get('/api/baskets')
            .then(function (res) {
                basketUpdate(res)
            })
    }, [])

    function basketUpdate(res) {
        const getQtys = [];
        if (res.data) {
            if (res.data.products.length > 0) {
                setBasket(res.data)
                res.data.products.map((item) => {
                    getQtys.push(item.qty)
                })
            }
        }

        setGetQtys(getQtys)
    }

    const classes = useStyles();
    const Context = () => {
        const [qtys] = useState(getQtys)
        if (!basket)
            return <div className={classes.root}>{"Henüz sepetinize ürün eklemediniz."}</div>
        if (basket.products.length == 0) {
            return <>
                <div className={classes.root}>{"Henüz sepetinize ürün eklemediniz."}</div>
            </>
        }

        const qtyHandle = (e) => {
            const {id, value} = e.target
            instance
                .post(`/api/basket/qty/update/${basket.id}/${id}/${value}`)
                .then(function (res) {
                    basketUpdate(res)
                })

            //setQtys({...qtys, [name]: value})
        }
        if (!qtys) {
            return <CircularProgress/>
        }

        function productDelete(sepet_id, product_id) {
            instance
                .delete(`/api/sepet/product/${sepet_id}/${product_id}`)
                .then(function (res) {
                    if (res.status == 200) {
                        setBasket(res.data)
                    }
                })
        }

        function ItemNameView({item}) {

            if (item.bbb === 1)
                return <>{item.name} <span style={{color: "#e84a5f"}}>(Canlı Ders)</span></>
            return <>{item.name} <span style={{color: "#c1c0b9"}}>(Kayıttan Ders)</span></>

        }

        return <>
            <Segment>
                <Grid container alignContent={"center"} alignItems={"center"} spacing={2}>
                    <Grid xs={12} sm={1} item></Grid>
                    <Grid xs={12} sm={4} item><b>İSİM</b></Grid>
                    <Grid xs={4} sm={2} item><b>ADET</b></Grid>
                    <Grid style={{textAlign: "right"}} xs={4} sm={2} item><b>FİYAT</b></Grid>
                    <Grid style={{textAlign: "right"}} xs={4} sm={2} item><b>TOPLAM</b></Grid>
                    <Grid xs={2} sm={1} item></Grid>
                    <Grid item xs={12} sm={12}>
                        <Divider/>
                    </Grid>
                </Grid>
                {basket.products.map((item, index) =>
                    <Grid key={item.id} container spacing={2} alignContent={"center"} alignItems={"center"}>
                        <Grid xs={12} sm={1} item>{index + 1}</Grid>
                        <Grid xs={12} sm={4} item><ItemNameView item={item}/></Grid>
                        <Grid xs={4} sm={2} item>
                            <Input id={item.id} type="number" onChange={qtyHandle} defaultValue={qtys[index]}/>
                        </Grid>
                        <Grid className={classes.price} xs={3} sm={2} item>{item.unit_price}</Grid>
                        <Grid className={classes.price} xs={3} sm={2} item>{(item.unit_price * qtys[index]).toFixed(2)}</Grid>
                        <Grid className={classes.price} xs={2} sm={1} item><DeleteIcon className={classes.icon} onClick={() => productDelete(basket.id, item.id)}/></Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider/><br/>
                        </Grid>
                    </Grid>
                )}
            </Segment>
            <Grid container spacing={1} alignContent={"center"} alignItems={"center"}>
                <Grid xs={12} sm={7}></Grid>
                <Grid item xs={12} sm={5}>
                    <Segment>
                        <Grid container spacing={2} className={classes.total}>
                            <Grid xs={4} sm={6} item><b>FİYAT</b></Grid>
                            <Grid xs={4} sm={4} item></Grid>
                            <Grid className={classes.price} xs={4} sm={2} item>{basket.unit_price}</Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider/>
                            </Grid>
                            <Grid xs={4} sm={6} item><b>KDV</b></Grid>
                            <Grid xs={4} sm={4} style={{textAlign: "center"}} item>%18</Grid>
                            <Grid className={classes.price} xs={4} sm={2} item>{basket.kdv}</Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider/>
                            </Grid>
                            <Grid xs={4} sm={6} item><b>TOPLAM</b></Grid>
                            <Grid xs={4} sm={4} item></Grid>
                            <Grid className={classes.price} xs={4} sm={2} item>{basket.dis_price}</Grid>
                        </Grid>
                    </Segment>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Button
                        style={{float: "right"}}
                        variant="contained"
                        color="primary"
                        onClick={nextStep}
                    >
                        DEVAM ET
                    </Button>
                </Grid>
            </Grid>


        </>
    }
    return (
        <Context/>
    );
}
